.vs-log-cinematic-section-connection {
    &__divider {
        margin-top: inherit;
        margin-right: 0.5rem;
    }

    & &__no-margin-top {
        margin-top: 0;
    }

    & &__no-padding-bottom {
        padding-bottom: 0;
    }
}
