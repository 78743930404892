.async-file-upload {
    &__file-upload-container {
        display: flex;
    }

    &__file-upload {
        flex: 1;
    }

    &__upload-button {
        box-sizing: border-box;
        margin-left: 5px;
    }

    &__progress {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        margin-top: 10px;
    }

    &__progress-display-value {
        flex: 0 0 auto;
        margin-left: 20px;
    }

    &__error {
        margin-bottom: 5px;
    }
}
