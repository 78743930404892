@import (reference) '~ajs/less/themes/default-theme/theme.less';
@import (reference) '~ajs/less/components/performance-chart.less';
@import (reference) '~ajs/less/layout/flex.less';

vs-security-ddos-top-lists {
    display: block;
    margin: 10px 0 15px 0;

    .ddos-table {
        width: inherit;
        text-align: initial;
        .small-shadow-bottom;

        .table-header {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: flex-start;
            height: @performanceChartHeaderHeight;
            padding: 10px 0;
            border-bottom: 1px solid @borderGray;
            background-color: @aviWhite;

            .attacks {
                flex: 0 0 40%;
            }

            .subtable-header {
                flex: 0 0 60%;
                .space-between;
            }

            .header {
                box-sizing: border-box;
                padding: 0 20px;

                a {
                    text-decoration: none;

                    &.active > h2 {
                        color: @aviBrandColor;
                    }

                    &.sub-active {
                        font-weight: 500;
                    }
                }
            }
        }

        .table-container {
            display: flex;
            box-sizing: border-box;

            .attacks {
                box-sizing: border-box;
                flex: 0 0 40%;
                height: 300px;
                padding: 10px 5px 5px 5px;
                border-right: 1px solid @borderGray;
                background-color: @aviBackgroundColor;

                &.active {
                    background-color: white;
                }
            }

            .subtable {
                box-sizing: border-box;
                flex: 0 0 60%;
                height: 300px;
                padding: 10px 5px 5px 5px;
                background-color: @aviBackgroundColor;

                &.active {
                    background-color: white;
                }
            }
        }

        .body-table-wrapper {
            max-height: 230px;
            overflow-y: auto;
        }
    }

    .ddos-grid {
        tr.header-table-row {
            th.grid-field-ip {
                width: 130px;
            }

            th.grid-field-rate {
                width: 100px;
            }

            th.grid-field-duration {
                width: 120px;
            }

            th.header-table-cell.rowactions {
                width: 60px;
            }
        }
    }
}
