.avi-dropdown-selected-values-container {
    &__multiple-selected-value-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__multiple-selected-value {
        margin: 2px;
    }

    &__selected-value-prepend {
        margin-right: 0.25rem;
        color: #454545;
        font-size: 0.65rem;
        font-weight: 600;
    }
}
