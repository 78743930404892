@import (reference) '~ajs/less/fonts/avi';

div.avi-main.events {
    .log-results {
        top: 165px; // hack for now
    }

    .reload-link {
        padding: 5px;
    }
}

collection-grid.events > div.grid > table {
    tr {
        &.row {
            height: 30px;

            //replaces plus with minus on details opening
            &.expanded > td.rowactions > span > a > i[class='icon-plus']:before {
                &:extend(.icon-minus:before);
            }

        }

        td, th {
            text-align: left;

            &.grid-field-report_timestamp, &.grid-cell-report_timestamp {
                width: 10em;
                padding-left: .5em;
                white-space: nowrap;
            }

            &.grid-field-config-module, &.grid-cell-config-module {
                width: 5em;
            }

            &.grid-field-data-config-obj_name, &.grid-cell-config-obj_name {
                width: 11em;
            }

            &.grid-field-obj_type, &.grid-cell-obj_type {
                width: 8em;
                text-transform: capitalize;
            }

            &.grid-field-data-config-event_id, &.grid-cell-config-event_id {
                width: 17em;
            }
            //&.grid-field-config-event_description, &.grid-cell-config-event_description {}
        }
    }
}

event-details, .event-details {
    display: block;
    margin: 0 auto;
    text-align: left;

    &--label {
        font-weight: bold;
        .hide-text-overflow();
    }

    &--description {
        font-weight: 500;
    }

    &__crs-data-link {
        grid-column-start: 3;
    }

    .summary {
        margin: .5em 0;

        span.key {
            font-weight: bold;
        }
    }

    ul {
        ul {
            border-left: 1px solid @aviGrey2;

            li {
                padding-left: 1em;
            }
        }

        li {
            margin: 0;
            padding: .2em 0;
            font-weight: normal;

            &.groupName {
                font-weight: bold;
            }

            & > span:first-child {
                font-weight: bold;
            }
        }
    }
}

.event-list-user-column {
    &--not-available {
        color: @textLiteGray;
    }
}
