/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import 'avi-form-label-tooltip/avi-form-label-tooltip';

.avi-form-label {
    &__help-icon {
        display: inline-block;
        color: @aviGrey2;
        cursor: pointer;

        &--active,
        &:hover {
            color: @aviBlue0;
        }
    }

    &__tooltip {
        &.aviPopover,
        &.aviPopoverCarat {
            z-index: @deepestModalZIndex;
        }

        &.aviPopover {
            max-width: 250px;
            padding: 5px 0;
            border: 1px solid @aviGreySpecial;
        }
    }
}
