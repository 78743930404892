@import '~ajs/less/themes/default-theme/clr-variables.less';

.avi-diff-grid {
    box-sizing: border-box;

    .avi-diff-grid-clarity-overrides();

    // make each column take up half of the width, excluding the expander icon
    // setting header would be enough for both the header and the cell, according to Clarity doc
    &__column-header {
        width: calc((100% - 40px) / 2);
    }

    &__content {
        display: flex;
        color: var(--cds-global-typography-color-500);
        font-size: var(--cds-global-typography-font-size-4);

        .datagrid-cell {
            align-self: center;
        }
    }

    &__diff-column {
        display: flex;
    }

    &__cell {
        flex: 1;
        min-height: 1.8rem;
        padding: 8px 12px;
        line-height: 1rem;
    }

    &__left-cell {
        &--new {
            background-color: var(--cds-global-color-cool-gray-100);
        }

        &--edit {
            background-color: var(--cds-global-color-yellow-100);
        }

        &--deleted {
            background-color: var(--cds-global-color-tangerine-200);
        }
    }

    &__right-cell {
        &--new {
            background-color: var(--cds-global-color-green-50);
        }

        &--edit {
            background-color: var(--cds-global-color-yellow-100);
        }

        &--deleted {
            background-color: var(--cds-global-color-cool-gray-100);
        }
    }

    &__icon {
        --color: var(--cds-global-color-blue-700);
        cursor: pointer;
    }
}

.avi-diff-grid-clarity-overrides() {
    // override Clarity data grid style to hide the placeholder image
    // it's not configurable (01/11/2021) according to: https://github.com/vmware/clarity/issues/580
    clr-datagrid .datagrid-placeholder-image {
        display: none;
    }

    clr-datagrid .datagrid {
        flex: revert;

        // override Clarity data grid cell styles to cancel the padding, since two diffs need to be
        // sitting next to each other (either left to right or top to bottom)
        .datagrid-cell {
            padding: 0;
        }

        // override Clarity data grid column styles to reduce column header font weight
        .datagrid-column {
            font-weight: var(--cds-global-typography-font-weight-medium);
        }
    }

    // override Clarity styles to avoid unexpected large z-index
    clr-datagrid {
        & .datagrid-row-sticky,
        & .datagrid-row .datagrid-row-sticky,
        & .datagrid-header {
            z-index: auto;
        }
    }

    // override Clarity styles so that the grid height would shrink as the number of items decreases
    // values from Clarity are - display: flex; flex-flow: column nowrap;
    clr-datagrid.datagrid-host {
        display: initial;
    }

    .datagrid-row {
        border: none;
    }
}
