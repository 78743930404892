@import (reference) '~ajs/less/themes/default-theme/theme.less';
@import (reference) '~ajs/less/layout/layout.less';

.avi-create-pool {
    textarea {
        resize: vertical;
    }

    .table tbody tr td.text-center {
        padding: 5px;
        text-align: center;
    }

    div.warning.select-cloud {
        margin: 1em;
    }

    .avi-create-pool-actions {
        margin-bottom: 5px;
    }

    .control-group {
        input.sel-lb-algorithm-consistent-hash-hdr {
            width: 90%;
            float: right;
        }

        .da-redirect-url {
            width: 100%;
        }

        .select-server {
            margin-right: 10px;
        }

        &.sub-group {
            width: 90%;
            float: right;
        }

        .inner-text {
            width: 100%;
        }

        input[type=number] {
            width: 100%;
        }
    }

    .table-servers {
        margin-bottom: 10px;

        .table {
            margin-bottom: 0;
        }
    }

    .gracefully-disable-form {
        margin-left: 9px;
    }

    .inline-healthmonitor {
        line-height: @buttonHeight;
    }

    .c-grid {
        .c-grid-table-header-cell--field-name-- {
            &status {
                width: 6%;
            }

            &hostname {
                width: 10%;
            }

            &ip {
                width: 15%;
            }

            &port {
                width: 9%;
            }

            &ratio {
                width: 5%;
            }

            &network {
                width: 10%;
            }

            &hdrVal {
                width: 5%;
            }

            &rewrite_host_header {
                width: 5%;
            }
        }

        /* Invalid inputs nested in grid must stay red even if they are pristine */
        input.ng-invalid {
            border-color: #CE4B18 !important;
            color: #CE4B18 !important;
        }

        input {
            width: 100%;
        }
    }

    e-auto-complete.placement-subnet {
        width: 12em;
        margin-left: 1em;
    }

    .add-pool-servers-mixin();

    .servers-list .c-grid {
        max-height: 600px;
    }

    .app-pool-create-settings {
        &__enable-switch {
            margin-top: 5px;
        }

        &__lookup-servers-checkbox {
            margin-bottom: 10px;
        }

        &__append_port {
            margin-bottom: 15px;
        }

        &__rewrite-headers-checkbox {
            margin-bottom: 10px;
        }

        &__health-monitors {
            margin-bottom: 15px;
        }

        &__health-monitors-header {
            margin-top: 5px;
        }

        &__health-monitors-label {
            margin-bottom: 10px;
        }

        &__min-health-monitors-up {
            margin-bottom: 10px;
        }

        &__lb-hash-dropdown {
            width: 95%;
            .pull-right();
            margin: 5px 0
        }

        &__lb-hash-arrow {
            .pull-left();
            margin-top: 5px;
        }

        &__lb-hash-hdr-input {
            .pull-right();
        }

        &__lb-hash-hdr-arrow {
            .pull-right();
            margin-top: 5px;
        }
    }

    .app-pool-create-other-advanced-settings {
        &__http-server-reselect, &__routing-pool {
            margin-top: 15px;
        }
    }
}

//used by VS basic create and pool edit
.add-pool-servers-mixin() {
    div.add-pool-servers {
        display: flex;
        flex-direction: row;

        add-pool-servers {
            flex: 1 1 auto;
            margin-right: 1em;
        }

        div.select-server-by-network {
            flex: 0 1 200px;
            padding-top: @lineHeight * 1em;
        }
    }
}
