@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.expander {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;

    &__expander-content {
        flex: 1;
        min-width: 0;
    }

    &__caret-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
    }

    &__caret {
        color: @textDarkGray;
        font-size: @avi-baseline-px;
    }
}

.expander-component-content-mixin() {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: white;

    &__cell {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 3fr 7fr;
        padding: 8px;
        border-bottom: 1px solid @aviGrey3;
        background: @aviWhite;

        &--header {
            border-top: 2px solid @aviGrey3;
            border-bottom: 2px solid @aviGrey3;
            background: @aviWhite;
            font-size: @avi-baseline-px * .625;
            font-weight: 600;
            text-transform: uppercase;
        }

        &--match-header {
            border-right: 1px solid @aviGrey3;
        }

        &--match {
            grid-column-start: 1;
            border-right: 1px solid @aviGrey3;


            &:last-child {
                border-bottom: 0;
            }
        }

        &--action {
            grid-column-start: 2;
            grid-row-start: 2;
        }

        &--no-border-bottom {
            border-bottom: 0;
        }
    }

    &__cell-label {
        font-weight: 600;
        justify-self: right;
    }
}
