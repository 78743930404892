.cloud-services-widget {
    &__config-container {
        padding: 0 10px;
    }

    &__registration-status-container {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid var(--cds-alias-object-border-color);
    }

    &__portal-url {
        flex: 1;
        padding-left: 5px;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__value-container {
        margin-top: 15px;
    }

    &__label {
        display: block;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__value {
        display: block;
        padding-left: 5px;
        font-size: var(--cds-global-typography-font-size-3);
        line-height: 24px;
    }

    &__unregistered-container {
        padding: 45px 0;
        text-align: center;
    }
}
