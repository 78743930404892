.jwt-match {
    &__name-container {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--clr-table-border-color);
    }

    &__claim-container {
        padding: 5px 0 0 15px;

        &:not(:last-child) {
            padding-bottom: 5px;
            border-bottom: 1px solid var(--clr-table-border-color);
        }
    }

    &__claim-match-config {
        margin-bottom: 10px;
    }

    &__add-claim-name-btn {
        margin: 10px 0 0 15px;
    }
}
