.vs-logs-filter-input {
    flex-grow: 1;
    padding: 0 .3rem;
    border: none;
    background: none;

    &:focus {
        outline: none;
    }

    &::placeholder {
        opacity: 0.54;
        color: var(--clr-forms-placeholder-color)
    }
}
