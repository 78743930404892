@import (reference) '~ajs/less/fonts/avi';

.system-configuration {
    width: 100%;

    .dnsSettings {
        .small-shadow-bottom;
        display: inline-block;
        box-sizing: border-box;
        overflow: hidden;
        background-color: white;
        .col(8, block, none, 0, 0, 2);

        div.control-group {
            overflow: hidden;
        }
    }

    .settingsValue {
        font-size: 1.286em;
    }

    pre.banner {
        font-weight: 500;
        white-space: pre-line;
    }

    div.header-left {
        overflow: hidden;
    }
}
