.vs-log-cinematic-section-wrapper {
    margin: 35px;

    &__header {
        display: flex;
        align-items: center;

        &-icon {
            margin-right: 5px;
        }
    }

    &__body {
        margin: 30px 25px;
    }
}
