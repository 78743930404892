.waf-enable {
    &--disabled {
        color: @aviGrey2;
    }

    &__data {
        display: flex;
        flex-grow: 1;
        min-width: 0;
    }

    &__name {
        flex-grow: 1;
        .hide-text-overflow;
        margin-right: 5px;
    }
}
