.waf-learning-link {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: .75rem;
    }

    &__learning-status {
        &--enabled {
            color: var(--clr-color-success-500);
        }

        &--disabled {
            color: var(--clr-color-neutral-400);
        }
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__disabled-body-text {
        color: var(--clr-color-neutral-400);
        font-size: .9rem;
        text-align: center;
    }

    &__info {
        flex: 1;
        min-width: 0;
        padding: 20% 15px;

        &:not(:last-child) {
            border-right: 1px solid var(--clr-card-border-color);
        }
    }

    &__setting {
        font-size: .9rem;
        text-align: center;
    }

    &__setting-label {
        font-size: .6rem;
        text-align: center;
    }
}
