@import (reference) '~ajs/less/themes/default-theme/colors.less';

@linkColor: #49a1c1;

// a little bit of styling for when things are expanded
.expandedMode .inner {
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    border-left: 1px solid #CCC;
}

.top-list-styling {
    .ellipsify {
        width: 500px;
        max-width: 500px;
        overflow-x: hidden;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
    }

    .list-item {
        line-height: 32px;

        .title {
            color: @linkColor;
        }

        & > span {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    .oneline-bar-chart {
        height: 15px;
    }

    .total-time {
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
    }

    .value, .percent-change-value {
        display: inline-block;
        text-align: right;
    }

    .flag {
        margin-top: 10px;
    }

    .oneActive {
        color: #AAA;

        .title {
            color: #AAA
        }

        .active {
            background-color: #FFF;
            color: @linkColor;

            .title {
                color: @linkColor;
            }
        }
    }

    .percentChange.pos {
        color: @aviGreen0;
    }

    .percentChange.neg {
        color: @aviRed0;
    }
}
