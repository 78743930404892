@import (reference) '~ajs/less/layout/grid-container.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.out-of-band-log-list-expander {
    margin: 0;
    padding: 0;
    font-size: 14px;

    .grid-container();

    &__header {
        .grid-container__cell--w--full();
        margin-top: 15px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 300;
    }

    &__container {
        padding: 10px;

        .grid-container();

        &-title {
            font-size: 16px;
            font-weight: 300;
        }

        &-response-request {
            .grid-container();
            .grid-container__cell--w--full();
            padding-top: 10px;
            border-top: 1px solid @borderGray;
        }

        &-sent-received-headers {
            .grid-container();
            .grid-container__cell--w--full();
            grid-column-gap: 0;
        }
    }

    &__field_name {
        margin-bottom: 7px;
        color: @textMidGray;
        font-weight: 700;
    }

    &__filter {
        color: @hyper-link;
        word-break: break-all;
    }

    &__headers-section {
        margin-bottom: 15px;
        padding: 0;

        &-title {
            border-top: 1px solid @borderGray;
            border-bottom: 1px solid @borderGray;
            background-color: @aviGrey3;
            font-weight: 700;

            &-text {
                padding: 10px;
                color: @textMidGray;
            }
        }

        &-content {
            margin-top: 15px;
            padding: 0 10px;
        }
    }
}
