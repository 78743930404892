@import (reference) '~ajs/less/themes/default-theme/theme.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.sparkline-popup {
    .small-shadow-bottom();
    display: block;
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    background-color: #EBEBEB;
    pointer-events: none;

    &__metrics-cell {
        &:before {
            .make-up-arrow(75px, -9px, #ebebeb, 12px);
        }

        &--no-data {
            width: 100px;
            text-align: center;

            &:before {
                .make-up-arrow(57px, -9px, #ebebeb, 12px);
            }
        }
    }
}

cell-sparkline {
    .cell-sparkline {
        display: inline-block;
        position: relative;
        text-align: left;

        &__container {
            display: inline-block;
            height: @buttonHeight;
            vertical-align: top;

            &--data {
                line-height: @buttonHeight;
            }

            &--loader {
                position: relative;
                top: 8px;
            }
        }

        &__unit-label {
            color: @analyticsUnitslabelColor;
            font-size: 14px;
            vertical-align: bottom;
        }

        &__loader {
            width: 50px;
            height: 20px;
        }

        &__no-data {
            color: @aviGrey2;
        }
    }
}
