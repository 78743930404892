.path-match {
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: bold;
    }

    &__close-button {
        border: none;
        background: none;
        box-shadow: none;
    }

    &__rule {
        display: flex;
        padding-left: 40px;
    }

    &__rule-column {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__criteria {
        margin: 0;
    }

    // Overriding string-or-group-list component styling
    .string-group-selector {
        min-width: 0;
    }
}
