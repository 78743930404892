/**
 * Mixin to show menu view.
 * Used in collection-grid-create-actions.partial.html and dashboard.partial.html
 */

.dropdown-panel() {
    display: block;
    z-index: @modalZIndex;
    border: 1px solid @aviGreySpecial;
    border-radius: @aviBorderRadius;
    background-color: @aviWhite;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
    color: @aviBlue0;
    line-height: 30px;
    text-align: left;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            a {
                display: inline-block;
                width: 100%;
                text-decoration: none;
            }

            &:hover {
                background-color: @aviBackgroundColor;
                color: darken(@aviBlue0, 10%);
            }

            &.selected {
                border-left: 4px solid @aviBlue0;
                background-color: @aviBackgroundColor;

                &:hover {
                    cursor: default;
                }
            }
        }
    }
}
