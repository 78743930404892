// Common mixins used for overriding undesired clarity styles.
.clr-form-control-mixin() {
    display: flex;
    flex-direction: row;
    margin-top: 0;

    .clr-control-container {
        box-sizing: border-box;
        flex: 1 1 auto;
        max-width: initial;
    }
}

.clr-login-page-overrides() {
    .login-wrapper {
        .login {
            width: 20.2rem;
            padding: 0 3rem;

            .clr-control-container {
                padding: 0;
            }

            .login-group {
                padding-top: 10px;

                .btn {
                    margin: 1.6rem 0 0 0;
                    padding: 0 3rem;
                }

                .error {
                    margin-top: 1.6rem;
                }

                clr-input-container.clr-form-control {
                    margin: 0;
                }
            }
        }
    }
}
