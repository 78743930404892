.vs-log-cinematic-sub-section-o-auth {
    &__column-group {
        display: flex;
    }

    &__expander {
        @border-style: 1px solid var(--clr-table-border-color);

        &-opener {
            box-sizing: border-box;
            padding: .5rem;
            border: @border-style;

            &-text {
                color: var(--cds-global-typography-color-200);
                font-size: var(--cds-global-typography-font-size-1);
                font-weight: var(--cds-global-typography-font-weight-bold);
            }

            &:first-of-type {
                margin-top: 1rem;
            }
        }

        &-content {
            &-section {
                box-sizing: border-box;
                padding: 10px 0;
                border: @border-style;
                background-color: var(--cds-alias-object-container-background);

                &:first-of-type {
                    border-top: none;
                }

                &:not(:last-of-type) {
                    border-bottom: none;
                }
            }
        }
    }
}
