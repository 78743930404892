@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.user-card-menu {
    display: flex;
    padding-right: 30px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: @aviTopHeaderHeight;
        color: @alb-white;
        cursor: pointer;

        &:hover {
            color: @aviWhite;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__list {
        display: inline-block;
        padding: 0;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
        color: var(--cds-global-color-gray-600);
        line-height: normal;

        &--user-card-menu-content {
            width: 330px;
        }
    }

    &__list-item {
        display: block;
        position: relative;
        padding: 15px;
        overflow: hidden;
        color: inherit;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--border {
            border-bottom: 1px solid var(--clr-table-border-color);
        }

        &--label {
            margin-left: 5px;
        }

        &.active {
            color: #FF4B00;
        }

        &--user-card {
            min-height: 115px;
            padding: 0;
        }

        &--with-hover {
            &:hover {
                background-color: var(--cds-global-color-gray-100);
                color: inherit;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}
