@import (reference) '~ajs/less/layout/layout.less';

.body-font-size-medium-font-weight-mixin {
    font-size: var(--cds-global-typography-body-font-size);
    font-weight: var(--cds-global-typography-font-weight-medium);
    line-height: var(--cds-global-typography-body-line-height);
}

.section-font-size-light-font-weight-mixin {
    font-size: var(--cds-global-typography-section-font-size);
    font-weight: var(--cds-global-typography-font-weight-light);
    line-height: var(--cds-global-typography-section-line-height);
}

ssl-bar-chart {
    display: block;
    width: 100%;

    .ssl-bar-chart-header {
        h2.ssl-bar-chart-header__title {
            .body-font-size-medium-font-weight-mixin();
        }
    }

    .ssl-score {
        width: 50px;
        border-top-width: 0;
        border-radius: @aviBorderRadius;
        stroke-width: 1px;
        line-height: 35px;
        text-align: center;

        span {
            .section-font-size-light-font-weight-mixin();
        }

        &.green {
            color: @aviGreen1;
            font-size: 1.286em;
        }

        &.orange {
            color: @aviHealthBad1;
            font-size: 1.286em;
        }

        &.disabled {
            width: 100px;
            color: @aviBlue1;
        }
    }

    .chart {
        rect {
            fill: @aviGreen1;
        }

        .name, .setting {
            text-anchor: end;
        }

        .name {
            fill: #666;
        }

        .setting {
            fill: @aviGrey2;
        }

        .value {
            fill: white;
            font-size: 20px;
            font-weight: 200;
            text-anchor: end;

            &[text-position='right'] {
                fill: @aviGreen1;
                text-anchor: start;
            }
        }

        g.disabled {
            rect, .value[text-position=right] {
                fill: @aviGrey2;
            }
        }

        g.poor {
            rect, .value[text-position=right] {
                fill: lighten(@Bad, 30%);
            }
        }

        g.threat {
            rect, .name, .setting, .value[text-position=right] {
                fill: @aviHealthBad1;
            }
        }
    }
}
