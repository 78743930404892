@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import (reference) '~ajs/less/pages/infrastructure/infrastructure.less';

@status-margin: 10px;

.cloud-status-tooltip {
    display: block;
    position: absolute;
    z-index: @aviSelectZindex;
    box-sizing: border-box;
    width: 210px;
    margin: 0;
    border: 1px solid @aviGreySpecial;
    background: @aviWhite;
    font-size: 14px;
    pointer-events: none;

    &__header {
        grid-column-gap: 0;
        margin: 5px @status-margin @status-margin 5px;

        &-cloud-name {
            margin-top: 7px;
        }

        &-cloud-state-reason {
            font-size: 10px;
        }
    }

    &__circle-icon {
        margin-top: 3px;
        font-size: 1.45em;
        .cloud-status-color();
    }

    &__separation-bar {
        margin: 3px 0 10px 0;
    }

    &__versions-list {
        margin: 0;
    }

    &__version-row {
        margin: 0 @status-margin @status-margin @status-margin;

        &-number {
            font-weight: bold;
        }

        &-status {
            display: inline-block;
            position: absolute;
            right: @status-margin;
        }
    }
}
