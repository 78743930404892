.vs-log-cinematic-section-out-of-band {
    &__grid-expanded-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-section {
            display: flex;
            box-sizing: border-box;
            padding: 10px 0;

            &:not(:first-child) {
                border-top: 1px solid var(--clr-table-border-color);
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--clr-table-border-color);
            }
        }

        &-response-title {
            padding-top: 10px;
        }
    }

    &__non-flex-content {
        display: block;
    }

    &__response-column {
        margin-right: 10px;
        background: var(--cds-global-color-gray-50);
    }
}
