@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/components/modal.less';
@import (reference) '~ajs/less/components/modal2.less';

@modalWizardNavHeight: 50px;
@modalAnchorNavHeight: 30px;

.modal-container__header {
    display: flex;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    flex-direction: column;
    min-height: @modal2HeaderHeight;
    margin: 0;
    padding: 20px 0;
    border: 0;
    border-bottom: 1px solid @modal2BorderColor;
    background: transparent;
    color: @modal2Color;
}

.modal-container__anchor-links {
    padding-left: 15px;
}

h1.modal-container__title {
    .hide-text-overflow;
    padding: 0 50px 0 15px;
}

.modal-container__body {
    position: absolute;
    top: @modal2HeaderHeight;
    bottom: @modal2FooterHeight;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px 50px;
    overflow-y: auto;

    &--anchor-nav {
        top: @modal2HeaderHeight + @modalAnchorNavHeight;
    }

    &--breadcrumbs {
        top: @modal2HeaderHeight + 17px;
    }

    &--anchor-nav-with-breadcrumbs {
        top: @modal2HeaderHeight + @modalAnchorNavHeight + 17px;
    }
}

.modal-wizard-nav {
    box-sizing: border-box;
    height: @modalWizardNavHeight;
    padding: 0 40px;
    border-bottom: 1px solid @modal2BorderColor;
    .display-flex;
    .justify-flex-start;
}

.modal-wizard-nav__tab {
    margin-right: 50px;
    padding: 5px 0;
}

a.modal-wizard-nav__link {
    transition: color .1s ease-in-out;
    font-size: 16px;

    &:not([disabled]) {
        color: inherit;
    }

    &--active:not([disabled]) {
        color: @aviBrandColor;

        &:hover {
            color: @aviBrandColor;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.modal-container--wizard .modal-container__header {
    border: 0
}

.modal-container--wizard .modal-container__body {
    top: @modal2HeaderHeight + @modalWizardNavHeight;
}
