@import (reference) '../vs-log-filter/vs-log-filter.directive.less';

.vs-log-partial-selection-filter {
    position: relative;

    &__container {
        color: @link-text-color;
        font-weight: @link-font-weight;
        cursor: pointer;

        &:hover {
            text-decoration: underline @link-underline-color 1px;
            text-underline-offset: 2px;
        }
    }

    &__selected {
        padding: 5px 0;
        background-color: var(--cds-global-color-ice-100);
    }

    &__search-icon-left {
        position: absolute;
        right: calc(100% + 5px);
    }

    &__search-icon-right {
        position: absolute;
        left: calc(100% + 5px);
    }
}
