.newline-converted-textarea {
    & &__textarea {
        padding: 1.5rem 0.6rem;
        resize: none;
    }

    &__no-margin-top, .avi-textarea-container, .clr-textarea-wrapper {
        margin-top: 0;
    }

    &__converted-value-preview {
        max-width: calc(100% - 0.3rem);
        height: 7.24rem;
        padding: 1.5rem 0.6rem;
        overflow: auto;
        border: 0.05rem solid var(--clr-forms-border-color);
        border-radius: 0.15rem;
        background-color: var(--cds-global-color-gray-100);
        color: var(--clr-forms-text-color);
        white-space: nowrap;
    }

    &__emphasized {
        font-weight: bold;
    }

    &__actions-wrapper {
        display: inline-flex;
        position: absolute;
        right: 0;
        padding: 1.5rem 0.5rem 0 0;
    }

    &__action-icon {
        margin: 0 0.25rem;
        cursor: pointer;
    }
}

.full-modal-config__form-control {
    position: relative;
}
