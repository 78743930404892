.hdr-match {
    &__input-container {
        display: block;
        margin-top: 10px;

        &--value {
            margin-left: 15px;
        }
    }
}
