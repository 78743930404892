/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

.c-grid-table-row-expander {
    position: relative;
    box-sizing: border-box;
    flex: 1 1 auto;
    min-height: @gridCellHeight;
    padding: 10px;
    overflow: hidden;
    border-top: 1px solid @gridBorderColor;
    background-color: white;
    cursor: default;
}
