@import (reference) '~ajs/less/layout/grid-container.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.icap-log-headers {
    .grid-container__cell--w--full();
    .grid-container();
    grid-column-gap: 0;

    &__section {
        .grid-container__cell--w--6();
    }

    &__title {
        border-top: 1px solid @borderGray;
        border-bottom: 1px solid @borderGray;
        background-color: @aviGrey3;
        font-weight: 700;

        &-text {
            padding: 10px;
            color: @textMidGray;
        }
    }

    &__content {
        margin-top: 15px;
        padding: 0 10px;
    }
}
