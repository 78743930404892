@import 'vs-waf-top-hits/vs-waf-top-hits';
@import 'waf-top-hits-container/waf-top-hits-container';
@import 'waf-exception-preview/waf-exception-preview';
@import 'waf-enable/waf-enable';

.vs-waf-page {
    &_main-viewport {
        display: block;
        flex-grow: 1;
        padding: 10px 30px;
        overflow-y: auto;
    }

    &__top-charts {
        display: flex;
        flex: 1;
        min-width: 0;
    }

    .vs-waf-top-hits {
        flex-shrink: 0;
    }

    .vs-waf-top-enforcements {
        margin-top: 2em;
    }

    .two-column-container .left-section {
        overflow-y: scroll;
    }

    &__top-container {
        display: flex;
        flex-direction: row;
    }

    &__waf-learning-link {
        flex: 0 0 auto;
        width: 300px;
        margin-left: 15px;
    }
}
