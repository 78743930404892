.vs-log-cinematic-field-title {
    display: block;
    padding-bottom: 8px;
    color: var(--cds-global-typography-color-500);
    font-size: var(--cds-global-typography-font-size-3);
    font-weight: var(--cds-global-typography-font-weight-medium);

    &__with-margin-top {
        margin-top: 25px;
    }
}
