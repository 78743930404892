.case-preview {
    margin: 0 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--cds-alias-object-interaction-color);

    .case-preview-clarity-overrides();

    &__item {
        margin: 15px 0;
    }

    &__item-value {
        color: var(--cds-global-color-green-500);
        line-height: 1.25rem;
        text-transform: capitalize;

        &--disabled-color {
            color: var(--cds-alias-object-interaction-color-disabled);
        }
    }
}

// To override clarity styles for header tags.
.case-preview-clarity-overrides() {
    h4:not([cds-text]), h6:not([cds-text]) {
        color: var(--cds-global-color-white);
    }

    h4:not([cds-text]) {
        margin-bottom: 25px;
    }
}
