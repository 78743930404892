@import (reference) '~ajs/less/themes/default-theme/colors.less';

.border-radius (@radius: @aviBorderRadius) {
    border-radius: @radius;
    background-clip: padding-box;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    border-top-left-radius: @topleft;
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    background-clip: padding-box;
}

.ellipsifier(@maxWidth: 180px) {
    max-width: @maxWidth;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        overflow: visible;
    }
}

.hide-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ellipsify {
    .ellipsifier();
}

.avi-white {
    color: @aviWhite;
}

.avi-grey {
    color: @aviGrey1;
}

.avi-green,
.avi-green1 {
    color: @aviGreen1;
}

.avi-red {
    color: @aviRed1;
}

.bg-white {
    background-color: @aviWhite;
}
