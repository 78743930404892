@panelHeaderColor: @aviWhite;
@panelBodyColor: @aviWhite;
@panelBorderColor: #e5e5e5;
@panelTransparentBorderColor: #ccc;

.panel {
    display: block;
}

.panel-body {
    padding: 10px 5px 5px 5px;
}

.panel.transparent {
    border: none;
}

.panel.table-panel {
    border: none;

    .panel-body {
        padding: 0;
    }
}
