@import (reference) '~ajs/less/themes/default-theme/colors.less';

.c-grid-table-header-cell-resize-handle {
    z-index: 1;
    flex: 0 0 2px;
    height: 25px;
    //to be right in between of cells
    margin-right: -1px;
    border-right: 1px solid @borderGray;
    cursor: col-resize;
}
