@import (reference) '~ajs/less/themes/default-theme/theme.less';

event-chart {
    .small-shadow-bottom;
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;

    .event-icon {
        @icon-size: 14px;
        @border-width: 2px;
        display: block;
        position: relative;
        width: @icon-size;
        height: @icon-size;
        overflow: hidden;
        border: @border-width solid white;
        border-radius: 100%;
        line-height: @icon-size;
        cursor: pointer;

        &.anomalies {
            background-color: @aviYellow1;
        }

        &.alerts {
            background-color: @aviRed1;
        }

        &.config {
            background-color: @aviBlue1;
        }

        &.system {
            background-color: @aviPurple1;
        }

        &.selected {
            left: -1px;
            border-width: @border-width + 1px;
        }

        i {
            display: block;
            position: relative;
            color: white;
            font-size: 10px;
            line-height: @icon-size;
        }
    }

    .chart-header {
        display: flex;
        position: relative;
        align-content: center;
        align-items: stretch;
        justify-content: space-between;
        min-height: 25px;
        padding: 10px 25px;
        border-bottom: 1px solid @borderGray;
        line-height: 25px;

        & > * {
            flex: 1 1 0;
        }

        h3.chart-title {
            display: inline-block;
            position: relative;
            color: black;
            font-size: 16px;
            font-weight: bold;
            white-space: nowrap;
        }

        .chart-controls {
            display: inline-block;
            position: relative;
            flex: 3 0 0;
            text-align: center;

            .event-controls {
                display: inline-flex;
                position: relative;

                .event-control {
                    display: inline-flex;
                    align-items: center;
                    padding: 0 20px;
                    white-space: nowrap;

                    .avi-label {
                        font-size: 12px;
                    }

                    .event-icon {
                        @icon-size: 18px;
                        width: @icon-size;
                        height: @icon-size;
                        margin: 0 5px;
                        border: 0;

                        i {
                            font-size: 12px;
                            line-height: @icon-size;
                        }
                    }
                }
            }
        }
    }

    .axis-container {
        display: flex;
        padding: 10px 20px 0;

        .axis-label {
            position: relative;
            flex: 1 1 0;
            font-size: 10px;

            .title {
                font-size: 1em;
            }

            &.left {
                .title {
                    font-weight: bold;
                }
            }

            &.right {
                text-align: right;
            }
        }
    }

    .chart-group {
        display: flex;
        position: relative;
        max-width: 100%;
        padding: 0 20px 10px;
        overflow: hidden;

        .chart-container {
            display: block;
            position: relative;
            box-sizing: border-box;
            flex: 1 1 0;
            height: 0;
            min-height: 250px;
            margin: 0;
            padding: 0;
            overflow: hidden;

            text.average-label {
                transform: translate(-27px, 0);
            }
        }

        .chart-events-container {
            display: block;
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 80px;

            .event-icons {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -100%);
            }
        }
    }
}
