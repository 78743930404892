@import (reference) '~ajs/less/fonts/avi';
@import (reference) '~ajs/less/layout/layout.less';

//main view for infrastructure pages
div[ui-view].infrastructure {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;

    collection-grid.cloud {
        table > thead > tr > th.rowactions {
            width: 200px;
        }

        .grid-field-state {
            width: 5em;
        }
    }

    & > div[ui-view].cloud {
        height: 100%;

        & > div {
            //cloud tabs: list view pages
            & > div[ui-view].cloud-detail {
                position: relative;
                margin: auto;
                padding: 0 @pagePaddingWidth;

                collection-grid.segroup {
                    table > thead > tr > th.rowactions {
                        width: 60px;
                    }

                    //details expander
                    table > tbody > tr.row {
                        &.expanded > td.rowactions > a > i[class='icon-plus']:before {
                            &:extend(.icon-minus:before);
                        }
                    }
                }

                collection-grid.service-engine-list {
                    table > thead > tr > th {
                        &.grid-field-alerts {
                            width: 6%;
                        }

                        &.grid-field-num_vs {
                            width: 10%;
                        }
                    }
                }
            }
        }

        & > div[ui-view].serviceengine {
            height: 100%;
        }
    }
}

.infrastructure-detail, .infrastructure-routing {
    top: @aviSubHeaderHeight;
    padding: 0 30px 40px;
}

.infrastructure-routing {
    top: @aviSubHeaderHeight + @subnavHeight;
}

.infrastructure .icon-circle {
    font-size: 1.45em;
    .cloud-status-color();
}

.cloud-status-color() {
    &.status-in-progress {
        color: @Fine;
    }

    &.status-unknown {
        color: @Bad;
    }

    &.status-ready {
        color: @Good;
    }

    &.status-failed {
        color: @Down;
    }
}

.linuxserver-cloud-expander {
    .linuxserver-hosts-expander-grid {
        .actions-panel {
            display: none !important;
        }

        .c-grid-table-header-cell--field-name--host_state, .c-grid-table-header-cell--field-name--host {
            width: 20% !important;
        }

        .c-grid__table-cell--field-name {
            &--host_state, &--se_state {
                height: auto;
                padding: 10px;
                line-height: normal;
                white-space: unset;

                .grid-cell-host_state, .grid-cell-se_state {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}
