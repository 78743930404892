client-log-filters {
    display: block;
    position: relative;

    .configuration {
        .control-group {
            &.enabled {
                width: 4%;
            }

            &.name {
                width: 64%;
                margin-right: 2%;

                .inner-text {
                    width: 100%;
                }
            }

            &.duration {
                width: 15%;

                .inner-text {
                    width: 100%;
                }
            }

            &.all-headers {
                width: 15%;
                padding-top: 1.5em;
            }
        }
    }

    .full-client-logs {
        .inner-text {
            width: 60%;
        }
    }

    .add-log-button-container {
        position: absolute;
        z-index: 1;
        right: 0;
        transform: translate(0, 5px);
    }

    dropdown.string-match-criteria {
        width: 200px;
        margin: 0 5px 5px 0;
    }
}
