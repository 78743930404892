@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.c-grid-table-header-checkbox {
    &__popover.aviPopover {
        z-index: @deepestModalZIndex; //FIXME popoverService should set it;
        padding: 0 1em;border: 1px solid @borderGray;
        line-height: 2;

        a {
            display: block;
            text-decoration: none;
        }
    }
}
