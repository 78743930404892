.vs-log-cinematic-section-security-waf {
    &__no-margin-top {
        .vs-log-cinematic-section-security-waf-avi-data-grid-title-override();
    }

    &__subsection {
        margin-top: 16px;
    }

    &__expander-list {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &__expander {
        display: block;
        border-top: var(--clr-table-borderwidth) solid var(--clr-table-border-color);

        &--group:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__expander-list > &__expander {
        border-right: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
        border-bottom: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
        border-left: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
    }

    &__expander-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 1rem;
        padding: 12px;
    }

    &__content {
        padding: 12px;
        border-top: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
        background-color: var(--clr-table-bgcolor);
    }

    &__icon {
        &--compass, &--layers {
            margin-right: 4px;
            margin-bottom: 2px;
        }

        &--file {
            margin-bottom: 3px;
        }

        &--plus {
            margin-top: 2px;
            float: right;
        }
    }

    & &__ruleid {
        font-weight: var(--cds-global-typography-font-weight-bold);
    }

    &__child-l1 {
        padding-left: 12px;
    }

    &__child-l2 {
        padding-left: 24px;
    }

    &__top-info {
        display: flex;
        padding-top: 10px;
        padding-left: 10px;

        &-end-to-end {
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: space-between;
            width: 75%;
            padding-right: 10px;

            &-body {
                display: flex;
                padding-top: 15px;
            }
        }

        &-resources {
            box-sizing: border-box;
            width: 25%;
            margin-bottom: 9px;
            padding-left: 12px;
            border-left: 1px solid var(--clr-table-border-color);

            &-header {
                padding-bottom: 13px;
            }

            &-body {
                display: flex;
                align-items: flex-start;
                padding-bottom: 15px;

                &--form-control {
                    flex: 1;
                    min-width: 0;

                    &:not(:last-child) {
                        margin-right: .5rem;
                    }
                }
            }
        }

        &-bridge {
            flex-grow: 1;
        }
    }
}

.vs-log-cinematic-section-security-waf-h5-clarity-override();

// override Clarity styles of h5 for top-info card inner titles
.vs-log-cinematic-section-security-waf-h5-clarity-override() {
    .clr-wrapper h5.vs-log-cinematic-section-security-waf__top-info-end-to-end-header,
    .clr-wrapper h5.vs-log-cinematic-section-security-waf__top-info-resources-header {
        color: var(--cds-global-typography-color-500);
        font-weight: var(--cds-global-typography-font-weight-light);
    }
}

// override avi-data-grid styles for space between gridtitle and avi-data-grid
.vs-log-cinematic-section-security-waf-avi-data-grid-title-override() {
    [class^='avi-data-grid'] {
        margin-top: 0;
    }
}
