@clr-small-border: .25rem;
@clr-large-border: .3rem;

.caret-borders(@topColor, @rightColor, @bottomColor, @leftColor) {
    border-top: @clr-small-border solid @topColor;
    border-right: @clr-large-border solid @rightColor;
    border-bottom: @clr-small-border solid @bottomColor;
    border-left: @clr-large-border solid @leftColor;
}

.avi-tooltip-content {
    position: relative;
    box-sizing: border-box;
    max-width: 14rem;
    padding: .45rem .6rem;
    border-radius: .15rem;
    background: #000;
    color: #FFF;

    // The rules below are for the tooltip caret, which needs to change based on the position
    // of the tooltip relative to the origin.
    &::before {
        content: '';
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }

    &--top {
        &::before {
            bottom: -.35rem;
            left: 50%;
            .caret-borders(#000, transparent, transparent, #000);
        }
    }

    &--top-right {
        &::before {
            bottom: -.35rem;
            left: 0;
            .caret-borders(#000, transparent, transparent, #000);
        }
    }

    &--top-left {
        &::before {
            right: 0;
            bottom: -.35rem;
            .caret-borders(#000, #000, transparent, transparent);
        }
    }

    &--bottom {
        &::before {
            top: -.35rem;
            left: 50%;
            .caret-borders(transparent, transparent, #000, #000);
        }
    }

    &--bottom-right {
        &::before {
            top: -.35rem;
            left: 0;
            .caret-borders(transparent, transparent, #000, #000);
        }
    }

    &--bottom-left {
        &::before {
            top: -.35rem;
            right: 0;
            .caret-borders(transparent, #000, #000, transparent);
        }
    }
}
