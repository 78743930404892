.vs-log-cinematic-section-summary {
    display: flex;

    &__clr-icon-with-margin-right {
        margin-right: 0.2rem;
    }

    &__uri-query-question-mark {
        color: var(--cds-global-color-blue-700);
    }

    &__policy-grid-row {
        display: flex;
        gap: 1rem;

        &-name {
            width: 50%;
            color: var(--cds-global-typography-color-500);
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-value {
            text-transform: capitalize;
        }
    }
}
