.match-wrapper {
    &__avi-fieldset-header {
        display: block;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-sub-label {
            font-size: var(--cds-global-typography-secondary-font-size);
            font-weight: var(--cds-global-typography-font-weight-regular);
        }
    }

    &__remove-icon {
        margin-right: 10px;
        cursor: pointer;
    }

}
