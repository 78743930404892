@import (reference) '../full-modal.component.less';

.full-modal-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__preview-container {
        flex: 1;
        margin: 10px;
    }

    &__footer {
        .full-modal-footer-mixin();
    }
}
