@import (reference) '~ajs/less/themes/default-theme/z-index.less';

@modal3BackgroundColor: @aviWhite;
@modal3BorderColor: #c4c4c4;
@modal3Color: #444f51;
@modal3CompBorderColor: #9da3a6;

.avi-modal.modal3 {
    display: block;
    position: absolute;
    z-index: @modalZIndex;
    top: 50% !important;
    left: 50% !important;
    width: auto;
    min-width: initial;
    height: auto;
    min-height: initial;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: @modal3BackgroundColor;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.7);
    color: @modal3Color;

    .dialog-text {
        display: block;
        position: relative;
        margin-bottom: 20px;
        color: @textDarkGray;
        font-size: 15px;
        text-align: center;
    }

    .avi-modal-header {
        display: block;
        position: relative;
        min-height: 60px;
        border: 0;
        background: transparent;

        button.close-button {
            top: 15px;
            right: 15px
        }

        button.close {
            position: absolute;
            top: 0;
            right: 0;
            color: inherit;
            font-size: 24px;
            font-weight: normal;
            text-shadow: none;

            &:hover {
                background-color: transparent;
                color: inherit;
            }
        }
    }

    .avi-modal-body {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 600px;
        height: auto;
        max-height: 500px;
        margin: 0;
        padding: 0 50px 20px;
        overflow: hidden;
        overflow-y: auto;
    }

    .avi-modal-footer {
        display: block;
        position: relative;
        height: 80px;
        border: 0;
        background: transparent;
        text-align: center;
    }

    .control-group {
        margin-bottom: 30px;

        & > label {
            margin: 0 0 10px 2px;
            color: @modal2Color;
            font-size: 18px;
            font-weight: 200;
        }
    }

    .avi-btn-group {
        label.avi-btn, button.avi-btn {
            border: 1px solid @modal3CompBorderColor;
            background: transparent;
            box-shadow: none;
            color: inherit;

            i {
                color: inherit;
            }

            &.disabled, &[disabled] {
                opacity: .5;
                background: transparent;
                color: inherit;
                cursor: auto;

                i {
                    color: inherit;
                }
            }

            /* stylelint-disable-next-line */
            &:not(.disabled):not([disabled]):not(.active):not(.avi-btn-success):not(.avi-btn-danger):not(.avi-btn-primary):not(.avi-btn-secondary) {
                &:hover {
                    background-color: transparent;
                }
            }

            &.active {
                background-color: @modal3CompBorderColor;
                color: #FFF;

                i {
                    color: inherit;
                }
            }
        }
    }
}
