.vs-logs-sidebar {
    &__saved-search-section {
        padding-bottom: 17px;
        border-bottom: 0.5px solid var(--cds-global-color-gray-400);

        &-entry {
            display: flex;
            align-items: center;
            height: 36px;
            padding-right: 16px;
            padding-left: 46px;
            overflow-x: hidden;
            color: var(--cds-global-color-gray-700);

            &:hover {
                transition-duration: 0.3s;
                background-color: var(--cds-global-color-gray-400);
                cursor: pointer;
            }

            &-text {
                flex-grow: 1;
                max-width: 162px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &__data-section {
        padding-top: 12px;
    }
}
