.referenced-objects-dialog {
    &__container {
        width: 100%;
        color: var(--cds-global-color-gray-800);
    }

    &__continue-question-warning {
        margin-top: 1rem;
        font-weight: bold;
    }
}
