.grid-modal-body {
    .grid-container();
    position: absolute;
    top: 47px;
    bottom: 45px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: scroll;

    > :last-child {
        margin-bottom: 20px;
    }

    //For each section inside modal; separated by line and header
    .grid-modal-panel {
        padding: 10px 5px 5px;

        .grid-container();
        .grid-container__cell--w--full();
    }

    .grid-modal-checkbox {
        padding-top: 23px;
    }

    .grid-modal-textarea {
        height: 102px;
        vertical-align: top;
        resize: none;
    }
}
