.resource-timing-cell {
    text-align: left;
    vertical-align: middle;

    & > div {
        display: inline-block;
    }

    .no-load-time {
        height: 20px; // same as barheight in resource-timing-cell.html
        border-right: 1px solid #CCC;
    }

    .line {
        // Colors for everything comes from client-insight.less
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
    }
}
