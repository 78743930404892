@import (reference) '~ng/modules/diagram/components/double-arrow/double-arrow.component.less';

@security-text-blue: @arrow-color-blue;

.vs-log-cinematic-end-to-end-knot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;

    &__header {
        padding-bottom: 10px;
        white-space: nowrap;
    }

    & &__header--blue {
        color: @security-text-blue;
    }
}
