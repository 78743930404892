div.avi-modal.string-group {
    div.view-type-selector {
        margin-top: 25px;
        text-align: center;
    }
}

.string-group-container {
    display: block;
    position: relative;

    .string-group-items {
        display: block;
        position: relative;

        .or {
            position: absolute;
            top: 10px;
            left: -20px;
        }

        .string-group-item {
            display: flex;
            margin-bottom: 10px;

            .string-group-selector {
                flex: 1;
            }
        }
    }
}
