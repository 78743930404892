@import (reference) '../../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-e2e-timing-data-grid {
    display: block;
    max-height: @scrollable-content-max-height;
    overflow: auto;

    &__content {
        min-width: @signpost-content-min-width;
        max-width: 7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
