@import 'grid-table-header-cell-resize-handle/grid-table-header-cell-resize-handle';
@import 'grid-table-header-cell-sort-icon/grid-table-header-cell-sort-icon';

.c-grid-table-header-cell {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 auto;
    align-items: center;
    height: @gridTableHeaderHeight;
    padding-left: 10px;
    white-space: nowrap;
    //no overflow:hidden here due to resize-handle position between cells

    &--sortable {
        cursor: pointer;
    }

    //specific but still common fields
    &--field-name-- {
        &select-checkbox {
            @selectCheckboxWidth: 60px;
            width: @selectCheckboxWidth;
            //need max- cause otherwise initial rendering for few columns would make those too wide
            max-width: @selectCheckboxWidth;
        }

        &single-action {
            @singleActionsColumnWidth: 30px;

            .widthGenerator(6, @singleActionsColumnWidth)
        }
    }

    .widthGenerator(@i, @width) when (@i > 0) {
        &.c-grid-table-header-cell--w--@{i} {
            width: (@i * @width) + 20;
            max-width: (@i * @width) + 20;
        }

        .widthGenerator(@i - 1, @width)
    }

    //transcluded stuff (main content) goes in here
    &__content-wrapper {
        flex: 1 1 auto;
        overflow: hidden;
    }
}
