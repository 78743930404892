@import (reference) '../avi-dropdown.component.less';

.avi-dropdown-menu {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--avi-silver-gray);
    .avi-dropdown-border-radius();
    background: var(--cds-global-color-white);
    box-shadow: 0 1px 0.125rem rgba(115, 115, 115, 0.25);

    &__search {
        padding: 5px;

        .clr-form-control {
            margin-top: 0;

            input.clr-input {
                width: 100%;
            }
        }
    }
}
