// AviFrame

// We start with some resets

// *, *:before, *:after {
// 	.box-sizing();
// }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font: inherit;
    font-size: 100%;
    // Fix to be base-align as default
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

html {
    height: 100%;
    background-color: @backgroundGray;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

b, strong {
    font-weight: 500;
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.col(@numCol, @display: block, @float: left, @lgutter: 1, @rgutter: 1, @push: 0, @pull: 0) {
    display: @display;
    position: relative;
    width: (100% / @column * @numCol) - (@margin * 2) + @push - @pull;
    margin-right: 1% * @rgutter;
    margin-left: 1% * @lgutter;
    float: @float;
}


// Responsive Debugger
.responsiveDebug(@debug: false) when (true) {
    display: block;
}

// Breakpoints

@ptablet: ~'all and (max-width: 800px)';
@ltablet: ~'all and (min-width: 801px) and (max-width: 1100px)';
@ldisplay: ~'all and (min-width: 1101px)';

// It's Grid Time

@column: 16;
@width: 96%;
@gutter: 1%;
@margin: 1%;
