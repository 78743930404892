.backup-page {
    &__header-container {
        display: inline-flex;
    }

    &__card-container {
        display: flex;
        justify-content: space-between;
    }

    &__edit-btn {
        margin-top: 6px;
        text-transform: uppercase;
    }

    &__backup-overview-card {
        display: block;
        flex: 1 1;
        margin-top: 25px;
        margin-right: 30px;
    }

    &__local-backup-card {
        display: block;
        flex: 1 1;
        margin-top: 25px;
        margin-right: 30px;
    }

    &__remote-backup-card {
        display: block;
        flex: 1 1;
        margin-top: 25px;
    }

    &__collection-grid {
        margin-top: 10px;
    }
}
