@import (reference) '~ajs/less/themes/default-theme/theme.less';

@logBarchartPadding: 5px;

.log-barchart {
    .small-shadow-bottom();
    display: block;
    position: relative;
    height: 125px;
    margin-bottom: 15px;
    background-color: white;

    & > div[avi-loader] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 22px;
        margin: auto;
    }

    // d3 gunk below...
    .brush .extent {
        stroke-width: 2px;
        stroke: @aviBlue1;
        shape-rendering: crispEdges;
    }

    .axis {
        shape-rendering: crispEdges;
    }

    rect.barchart {
        stroke-width: 1;
    }

    rect.barchart.adf {
        stroke: @aviRed0;
        fill: @aviRed3;
    }

    rect.barchart.nf {
        stroke: @aviGreen0;
        fill: @aviGreen3;
    }

    /* icons used inside SVG */
    div.in-svg {
        i.icon-zoom-in {
            color: @aviBlue1;
            font-size: 1.3em;
            cursor: pointer;
        }

        i.icon-lostlog {
            color: @aviYellow0;
        }

        i.icon:before {
            margin: 0;
            padding-right: 1px;
        }
    }


    .y.axis, .x.axis {
        line, path {
            stroke: @aviGrey2;
            fill: none;
        }

        line.topline {
            stroke-width: 1;
        }
    }

    .highlight {
        //display:none;
        stroke: @aviPurple3;
    }

    &.events {
        height: 100px;

        rect.barchart {
            stroke: @aviGreen0;
            fill: @aviGreen3;
        }
    }

    g.waf-container {
        pointer-events: none;

        .waf-icon-container {
            display: block;
            position: absolute;

            .waf-icon {
                display: block;
                position: relative;
                width: 12px;
                height: 12px;
                transform: translate(-100%, -100%);
                border-radius: 100%;
                background-color: #485465;
                color: white;
                font-size: 8px;

                .icon-shield {
                    display: block;
                    position: relative;
                    transform: translate(2px, 2px);
                }
            }
        }
    }
}

div.chart-wrapper {
    position: relative;

    & > div.chart-legend {
        position: absolute;
        z-index: auto;
        top: 6px;
        right: 12px;

        span.chart-color-warning {
            font-size: .9em;
        }

        span.legend-pair {
            border-left: 0;
            cursor: pointer;

            &.not-clickable {
                cursor: not-allowed;

                &:hover {
                    text-decoration: none;
                }
            }

            &.non-significant-log-disabled {
                opacity: .5;
                cursor: not-allowed;
            }

        }
    }
}

// discreet loading bar
@aviLogProgressHeight: 3px;

.log-barchart-loading {
    height: @aviLogProgressHeight;

    .avi-progress {
        height: @aviLogProgressHeight;
        color: @aviBlue0;
    }
}

//logBarChart tooltip
div.logBarchartCarat {
    position: absolute;
    z-index: 102;
    width: 0;
    height: 0;

    //triangle arrow mixIn
    .boxArrow() {
        content: ' ';
        position: absolute;
        width: 0; height: 0;
        border: 10px solid transparent;
    }

    &:before {
        border-top-color: @aviGreySpecial;
        //to add border we need two overlaying triangles
        .boxArrow();
    }

    &:after {
        top: -1px;
        border-top-color: @aviWhite;
        .boxArrow();
    }

    &.inCallout {
        position: fixed;
        z-index: 1001;
    }
}
