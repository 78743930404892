@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.bot-logs {
    padding: 6px 20px;
    border-top: 1px solid @aviGreySpecial;

    &__title {
        margin-bottom: 25px;
        color: @textMidGray;
        font-size: @avi-baseline-px * 1.4;
        line-height: 1.2em;
    }

    &__subheader {
        padding-bottom: 5px;
        color: #666;
        font-weight: 700;
    }

    &__grid {
        margin-top: 25px;
    }

    & .c-grid__table-wrapper {
        margin: 1px;
    }

    // Have to override defaults to get varying backgrounds colors for each grid.
    .c-grid-table-row-expander {
        margin: 0;
        padding: 0;
    }

    &__warning-icon {
        display: inline-block;
        margin-right: 2px;
    }

    &__clickable-filter {
        color: @hyper-link;
    }

    &__consolidation-row {
        display: flex;

    }

    &__consolidation-row-card {
        padding: 15px;
        border: 1px solid var(--clr-table-border-color);
        border-bottom-width: 3px;
        border-radius: 3px;

        &--classification {
            flex: 1;
            margin-right: 25px;
        }

        &--consolidation-data {
            display: flex;
            flex: 2;
        }
    }

    &__consolidation-component-data {
        flex: 1;
        min-width: 0;
    }

    &__show-components-button {
        margin-top: 5px;
    }
}
