.prechecks-progress-modal {
    &__content {
        min-height: 318px;
    }

    & &__transcript-textarea {
        height: 140px;
        line-height: 20px;
        resize: none;
    }

    .prechecks-transcript-mixin();

    &__section {
        margin-top: 15px;
    }

    &__submit-btn {
        margin-left: 5px;
    }

    &__action-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

// To override readonly textarea's styles from .avi-textarea-container component
.prechecks-transcript-mixin() {
    .clr-form-control {
        margin-top: 0;

        .clr-textarea {
            &[readonly] {
                padding: 0.4rem 0.6rem;
                border: 1px solid var(--clr-table-border-color);
                font-size: 0.65rem;
                pointer-events: auto;
            }
        }
    }

    .avi-textarea-container {
        margin-top: 0;
    }
}
