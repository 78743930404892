.pool-health-score-body {
    margin: 0 14px;

    &__sparkline-graph {
        width: 320px;
        height: 75px;
        margin-bottom: 10px;
    }

    &__trending-score-label {
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}
