@import (reference) '~ajs/less/themes/default-theme/colors.less';

.modal-container-breadcrumbs {
    padding: 0 50px 0 15px;
    overflow: hidden;
    color: @aviGreySpecial;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__breadcrumb {
        padding-right: 5px;

        &:not(:last-child)::after {
            content: '>';
            padding-left: 5px;
        }
    }
}
