@import (reference) '~ajs/less/themes/default-theme/colors.less';

.license-summary-card {
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__license-title {
        display: flex;
    }

    &__license-title-icon {
        padding-right: 5px;
        color: @green;
    }

    &__license-title-text {
        font-size: 0.8em;
        line-height: 1.8;
        text-transform: uppercase;
    }

    &__body {
        color: @aviBlack;
        text-align: center;
    }

    &__license-usage-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__license-usage-section {
        display: flex;
        min-height: 182px;
        padding: 0.6rem 0.9rem 0;
        border-left: 1px solid var(--clr-table-border-color);
    }

    &__license-details {
        display: flex;
    }

    &__license-usage {
        width: 290px;
        height: 100%;
        padding: 0.6rem 0.9rem 0;
    }

    &__available-service-units {
        padding: 0 0 0.9rem;
        border-bottom: 1px solid #D3D3D3;
    }

    &__org-id {
        padding-bottom: 18px;
    }

    &__controller-max-allowed {
        padding: 0.9rem 0;

        &:first-child {
            padding-top: 0;
        }
    }

    &__reserved-licenses {
        padding-bottom: 0.6rem;
    }
}
