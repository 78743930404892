.avi-data-grid {
    &__childGrid {
        width: 100%;
        .pki-profile-list-clarity-overrides();
    }

    &__loader {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.pki-profile-list-clarity-overrides() {
    .clr-wrapper {
        .datagrid-select {
            .clr-control-label {
                padding-left: 0;
            }
        }

        .datagrid-table {
            .datagrid-column {
                .datagrid-column-separator {
                    display: block;
                }
            }
        }

        .datagrid-table .datagrid-cell.datagrid-fixed-width {
            padding-top: 0.55rem;
        }

        .datagrid-table .datagrid-cell.datagrid-fixed-column {
            padding-top: 0.55rem;
        }

        .avi-data-grid__cell-transform {
            padding-top: 0.55rem;
        }
    }
}
