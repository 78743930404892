.user-menu-additional-menu {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &__list {
        width: 330px;
        background-color: var(--cds-global-color-white);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);

        .user-menu-additional-menu__item {
            display: block;
            padding: 15px;
            color: var(--cds-global-color-gray-600);
            cursor: pointer;

            &:hover {
                background-color: var(--cds-global-color-gray-100);
                text-decoration: none;
            }
        }
    }

    &__list-item {
        &:not(:last-child) {
            border-bottom: 1px solid var(--clr-table-border-color);
        }
    }
}
