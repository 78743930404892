.seg-image-selection-modal {
    &__content-container {
        width: 100%;
    }

    &__data-grid {
        .seg-images-data-grid-overrides();
    }
}

.seg-images-data-grid-overrides() {
    .avi-collection-data-grid {
        margin-top: 1.5rem;
    }

    .avi-data-grid {
        margin-top: 0;

        /**
         * Hide action bar because 'Delete' button is shown when we select any row.
         * we dont require any multi-action for this grid.
         */
        &__action-bar {
            display: none;
        }
    }
}
