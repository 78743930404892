.nodes-page {
    &__header-card {
        display: inline-block;
        min-width: 300px;
        max-width: 30%;
        margin-bottom: 24px;
    }

    .nodes-page-clarity-overrides();
}

.nodes-page-clarity-overrides() {
    h6:not([cds-text]) {
        color: var(--cds-global-color-blue-700);
    }
}
