@import (reference) '~ajs/less/layout/grid-container.less';

.tech-support {
    .grid-container();

    &__capture-section {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #E2E6EA;
        border-radius: 2px;
        background-color: white;

        &--ready {
            justify-content: center;
        }

        &--in-progress {
            padding-left: 10px;
        }

        &-resource-name, &-complete-message {
            font-weight: 500;
        }

        &-text {
            display: inline-block;
            padding-left: 3px;
        }
    }

    &__insufficient-permissions-warning-header {
        margin-bottom: 5px;
        font-weight: bold;
    }
}

.tech-support-expander {
    margin-left: 90px;

    &__file-name-label {
        .mr5();
    }
}
