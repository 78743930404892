// Don't have a cds variable for #eee yet.
@import (reference) '~ajs/less/themes/default-theme/colors.less';

// z-index value.
@zIndex: 999;

.custom-tags {
    position: relative;

    &__disabled {
        position: absolute;
        z-index: @zIndex;
        inset: 0;
        opacity: 0.65;
        background-color: @aviHealthDisabled1;
    }
}
