.dns-records-tab__grid {
    .c-grid-table-header-cell--field-name--type {
        width: 12%;
    }

    .c-grid-table-header-cell--field-name--ttl {
        width: 10%;
    }

    .c-grid-table-header-cell--field-name--algorithm {
        width: 13%;
    }
}
