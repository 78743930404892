@import (reference) '~ajs/less/pages/application/pool-create.less';

.avi-virtualservice-create {
    textarea {
        resize: vertical;
    }

    div.warning.select-cloud {
        margin: 1em;
    }

    .clientIp, .path, .query {
        display: inline-block;
        width: 60%;

        .avi-btn-group {
            width: 100%;

            .match-operation {
                border-right: none;
                line-height: 33px;

                checkbox {
                    margin-right: 3px;
                    margin-bottom: -2px;
                    margin-left: 3px;
                }
            }

            ip-or-group-list {
                [ng-repeat]:first-child {
                    collection-dropdown-custom {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }

    .controls {
        margin-left: 0;
        // This height was breaking domain lookup
        // height: @buttonHeight;
        // margin-bottom: @standardMargin;

        div[avi-loader] {
            position: absolute;
            top: 0;
            right: 20px;
            line-height: 35px;
        }
    }

    .logfilters-table {
        width: 60%;
    }

    .panel, .panel.transparent {
        border: 0;

        .panel-body {
            padding: 0;
        }
    }

    e-auto-complete.placement-subnet {
        width: 50%;
    }

    .add-pool-servers-mixin();

    .analytics-form {
        margin: 0;
        padding: 0;
        list-style: none;

        & > li {
            display: inline-block;
            width: 24%;
            height: 77px;
            margin: 0;
            float: left;
            border-right: 1px solid #CCC;

            .control-group {
                box-sizing: border-box;
                margin-top: -5px;
                padding: 0 20px;
            }

            input[type=checkbox] {
                margin-right: 5px;
            }

            .comment {
                font-size: 12px;
            }
        }

        li:last-child {
            border-right: 0;
        }
    }

    .summary {
        h2 {
            margin-top: @standardMargin;
        }

        .grid {
            .actions-panel {
                display: none;
            }
        }
    }

    .remove-rounded-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .basic-advanced {
        position: absolute;
        top: 21px;
        right: 0;
    }

    .service-port-list {
        display: block;
        position: relative;
        width: 70%;

        .service-port {
            display: flex;
            position: relative;
            flex: 1;
            align-items: stretch;
            justify-content: flex-start;
            border-radius: 3px;

            .service-port-input-container {
                display: inline-flex;
                position: relative;
                flex: 1;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                border-right: 1px solid @borderGray;

                .service-port-input {
                    flex: 1;
                    min-width: 20px;
                    margin: 0;
                }

                .advanced-service-port {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: flex-start;

                    .to {
                        margin: 0 30px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }

            .service-port-addon {
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0 10px;

                &--enable-http2 {
                    border-right: 1px solid @borderGray;
                }
            }
        }
    }

    .address.aws-dns {
        width: 100%;
    }

    dropdown {
        &.url-pattern {
            width: 30%;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0
        }
    }

    collection-dropdown-custom {
        &.url-string-group {
            width: 70%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }
    }

    &__ssl-certificate {
        margin-top: 15px;
    }
}
