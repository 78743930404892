@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.waf-logs-list {
    &__container {
        margin-bottom: 5px;
    }

    &__expander {
        display: block;
        margin-bottom: 2px;
    }

    &__expander-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;
        padding: 6px 10px;
        border: 1px solid @avi-border-color;
        background-color: @avi-border-color;

        &-text {
            color: @aviBrandColor;
            font-size: @avi-baseline-px * .625;
            font-weight: 600;
            text-transform: uppercase;
        }

        &-link {
            font-size: @avi-baseline-px * .75;
        }
    }
}
