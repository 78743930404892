@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ng/modules/update/components/system-update/system-update.component.less';

@tile-separator: thin solid @backgroundGray;

.update-node-status-card {
    &__main-header {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        background-color: var(--cds-global-color-white);

        &__tile {
            padding: 8px;

            &--footer {
                flex-grow: 1;
            }

            &--with-icon {
                display: flex;
                align-items: center;
            }

            &-action-icons-container {
                margin-left: auto;
            }

            &-action-icon {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 5px
                }
            }

            &-title {
                font-weight: bold;

                &--started {
                    margin-top: 7px;
                }
            }

            &-icon-container {
                margin-right: 5px;
            }

            &-header-icon {
                .update-page__update-card-header-icon-mixin();
            }

            &-icon {
                margin-right: 5px;
            }
        }
    }

    .card-footer {
        padding: 0;
    }

    .clarity-override()
}

.clarity-override() {
    .card-block {
        padding: 0;
    }

    .card-header {
        padding: 0 0;
        color: var(--cds-global-color-gray-666);
        font-size: 0.7rem;
        font-weight: none;
    }

    .card {
        margin: 10px 0;
    }
}
