//also for GslbPoolMemberForm directive
.gslb-service-edit {
    .gslb-modals-mixin();

    div.domain-name-wrapper {
        position: relative;

        div.trash-button {
            bottom: 10px;
        }
    }

    div.control-group.consistent-hash-mask {
        margin-top: 10px;
    }

    div.control-group.fallback-algorithm {
        margin-top: 10px;
    }

    label.flex-checkbox {
        justify-content: center;
    }

    div.avi-btn-group.basic-create-mode-switch {
        display: inline-flex;
        flex: initial;
        width: initial;
    }

    gslb-pool-member-domain-name {
        flex: 1 1 0;
    }

    &__cname-field {
        margin-top: 44px;
    }

    &__cname-checkbox {
        margin-right: 5px;
    }
}
