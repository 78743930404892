.vs-vip-address-grid-cell {
    &__state-icon {
        margin-right: 6px;
        cursor: pointer;

        &--up {
            fill: var(--cds-global-color-green-600);
        }

        &--pending {
            fill: var(--cds-global-color-gray-500);
        }

        &--down {
            fill: var(--cds-global-color-red-700);
        }
    }

    &__item {
        margin-right: 15px;
    }

    &__item-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__popover-caret {
        position: relative;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
    }
}

.right {
    .vs-vip-address-grid-cell__popover-caret {
        border-right: 0.5rem solid var(--cds-global-color-gray-400);
    }
}

.left {
    .vs-vip-address-grid-cell__popover-caret {
        left: 100%;
        border-left: 0.5rem solid var(--cds-global-color-gray-400);
    }
}
