@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.avi-dropdown {
    &__container {
        display: flex;
        box-sizing: border-box;
        min-height: 24px;
        border-bottom: 1px solid var(--clr-forms-border-color);
        cursor: pointer;

        &--unclickable {
            cursor: default;
        }

        &--readonly {
            border-bottom: none;
            cursor: initial;
        }

        &--disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }

    &__opener {
        display: flex;
        flex: 1;
        align-items: flex-end;
        justify-content: space-between;
        min-width: 0;
    }

    &__selected-values-container {
        flex: 1;
        min-width: 0;
    }

    &__placeholder {
        padding: 0 .3rem 3px .3rem;
        opacity: .54;
        color: var(--clr-forms-text-color);
        font-size: var(--cds-global-typography-font-size-4);
    }

    &__opener-icons {
        display: flex;
        align-items: flex-end;
    }

    &__opener-icon {
        margin-bottom: 3px;
        margin-left: 5px;
        cursor: pointer;

        &:last-child {
            padding-left: 0;
        }

        &.avi-dropdown__opener-icon--times-circle {
            --color: var(--clr-btn-link-color);
            margin-right: 7px;
        }

        &--unclickable {
            cursor: default;
        }
    }

    &__helper-text {
        margin-top: 3px;
        font-size: .55rem;
    }

    &__view-mode-container {
        padding: 4px 6px 0;
        color: var(--clr-forms-text-color);
    }

    &__view-mode-option:not(:last-child) {
        margin-bottom: 5px;
    }
}

.avi-dropdown-border-radius() {
    border-radius: 0.15rem;
}
