@import (reference) '~ajs/less/themes/default-theme/pixels.less';
@import (reference) '../tenant-selector/tenant-selector.less';

.controller-site-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: @avi-baseline-px * 9;
    min-width: @avi-baseline-px * 5.5;
    height: @aviTopHeaderHeight;
    margin: 0 15px 0 0;

    &__collection-dropdown {
        .navbar-dropdown-overrides();
        width: inherit;
    }

    &__dropdown-popover {
        top: 61px !important;
    }
}
