@import (reference) '~ajs/less/themes/default-theme/colors.less';

.rbac-label-grid {
    margin-top: 1rem;

    // override Clarity data grid cell style
    & clr-dg-cell.datagrid-cell {
        padding-bottom: 0 !important;
    }

    &__error-msg {
        margin-top: 0.6rem;
    }

    &__error-msg-text {
        color: @textMidGray;
    }
}
