.control-group {
    width: 100%;
    margin-bottom: @standardMargin;

    & > label {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > i {
            //fix for help icon inside label when coming with the checkbox
            vertical-align: inherit;

            &[class^='icon-']:before, &[class*=' icon-']:before {
                vertical-align: inherit;
            }
        }

        &.inline-block {
            //to avoid having too wide area triggering input's onClick
            display: inline-block;
            width: auto;
        }
    }

    & > .controls {
        display: inline-block;
        position: relative;
        width: 100%;

        & > input[type=checkbox],
        input[type=radio] {
            width: auto;
        }

        & > input,
        select,
        dropdown,
        collection-dropdown,
        collection-dropdown-custom,
        textarea,
        .inner-text {
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 0;
        }

        & > input, select, dropdown, collection-dropdown, collection-dropdown-custom {
            min-height: @buttonHeight;
        }

        & > .icon-ok {
            padding-left: 5px;
            color: #BBB;
        }

        input[type=text], input[type=number] {
            &.subcontrols {
                width: 95%;
            }
        }
    }
}

.h-controls-group {
    input {
        width: 100%;
    }

    .control-group:not(.preserve) {
        margin: 0;
    }

    .h-control {
        collection-dropdown, dropdown {
            width: 100%;
        }
    }
}

.flex-input-append {
    display: flex;
    box-sizing: border-box;
    height: 35px;
    border: 1px solid @borderGray;
    border-radius: @aviBorderRadius;

    & > * {
        &:not(:first-child) {
            border-left: 1px solid @borderGray;
        }

        &:first-child {
            .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
        }

        &:last-child {
            .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);
        }
    }

    collection-dropdown, dropdown, input {
        height: 33px;
        min-height: inherit;
        border: none;
        border-radius: 0;

        &:disabled {
            border: none;
        }
    }

    div[avi-loader] {
        box-sizing: border-box;
        height: 33px;
        border-left: none;
    }

    .inner-text .add-on {
        top: 0;
        right: 0;
    }
}

.input-append {
    input[type=text],
    input[type=password],
    input[type=number],
    dropdown,
    collection-dropdown,
    collection-dropdown-custom {
        .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
    }

    .avi-btn {
        .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
        display: inline-block;
        padding: 0 10px;
        float: left;
        border: 1px solid #CCC;
        line-height: 35px;
    }

    .add-on {
        display: inline-block;
        box-sizing: border-box;
        height: @buttonHeight;
        padding: 0 5px;
        float: right;
        border: 1px solid @borderGray;
        border-left: none;
        background-color: @aviBackgroundColor;
        line-height: @buttonHeight;
        .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);
    }
}

.dropdown-prepend {
    @height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    border: 1px solid @borderGray;
    border-radius: @aviBorderRadius;
    background-color: white;
    color: @textDarkGray;

    .prepend {
        padding: 0 5px 0 15px;
        color: inherit;
    }

    dropdown, collection-dropdown {
        border: 0;
        border-radius: 0;
        background-color: transparent;

        .dropdown-container {
            .expand, .choice {
                height: @height;
                margin-left: 0;
                line-height: @height;
            }
        }

        .dropdown-value-container {
            height: @height;
            padding: 0;
            line-height: @height;
        }
    }
}

.input-prepend {
    input[type=text],
    input[type=password],
    input[type=number],
    dropdown,
    collection-dropdown,
    collection-dropdown-custom {
        .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);
    }

    .avi-btn {
        .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);
        display: inline-block;
        padding: 0 10px;
        border: 1px solid @borderGray;
        line-height: 35px;
    }

    .add-on {
        .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
        display: inline-block;
        box-sizing: border-box;
        height: @buttonHeight;
        padding: 0 5px;
        float: left;
        border: 1px solid @borderGray;
        border-right: none;
        background-color: @aviBackgroundColor;
        line-height: 35px;
    }

    .inner-text {
        .add-on {
            top: 0;
            right: 4px;
            height: 35px;
        }
    }
}

.control-caption {
    display: inline-block;
    position: relative;
    max-width: 90%;
    word-break: normal;
    white-space: normal;
}
