@import (reference) './button.less';
@import (reference) '~ajs/less/fonts/avi.less';

switch {
    display: inline-block;
    cursor: pointer;

    .switch-state {
        width: 50px;
        height: 25px;
        border-radius: 15px;
        background-color: #E55B24;
        color: #FFF;
        /* stylelint-disable-next-line */
        font-family: 'avi';
        font-size: 14px;
        line-height: 20px;

        .true {
            width: 19px;
            height: 19px;
            margin: 2px;
            float: left;
            border: 1px solid #3FA1E1;
            border-radius: 15px;
            background: #67B0CB;
        }

        .true.selected {
            margin-right: -7px;
            margin-left: 7px;
            border: none;
            background-color: #AFCC48;

            &:before {//have to use this because of auto generated icon styles
                /* stylelint-disable-next-line */
                font-family: 'avi';
                &:extend(.icon-check:before);
            }
        }

        .false {
            width: 19px;
            height: 19px;
            margin: 2px;
            float: right;
            border: 1px solid #3FA1E1;
            border-radius: 15px;
            background: #67B0CB;
        }

        .false.selected {
            border: none;
            background-color: #E55B24;

            &:before {
                /* stylelint-disable-next-line */
                font-family: 'avi';
                &:extend(.icon-minus:before);
            }
        }
    }

    .switch-state.enabled {
        background-color: #AFCC48;
    }

    &.small {
        .switch-state {
            width: 40px;
            height: 20px;

            .true {
                width: 14px;
                height: 14px;

                &.selected {
                    margin-top: 0;
                    margin-left: 5px;
                }
            }

            .false {
                width: 14px;
                height: 14px;

                &.selected {
                    margin-top: 1px;
                }
            }
        }
    }
}

switch.vertswitch {
    display: inline-block;
    padding: 1px;
    text-align: center;
    cursor: pointer;

    .switch-state {
        position: relative;
        width: 20px;
        height: @buttonHeight;
        border-radius: 15px;
        background-color: @aviGrey0;
        color: #FFF;
        /* stylelint-disable-next-line */
        font-family: 'avi';
        font-size: 10px;
        line-height: 20px;
        text-align: center;

        .true, .false {
            position: absolute;
            width: 14px;
            height: 14px;
            margin: 0 2px;
            border: 1px solid darken(@aviBlue0, 10%);
            background: @aviBlue1;
            /* stylelint-disable-next-line */
            font-family: 'avi';

            &.selected {
                width: 16px;
                height: 16px;
                border: none;
                background-color: transparent;
            }
        }

        .true {
            bottom: 2px;
        }

        .false {
            top: 2px;
        }

        &.enabled {
            box-sizing: border-box;
            background-color: @aviGreen1;
        }
    }
}
