.licensing-page-header {
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    &__header-text {
        display: flex;
        padding-right: 12px;
        font-size: 1.3rem;
        font-weight: 300;
    }

    &__status-container {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
    }

    &__header-icon {
        :hover {
            cursor: pointer;
        }
    }

    &__alert {
        margin-bottom: 20px;
    }
}
