@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/components/button.less';

.waf-top-hits-logs-list-modal {
    width: 90%;

    &_filters-wrapper {
        display: flex;
        margin-bottom: 20px;
        padding: 4px;
        background-color: @aviGrey3;

        &-left {
            display: flex;
            flex: 1 1 85%;
            flex-wrap: wrap;
            font-size: 1.1em;

            capsule {
                margin: 3px;
            }
        }
    }

    &_go-to-logs-button {
        flex: 1 0 auto;

        .avi-btn();
        .avi-btn-primary();
    }

    log-timeline {
        margin-right: 2em;
    }
}
