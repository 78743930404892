.full-modal-config-header-tabs {
    & &__tabs-list {
        display: flex;
        align-items: flex-end;
        padding: 0 24px;
    }

    &__tab {
        box-sizing: border-box;
        margin-right: 24px;
        padding: 10px 5px 3px 5px;
        color: var(--clr-nav-link-color);
        font-size: .8rem;
        cursor: pointer;

        &:hover, &--active {
            padding-bottom: 0;
            border-bottom: 3px solid var(--clr-color-action-600);
        }

        &--active {
            color: var(--clr-nav-link-active-color);

            &:hover {
                background-color: var(--clr-nav-active-bg-color, transparent);
            }
        }

        &--disable {
            color: #999;
            cursor: not-allowed;
        }
    }
}
