@import (reference) '~ajs/less/themes/default-theme/colors.less';

div[pie-chart] {
    position: relative;

    & > svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        path.placeholder {
            stroke-width: 2;
            stroke: @aviDisabled;
            fill: none;
        }
    }
}
