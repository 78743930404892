.host-hdr-match {
    &__input-container {
        display: block;

        &--value {
            margin-top: 10px;
            margin-left: 15px;
        }
    }
}
