@import (reference) './panel.less';

@modalHeaderBackground: @panelHeaderColor - #111;
@modalHeaderBorder: @panelHeaderColor - #222;
@modalTextColor: #555;

.avi-modal {
    display: none;
    position: fixed;
    box-sizing: unset;
    width: 85%;
    min-width: 850px; //makes sense for the small resolutions
    height: 90%;
    min-height: 200px;
    border: 1px solid @aviGreySpecial;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    color: @aviBodyColor;
    font-family: @fontFamily;
    .border-radius(@aviBorderRadius);

    p {
        margin-bottom: @standardMargin;
    }
    //Modal Header
    h1 {
        margin: 0;
        padding: 0 15px;
        color: @aviWhite;
        font-size: 1.286em;
        line-height: 44px;
        text-shadow: none;
    }

    h2 {
        margin-top: @standardMargin;
        margin-bottom: @standardMargin;
        padding-top: 5px;
        border-top: 1px solid @aviGreySpecial;
        box-shadow: 0 -2px 3px rgba(0, 0, 0, .1);
        font-size: 1.286em;
        text-align: center;

        &:before, &:after {
            content: '•';
            padding: 0 @standardMargin/3;
        }

        &.confirmation {
            border: none;
            box-shadow: none;

            &:before, &:after {
                content: '';
            }
        }
    }

    h3 {
        font-size: 1em;
        font-weight: bold;
    }

    .edit-mode {
        h2 {
            border-color: @aviGreen0;
            color: @aviGreen0;
        }
    }

    .avi-modal-header {
        position: relative;
        z-index: 10;
        box-sizing: unset;
        height: 44px;
        margin: -1px -1px 0 -1px;
        padding: 0;
        border: 1px solid darken(@aviBrandColor2, 20%);
        border-bottom: 2px solid darken(@aviBrandColor2, 20%);
        background-color: @aviBrandColor2;
        line-height: 44px;

        .divider {
            width: 0;
            height: 44px;
            border-right: 1px solid rgba(49, 20, 20, 0.25);
            border-left: 1px solid darken(@aviBrandColor2, 20%);
        }

        .element-section {
            float: left;
            font-size: 1em;
        }

        .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);

        .close {
            position: relative;
            width: 44px;
            height: 44px;
            margin: 0;
            float: right;
            opacity: 1;
            color: lighten(@aviBrandColor2, 40%);
            text-shadow: none;

            &:hover {
                background-color: lighten(@aviBrandColor2, 20%);
                color: @aviWhite;
                text-decoration: none;

                &.disabled { //used for help button only
                    background: inherit;
                    color: lighten(@aviBrandColor2, 40%);

                }
            }
        }
    }

    .avi-modal-body {
        position: absolute;
        top: 47px;
        bottom: 45px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        padding: 15px;
        overflow-y: auto;
    }

    .avi-modal-body.reduced-height {
        top: 77px;
    }

    .scrollable {
        overflow-x: hidden;
    }

    // section of the modal (below the header tag)
    .modal-section {
        margin-top: @modalSectionVerticalMargin;
        margin-bottom: @modalSectionVerticalMargin;
    }

    // Common Summary Page
    .summary {
        h1.name-summary {
            margin: 0;
            padding: 0;
            border-bottom: 1px solid @aviGreySpecial;
            color: @aviGrape0;
            font-size: 1.286em;
        }

        .information-summary {
            display: block;

            label {
                font-weight: bold;
            }

            p {
                font-size: 1em;
            }
        }
    }

    .avi-modal-footer {
        display: block;
        position: absolute;
        // To get past the border
        bottom: 1px;
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        padding: 0;
        border-top: 1px solid @aviGreySpecial;
        background-color: @aviBackgroundColor;
        box-shadow: inset 0 1px 0 @aviWhite;
        .border-radiuses(0, @aviBorderRadius, @aviBorderRadius, 0);

        button {
            &.pull-left, &.pull-right {
                height: 45px;
                margin: 0;

                &.avi-btn {
                    min-width: 75px;
                }

                &.avi-btn:hover {
                    border-bottom-width: 0;
                }
            }

            &.pull-left {
                .border-radiuses(0, 0, @aviBorderRadius, 0);
                border-width: 0 1px 0 0;
            }

            &.pull-right {
                .border-radiuses(0, @aviBorderRadius, 0, 0);
                border-width: 0 0 0 1px;
            }

            &.prev {
                .border-radius(0);
            }
        }

        .margin9 {
            margin: 8px 9px 9px 9px;
        }

        & > div[avi-loader] {
            margin-top: .75em;
        }
    }

    .modal-confirm {
        display: block;
        position: absolute;
        z-index: 10001;
        top: 46px;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 10%;
        overflow: hidden;
        background: rgba(255, 255, 255, .9);
        text-align: center;
        .border-radiuses(0, @r, @r, 0);
    }
}

.avi-modal.about {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 500px;
    min-width: 500px;
    height: 450px;
    margin: auto;

    & > div.body {
        margin: 5%;

        p {
            margin: 0;
        }

        & > div.logo {
            height: 150px;
            margin-bottom: 1em;
            background-image: url(../../img/newAviLogo.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 210px 120px;
        }

        & > div.name-version {
            & > p.name {
                color: @aviBrandColor;
                font-size: x-large;
            }
        }

        & > div.copyright {
            font-size: x-small;
        }
    }
}
