.json-viewer {
    &__modal-content {
        width: 100%;
        min-height: 3rem;
        padding: 0 0.5rem;
        border: 1px solid var(--clr-table-border-color);
        border-radius: 4px;

        & &-config {
            overflow: scroll;
            border: none;
            white-space: pre-wrap;
        }
    }

    &__alert-group {
        margin-bottom: 1rem;
    }

    &__actions-wrapper {
        position: absolute;
        right: 0;
        padding: 0.5rem;
    }

    &__action-icon {
        --color: var(--cds-alias-status-info);
        margin: 0 0.25rem;
        cursor: pointer;
    }
}
