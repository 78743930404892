.pool-group-list-page {
    .avi-data-grid {
        &__child-grid {
            width: 100%;
            padding: 0 16px 16px;
            .pool-group-list-clarity-overrides();
        }

        .avi-health-score-overrides();
    }
}

.pool-group-list-clarity-overrides() {
    .avi-label-with-tooltip__label {
        color: var(--clr-table-font-color);
        font-weight: normal;
    }

    .avi-data-grid__child-grid .avi-data-grid__cell-transform {
        padding-top: 0;
    }
}

.avi-health-score-overrides {
    .avi-health-score__outer {
        margin-top: 5px;
    }
}
