@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.icap-logs {
    padding: 6px 20px;
    border-top: 1px solid @aviGreySpecial;

    &__title {
        margin-bottom: 25px;
        margin-left: -10px;
        color: @textMidGray;
        font-size: @avi-baseline-px * 1.4;
        line-height: 1.2em;
    }

    &__action {
        &-section {
            margin-left: 11px;
        }

        &-title {
            margin-bottom: 7px;
            color: @aviBlack;
            font-size: 16px;
            font-weight: 300;
        }

        &-entry {
            margin-top: 8px;
            color: @hyper-link;
            font-size: 14px;
        }
    }

    &__grid {
        margin-top: 25px;
    }


    & .c-grid__table-wrapper {
        margin: 1px;
    }

    // Have to override defaults to get varying backgrounds colors for each grid.
    .c-grid-table-row-expander {
        margin: 0;
        padding: 0;
    }

    &__warning-icon {
        display: inline-block;
        margin-right: 2px;
    }

    &__clickable-filter {
        color: @hyper-link;
    }
}
