.vs-logs-editable-filter {
    display: flex;
    align-items: center;

    &__tag {
        &:focus {
            outline: none;
        }

        &-container {
            display: flex;
        }

        &-content {
            font-size: var(--clr-btn-appearance-form-font-size);
        }

        &-close-icon {
            margin-left: 3px;
        }
    }
}
