@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

a.state-button {
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &--main-menu {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0 10px 0 30px;
        color: @textMidGray;
        font-size: 18px;
        font-weight: 100;

        &:hover {
            background-color: rgba(0, 0, 0, .1);
            color: @textMidGray;
        }
    }

    &--category {
        display: flex;
        align-items: center;
        justify-content: center;
        height: @aviTopHeaderHeight;
        padding: 0 10px;
        opacity: .65;
        color: @alb-white;

        &--active {
            opacity: 1;
            background-color: @alb-active-state-button-bg-color;
        }
    }
}
