.avi-dropdown {
    &__success-text {
        margin-top: 3px;
        color: var(--cds-alias-status-success);
        font-size: .55rem;
    }

    &__error-text {
        margin-top: 3px;
        color: var(--cds-alias-status-danger);
        font-size: .55rem;
    }
}
