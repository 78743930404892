.cportal-info-modal {
    & &__label-font {
        span {
            font-weight: normal;
        }
    }

    & &__case-management-checkbox-container {
        margin-top: 0.3125rem;
    }

    &__alert {
        margin-top: 10px;
    }
}
