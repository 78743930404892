@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.avi-header {
    display: flex;
    z-index: @headerZindex;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    flex-direction: column;
    min-width: 768px;
    height: @aviTopHeaderHeight;
    background-color: @alb-nav-background-color;
    color: @alb-white;

    &__navigation {
        display: flex;
    }

    &__profile-options {
        display: flex;
        justify-content: flex-end;
        margin-right: 5px;
        margin-left: auto;
    }
}
