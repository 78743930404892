@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-e2e-timing {
    max-width: @custom-e2e-signpost-max-width;

    &__loader {
        overflow: hidden;
        text-align: center;
    }
}
