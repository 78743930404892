/**
 * Global variables for colors.
 */

:root {
    /**
     * Colors from Clarity palette that are not tokenised in Clarity.
     */
    --avi-clr-viking-blue: hsl(198, 58%, 78%);
    --avi-clr-turquoise-blue: hsl(198, 54%, 92%);
    --avi-clr-prussian-blue: hsl(198, 100%, 36%);

    /**
     * Avi-defined global variables for colors.
     * Color names taken from http://chir.ag/projects/name-that-color
     */
    --avi-daintree-blue: #002438;
    --avi-lochmara-blue: #0079B8;
    --avi-scarlet-red: #E12200;
    --avi-milano-red: #C92100;
    --avi-silver-gray: #CCC;
    --avi-gallery-gray: #EEE;
    --avi-alto-gray: #DDD;
    --avi-dusty-gray: #9A9A9A;
    --avi-scorpion-gray: #565656;
    --avi-mystic-white: #D9E4EA;
    --avi-ebonyclay-blue: #25333D;
    --avi-tundora-gray: #454545;
    --avi-morning-glory-blue: #89CBDF;
    --avi-iceberg-blue: #E1F1F6;
}
