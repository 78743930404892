@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.tooltip2 {
    display: block;
    position: absolute;
    z-index: @aviSelectZindex;
    box-sizing: border-box;
    border: 1px solid @aviGreySpecial;
    background: @aviWhite;
    pointer-events: none;
}

.tooltip2-text {
    padding: 10px;
    overflow-wrap: break-word;
}

.tooltip2-title {
    padding: 5px 10px;
    background: @aviGreySpecial;
    color: @aviWhite;
}
