@import (reference) '~ajs/less/themes/default-theme/colors.less';

.button-progressbar() {
    border: none;
    opacity: .4;
    background-color: @borderGray;
}

.button-loader {
    display: grid;

    & &__progressbar {
        .button-progressbar();

        &:hover {
            .button-progressbar();
        }
    }
}
