@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';

.plt-down-arrow {
    height: 10px;
    margin-top: 2px;

    .box {
        height: 2px;
        background-color: @aviGreen1;
        .border-radiuses(0, @aviBorderRadius, @aviBorderRadius, 0);

        .down-arrow {
            width: 0;
            height: 0;
            margin: auto;
            border-width: 8px 5px 0 5px;
            border-style: solid;
            border-color: @aviGreen1 transparent transparent transparent;
        }
    }
}
