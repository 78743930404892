@import (reference) '~ajs/less/themes/default-theme/colors';
@import (reference) '~ajs/less/components/button';
@import (reference) '~ajs/less/fonts/avi';
@import (reference) '~ajs/less/themes/default-theme/theme.less';

// Will TODO -- make the css and classNames in this files less confusing
@column-side-width: 240px;
@right-section-collapse-width: 20px;

.two-column-container {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;

    .performance-chart,
    .stacked-bar-chart,
    .scatter-plot.lg {
        height: 400px;
    }

    .side-menu-container {
        position: relative;

        dropdown {
            display: block;
            box-sizing: border-box;
            margin: 10px;
            float: none;
            border: 1px solid @borderGray;

            .expand {
                height: auto;
            }
        }
    }

    .menuCollapse {
        line-height: @buttonHeight;
    }

    .left-section, .right-section {
        display: flex;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        width: 100%;
    }

    .left-section {
        right: @column-side-width;
        width: calc(100% - @column-side-width);

        &.expanded {
            right: @right-section-collapse-width;
            width: calc(100% - @right-section-collapse-width);
        }

        &.no-sidebar {
            right: 0;
            width: 100%;
        }

        div[ui-view].main {
            height: 100%;
            overflow: auto;
        }
    }

    .right-section {
        position: relative;
        z-index: 5;
        right: 0;
        left: auto;
        width: @right-section-collapse-width;
        overflow: hidden;
        overflow-y: auto;
        background-color: @aviWhite;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        .right-section-content {
            display: none;
            position: relative;
            border-left: 1px solid @backgroundDarkGray;
        }

        &.expanded {
            box-sizing: border-box;
            width: @column-side-width;

            .right-section-content {
                display: block;
            }

            .healthscore-group {
                padding: 10px;

                unit-card {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 10px;

                    &:not(:last-child)&:not(.poolgroup-pool) {
                        margin-bottom: 10px;
                    }

                    .unit-card {
                        width: 100%;
                        height: 40px;

                        .card-info {
                            .health-score {
                                width: 20px;
                                height: 20px;
                                text-indent: -999px;
                            }
                        }
                    }
                }

                &.server-page {
                    padding: 10px 20px 10px 20px;

                    & > div > dropdown {
                        width: 88%;
                        margin: 5px 0 0 auto;
                        float: none;

                        //arrow
                        &:before {
                            position: absolute;
                            top: 8px;
                            left: -25px;

                            &:extend(.icon-level-up:before);
                        }
                    }
                }
            }
        }
    }

    &.left-menu {
        .left-section {
            .shift-right {
                .alertsTimeAndHealthscore {
                    margin-right: 0;
                }

                .avi-nav {
                    margin-left: 30px;
                }
            }

            &.expanded {
                padding-right: 0;
                padding-left: @column-side-width;
            }
        }

        .right-section {
            border-right: 1px solid @aviGreySpecial;
        }
    }
}

div.operations, div.administration {
    position: relative;
    height: 100vh;
}

.right-section {
    .small-cards {
        &.card-styling, &.pie-chart-card-styling, & .card-styling, & .pie-chart-card-styling,
        & .sparkline-card-styling {
            box-sizing: border-box;
            padding: 10px 0 0 20px;
        }

        &.card-styling, & .card-styling {
            padding: 0;
        }

        &.card-styling.larger-side-card {
            box-sizing: border-box;
            height: 85px;
            padding: 5px 0;
            padding-left: 5px;
        }

        .lg-txt {
            font-size: 1em;
        }

        .sparkline-styling, .sparkline {
            height: 30px;
            padding-right: 20px;
        }
    }
}

.button-side-column {
    display: inline-block;
    position: relative;
    width: 15px;
    padding: 10px 0 10px 10px;
    transition: none;
    border-left: 1px solid @backgroundDarkGray;
    color: #000;
    font-size: 12px;
    cursor: pointer;

    .icon.icon-chevron-left {
        display: none;
    }

    &.collapsed {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 5px 2px;

        .icon.icon-chevron-left {
            display: block;
        }

        .icon.icon-cancel-1 {
            display: none;
        }
    }
}

.breaking-header {
    display: block;
    position: relative;
    padding: 10px 20px;
    border-top: 1px solid @borderGray;
    border-bottom: 1px solid @borderGray;
    background-color: #F3F3F3;
    font-size: 20px;
    line-height: 1.5;

    .card-collapse-button {
        position: absolute;
        top: 14px;
        right: 20px;
    }
}
