.welcome-tenant-settings {
    & &__se-config-fieldset { // To override `.clr-wrapper .clr-form-control` style
        margin: 5px 0;
        background: var(--clr-color-neutral-0);
    }

    &__se-config-provider-context {
        padding: 0.5rem;
        border: 1px solid var(--clr-table-border-color);

        &--access {
            border-top: 0;
        }
    }

    & &__se-provider-context-access-select { // To override `.clr-wrapper .clr-form-control` style
        display: block;
        margin: 0 1.2rem;
    }

    &__se-config {
        margin-top: 1rem;
    }
}
