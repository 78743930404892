@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

@top-card-content-padding: 12px;

.vs-log-cinematic-top-info {
    margin: 25px 35px 45px 35px;

    &__title {
        padding: 9px 0 5px 0;
        color: var(--cds-global-typography-color-500);
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-medium);
    }

    &__top-info-section-row {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        & &-col {
            display: flex;
            flex: 1 0;
        }
    }

    &__top-card {
        width: 100%;
        margin-bottom: 35px;

        &-header {
            display: flex;
            align-items: center;

            &-text {
                padding-left: 5px;
                font-size: var(--cds-global-typography-font-size-4);
                font-weight: var(--cds-global-typography-font-weight-regular);
                text-transform: uppercase;
            }
        }

        &-body {
            display: flex;
            justify-content: center;
            font-size: var(--cds-global-typography-font-size-2);
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-knot--security {
            box-sizing: border-box;
            margin-left: calc(@top-card-content-padding - 2px);
            padding-bottom: 45px;
            background-color: var(--cds-alias-object-interaction-background-hover);
        }

        &-box-section {
            box-sizing: border-box;
            margin-left: 10px;
            border: var(--cds-alias-object-border-width-100) solid var(--cds-alias-object-border-color);
            border-radius: var(--cds-alias-object-border-radius-100);
        }

        &-server-app-box {
            display: flex;
            flex-grow: 1;
            min-width: 30%;
            height: fit-content;
        }

        &-dns-end-to-end {
            display: flex;
            width: 33%;
        }

        &-l4-end-to-end {
            display: flex;
            width: 66%;
        }
    }

    &__bridge {
        padding-top: @top-card-content-padding;

        &--medium {
            width: 28%;
        }

        &--long {
            flex-grow: 1; // take the rest of the width
        }
    }

    &__connection-arrow {
        padding-top: 22px;
    }

    &__communicating-ips {
        margin-top: 10px;
    }
}
