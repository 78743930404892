.system-update {
    .system-update__header {
        padding-right: .25rem;
    }
    .update-page-mixin();

    .avi-data-grid-overrides();
}
.update-page-mixin() {
    &__container {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }

    &__update-card {
        flex: 1 1;
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        &-header-icon {
            .update-page__update-card-header-icon-mixin();
        }

        &-progress {
            height: 100%;
        }
    }
}
.update-page__update-card-header-icon-mixin() {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 18px;
}

.avi-data-grid-overrides {
    .avi-data-grid {
        &__btn-group {
            cds-button {
                margin-left: 0;
            }

            &:not(:first-child) {
                margin-left: 0;
            }
        }
    }
}
