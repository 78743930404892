@import (reference) '~ajs/less/themes/default-theme/colors.less';

div[scatter-plot] {
    display: block;

    .brush {
        stroke-width: 0;
        stroke-opacity: .125;
        stroke: black;
        fill-opacity: .125;
        shape-rendering: crispEdges;
    }

    g.axis.no-ticks {
        stroke-width: 1px;
        stroke: #777;
    }

    .red {
        stroke: @aviRed0 !important;
        fill: @aviRed0 !important;
    }
}
