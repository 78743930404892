@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.out-of-band-logs {
    padding: 35px 20px;
    border-top: 1px solid @aviGreySpecial;

    &__title {
        margin-bottom: 25px;
        color: @aviBlack;
        font-size: 18px;
        line-height: 1.2em;
    }

    &__grid {
        margin-top: 25px;
    }

    & .c-grid__table-wrapper {
        margin: 1px;
    }

    .grid-table-row-expander-override();

    &__clickable-filter {
        color: @hyper-link;
    }
}

.grid-table-row-expander-override() {
    .c-grid-table-row-expander {
        margin: 0;
        padding: 0;
    }
}
