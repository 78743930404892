@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/layout/two-column-layout.less';

item-detail-page-header {
    display: flex;
    position: relative;
    flex: 0 0 auto;
    flex-direction: column;

    > subheader {
        .flex-col();
    }
}
