.radio-controls {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 35px;

    > label {
        display: flex;
        flex: 0 1 auto;
        align-items: flex-start;
        min-height: initial;
        margin-right: 15px;
        padding: 5px;
    }

    radio {
        flex: 0 0 auto;
        margin-right: 5px;
    }

    &.equal-width > label {
        flex: 1 1 0;
    }
}

.radio-label {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
    line-height: 20px;
    white-space: normal;

    > radio {
        margin-right: 5px;
    }

    span[avi-form-label] {
        display: inline-block;
    }
}

radio {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    transition: background-color .25s ease-in-out;
    border: 1px solid @aviGreySpecial;
    border-radius: 20px;
    background-color: #FFF;
    line-height: 20px;
    text-align: left;

    .icon-check {
        display: none;
        position: absolute;
        top: -2px;
        left: -3px;
        width: 15px;
        height: 15px;
        margin: 0;
        padding: 0;
        float: left;
        color: @aviGreen0;
        line-height: 17px;
    }

    &.checked {
        background-color: @aviGreen0;
        box-shadow: inset 0 0 0 4px #FFF;

        .icon-check {
            display: none;
        }
    }

    &:not(.checked):not(.disabled):hover {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .25), inset 0 0 0 4px #FFF;
    }

    input[type=radio] {
        position: absolute;
        width: 20px;
        height: 20px;
        margin: -1px 0 0 -1px;
        opacity: 0;
        cursor: pointer;
    }

    &.disabled {
        border-color: @aviGrey2;
        background: @aviGrey3;

        input[type=radio] {
            cursor: inherit;
        }
    }
}
