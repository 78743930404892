.node-vitals-usage-bar {
    height: 5px;
    padding: 1px;
    border-radius: 5px;
    background-color: var(--clr-color-neutral-300);

    &__used {
        height: 5px;
        border-radius: 3px;
    }
}
