.license-card {
    &__license-body {
        width: 360px;
        padding: 0.5rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        font-size: 0.9em;
    }

    &__title {
        font-size: 14px;
        font-weight: var(--cds-global-typography-font-weight-medium);
        line-height: var(--cds-global-typography-section-line-height);
        text-transform: uppercase;
    }

    &__controller-header {
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__organization-usage {
        padding-top: 10px;
    }

    &__usage-label {
        padding: 0.25rem 0;
        color: var(--cds-global-color-black);
        font-size: 13px;
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__usage-count {
        color: var(--cds-global-color-blue-600);
        font-size: 14px;
        font-weight: var(--cds-global-typography-font-weight-bold);
        line-height: var(--cds-global-typography-section-line-height);

    }

    &__organization-max-count {
        color: var(--cds-global-color-warm-gray-700);
        font-size: 14px;
        font-weight: var(--cds-global-typography-font-weight-bold);
        line-height: var(--cds-global-typography-section-line-height);
    }

    &__organization-header {
        padding: 0.5rem 0 0;
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__controller-usage {
        border-bottom: 1px solid var(--cds-global-color-warm-gray-200);
    }

    &__tooltip {
        vertical-align: bottom;
    }

    &__spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .clarity-overrides();
}

.clarity-overrides() {
    .top.progress {
        height: 14px;
    }

    .top.progress > progress {
        height: 14px;
        margin-bottom: 20px;
    }

    .avi-usage-bar-with-overflow__range-tick {
        top: 0;
    }

    .avi-usage-bar__footer {
        margin-top: .75rem;
    }

    .license-card__organization-usage {
        margin-top: 0.5rem;
    }

    .license-card__controller-usage {
        padding-bottom: 0.5rem;
    }
}
