@import (reference) '~ajs/less/themes/default-theme/theme.less';

.avi-virtualservice-analytics {
    .timing-tiles {
        margin-bottom: 10px;
        .small-shadow-bottom();
    }
}

.chartWithOverlaysGridWrapper {
    position: relative;

    .chart-grid-toggle-controls {
        display: block;
        position: relative;
        margin-bottom: 2px;
        padding: 0 2px;
    }

    .hideButton {
        position: absolute;
        top: 0;
        right: 2px;
    }
}

