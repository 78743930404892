@import (reference) '~ajs/less/themes/default-theme/colors.less';

@activeCheckpointColor: #EDB200;

.gslb-federation-checkpoints-list {
    &__icon-star {
        color: @aviBlack;

        &--active {
            color: @activeCheckpointColor;
        }
    }
}
