@import (reference) '~ajs/less/layout/flex.less';
@import (reference) './healthscore.less';
@import (reference) '~ajs/less/fonts/avi';

@unitCardDisabledColor: #f9f9f9;

//Dashboard elements
.unit-card {
    display: block;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 215px;
    height: 60px;
    border: 1px solid;
    cursor: pointer;

    .cardColors(@backgroundColor, @borderColor) {
        border-color: @borderColor;
        background-color: @backgroundColor;
    }

    .card-info {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        .health-circle {
            flex: 0 0 auto;
            padding-left: 5px;
        }

        .unit-name {
            display: block;
            position: relative;
            margin-left: 5px;
            padding-right: 5px;
            overflow: hidden;
            color: @textDarkGray;
            text-overflow: ellipsis;
            white-space: nowrap;

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .unit-card-type {
        position: absolute;
        right: 4px;
        bottom: 2px;
        overflow: hidden;
        font-size: 10px;
        text-transform: uppercase;
    }

    .info-block {
        position: absolute;
        top: 0;
        right: 2px;
        color: @aviGrey2;
        font-size: 12px;

        i.icon {
            color: @aviBodyColor;
        }

        & > * {
            display: inline-block;
            position: relative;
            margin-left: 2px;
        }
    }

    &.high {
        .cardColors(#F3F6E8, @green);
    }

    &.medium {
        .cardColors(#FDFAE7, #f3d416);
    }

    &.low {
        .cardColors(#FFF4E5, #ff9900);
    }

    &.down {
        .cardColors(#fff0f0, @aviHealthDown1);
    }

    &.disabled {
        .cardColors(@unitCardDisabledColor, #ccc);
    }

    &.fqdn {
        .cardColors(@unitCardDisabledColor, #56bfe5);
    }
}

unit-card {
    display: inline-block;
    position: relative;
    overflow: hidden;

    &:not([type=server]):not([type=net]) {
        .unit-card {
            &.down, &.disabled {
                color: inherit;

                .card-info {
                    color: inherit;
                }
            }
        }
    }

    &[type=server], &[type=net] {
        .unit-card {
            width: auto;
            height: auto;
            border: 0;
            background-color: transparent;
        }
    }

    &[type=net] {
        .card-info {
            .unit-name {
                margin-left: 0;
                padding-left: 20px;

                &:before {
                    &:extend(.icon-share:before);
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 1em;
                    height: 1em;
                    /* stylelint-disable-next-line */
                    font-family: 'avi';
                }
            }

            .health-circle {
                display: none;
            }
        }

    }

    &[type=server] {
        &.minimized {
            .health-entity-name.unit-name {
                display: none;
            }
        }

        .unit-card {
            .unit-name {
                margin-left: 5px;
            }
        }
    }

    &[type=se] {
        .unit-card {
            width: 165px;
            height: 50px;
            border-radius: 25px;

            .card-info {
                .unit-name {
                    margin: 0 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .unit-card-type {
                right: 20px;
            }
        }

        .info-block {
            top: 2px;
            right: 15px;
        }
    }

    &[type=pool] {
        .unit-card {
            border-radius: 12px;

            .unit-card-type {
                right: 10px;
            }
        }

        .info-block {
            top: 2px;
            right: 10px;
        }
    }
}

body > div.aviPopover.unit-card-popover {
    z-index: @headerZindex + 1;
}

body > div.aviPopoverCarat.unit-card-popover {
    .healthAndUnitCardPopoverCarat();
}

pool-group-unit-cards {
    display: block;

    &.dashboard {
        .poolgroup-name {
            .text-overflow();
            width: 235px;
            border-width: 1px 1px 0 1px;
            border-style: solid;
            border-color: @aviGrey2;
        }

        .poolgroup-member {
            .align-flex-start;
            .justify-flex-start;

            .unit-card-wrapper {
                padding: 0 5px;
                border-right: 1px solid @aviGrey2;
                border-left: 1px solid @aviGrey2;
            }
        }

        .poolgroup-priority:last-child .poolgroup-member:last-child .unit-card-wrapper {
            border-bottom: 1px solid @aviGrey2;
        }

        .priority-label {
            width: 235px;
            padding: 0 5px;
            border-right: 1px solid @aviGrey2;
            border-left: 1px solid @aviGrey2;
        }

        .no-pools-wrapper {
            width: 235px;
            border-width: 0 1px 1px 1px;
            border-style: solid;
            border-color: @aviGrey2;
        }
    }

    .poolgroup-name {
        padding: 5px;
    }

    .poolgroup-priority {
        .unit-card-wrapper, .priority-label {
            background-clip: content-box;
            background-color: lighten(@Grey, 36%);
        }

        &:first-child {
            .unit-card-wrapper, .priority-label {
                background-color: #EEF9FD;
            }
        }

        &:nth-child(2) {
            .unit-card-wrapper, .priority-label {
                background-color: lighten(@Grey, 30%);
            }
        }

        &:nth-child(3) {
            .unit-card-wrapper, .priority-label {
                background-color: lighten(@Grey, 33%);
            }
        }

        .priority-label > div {
            padding: 5px 10px;
        }

        .poolgroup-member {
            unit-card[type=pool] {
                padding: 0 10px;
            }

            &:last-child {
                .unit-card-wrapper {
                    padding-bottom: 5px;
                }

                unit-card[type=pool] {
                    padding-bottom: 10px;
                }
            }

            ul.nets {
                margin-left: 4em;
            }
        }
    }

    .no-pools-wrapper {
        padding: 0 5px 5px 5px;
        background-clip: content-box;
        background-color: lighten(@Grey, 36%);

        .no-pools {
            padding: 5px;
        }
    }
}

.gslb-service-unit-tree-group {
    max-width: 490px;
    margin-bottom: 30px;
    padding: 10px 0 20px 20px;
    background: @unitCardDisabledColor;

    .header {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: bold;
    }

    .member-list {
        display: block;
        position: relative;
        white-space: normal;

        .member {
            display: inline-block;
            position: relative;
            margin-right: 20px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .unit-card {
                cursor: default;
            }
        }
    }
}

.unit-card.gslb-service-unit-name {
    display: inline-block;
}
