.body-font-size-medium-font-weight-mixin {
    font-size: var(--cds-global-typography-body-font-size);
    font-weight: var(--cds-global-typography-font-weight-medium);
    line-height: var(--cds-global-typography-body-line-height);
}

.clr-wrapper .pie-chart-dashboard {
    position: relative;

    &__title h2 {
        .body-font-size-medium-font-weight-mixin();
    }

    .legend {
        flex: 1 0 25%;
        height: 158px;
    }

    .highlight0, .highlight1 {
        position: absolute;
    }

    .highlight0 {
        top: -10px;
        left: 0;
    }

    .highlight1 {
        right: 10px;
        bottom: -20px;
    }

    div.pie-chart {
        flex: 1 0 70%;

        & > svg {
            position: initial;
        }
    }

    .security-pct-txt {
        font-size: 1.3em;

        @media (max-width: 1152px) {
            font-size: 1em;
        }
    }
}
