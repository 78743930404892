.ip-addr-match {
    &__entry {
        display: block;
        margin-bottom: 10px;
    }

    &__address-container {
        margin: 15px 0 0 15px;

        &:not(:last-child) {
            padding-bottom: 5px;
            border-bottom: 1px solid var(--clr-table-border-color);
        }
    }

    &__address-header {
        display: flex;
        justify-content: space-between;
    }

    &__add-item-button {
        margin-left: 15px;
    }

    &__remove-icon {
        margin-right: 10px;
        cursor: pointer;
    }
}
