@import (reference) '~ajs/less/themes/default-theme/theme.less';

.avi-pool-analytics {
    .anomaly-panel {
        margin-top: 10px;
    }

    .pool-performance, .server-summary {
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }

    @media (max-width: 750px) {
        .pool-performance, .server-summary {
            width: 100%;
        }
        // a silly little hack to switch the position of the two groups
        .server-summary {
            position: relative;
            top: -300px;
        }

        .pool-performance {
            position: relative;
            top: 300px;
        }
    }

    .avi-subheader .navbar {
        width: auto;
    }

    table {
        .avi-healthscore {
            margin-top: 5px;
            line-height: 35px;
        }
    }

    .timing-tiles {
        margin-bottom: 10px;
        .small-shadow-bottom();
    }
}
