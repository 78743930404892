.system-settings-auth-card {
    & &__header {
        text-transform: uppercase;
    }

    & &__type {
        color: var(--cds-global-color-green-700);
    }

    & &__row {
        display: flex;
        justify-content: space-between;
    }
}
