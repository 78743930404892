@import '~ajs/less/themes/default-theme/clr-variables.less';

.avi-search {
    display: flex;
    position: relative;
    justify-content: flex-end;

    &__search-icon {
        cursor: pointer;
    }

    &__search-input {
        width: 100%;

        & input[type=search] {
            font-family: @font-family;
        }
    }

    &__clear-icon {
        --color: var(--clr-btn-primary-bg-color);
        position: absolute;
        top: 2px;
        right: 0;
        cursor: pointer;
    }
}
