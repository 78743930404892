.vertical-nav {
    box-sizing: border-box;
    height: 100%;

    &__nav-button {
        display: block;
        width: 100%;
        background-color: transparent;
        text-align: left;
    }
}

/**
 * Adding below class to override the clr-wrapper class
 * To update the font weight of headings in the left navigation tree
 */

.clr-wrapper {
    .clr-vertical-nav.has-nav-groups {
        .nav-group-children {
            .nav-text {
                font-weight: 300;
            }
        }
    }

    .clr-vertical-nav {
        .nav-text {
            font-weight: 600;
        }

        .nav-content {
            margin-bottom: 2rem;
        }
    }

    .main-container:not([class*=open-overflow-menu]):not([class*=open-hamburger-menu])
    .clr-vertical-nav.is-collapsed .nav-group-trigger .nav-group-trigger-icon {
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 0.1rem;
    }
}
