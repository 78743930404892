.licensing-page {
    width: 100%;
    padding: 20px;

    &__error {
        margin-bottom: 10px;
    }

    &__licenses-header {
        margin-top: 20px;
    }

    &__cloud-services-tier-details {
        flex: inherit;
        width: 50%;
    }
}
