.vs-log-cinematic-sub-section-wrapper {
    display: block;

    &__body {
        padding: 10px 20px 0;
    }

    &:not(:first-child) {
        padding-top: 35px;
        border-top: 1px solid var(--clr-table-border-color);
    }

    &:not(:last-child) {
        padding-bottom: 40px;
    }
}

.h5-clarity-override();

// override Clarity styles of h5 for the wrapper header
.h5-clarity-override() {
    .clr-wrapper h5.vs-log-cinematic-sub-section-wrapper__header {
        color: var(--cds-global-typography-color-500);
        font-weight: var(--cds-global-typography-font-weight-light);
    }
}
