.virtualservice-config {
    .grid-field-name {
        width: 15%;
    }

    .grid-field-address {
        width: 15%;
    }

    .grid-field-service {
        width: 5%;
    }

    .grid-field-pool {
        width: 15%;
    }

    .grid-field-serviceengine {
        width: 15%;
    }
}
