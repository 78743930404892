.ng-file-upload-textarea {
    &__textarea-container {
        display: flex;
        flex-direction: column;
        margin-top: .5rem;
    }

    &__textarea {
        &::placeholder {
            opacity: 0.54;
            color: var(--clr-forms-text-color);
            font-size: var(--cds-global-typography-font-size-4);
        }
    }
}
