.string-match-config {
    &__string-group-or-custom-value {
        display: block;
        margin-bottom: 10px;
    }

    &__string-container {
        margin: 1rem 0 0 30px;

        &:not(:last-child) {
            padding-bottom: 5px;
            border-bottom: 1px solid var(--clr-table-border-color);
        }
    }

    &__string-header {
        display: flex;
        justify-content: space-between;
    }

    &__add-item-button {
        margin-left: 15px;
    }

    &__remove-icon {
        margin-right: 10px;
        cursor: pointer;
    }
}
