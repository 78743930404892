.recommendation-grid {
    &__field-container {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__field-value {
        padding: 0 6px;
    }

    &__expanded-content {
        padding: 0.55rem 0.6rem 0.55rem;
    }
}
