@import (reference) '~ajs/less/themes/default-theme/theme.less';

.traffic-capture {
    padding: 10px 30px;
}

.traf-cap-switch-text {
    line-height: 1.8em;
}

.traf-cap-grid th.rowactions {
    width: 5%;
}

virtualservice-traffic-capture-status {
    .small-shadow-bottom;
    .col(8, block, none, 0, 0, 2);
    box-sizing: border-box;
    margin: 10px 0;
    padding: 15px;
    background-color: @aviWhite;
}

.traffic-capture .progress {
    height: 8px;
    padding: 1px;
    border: 1px solid #888;
    border-radius: 5px;
}

.traffic-capture .progress .bar {
    height: 8px;
    transition: width 1s;
    border-radius: 3px;
    background-color: #B5DA2F;
}
