.ip-reputation-match {
    .clr-form-control {
        margin-top: 0;
    }

    &__reputation-container {
        margin: 15px 0 0 15px;
    }

    &__reputation-header {
        display: flex;
        justify-content: space-between;
    }
}
