.avi-checkbox-container-group {
    margin-top: 1.2rem;

    &--no-margin-top {
        margin-top: 0;
    }

    clr-checkbox-container.clr-form-control, .avi-checkbox {
        margin-top: .6rem;
    }
}
