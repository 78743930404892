@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.expander-with-actions {
    &__opener-container {
        display: flex;
        align-items: center;
    }

    &__opener {
        flex: 1;
        min-width: 0;
    }

    &__caret-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 50px;
        cursor: pointer;

        &--hovered {
            background: @expander-button-color;
            color: white;

            &:hover {
                color: @aviBrandColor;
            }
        }
    }

    &__caret {
        color: @textDarkGray;
        font-size: @avi-baseline-px;

        &--hovered {
            color: inherit;
        }
    }

    &__actions {
        display: flex;
    }

    &__actions-container {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
