@import (reference) '../../full-modal.component.less';

.full-modal-footer {
    .full-modal-footer-mixin();

    &__buttons-container, &__spinner-container, &__footer-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__buttons-container {
        justify-content: space-between;
    }

    &__spinner-container {
        justify-content: center;
    }

    & &__footer-container {
        flex-grow: 1;
        justify-content: flex-end;
    }
}
