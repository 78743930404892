.gslb-subdomains-tree-node {
    display: inline-flex;
    position: relative;
    z-index: 1;

    .avi-card-body-overrides();

    &__card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 12rem;
        min-height: 40px;
    }

    &__root-node {
        margin-right: 3rem;
        vertical-align: top;
    }

    &__parent-node {
        margin-right: 3rem;
        vertical-align: top;
    }

    &__data-container {
        display: flex;
        align-items: center;
    }

    &__host-subdomain-tag {
        display: block;
        width: 6rem;
    }

    &__node-status {
        margin-right: 10px;
    }

    &__card {
        display: flex;
        min-height: 3rem;
    }

    &__name-section {
        max-width: 9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn.btn-link.gslb-subdomains-tree-node__action-btn:last-child {
        margin-right: 0;
    }
}

.avi-card-body-overrides() {
    h6:not([cds-text]).gslb-subdomains-tree-node__node-name {
        display: inline;
        font-weight: inherit;
    }

    avi-card.gslb-subdomains-tree-node__focus-high .avi-card__card {
        border-color: var(--cds-global-color-blue-700);
        box-shadow: 0 0.15rem 0 0 var(--cds-global-color-blue-700);
    }

    avi-card.gslb-subdomains-tree-node__focus-low .avi-card__card {
        opacity: 0.5;
    }

    .avi-card__body {
        display: flex;
        padding: 0 0.6rem;
    }
}
