.role-access-expander {
    &__header {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }

    .role-access-expander-clarity-overrides();

    // To override avi tooltip icon style. If button is disable then cursor should show disabled state.
    .avi-tooltip-icon__icon {
        cursor: inherit;
    }

    &__accordian-title-container {
        display: flex;
        align-items: center;
    }

    &__accordian-title {
        flex-grow: 1;
    }

    &__legend-container {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }

    &__legend {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    &__legend-label {
        margin-left: 5px;
    }

    &__filter-container {
        width: 45%;
    }

    &__permissions-header {
        margin-right: 10px;
        padding-left: 10px;
        border-left: 1px solid var(--clr-accordion-border-color);
    }

    &__permission-list {
        margin-left: 0;
        padding: 0 !important
    }

    & &__permission-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.3em 2.5em 0.3em 1em;
        border-bottom: 1px solid var(--clr-accordion-border-color);
    }
}

// To override clarity styles for accordian.
.role-access-expander-clarity-overrides() {
    .clr-accordion-status {
        display: none;
    }

    .clr-accordion-panel {
        margin-bottom: 10px;
    }

    .clr-accordion-header {
        padding: 0;
        border-bottom: 1px solid var(--clr-accordion-border-color) !important;
        background: var(--cds-global-color-warm-gray) !important;

        .clr-accordion-header-button {
            box-sizing: border-box;
            outline: none;
        }

        .clr-accordion-title {
            width: 100%;
        }
    }

    & .clr-accordion-inner-content {
        padding: 0 !important;
    }
}
