.system-update-modal {
    &__content-container {
        width: 100%;
    }

    &__check-list {
        margin-top: 15px;
    }

    &__check-list-item {
        margin-top: 5px;
        font-size: 14px;
    }

    &__cds-divider {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
