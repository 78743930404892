.avi-data-grid {
    &__header {
        padding-bottom: 0.3rem;
    }

    .avi-data-grid-clarity-overrides();

    & &__grid-alerts {
        margin-bottom: 10px;
    }
}

.avi-data-grid-clarity-overrides() {
    h6:not([cds-text]).avi-data-grid__grid-title {
        padding-bottom: 0.3rem;
        color: var(--cds-global-color-blue-700);
    }
}
