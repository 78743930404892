.vs-log-list {
    &__err-state {
        width: auto;
        height: 80px;
        margin: 20px auto;
        padding-top: 58px;
        padding-right: 10px;
        border: var(--cds-global-color-gray-400) solid 1px;
        box-shadow: 0 4px 2px -2px var(--cds-global-color-gray-400);
        text-align: center;
    }

    &__overflow-container {
        display: flex;
        max-width: 100%;
    }

    &__overflow-element {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    &__response-code-wrapper {
        display: flex;
        align-items: center;
    }

    &__response-code-separator {
        margin-right: 6px;
    }

    &__uri-question-mark {
        color: var(--cds-global-color-blue-700);
    }

    &__dns-response-ip {
        margin-right: 5px;
    }

    .vs-log-list-clarity-overrides();
}

.vs-log-list-clarity-overrides() {
    .clr-wrapper .datagrid {
        margin-top: 0;
    }
}
