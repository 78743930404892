.label.avi-status-label {
    height: 18px;
    padding: 2px 10px 2px 10px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
}

.label-success.avi-status-label {
    background-color: @aviGreen0;
}
