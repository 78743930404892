@import (reference) '~ajs/less/themes/default-theme/animate.less';

@marginValue: 10px;

.update-progress-card {
    display: block;
    height: 100%;

    &__update-progress-info {
        &__prechecks-info {
            margin: 0 0 5px @marginValue;
            font-weight: var(--cds-global-typography-font-weight-semibold);
        }

        &__prechecks-message {
            margin: 5px 0 0 30px;
        }

        &__update-progress {
            padding-bottom: 20px;
            border-bottom: var(--clr-card-border-width) solid var(--clr-card-border-color);
        }

        &__progress-bar {
            .animated();
            .fadeIn();
        }

        &__subsection {
            margin-top: 30px;

            &__loader {
                vertical-align: super;
            }

            &__seg-info {
                display: inline-block;
                margin-left: @marginValue;

                &__label {
                    font-weight: var(--cds-global-typography-font-weight-semibold);
                }

                &__status {
                    margin-top: @marginValue;
                }
            }

            &__text-info {
                margin-left: @marginValue;
            }

            &--no-margin-top {
                margin-top: 0;
            }
        }

        &__no-progress {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: var(--cds-alias-status-disabled);
        }

        &__loader {
            vertical-align: middle;
        }
    }
}
