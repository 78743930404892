.in-use-interface-list-dialog {
    &__vlan-interfaces-datagrid {
        display: block;
        margin-top: 15px;
    }

    &__expanded-content {
        display: block;
        width: 100%;
    }
}
