.path-or-query-match {
    .clr-form-control {
        margin-top: 0;
    }

    &__container {
        margin: 10px 0 0 0;

        &--no-margin {
            margin: 0;
        }
    }

    &__string-group-or-custom-value {
        display: block;
        margin-bottom: 10px;
    }

    &__string-container {
        margin: 10px 0 0 15px;

        &:not(:last-child) {
            padding-bottom: 5px;
            border-bottom: 1px solid var(--clr-table-border-color);
        }

        &--no-margin {
            margin: 0;
        }
    }

    &__match-case-container {
        margin-top: 10px;
    }

    &__string-header {
        display: flex;
        justify-content: space-between;
    }

    &__add-item-button {
        margin-left: 15px;
    }

    &__remove-icon {
        margin-right: 10px;
        cursor: pointer;
    }
}
