ordered-grid.policy-grid {
    .grid-field-match, .grid-field-action {
        > .td-wrapper {
            cell {
                .align-flex-start;
                padding: 10px;

                policy-grid-match-column, policy-grid-action-column {
                    width: 100%;

                    div {
                        .hide-text-overflow;
                    }
                }
            }
        }
    }
}
