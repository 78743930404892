.gslb-service-pools-grid {
    &__mandatory-indicator {
        color: var(--clr-color-danger-700);
        font-size: 20px;
    }

    & &__no-margin-top {
        margin-top: 0;
    }
}
