.avi-repeated-key-values-grid {
    // override Clarity data grid cell style
    & clr-dg-cell.datagrid-cell {
        padding-bottom: 0 !important;
    }

    &__error-msg {
        margin-top: 0.6rem;
    }
}
