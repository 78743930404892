.ip-reputation-db-list-page {
    &__spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    &__change-request-card {
        flex: 1 1;
        margin-left: 20px;
    }

    &__search-record-card {
        flex: 1 1;
    }
}
