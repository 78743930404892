@import (reference) '~ajs/less/themes/default-theme/colors.less';

@performanceChartHeaderHeight: 45px;

.performance-chart {
    box-sizing: border-box;
    // Making the plus column in events table skinny
    th.checkbox {
        width: 20px;
    }

    .alert-priority {
        line-height: 32px;

        .alertOutter {
            margin-top: 8px;
        }
    }
    // dot wrapper contains events alerts anomalies on charts
    .dotWrapper {
        position: absolute;
        height: 45px;
        margin-top: @performanceChartHeaderHeight;
    }

    // Must be same hight as dotWrapper to allow vertical-align bottom to work
    .alignHeight {
        width: 0;
        height: 45px;
    }
    // vertically aligns with alignHeight making dots start from bottom
    .dotContainer {
        width: 0;

        .graph-shape.shape {
            display: block;
            position: relative;
            margin: 0;
        }
    }
    // Makes the dots in dotContainer stack from the bottom up
    .dotWrapper > * {
        display: inline-block;
        vertical-align: bottom;
    }

    &__license-upgrade-message {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.performance-chart-controls-container {
    position: relative;
    z-index: 3;
}

.performance-chart-controls {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;

    .chart-control {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px;
        white-space: nowrap;
        cursor: pointer;

        .avi-label {
            @media screen and (max-width: 1300px) {
                display: none;
            }
        }

        .shape {
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            margin-right: 2px;
            margin-left: 5px;
            font-size: 12px;
            line-height: 20px;

            i {
                line-height: 20px;
            }
        }
    }

    .chart-controls-checkbox {
        pointer-events: none;
    }

    .control-count {
        margin-left: 10px;
    }
}

.performance-chart-header {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    height: @performanceChartHeaderHeight;
    padding: 10px 20px;
    border-bottom: 1px solid @borderGray;

    .chart-header-content-middle {
        display: block;
        position: relative;
        flex: 1 1 0;
    }
}
