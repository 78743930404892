@import 'timing-tile/timing-tile.component.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.timing-tiles {
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 120px;
    padding: 10px;
    overflow: hidden;
    background: @aviWhite;
    white-space: nowrap;

    &__header {
        position: absolute;
        z-index: 0;
        top: 5px;
        left: 10px;
        font-size: 1em;
    }

    &__list-wrapper, &__license-upgrade-message {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        &--lower-opacity {
            opacity: .1;
        }
    }

    &__license-upgrade-message {
        position: absolute;
        top: 0;
    }

    .timing-tile {
        z-index: 1;

        &--icon {
            flex-grow: 0.5;
            flex-shrink: 2;
        }
    }
}
