.top-list-timing-section {
    display: block;
    position: relative;
    height: 35px;
    overflow: hidden;

    .plt-section {
        height: 10px;

        .stacked-end-to-end {
            height: 5px;
        }

        .bar {
            height: 10px;
            line-height: 10px;
        }
    }

    .pdt-section {
        z-index: 1;
        height: 5px;

        .bar {
            height: 10px;
            line-height: 10px;
        }
    }

}
