@import (reference) '~ajs/less/layout/grid-container.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.icap-log-list-expander {
    margin: 0;
    padding: 0;
    font-size: 14px;

    .grid-container();

    &__container {
        padding: 10px;

        .grid-container();

        &-title {
            font-size: 16px;
            font-weight: 300;
        }

        &--full {
            .grid-container__cell--w--full();
        }

        &--left {
            .grid-container__cell--w--6();
        }

        &-right {
            .grid-container__cell--w--6();
        }

        &--response-request {
            padding-top: 25px;
            border-top: 1px solid @borderGray;
        }

        &--logs {
            .grid-container__cell--w--full();
            padding-top: 15px;
            border-top: 1px solid @borderGray;
        }

    }

    &__cell {
        &--width {
            &--full {
                .grid-container__cell--w--full();
            }

            &--half {
                .grid-container__cell--w--6();
            }
        }
    }

    &__field_name {
        margin-bottom: 7px;
        color: @textMidGray;
        font-weight: 700;
    }

    &__clickable-filter {
        color: @hyper-link;
        word-break: break-all;
    }

    &__http-method-val, &__icap-method-val {
        text-transform: uppercase;
    }

    &__icap-headers {
        margin-bottom: 15px;
        padding: 0;
    }
}
