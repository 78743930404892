@font-face {
    font-family: 'avi';
    src: url('./avi.woff2') format('woff2'),
    url('./avi.woff') format('woff'),
    url('./avi.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'avi';
    src: url('../font/avi.svg?21754991#avi') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "avi";
    font-style: normal;
    font-weight: normal;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-app:before { content: '\e800'; } /* '' */
.icon-server:before { content: '\e801'; } /* '' */
.icon-adc:before { content: '\e802'; } /* '' */
.icon-user:before { content: '\e803'; } /* '' */
.icon-menuright:before { content: '\e804'; } /* '' */
.icon-menuleft:before { content: '\e805'; } /* '' */
.icon-activate:before { content: '\e806'; } /* '' */
.icon-suspend:before { content: '\e807'; } /* '' */
.icon-gradual:before { content: '\e808'; } /* '' */
.icon-safari:before { content: '\e809'; } /* '' */
.icon-ubuntu:before { content: '\e80a'; } /* '' */
.icon-lostlog:before { content: '\e80b'; } /* '' */
.icon-loader:before { content: '\e80c'; } /* '' */
.icon-firefox:before { content: '\e80d'; } /* '' */
.icon-chrome:before { content: '\e80e'; } /* '' */
.icon-opera:before { content: '\e80f'; } /* '' */
.icon-ie:before { content: '\e810'; } /* '' */
.icon-search:before { content: '\e811'; } /* '' */
.icon-envelope:before { content: '\e812'; } /* '' */
.icon-heart:before { content: '\e813'; } /* '' */
.icon-star:before { content: '\e814'; } /* '' */
.icon-star-empty:before { content: '\e815'; } /* '' */
.icon-expanded:before { content: '\e816'; } /* '' */
.icon-compact:before { content: '\e817'; } /* '' */
.icon-check:before { content: '\e818'; } /* '' */
.icon-check-circle:before { content: '\e819'; } /* '' */
.icon-ok-circled2:before { content: '\e81a'; } /* '' */
.icon-cancel:before { content: '\e81b'; } /* '' */
.icon-plus:before { content: '\e81c'; } /* '' */
.icon-plus-circle:before { content: '\e81d'; } /* '' */
.icon-minus:before { content: '\e81e'; } /* '' */
.icon-minus-circle:before { content: '\e81f'; } /* '' */
.icon-help-circled:before { content: '\e820'; } /* '' */
.icon-info-sign:before { content: '\e821'; } /* '' */
.icon-link-ext:before { content: '\e822'; } /* '' */
.icon-lock:before { content: '\e823'; } /* '' */
.icon-download-1:before { content: '\e824'; } /* '' */
.icon-upload-cloud:before { content: '\e825'; } /* '' */
.icon-share:before { content: '\e826'; } /* '' */
.icon-pencil:before { content: '\e827'; } /* '' */
.icon-bell-empty:before { content: '\e828'; } /* '' */
.icon-bell:before { content: '\e829'; } /* '' */
.icon-exclamation:before { content: '\e82a'; } /* '' */
.icon-attention:before { content: '\e82b'; } /* '' */
.icon-location:before { content: '\e82c'; } /* '' */
.icon-direction:before { content: '\e82d'; } /* '' */
.icon-trash:before { content: '\e82e'; } /* '' */
.icon-folder-open:before { content: '\e82f'; } /* '' */
.icon-menu:before { content: '\e830'; } /* '' */
.icon-cog:before { content: '\e831'; } /* '' */
.icon-calendar:before { content: '\e832'; } /* '' */
.icon-clock:before { content: '\e833'; } /* '' */
.icon-block:before { content: '\e834'; } /* '' */
.icon-zoom-in:before { content: '\e835'; } /* '' */
.icon-zoom-out:before { content: '\e836'; } /* '' */
.icon-download:before { content: '\e837'; } /* '' */
.icon-caret-left:before { content: '\e838'; } /* '' */
.icon-caret-right:before { content: '\e839'; } /* '' */
.icon-chevron-down:before { content: '\e83a'; } /* '' */
.icon-chevron-left:before { content: '\e83b'; } /* '' */
.icon-chevron-right:before { content: '\e83c'; } /* '' */
.icon-chevron-up:before { content: '\e83d'; } /* '' */
.icon-angle-down:before { content: '\e83e'; } /* '' */
.icon-left:before { content: '\e83f'; } /* '' */
.icon-right:before { content: '\e840'; } /* '' */
.icon-arrow-down:before { content: '\e841'; } /* '' */
.icon-arrow-left:before { content: '\e842'; } /* '' */
.icon-arrow-right:before { content: '\e843'; } /* '' */
.icon-arrow-up:before { content: '\e844'; } /* '' */
.icon-right-circle:before { content: '\e845'; } /* '' */
.icon-refresh:before { content: '\e846'; } /* '' */
.icon-level-up:before { content: '\e847'; } /* '' */
.icon-desktop:before { content: '\e848'; } /* '' */
.icon-tablet:before { content: '\e849'; } /* '' */
.icon-mobile:before { content: '\e84a'; } /* '' */
.icon-globe:before { content: '\e84b'; } /* '' */
.icon-cloud:before { content: '\e84c'; } /* '' */
.icon-flash:before { content: '\e84d'; } /* '' */
.icon-columns:before { content: '\e84e'; } /* '' */
.icon-ellipsis-vert:before { content: '\e84f'; } /* '' */
.icon-system:before { content: '\e850'; } /* '' */
.icon-circle:before { content: '\e851'; } /* '' */
.icon-circle-empty:before { content: '\e852'; } /* '' */
.icon-sort:before { content: '\e853'; } /* '' */
.icon-sort-desc:before { content: '\e854'; } /* '' */
.icon-sort-asc:before { content: '\e855'; } /* '' */
.icon-sitemap:before { content: '\e856'; } /* '' */
.icon-shield:before { content: '\e857'; } /* '' */
.icon-database:before { content: '\e858'; } /* '' */
.icon-plug-1:before { content: '\e859'; } /* '' */
.icon-android:before { content: '\e85a'; } /* '' */
.icon-apple:before { content: '\e85b'; } /* '' */
.icon-linux:before { content: '\e85c'; } /* '' */
.icon-windows:before { content: '\e85d'; } /* '' */
.icon-down-open-mini:before { content: '\e85e'; } /* '' */
.icon-down-open-big:before { content: '\e85f'; } /* '' */
.icon-key:before { content: '\e860'; } /* '' */
.icon-plug:before { content: '\e861'; } /* '' */
.icon-key-outline:before { content: '\e862'; } /* '' */
.icon-key-1:before { content: '\e863'; } /* '' */
.icon-puzzle:before { content: '\e864'; } /* '' */
.icon-key-2:before { content: '\e865'; } /* '' */
.icon-key-inv:before { content: '\e866'; } /* '' */
.icon-cancel-2:before { content: '\e867'; } /* '' */
.icon-down-open:before { content: '\e868'; } /* '' */
.icon-key-3:before { content: '\e869'; } /* '' */
.icon-cancel-1:before { content: '\e86a'; } /* '' */
.icon-unknown:before { content: '\e86b'; } /* '' */
.icon-pencil-1:before { content: '\e86c'; } /* '' */
.icon-pencil-2:before { content: '\e86d'; } /* '' */
.icon-pencil-3:before { content: '\e86e'; } /* '' */
.icon-pencil-4:before { content: '\e86f'; } /* '' */
.icon-pencil-5:before { content: '\e870'; } /* '' */
.icon-dot-3:before { content: '\e871'; } /* '' */
.icon-left-1:before { content: '\e872'; } /* '' */
.icon-bell-1:before { content: '\e873'; } /* '' */
.icon-bell-2:before { content: '\e874'; } /* '' */
.icon-attention-1:before { content: '\e875'; } /* '' */
.icon-attention-2:before { content: '\e876'; } /* '' */
.icon-cog-1:before { content: '\e877'; } /* '' */
.icon-help:before { content: '\e878'; } /* '' */
.icon-plus-1:before { content: '\e879'; } /* '' */
.icon-minus-1:before { content: '\e87a'; } /* '' */
.icon-download-2:before { content: '\e87b'; } /* '' */
.icon-help-circled-1:before { content: '\e87c'; } /* '' */
.icon-wordwrap:before { content: '\e8b0'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-comment:before { content: '\e87d'; } /* '' */
