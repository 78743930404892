.cloud-token-dialog {
    &__message {
        margin-bottom: 20px;
    }

    .cloud-token-dialog-clarity-overrides();

    &__token-details {
        height: 48px;
        margin-top: 10px;
        padding: 12px;
        border: 1px solid var(--avi-morning-glory-blue);
        border-radius: 3px;
        background-color: var(--avi-iceberg-blue);
        color: var(--avi-lochmara-blue);
        font-weight: var(--cds-global-typography-font-weight-semibold);
        text-align: center;
    }

    &__token {
        margin-left: 5px;
        vertical-align: middle;
    }
}

.cloud-token-dialog-clarity-overrides() {
    strong {
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }
}
