.controller-widget {
    &__cluster-container {
        padding: 10px;
        border-bottom: 1px solid var(--cds-alias-object-border-color);
    }

    &__version-data-container {
        padding: 0 10px;
    }

    &__version-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    &__version {
        flex: 1;
    }

    &__value-container {
        margin-top: 15px;
    }

    &__label {
        display: block;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__value {
        display: block;
        padding-left: 5px;
        font-size: var(--cds-global-typography-font-size-3);
        line-height: 24px;
    }
}
