@top-card-content-padding: 12px;

.vs-logs-signpost-custom-e2e-timing-header {
    display: flex;
    margin-bottom: 0.7rem;
    overflow-x: auto;
    font-size: var(--cds-global-typography-font-size-2);
    font-weight: var(--cds-global-typography-font-weight-medium);

    &__top-card {
        &-body {
            display: flex;
            justify-content: center;
            font-size: var(--cds-global-typography-font-size-2);
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-box-section {
            box-sizing: border-box;
            margin-left: 10px;
            border: var(--cds-alias-object-border-width-100) solid var(--cds-alias-object-border-color);
            border-radius: var(--cds-alias-object-border-radius-100);
        }

        &-server-app-box {
            display: flex;
            flex-grow: 1;
            height: fit-content;
        }

        &-dns-end-to-end {
            display: flex;
            width: 33%;
        }

        &-l4-end-to-end {
            display: flex;
            width: 66%;
        }
    }

    &__bridge {
        padding-top: @top-card-content-padding;

        &--medium {
            width: 28%;
        }

        &--long {
            flex-grow: 1; // take the rest of the width
        }
    }

    &__connection-arrow {
        padding-top: 22px;
    }

    &__communicating-ips {
        margin-top: 10px;
    }
}
