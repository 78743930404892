@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/avistrap.less';

grid-search {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid @borderGray;
    background-color: white;

    .grid-search {
        .display-flex;
        .flex-row;

        .search-icon {
            .display-flex;
            flex: 0 0 0;
            padding: 0 4px;
            font-size: 20px;
        }

        .grid-search-input {
            flex: 1 0 0;
            width: 0;
            max-width: 0;
            height: 33px;
            padding: 0;
            border: 0;
            background: transparent;
        }
    }

    &.expanded {
        .grid-search-input {
            width: 150px;
            max-width: 150px;
            padding: 0 5px;
        }
    }
}

