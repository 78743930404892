@import (reference) '~ajs/less/layout/flex.less';

@exceptionPreviewBorderColor: lighten(@Grey,10%);

.waf-exception-preview {
    @leftExceptionsPadding: 7px;
    @leftRuleExceptionPadding: @leftExceptionsPadding + 5px;

    &__exceptions-container {
        margin: 10px;
        border: 1px solid @exceptionPreviewBorderColor;
        border-radius: 3px;
        background-color: white;
    }

    &__exception-group {
        &:not(:last-child) {
            border-bottom: 1px solid @exceptionPreviewBorderColor;
        }
    }

    &__exception-container {
        display: flex;

        &:not(:last-child) {
            border-bottom: 1px solid @exceptionPreviewBorderColor;
        }
    }

    &__close-button-container {
        position: relative;
        flex: 0 0 20px;
    }

    &__close-button {
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 5px;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        background: transparent;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            transform: rotate(45deg);
            border-bottom: 1px solid;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            transform: rotate(-45deg);
            border-bottom: 1px solid;
        }
    }

    &__exception {
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        flex-grow: 1;
        width: 92%;
        padding: 10px 5px 10px @leftExceptionsPadding;

        &--rule {
            padding-left: @leftRuleExceptionPadding;
        }
    }

    &__exception-name {
        padding: @leftExceptionsPadding;
        border-bottom: 1px solid @exceptionPreviewBorderColor;
        font-weight: 700;
        .hide-text-overflow;

        &--rule {
            padding-left: @leftRuleExceptionPadding;
        }
    }

    &__exception-capsule {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__add-exception-container {
        .display-flex;
        margin-top: 20px;
    }

    &__empty-list {
        margin-top: 20px;
        text-align: center;
    }
}
