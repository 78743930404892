.vs-enhanced-vh-child-hosts {
    &__match {
        .vs-enhanced-vh-child-hosts-repeated-section();
    }

    &__domain-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__domain-title {
        font-weight: bold;
    }

    &__close-button {
        border: none;
        background: none;
        box-shadow: none;
    }

    &__path-match-container {
        margin-left: 40px;
    }

    &__path-match {
        .vs-enhanced-vh-child-hosts-repeated-section();

        // Overriding path-match component styling
        .path-match__header {
            margin-bottom: 10px;
        }

        // Overriding path-match component styling
        .path-match__rule {
            padding-left: 0;
        }
    }

    &__button-container {
        margin: 10px 0;
    }
}

// Used for the match-criteria section and its child patch-match sections.
.vs-enhanced-vh-child-hosts-repeated-section() {
    padding: 10px 0;

    &:not(:last-child) {
        margin-bottom: 5px;
        border-bottom: 1px solid var(--clr-forms-border-color);
    }
}
