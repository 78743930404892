.waf-policy-psm-group-modal-preview {
    padding: 0 24px;

    &__notes-header {
        display: flex;
        padding-bottom: 24px;
        font-size: 1rem;
    }

    &__notes-header-text {
        color: var(--cds-global-color-white);
    }

    &__notes-title {
        margin-bottom: 12px;
        color: var(--cds-global-color-white);
        font-size: 14px;
        font-weight: bold;
    }

    &__notes-message {
        margin-bottom: 12px;
        color: var(--cds-global-color-white);
        font-size: 14px;
    }

    &__item-preview-defaults {
        padding-top: 24px;
        border-top: 1px solid var(--cds-alias-object-interaction-color);
    }
}
