@link-underline-color: var(--cds-global-color-azure-200);
@link-text-color: var(--cds-global-color-blue-700);
@link-font-weight: var(--cds-global-typography-font-weight-light);

.vs-log-filter {
    color: @link-text-color;
    font-weight: @link-font-weight;
    cursor: pointer;

    &:hover {
        text-decoration: underline @link-underline-color 1px;
        text-underline-offset: 2px;
    }
}
