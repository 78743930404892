@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

@link-text-color: var(--cds-global-color-blue-700);

.vs-log-cinematic {
    &__header {
        padding-left: 35px;

        &-main {
            display: flex;
            align-items: center;
        }

        &-top,
        &-subtitle {
            font-size: var(--cds-global-typography-font-size-4);
            font-weight: var(--cds-global-typography-font-weight-light);
        }

        &-top {
            margin-bottom: -5px;
            color: @link-text-color;
        }

        &-subtitle {
            margin-bottom: 25px;
            color: var(--cds-global-color-warm-gray-500);
        }

        &-text {
            .hide-text-overflow();
        }

        &-icon {
            margin: 0 6px;
            cursor: pointer;

            &:first-of-type {
                margin-left: 12px;
            }

            &--deactivated {
                cursor: not-allowed;
            }
        }
    }

    &__loader-container {
        display: flex;
        justify-content: center;
        padding-top: 1.5rem;
    }
}
