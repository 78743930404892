@import (reference) '~ajs/less/themes/default-theme/colors.less';

.ip-reputation-search-record-card {
    display: block;
    height: 100%;

    &__header {
        text-transform: uppercase;
    }

    &__search-result {
        margin-top: 12px;
        color: @aviBlack;
    }

    &__search-result-tags {
        margin-right: 3px;
    }

    &__search-result-label {
        margin-bottom: 4px;
    }

    &__search-container {
        position: relative;
    }

    &__search-clear-icon {
        --color: var(--clr-btn-primary-bg-color);
        position: absolute;
        top: 10px;
        right: 0;
        line-height: 30px;
        cursor: pointer;

        &--offset-right {
            right: 22px;
        }
    }

    &__result-description {
        margin-top: 5px;
        color: @green;
        font-size: 0.8em;
    }

    &__empty-result-description {
        margin-top: 25px;
        text-align: center;
    }

    &__loader {
        margin-top: 25px;
        text-align: center;
    }

    &__alert {
        margin-top: 25px;
    }
}
