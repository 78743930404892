// Variables
@aviTopHeaderHeight: 60px;
@aviSubHeaderHeight: 60px;
@aviFullheaderHeight: 100px;
@aviHeaderLeftMenuWidth: 205px;
@aviHeaderRightMenuWidth: 120px;
@subnavHeight: 40px;

.make-up-arrow(@x, @y, @color, @size: 21px) {
    content: '▲';
    position: absolute;
    top: @y;
    left: @x;
    transform: scaleX(1.5);
    color: @color;
    font-size: @size;
}

// Sub Navigation
.left-section {
    .avi-subheader {
        .avi-subnav {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            border-bottom: none;
        }
    }
}

.avi-subheader {
    display: flex;
    position: relative;
    z-index: @headerZindex - 1;
    box-sizing: border-box;
    align-items: center;
    height: @aviTopHeaderHeight;
    padding: 0 @pagePaddingWidth;
    background-color: @aviWhite;

    .subheader-back-button {
        margin-right: 10px;
        margin-left: -10px;
    }
}

.metrics-controls {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.two-column-container {
    .avi-subnav {
        border-top: 0;
    }
}

.avi-subnav {
    display: flex;
    z-index: @headerZindex - 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    min-height: @subnavHeight;
    padding: 0 @pagePaddingWidth;
    background-color: @aviWhite;
    box-shadow: inset 0 -.05rem 0 @alb-subnav-box-shadow-color;

    i.icon-down-open-big {
        color: @aviGreySpecial;
    }

    .avi-subnav-name {
        margin-left: 15px;
        cursor: pointer;
    }

    .navbar {
        display: block;
        position: relative;
        flex: 1 1;

        ul.avi-nav {
            display: inline-block;
            position: relative;
            list-style: none;

            li {
                display: inline-block;
                position: relative;
                box-sizing: border-box;
                padding: 0;
                transition: box-shadow .2s ease-in;
                color: @textMidGray;
                font-size: 15px;
                line-height: 40px;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                a {
                    color: inherit;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &.active {
                    box-shadow: 0 -.15rem 0 var(--clr-nav-active-box-shadow-color) inset;
                    color: #000;

                    & > a {
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__toggle {
        margin-right: 15px;

        &-label {
            padding-bottom: 3px;
        }
    }
}

div.avi-root-state-view {
    display: flex;
    flex-direction: column;
    height: 100%;

    .navbar-unit-name {
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.se {
            max-width: 300px;
        }

        &.se-cloud {
            margin-right: 10px;
        }

        &.cloud {
            max-width: 450px;
        }
    }
}
