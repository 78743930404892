@import (reference) '~ajs/less/themes/default-theme/z-index.less';

@cinematicBackdropZIndex: @headerZindex * 100;
@cinematicContentZIndex: @cinematicBackdropZIndex + 100;

.avi-cinematic-portal {
    &__backdrop {
        position: fixed;
        z-index: @cinematicBackdropZIndex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--cds-alias-object-overlay-backdrop-background);
    }
}
