.clr-control-container .clr-form-control {
    input[type=password],
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=tel],
    input:not([type]) {
        &.input--view-mode.clr-input {
            // Adding !important here to override 'disabled' styling, which has high specificity.
            border: 0 !important;
            background: none;
            color: var(--clr-forms-text-color);
            pointer-events: none;
        }
    }
}

.clr-form-control .clr-control-container {
    textarea {
        &.input--view-mode.clr-textarea {
            padding: 0 0.3rem;
            border: 0;
            background: initial;
            color: var(--clr-forms-text-color);
            pointer-events: none;
        }
    }
}
