.se-group-list-page {
    &__header-container {
        margin-left: 10px;
    }

    &__collection-grid {
        margin: 10px;
    }

    &__expander {
        width: 100%;
    }
}
