.http-method-match {
    .clr-form-control {
        margin-top: 0;
    }

    &__dropdown-wrapper {
        margin: 15px 0 0 15px;

        .avi-dropdown__container--disabled {
            opacity: 1;
        }
    }
}
