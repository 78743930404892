@import (reference) '~ajs/less/themes/default-theme/colors.less';

.match-container {
    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__close-button {
        border: none;
        background: none;
    }

    .h-controls-group > .match-container__radio-group {
        flex: 0 0 175px;
        margin-right: 15px;
    }
}
