.avi-welcome {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../../img/polygon.png) bottom center @aviBrandColor2 repeat-x;
    background-size: 50%;

    .avirights {
        position: absolute;
        z-index: -1;
        bottom: 0;
        padding: 1%;
        font-size: 0.714em;
    }

    .please-reload {
        .absolutelyCentered();
        width: 400px;
        height: 2em;
        color: @aviWhite;
        font-size: 2em;
        text-align: center;
    }
}
