@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';

wizard {
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
    float: left;
    overflow: hidden;
    border-bottom: 1px solid darken(@aviBlue0, 10%);
    background-color: @aviBlue1;
    line-height: 10px;

    ul {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            height: 30px;
            float: left;
            white-space: nowrap;

            &:nth-child(1) {
                z-index: 10;
            }

            &:nth-child(2) {
                z-index: 9;
            }

            &:nth-child(3) {
                z-index: 8;
            }

            &:nth-child(4) {
                z-index: 7;
            }

            &:nth-child(5) {
                z-index: 6;
            }

            a {
                display: block;
                overflow: hidden;
                color: darken(@aviBlue0, 10%);
                line-height: 30px;
                text-overflow: ellipsis;

                &:hover {
                    text-decoration: none;
                    cursor: not-allowed;
                }
            }

            i {
                margin-left: @standardMargin;
                float: left;
                color: darken(@aviBlue0, 10%);
                font-size: 18px;
                line-height: 30px;
                text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -10px;
                width: 0;
                height: 0;
                transform: rotate(360deg);
                transition: all .2s linear;
                border-width: 15px 0 16px 10px;
                border-style: solid;
                border-color: transparent transparent transparent darken(@aviBlue0, 10%);
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -9px;
                width: 0;
                height: 0;
                transform: rotate(360deg);
                transition: all .2s linear;
                border-width: 15px 0 16px 10px;
                border-style: solid;
                border-color: transparent transparent transparent @aviBlue1;
            }

            &.unlocked {
                &:before {
                    border-color: transparent transparent transparent darken(@aviBlue0, 10%);
                }

                &:after {
                    border-color: transparent transparent transparent @aviBlue1;
                }

                i {
                    color: @aviGreen1;
                    text-shadow: none;
                }
            }

            &.unlocked:not(.current) {
                a {
                    color: darken(@aviBlue0, 10%);

                    &:hover {
                        color: @aviWhite;
                        cursor: pointer;
                    }
                }
            }

            &.current {
                background-color: @aviBlue0;

                a {
                    color: @aviWhite;
                    cursor: default;
                }

                &:before {
                    border-color: transparent transparent transparent darken(@aviBlue0, 10%);
                }

                &:after {
                    border-color: transparent transparent transparent @aviBlue0;
                }

                i {
                    color: darken(@aviBlue0, 10%);
                }

                &.unlocked {
                    i {
                        color: @aviGreen0;
                        text-shadow: none;
                    }
                }
            }
        }
    }
}
