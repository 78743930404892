.editable-list {
    clear: both;

    .rule-details {
        transition: height 1s;

        .column {
            display: inline-block;
            width: 40%;
            float: left;

            .item {
                margin-top: 5px;
                font-size: 12px;
            }
        }
    }

    .edit-mode {
        // Field Sizing
        .serverState, .ruleEdit {
            position: relative;
            float: left;

            label {
                margin-bottom: @standardMargin/3;
            }

            & > input[type=checkbox], input[type=radio] {
                width: auto;
            }

            & > input, select, dropdown, collection-dropdown, textarea {
                box-sizing: border-box;
                width: 100%;
                margin-bottom: 0;
            }

            & > input, select, dropdown, collection-dropdown {
                height: @buttonHeight;
            }
        }

        .serverState {
            width: 2em;
            padding-top: 1em;
        }

        .configuration {
            display: inline-block;
            width: 100%;
            margin-bottom: @standardMargin/3;

            .ruleEdit {
                box-sizing: border-box;
                float: none;
            }

            .controls {
                width: 100%;

                input[type=text].ruleName {
                    width: 90%;
                }

                .logging {
                    width: 10%;
                    padding: 0;
                    text-align: center;

                }
            }

            input[type=text].transparent {
                width: 400px;
                border: none;
                background: transparent;
                font-family: sans-serif;
                font-size: 14px;
                .border-radius(0);
            }

            input[type=text].transparent:focus {
                box-shadow: none;
            }

            input[type=text].duration {
                width: 40px;
                height: 24px;
                padding: 0 3px;
                line-height: 10px;
            }

            &.log-headers-option {
                position: relative;

                & > div.ruleEdit {
                    margin-right: 235px;

                    & > label {
                        display: block;

                        & > input {
                            width: 100%;
                        }
                    }
                }

                & > div.avi-btn-group {
                    position: absolute;
                    top: 17px;
                    right: 0;
                    float: none;

                    & > label.avi-btn {
                        float: none;
                    }
                }
            }
        }

        .rule-match, .rule-action {
            .ruleHeader {
                background-color: @aviGrey0;
                color: #FFF;
                line-height: 25px;
                text-align: center;

                .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);
            }

            .ruleContainer {
                position: relative;
                border: 1px solid @aviGreySpecial;
                .border-radiuses(0, @aviBorderRadius, @aviBorderRadius, 0);
            }

            .matchingRule, .actionRule {
                width: 100%;
                margin: 0;
                padding: 0;
            }

            .match-operation {
                position: relative;
                left: 1px;
                margin-right: 0;
                padding: 0 5px;
                float: left;
                border: 1px solid @aviGreySpecial;
                background-color: @aviBackgroundColor;
                line-height: @buttonHeight;
                .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
            }

            .file-input-name {
                display: none;
            }
        }

        .rule-match {
            .border-radius();
        }

        .matching-rule {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 40px;

            .match-case {
                bottom: 22px;

                span {
                    position: relative;
                    bottom: 0;
                }
            }

            &.center {
                align-items: center;
            }

            .matching-rule-column {
                display: block;
                position: relative;
                flex: 1;
                max-width: 50%;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            .match-label {
                display: block;
                position: relative;
            }

            .match-value {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                margin-bottom: 10px;
            }
        }

        .matching-rule-grid {
            margin-top: 10px;
            padding-left: 40px;
        }

        .matching-rule-grid-header {
            color: var(--cds-global-color-blue-700);
            font-weight: 600;
        }
    }

    .rule-remove-button {
        position: absolute;
        top: 0;
        right: 0;
    }

    .new-rule-form {
        margin-top: @standardMargin;
    }

    .grid-field-enable, .grid-field-enabled, .grid-field-log {
        width: 15%;
    }

    .grid table thead tr th.rowactions {
        width: 120px;
    }

    label {
        .radio {
            position: relative;
            top: -3px;
            margin-top: 3px;
        }
    }

    /*Rule styles*/
    .rule {
        margin-bottom: @standardMargin/3;

        .matchlist, .actionlist {
            .matchlistRow, .actionlistRow {
                position: relative;
                padding: 10px;
                border-bottom: 1px solid @aviGreySpecial;

                input[type=text], input[type=number] {
                    width: 100%;
                }

                &:last-child {
                    border: none;
                }

                .rate-limit {
                    .inner-text {
                        width: 100%;
                    }
                }
            }
        }

        .name {
            margin-bottom: 20px;
            font-weight: bold;
            vertical-align: middle;
        }

        .prefix {
            font-weight: 300;
        }

        .matchlist > :last-child .delimiter {
            display: none;
        }

        .actionlist > :last-child .delimiter {
            display: none;
        }

        .delimiter {
            display: none;
        }

        button.icon-trash {
            color: #73787E;
        }

        button.icon-trash:hover {
            color: #E94848;
        }
    }

    .rule.disabled {
        color: #B4B4B4;
    }

    .transparent {
        border: none;
        background: none;
        box-shadow: none;
    }

}

.table {
    tr.disabled {
        color: #A8A8A8;

        a {
            color: #A8A8A8;
        }
    }
}

.modal-scrollable {
    .http-content-policy {
        .avi-modal-body {
            min-height: 100px;
        }
    }
}

.modify-header-index {
    padding-right: 0.5rem !important;

    &__value {
        display: flex;
        align-items: center;
        height: 1.8rem;
    }
}
