@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-default-view-log-list {
    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__content {
        min-width: @signpost-content-min-width;
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
