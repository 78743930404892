.system-settings-page {
    & &__card {
        margin-top: 20px;
    }

    & &__row {
        display: flex;
        align-items: stretch;
    }

    & &__container-right {
        width: 70%;
    }

    & &__container-left {
        width: 30%;
    }

    & &__control {
        flex-grow: auto;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}
