@import (reference) '~ajs/less/layout/layout';
@import (reference) '~ajs/less/themes/default-theme/colors';
@import (reference) '~ajs/less/themes/default-theme/z-index';
@import (reference) 'log-paginate';

div.logCalloutCarrat {
    display: none;
    //carrat
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    width: 0;
    height: 0;

    //triangle arrow mixIn
    .boxArrow() {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        border: 10px solid transparent;
    }

    &:before {
        //to add border we need two overlaying triangles
        .boxArrow();
        border-left-color: @aviGreySpecial;
    }

    &:after {
        .boxArrow();
        left: -1px;
        border-left-color: @aviWhite;
    }

    &.left {
        //triangle arrow on left position
        &:before, &:after {
            border-left-color: transparent;
        }

        &:before {
            border-right-color: @aviGrey1;
        }

        &:after {
            left: 1px;
            border-right-color: @aviWhite;
        }
    }
}

div.e2e-timings {
    height: 50px;
    padding: 1em 0 .5em 0;

    & > div {
        margin-left: -0.5ex;
    }
    .divMixIn() {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        width: 18%;
        padding-left: 2em;
        text-align: center;

        & > .caption {
            //caption under the icon and text, used in log details
            position: absolute;
            bottom: -1.85em;
            left: 0;
            width: 100%;

            & > .details {
                //central block
                padding-left: 2em;
            }

            & > .name {
                position: absolute;
                top: 0;
                left: 0;
                color: @aviGrape0;
                font-size: 1.1em;
                font-weight: bold;
            }

            & > .name.right {
                right: 0;
                left: auto;
            }
        }

        &:before, &:after {
            position: absolute;
            top: .2em;
            color: @aviGrey1;
            font-family: avi, serif;
            font-size: x-large;
        }

        &:before {
            left: .15em;
        }

        &:after {
            right: .15em;
        }

        a.active {
            color: inherit;

            &:hover {
                text-decoration: none;
                cursor: default;
            }
        }
    }

    & > .c_rtt {
        .divMixIn();

        &:before {
            content: '\e827';
        }
    }

    & > .s_rtt {
        .divMixIn();

        &:before {
            content: '\e826';
        }
    }

    & > .app_resp {
        .divMixIn();
        width: 27%;
        padding: 0 2em;

        & > .caption > .details {
            padding: 0 2em;
        }

        &:before {
            content: '\e825';
        }

        &:after {
            content: '\e824';
        }
    }

    & > .data_tr {
        .divMixIn();
        width: 17%;
        padding-left: 0 !important;

        &:before {
            content: '';
        }
    }

    & > .total {
        .divMixIn();
        width: 15%;
        font-weight: bold;

        &:before {
            content: '=';
        }

        & > span.number {
            color: @aviGreen0;
            //total duration
            font-size: larger;
        }
    }

    & > .l_bracket, & > .r_bracket {
        display: inline-block;
        position: relative;
        top: -.55em;
        width: .75em;
        height: 3.5em;
        border-top: 1px solid @aviGreySpecial;
        border-bottom: 1px solid @aviGreySpecial;
    }

    & > .l_bracket {
        border-left: 1px solid @aviGreySpecial;
    }

    & > .r_bracket {
        border-right: 1px solid @aviGreySpecial;
    }

    &.layer4 {
        & > .c_rtt, & > .s_rtt, & > .total {
            width: 33%;
        }

        & > .s_rtt {
            padding: 0 3.25em 0 1.5em;

            &:after {
                content: '\e824';
                right: 1em;
            }
        }

        & > .total {
            padding-left: 2.5em;

            &:before {
                content: '';
            }
        }
    }
}

.events-list-popover-mixin() {
    z-index: @headerZindex;
    width: 600px;
    min-height: 130px;
    max-height: 80%;
    padding: 15px; /* in px only as used by JS */
    overflow-y: auto;
    transition: height .5s,
        top 1s;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    box-shadow: 0 1px 5px 0 @aviGrey1;

    h2 {
        padding: 0 0 0.3em 0;
    }

    i.closeButton {
        position: absolute;
        z-index: 2;
        top: 0.35em;
        right: 0.35em;
        cursor: pointer;
    }

    /* popover > wrap > content */
    div.wrap {
        min-height: 100px;
        overflow: hidden;
    }

    span[log-filter-click], div[log-filter-click], span.clickable {
        color: @aviBlue0;
    }

    .c-grid-table-header-cell--field-name-- {
        &value {
            width: 30%;
            word-break: break-all;
        }

        &count {
            width: 15%;
        }

        &percentage {
            width: 14%;
        }

        &percentageBar {
            width: 41%;

        }
    }

    .c-grid__table-cell {
        div.progress {
            width: 100%;
        }
    }
}

body > div.aviPopover.event-list-sidebar-popover {
    .events-list-popover-mixin();
}

body > div.aviPopoverCarat.event-list-sidebar-popover {
    z-index: @headerZindex;
}

.log-callout {
    .events-list-popover-mixin();
    position: fixed;
    height: 100px;
    min-height: 100px;
    border: 1px solid @aviGrey1;

    & > div[ng-include] {
        overflow: hidden;
    }

    /* animation oh popover hide/show driven by ngAnimate */
    &.callout-show, &.callout-hide {
        transition: all ease .3s;
    }

    &.callout-show {
        opacity: 0;

        &.callout-show-active {
            opacity: 1;
        }
    }

    &.callout-hide {
        opacity: 1;

        &.callout-hide-active {
            opacity: 0;
        }

    }

    /* callout > div[ng-include] > wrap > content */
    div.wrap {
        height: 100px;
        transition: height .5s ease;
    }
    //for center align
    div.log-popover-header-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: .5em;
        background-color: white;
        text-align: center;

        div.line {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: @aviGrey1;
        }
    }

    div.log-popover-header {
        display: inline-block;
        padding: 0 1em;
        background-color: white;
        line-height: 2em;

        span.bigNumber {
            color: @aviGreen0;
            font-size: x-large;
        }

        span.small {
            color: @aviGrey0;
            font-size: small;
        }
    }

    table.log-popover-table {
        th, td {
            vertical-align: middle;

            &.name {
                width: 44%;
                word-break: break-all;
            }

            &.number {
                width: 12%;
            }

            &.percent {
                width: 13%;
            }

            &.progress-chart {
                width: 31%;

                & > div.progress {
                    width: 100%;
                }
            }
        }

        th {
            padding: 0 5px;
            text-overflow: clip;

            &.percent {
                padding: 0 0 0 5px;
                overflow: visible;
                text-overflow: clip;
            }

            &.number {
                padding: 0 0 0 5px;
            }

            &.progress-chart {
                border-left: none;
            }
        }

        tr {
            &.subheader {
                border-right: 1px solid;
                border-left: 1px solid;

                & > td {
                    padding: .3em 0;
                    border-bottom: 1px solid @aviGrey0;
                    background-color: @aviGrey1;
                    color: @aviWhite;
                    text-align: center;
                }
            }

            &.row {
                & > td {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        &.uri_path, &.referrer {
            //template names
            th.name {
                width: 56%;
            }

            td.name {
                padding: .5em;
            }

            th, td {
                &.number {
                    width: 13%;
                }

                &.percent {
                    width: 11%;
                }

                &.progress-chart {
                    width: 20%;
                }
            }
        }

        &.groups {
            th, td {
                &.progress-chart {
                    width: 30%;
                    min-width: 150px;
                }

                &.checkbox {
                    width: 2em;
                    //checkbox or icon for expander
                    padding: 0;
                    text-align: center;
                }
            }
        }

        &.significance {
            tr.row.child > td.value {
                padding-left: 1.5em;
            }
        }

        & > tbody {
            &:nth-child(even) > tr.row {
                &:nth-child(even) {
                    background-color: @aviWhite;
                }

                &:nth-child(odd) {
                    background-color: @aviGrey3;
                }
            }

            &:last-child > tr.subheader td {
                border-bottom: none;
            }
        }

    }

    .e2eChart {
        width: 100%;
        height: 150px;
        padding: 1.5em 0;

        svg {
            border: 1px solid @aviGrey2;
            .border-radiuses();

            .bar {
                stroke: @aviGreen0;
                fill: @aviGreen3;

                &:hover {
                    stroke: black;
                    fill: @aviGreen2
                }
            }

            .axis {
                path, line {
                    stroke: @aviGrey2;
                    fill: none;
                    shape-rendering: crispEdges;
                }

                line {
                    stroke: @aviGreySpecial;
                }

                &.x .tick {
                    padding-top: 1em
                }

                &.y .tick line {
                    stroke-width: 1px;
                }

                text {
                    fill: @aviGreySpecial;
                    font: 10px sans-serif;
                }
            }
        }
    }

    .log-popup-empty-data-label {
        margin-top: 1em;
        font-weight: bold;
        text-align: center;
    }

    .waf-phase-latency {
        &__table {
            width: 100%;
            table-layout: fixed;

            tr {
                border-bottom: 1px solid @borderGray;
            }

            &-head {
                text-align: left;

                th:not(.waf-phase-latency__table-head-phase) {
                    padding: 10px;
                }

                &-phase {
                    width: 120px;
                }
            }

            &-body {
                tr {
                    td {
                        .progress-container {
                            display: flex;

                            .progress {
                                width: 80%;

                                .progress-bar {
                                    box-sizing: border-box;
                                    border-left-width: 1px;
                                    border-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }

            &-phase {
                &-cell {
                    padding: 0 10px;
                    overflow: hidden;
                    background-color: #49A1C1;
                    color: white;
                    font-size: 12px;
                    text-align: center;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }

                &-label {
                    overflow: hidden;
                    word-wrap: normal;
                    white-space: nowrap;
                }
            }

            &-cell {
                padding: 10px;
            }
        }
    }
}

.log-analytics {
    &__group-table {
        width: 100%;
        table-layout: fixed;

        th, td {
            padding: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        th {
            font-weight: normal;
            text-transform: uppercase;
        }

        tr {
            border-bottom: 1px solid @borderGray;
        }

        thead {
            font-size: 12px;
        }

        &-value {
            width: 60px;
            font-weight: bold;
            text-align: right;
        }

        &-name {
            text-align: left;
        }

        &-child {
            font-size: 12px;

            .log-analytics__group-table-name {
                padding-left: 20px;
            }
        }

        &-controls-col {
            width: 25px;
        }
    }
}
