// Don't have a cds variable for #eee yet.
@import (reference) '~ajs/less/themes/default-theme/colors.less';

// z-index value.
@zIndex: 999;

.ipam-dns-configuration {
    position: relative;

    & &__disabled {
        position: absolute;
        z-index: @zIndex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.65;
        background-color: @aviHealthDisabled1;
    }
}
