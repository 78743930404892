.avi-health-score-bars {
    &__container {
        position: relative;
    }

    &__rakeline {
        position: absolute;
        z-index: 100;
        left: 130px;
        width: 190px;
        height: 100%;

        &-bar {
            width: calc(var(--healthScore) * 1%);
            height: 100%;
            border-right: 1px dashed var(--cds-global-color-blue-500);
        }
    }

    &__partition {
        display: flex;
        align-items: center;

        &-header {
            width: 100px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }

        &-line {
            width: 100%;
            margin: 0.5em auto;
            border-width: 1px;
            border-style: inset;
            color: var(--cds-global-color-gray-500);
        }
    }
}
