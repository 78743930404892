.file-upload-textarea {
    display: block;

    &__header {
        display: flex;
        margin-bottom: 5px;
    }

    &__label {
        flex: 1 1 auto;
    }

    &__radio {
        flex: 0 1 auto;

        //override
        &:last-child {
            margin-right: 0;
        }
    }

    &__file-upload-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__file-upload {
        flex: 1 1 auto;
    }
}
