@import (reference) '~ajs/less/themes/default-theme/colors.less';

@modal2HeaderHeight: 85px;
@modal2FooterHeight: 85px;

.avi-modal.modal2 {
    top: 0 !important;
    bottom: 0;
    left: 50% !important;
    width: 55%;
    height: initial;
    transform: translate(-50%);
    border: 0;
    border-radius: initial;
    background: @modal2BackgroundColor;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.5);
    color: @modal2Color;

    .modal-section {
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:not(:last-child) {
            border-bottom: 1px solid @borderGray;
        }
    }

    .modal-section-title {
        margin: 0 0 1em 0;
        padding: 0;
        border: 0;
        box-shadow: none;
        font-size: 24px;
        text-align: left;

        &:before, &:after {
            content: '';
            display: none;
        }
    }

    .avi-modal-header {
        @closeButtonSize: 44px;
        box-sizing: border-box;
        height: @modal2HeaderHeight;
        margin: 0;
        padding: 20px 0;
        border: 0;
        border-bottom: 1px solid @modal2BorderColor;
        background: transparent;
        color: #444F51;

        h1 {
            padding-right: @closeButtonSize;
        }

        button.close {
            position: relative;
            width: @closeButtonSize;
            height: @closeButtonSize;
            margin: 0 10px 0 0;
            color: inherit;
            font-size: 24px;
            font-weight: normal;
            text-shadow: none;

            &:hover {
                background-color: transparent;
                color: inherit;
            }
        }

        .divider {
            width: 0;
            border: 0;
        }
    }

    .avi-modal-body {
        top: @modal2HeaderHeight;
        bottom: @modal2FooterHeight;
        box-sizing: border-box;
        padding: 20px 50px;
    }

    .control-group, .h-control {
        label {
            font-weight: 500;
        }
    }

    collection-dropdown {
        float: none;
    }
}

.avi-modal.modal2, .avi-modal.modal3 {
    font-size: 14px;

    .avi-modal-footer {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        height: @modal2FooterHeight;
        padding: 20px;
        border-top: 1px solid @modal2BorderColor;
        background-color: transparent;

        .avi-btn-primary, .avi-btn-default {
            min-width: 150px;
            height: 40px;
        }
    }

    h1 {
        background: none;
        color: @modal2Color;
        font-size: 24px;
        font-weight: 500;
    }

    h3 {
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: inherit;
    }

    h4 {
        font-size: 16px;
        font-weight: inherit;
    }

    .duration-combo-box {
        .add-on {
            background-color: white;
        }
    }

    .control-group > label, label.control-label {
        margin: 0 0 5px 2px;
        color: @modal2Color;
        font-size: 14px;
        font-weight: 500;
    }

    .checkbox-label-on-top {
        .controls {
            box-sizing: border-box;
            padding-top: 10px;
        }
    }

    .link {
        color: @green;

        &:hover {
            text-decoration: none;
        }
    }

    .trash-button {
        font-size: 25px;
        cursor: pointer;
    }

    .modal-confirm {
        display: flex;
        top: 0;
        bottom: 0;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        padding: 0;
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0.74);

        h2.confirmation {
            margin: 0 0 2em;
            padding: 0;
            color: white;
        }
    }

    .close-button {
        display: inline-block;
        position: absolute;
        top: 25px;
        right: 20px;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        background: transparent;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            transform: rotate(45deg);
            border-bottom: 1px solid;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            transform: rotate(-45deg);
            border-bottom: 1px solid;
        }
    }

    &.xl-padding {
        > .avi-modal-header > h1 {
            padding: 0 100px;
        }

        > .avi-modal-body {
            padding: 15px 100px;
        }
    }

    section {
        margin: @modalSectionVerticalMargin 0;
    }

    .warning {
        align-self: center;
    }
}
