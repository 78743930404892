/**
* To be imported by every specific Item detail component.
* Not to be used directly, mixins only.
**/

@import (reference) '~ajs/less/themes/default-theme/theme.less';

.item-detail-component() {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;

    //default viewport
    > div[ui-view=''] {
        position: relative;
        flex: 1 1 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .absolute-page {
        top: @headerHeight;
    }
}
