@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

@borderColor: #888;

.waf-top-hits-container {
    display: flex;
    position: relative;
    flex-direction: column;
    border: 1px solid @borderColor;
    border-radius: 3px;
    background-color: white;

    [avi-loader] {
        .absolutelyCentered();
        opacity: 0.75;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid @borderGray;
    }

    &__title {
        font-size: 16px;
    }

    &__label {
        color: @aviGrey1;
        font-size: 10px;
        text-transform: uppercase;
    }

    &__grid {
        width: 100%;
        height: 150px;
        overflow-y: auto;
    }

    &__list-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 7px 10px;
        border-bottom: 1px solid @borderGray;

        &:hover {
            background-color: @aviGrey3;
        }

        //applies to the last element in case if list has at least five entries
        &:nth-child(n + 5):last-child {
            border-bottom: none;
        }

        &--selected {
            background-color: lighten(@aviGrey3, 5%);
        }
    }

    &__hit {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
        font-weight: 500;

        &--name {
            flex-grow: 1;
            margin-right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--total {
            display: flex;
            font-size: 11px;
            .hide-text-overflow;
        }
    }

    &__total {
        display: flex;
        height: 30px;
        background-color: @aviGrey3;
        color: @borderColor;
        text-transform: uppercase;
        pointer-events: none;

        .waf-top-hits-container__list-item {
            border-bottom: 0;
        }
    }

    &__waf-enable {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-start;
        min-width: 0;
    }
}
