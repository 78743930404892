.vs-log-cinematic-section-column {
    box-sizing: border-box;
    flex: 1;
    padding: 0 10px;

    &--with-divider {
        &:not(:first-child) {
            border-left: 1px solid var(--clr-table-border-color);
        }
    }

    &--with-content-top-padding {
        padding-top: 25px;
    }

    &--expand-two {
        flex: 2;
    }
}

.vs-log-cinematic-section-clarity-override();

.vs-log-cinematic-section-clarity-override() {
    .clr-wrapper {
        h5.vs-log-cinematic-section__column-title {
            color: var(--cds-global-typography-color-500);
            font-weight: var(--cds-global-typography-font-weight-light);

            &:not(:first-child) {
                margin-top: 25px;
            }
        }
    }
}
