.avi-serviceengine-analytics {
    h4 {
        height: 20px;
        margin: 0;
        padding: 0;
        font-size: 18px;
    }

    .card-quarter {
        height: 105px;
    }

    .sparkline-card, .gauge-card-class, .sparkline-card-class {
        padding: 0 10px;
    }

    .performance-chart, .chart-with-overlays {
        width: 100%;
    }

    .avi-subheader .navbar {
        width: auto;
    }

    .navbar-bottom-section {
        height: 35px;
    }
}
