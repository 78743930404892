// Chart Tooltip
.graph-tooltip {
    display: block;
    position: absolute;
    z-index: 101 !important;
    box-sizing: border-box;
    overflow-x: visible;
    border: 1px solid @aviGreySpecial;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    white-space: nowrap;

    .tooltip-date {
        padding: 5px;
        border-bottom: 1px solid @aviGreySpecial;
        font-size: 0.714em;
        text-align: left;
    }

    // this is the bar on top of the tooltip legend
    .tooltip-legend-color.chart-color {
        height: 4px;
        border-right: 0;
        border-left: 0;
    }

    .tooltip-legend {
        display: inline-block;
        min-width: 75px;
        text-align: center;

        .legend-value {
            div {
                padding: 0 10px;
                line-height: 16px;
            }
            // putting a little gap in between this and color line
            div:first-child {
                padding-top: 3px;
            }
        }

        .legend-scale {
            color: @aviGrey2;
            font-size: 0.857em;
        }
    }

    &.logBarChart {
        &.inCallout {
            position: fixed;
            z-index: 1000 !important;
        }

        div.tooltip-date {
            border-bottom: 1px solid @aviGrey2;
            font-size: 1em;

            &:only-child {
                border-bottom: none;
            }
        }

        span.tooltip-legend {
            min-width: 90px;
            //height: 2.2em;
            overflow: hidden;

            span.legend-value {
                & > div:first-child {
                    border-top: 1px solid @aviGrey2;
                }

                & > div.value {
                    padding: .25em .25em .5em .25em;
                }
            }

        }

        div.tooltip-legend-color {
            height: 4px;
            border-width: 1px 0;
            border-style: solid;

            &.significant {
                border-color: @aviRed0;
                background-color: @aviRed3;
            }

            &.missing {
                border-color: @aviYellow0;
                background-color: @aviYellow3;
            }
        }

        & > .tooltip-date {
            text-align: center;
        }
    }


    .triangle-group {
        position: absolute;
    }

    .tooltip-dots {
        box-sizing: border-box;
        width: 150px;
        margin: auto;
        padding: 0 5px;

        .dots-box {
            display: inline-block;
            width: 25%;
            height: 20px;
            text-align: center;

            &.true-colors {
                .anomaly-color { color: @aviYellow0; }
                .alert-color { color: @aviRed0; }
                .config-color { color: @aviBlue0; }
                .system-color { color: @aviGrape0; }
            }
        }

        .false-colors {
            opacity: .25;
        }
    }
}

.performance-chart .graph-tooltip {
    .shape {
        width: 10px;
        height: 10px;

        i {
            width: 10px;
            margin-left: -2px;
            font-size: 0.856em;
            line-height: 10px;
        }
    }
}

.graph-tooltip-triangle {
    position: absolute;
    z-index: 102;
    width: 0;
    height: 0;
    border-width: 10px 10px 0 10px;
    border-style: solid;
    border-color: @aviWhite transparent transparent transparent;
}

.graph-tooltip-outer-triangle {
    margin-top: -1px;
    border-color: @aviGreySpecial transparent transparent transparent;
}

.graph-tooltip-inner-triangle {
    // border-width: 8px 8px 0 8px;
    z-index: 103;
}

// Inline help ToolTip
.avi-tooltip {
    display: block;
    visibility: visible;
    position: absolute;
    z-index: @deepestModalZIndex;
    opacity: 0;
    // font-size:11px;
    line-height: 1.4;
    filter: alpha(opacity=0);

    &.in {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    &.top {
        // margin-top:-8px;
        padding: 6px 0;
    }
}

.tooltip-inner {
    box-sizing: border-box;
    max-width: 250px;
    padding: 5px;
    border: 1px solid @aviGreySpecial;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    color: @aviBodyColor;
    font-size: 0.857em;
    text-align: center;
    text-decoration: none;
    word-break: break-word;
    white-space: normal;
}

.tooltip-arrow {
    position: absolute;
    z-index: 102;
    width: 0;
    height: 0;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: @aviGreySpecial transparent transparent transparent;
}

.avi-tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -6px;
    border-width: 6px 6px 0 6px;
    border-top-color: @aviGreySpecial;

    &:after {
        content: '';
        position: absolute;
        top: -8px;
        left: -6px;
        width: 0;
        height: 0;
        border-width: 6px 6px 0;
        border-style: solid;
        border-color: rgb(255, 255, 255) transparent transparent;
    }
}

body > div.aviPopover.aviTooltip {
    padding: 3px 5px;
    border: 1px solid @aviGreySpecial;
}
