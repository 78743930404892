@import (reference) '~ajs/less/components/button.less';
@import (reference) '~ajs/less/layout/layout.less';

// logs
.searchWrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    min-height: @buttonHeight;
    margin-bottom: .75em;

    button.refresh {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 38px;
    }

    event-search, .event-search {
        display: block;
        position: relative;
        flex: 1;
    }

    log-search {
        flex: 1;

        button.saved i {
            color: @aviYellow2 !important;
        }
    }

    .searchRight {
        margin-left: 10px;
    }

    &__filter-log-search {
        margin-right: 5px;

        &--by-vs {
            min-width: 15%;
        }

        &--select {
            width: 100%;
        }
    }
}


div.avi-main.events, div.avi-main.alerts {
    event-search, .event-search {
        display: block;
    }
}

@logSearchHeight: 45px;

.log-search {
    position: relative;
    width: 100%;
    margin-right: 10px;

    search-pill {
        display: block;
        position: relative;
        height: 100%;
        margin: 4px 2px 3px 6px;
        padding-right: 22px;
        line-height: 18px;

        & > i {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }

        &.edit-mode {
            margin: 0;
            padding-right: 0;
            padding-left: 6px;
            background-color: @aviWhite;
        }
    }

    log-input-pills {
        box-sizing: border-box;
        min-height: @buttonHeight;
        padding-right: 80px;
        float: none;

        span.choice ul {
            width: auto;

            > li.search-pill-wrapper {
                height: 27px;
                padding: 0;
            }
        }

        input[type='text'] {
            float: none;
            font-family: 'Helvetica', 'Arial', sans-serif;
            font-size: 1em;
            line-height: 30px !important;
        }
    }

    .log-search-input { //for events and alerts only
        display: block;
        position: relative;
        width: 100%;
        height: @buttonHeight;
        margin-bottom: 0;
        margin-left: 0;
        padding: 4px 80px 4px 4px;
        border-radius: @aviBorderRadius;
        font-weight: bold;
        vertical-align: top;
    }

    & > button { //buttons at the end of search field
        position: absolute;
        top: 0;
        height: 30px;
        padding: 0 5px;
        line-height: 30px;

        .rightPos(@n) {
            right: unit((@n - 1) * 30, px)
        }

        &:nth-last-child(1) {
            .rightPos(1);
        }

        &:nth-last-child(2) {
            .rightPos(2);
        }

        &:nth-last-child(3) {
            .rightPos(3);
        }
    }

    &.multiline {/* searchbar in logs can be multiline */
        .log-search-button-search {
            border-bottom-right-radius: 0;
        }

        .log-empty-field {
            border-bottom-left-radius: @aviBorderRadius;
        }
    }
}

div.log-search-typeahead {
    display: none;
    position: absolute;
    z-index: 4;
    min-height: 8px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid @aviGrey2;
    background: @aviWhite;
    box-shadow: 0 1px 5px 0 @aviGrey2;
    cursor: pointer;

    & > div.log-search-typeahead-list {
        & > table {
            width: 100%;

            &.variable td {
                &.value { width: 17em }
            }

            &.operator td {
                &.value {  width: 2.5em }
            }

            &.query td {
                &.value { width: 6em; }
                &.percent { width: 6em; }
                &.padding { width: 10em; }
            }

            tr.selected {
                background: @aviGrey3;
            }

            td {
                padding: .5em 0 .5em 1em;
                color: @aviBodyColor;

                &.descr { width: 17em; }

                &.value {
                    color: @aviBlue0;

                    & > span.provided {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

body > div.aviPopover.saveLogSearch {
    z-index: @headerZindex + 1;
    padding: .5em;
    border: 1px solid @aviGrey2;

    input {
        margin-right: .5em;
        font-family: 'Helvetica', sans-serif;
    }
}

body > div.aviPopoverCarat.saveLogSearch {
    z-index: @headerZindex + 2;
}

body > div.aviPopover.exportLogs {
    line-height: 30px;

    li {
        padding: 0 1em;
        white-space: nowrap;

        &:hover {
            background-color: @aviBackgroundColor;
            color: darken(@aviBlue0, 10%);
        }

        a { width: 100%; }
    }
}

.tech-preview-banner {
    margin-bottom: 15px;

    &__text {
        font-size: var(--cds-global-typography-font-size-4);
    }

    &__header {
        font-weight: var(--cds-global-typography-font-weight-bold);
    }
}
