@import (reference) '~ajs/less/themes/default-theme/colors.less';

sparkline-chart, sparkline-chart-list {
    display: block;
    position: relative;
    overflow: hidden;
}

sparkline-chart {
    .sparkline-chart {
        display: block;
        position: relative;

        .chart-title {
            padding-bottom: 5px;
            border-bottom: 1px solid @borderGray;
            font-size: 12px;
        }

        .chart-container {
            display: block;
            position: relative;
            height: 60px;
        }

        g.guideline {
            g.guideline-container {
                circle.v-circle {
                    stroke-width: 3px;
                    stroke-opacity: 1;
                    stroke: #BCBCBC;
                    fill: #6B6B6B;
                    fill-opacity: 1;
                    r: 5px;
                }
            }
        }
    }
}

sparkline-chart-list {
    flex: 1 1 0;

    sparkline-chart {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .sparkline-chart-list {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
    }

    .chart-list {
        position: relative;
        flex: 1 1 0;
        overflow: hidden;
        overflow-y: auto;
    }

    .sparkline-list-axis {
        display: block;
        position: relative;
        height: 30px;

        g.chart-axis path, g.chart-axis line {
            stroke-width: 1px;
        }

        g.chart-axis {
            g.tick {
                line {
                    stroke-opacity: 0;
                }
            }
        }

        &.top {
            margin-bottom: 5px;

            g.chart-axis {
                transform: translate(0, 23px);

                g.tick {
                    text {
                        transform: translate(0, -5px);
                    }
                }
            }

            g.axis-timestamp {
                text.axis-timestamp-text {
                    transform: translate(0, 18px);
                }
            }

            g.axis-timestamp-circle {
                circle {
                    transform: translate(0, 23px);
                }
            }
        }

        &.bottom {
            margin-top: 5px;

            g.chart-axis {
                transform: translate(0, 2px);

                g.tick {
                    text {
                        transform: translate(0, 7px);
                    }
                }
            }

            g.axis-timestamp {
                text.axis-timestamp-text {
                    transform: translate(0, 15px);
                }
            }

            g.axis-timestamp-circle {
                circle {
                    transform: translate(0, 3px);
                }
            }
        }
    }
}
