.reset-password {
    &__goto-login {
        margin-top: 0.6rem;
        font-size: 0.7rem;
        text-align: right;
    }

    &__hint {
        margin-bottom: 15px;
    }

    & &__hint-header {
        margin-bottom: 30px;
    }
}
