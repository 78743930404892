.cluster-state-info {
    &__cluster-status-container {
        display: flex;
        align-items: center;
    }

    &__cluster-name {
        flex: 1;
        min-width: 0;
        padding: 0 5px;
        overflow: hidden;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__value-container {
        margin-top: 15px;
    }

    &__label {
        display: block;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__value {
        display: flex;
        align-items: center;
        font-size: var(--cds-global-typography-font-size-3);
        line-height: 24px;
    }

    &__cluster-vip-address {
        padding-left: 5px;
    }
}
