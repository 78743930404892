@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/themes/default-theme/theme.less';

hs-graph {
    @chartFontSize: 15px;
    @defaultNodeRadius: 10px;
    @rootNodeRadius: @defaultNodeRadius * 3;
    @scoresNodeRadius: @defaultNodeRadius * 2;
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    padding: .75em;
    background-color: @aviWhite;

    div[avi-loader] {
        .absolutely-centered();
    }

    //within svg.foreignObject
    .nodeDivClass(@nodeRadius) {
        div.container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: @nodeRadius;
            color: @textMidGray;
            text-align: center;

            > * {
                font-size: @nodeRadius * 1;
                line-height: @nodeRadius * 2;
            }

            > metrics-value {
                width: 100%;
            }
        }
    }

    svg {
        g.wrapper {
            g.node {
                circle {
                    stroke-width: 1.5px;
                    stroke: @midGray;
                    fill: @aviWhite;
                }

                text {
                    font-size: @chartFontSize;
                }

                &.parent {
                    cursor: pointer;
                }

                &.leaf {
                    circle {
                        fill: @aviWhite;
                    }
                }

                &.root {
                    circle {
                        stroke-width: 3px;
                        .healthScoreColors(fill);
                    }

                    .nodeDivClass(@rootNodeRadius);
                }

                &.contributor {
                    circle {
                        stroke-width: 3px;
                    }
                }

                &.score {
                    .nodeDivClass(@scoresNodeRadius);
                }

                &.active {
                    text {
                        fill: @aviBrandColor;
                    }
                }
            }

            .link {
                stroke-width: 1px;
                stroke: @midGray;
                fill: none;
                stroke-opacity: .4;

                &.contributor {
                    stroke-width: 2px;
                    stroke-opacity: 1;
                }
            }
        }
    }
}
