@import 'grid-table-header-cell/grid-table-header-cell';
@import 'grid-table-header-checkbox/grid-table-header-checkbox';

@gridTableHeaderHeight: 45px;

.c-grid-table-header {
    display: block;
    height: @gridTableHeaderHeight;
    overflow: hidden;
    border-bottom: 1px solid @borderGray;
    color: @textMidGray;
    font-weight: bold;

    &__wrapper {
        display: flex;
        overflow: hidden;
    }
}
