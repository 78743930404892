@import (reference) '~ajs/less/themes/default-theme/colors.less';

.bot-log-list-expander {
    display: block;

    &__container {
        padding: 10px;
    }

    &__label {
        padding-bottom: 5px;
        color: #666;
        font-weight: 700;
    }

    &__clickable-filter {
        color: @hyper-link;
    }

    &__content {
        font-size: 13px;
        font-weight: inherit;
        word-break: break-all;
    }
}
