@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

*[percent-pile-chart] {
    & > div {
        height: 100%;

        & > div.bar {
            width: 70px;
            height: 3px;
            margin: 1px auto 2px auto;
            background-color: @aviGrey3;
            .border-radius(3px);

            &.full {
                background-color: @aviBlue1;
            }
        }
    }
}

server-percent-pile-chart {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
