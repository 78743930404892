.vs-log-cinematic-section-security-bot {
    & &__no-margin-top {
        margin-top: 0;
    }

    &__avi-card:first-of-type {
        max-width: 33%;
    }

    &__show-components-button {
        margin-top: 5px;
    }

    &__notes-card {
        width: 100%;
    }
}
