.subheader {
    &__nav-link {
        display: inline-block;

        &:focus-visible {
            outline: 2px solid -webkit-focus-ring-color;
            outline-offset: 1px;
        }
    }
}
