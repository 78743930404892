@import (reference) '~ajs/less/themes/default-theme/colors.less';

.avi-health-score {
    display: flex;
    flex-direction: column;

    &__icon {
        color: var(--cds-global-color-gray-400);

        &:hover,
        &:active {
            cursor: pointer;
        }

        &--down {
            color: var(--cds-global-color-white);
        }

        &--deactivated {
            color: @aviBodyColor;
        }

        &--exclamation {
            width: inherit;
            height: inherit;
            fill: var(--cds-global-color-white);
        }
    }

    &__health-score-count {
        color: var(--cds-global-color-black);
        font-weight: 600;
        line-height: 16px;
        text-align: center;
    }

    &__health-title {
        .avi-health-score-flex();
        margin: 16px 14px;

        &--icon {
            margin-right: 4px;
        }

        &--label {
            font-size: 12px;
            font-weight: 600;
        }

        &--not-available-label {
            color: var(--cds-global-color-gray-500);
            font-size: 11px;
            font-weight: 400;
        }
    }

    &__colors {
        &--high {
            .avi-health-score-color-maker(var(--cds-alias-status-success));

            .avi-health-score__health-score-count {
                color: var(--cds-global-color-white);
            }
        }

        &--medium {
            .avi-health-score-color-maker(var(--cds-alias-status-warning));
        }

        &--low {
            .avi-health-score-color-maker(var(--cds-global-color-tangerine-500));
        }

        &--unknown,
        &--down,
        &--resources {
            .avi-health-score-color-maker(var(--cds-alias-status-danger));
        }

        &--deactivated,
        &--inactive,
        &--creating,
        &--unused,
        &--initializing,
        &--loading {
            .avi-health-score-color-maker(var(--cds-alias-status-disabled));
        }
    }

    &__outer {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        color: var(--cds-global-color-gray-600);
        font-size: 12px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
    }

    &__spinner {
        margin: 20px;
        text-align: center;
    }

    &__signpost {
        .signpost {
            width: 350px;
            padding: 0;

            .signpost-header {
                margin: 8px;
            }

            .signpost-body {
                margin: 0;
            }

            .avi-health-score__title {
                margin: -12px 26px 8px 14px;
                font-size: 22px;
                font-weight: 400;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
        }
    }

    .avi-health-score-color-maker(@color) {
        transition: all .25s linear;
        background-color: @color;
        fill: @color;
    }

    .avi-health-score-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
