.navtop-info-block() {
    display: inline-block;
    position: relative;
    min-width: 400px;

    &.popup-opened {
        .wrapper {
            .small-shadow-bottom();
            margin-left: -10px;
            padding: 10px;
            background-color: white;
        }
    }
}

div[service-engine-info], div[unit-info] {
    .navtop-info-block();
}

div.avi-info-popup {
    .small-shadow-bottom();
    position: absolute;
    z-index: 10;
    top: calc(100%);
    right: 0;
    left: -10px;
    padding-bottom: 5px;
    background-color: white;
    font-size: 14px;
    line-height: 16px;

    .avi-info-popup-value.info-ports {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .avi-info-popup-title {
        padding: 0 10px;
        background: #333;
        color: white;
        line-height: 25px;
    }

    .avi-info-popup-group {
        display: inline-block;
        width: 44%;
        padding: 5px 10px 5px 10px;
        vertical-align: top;

        &.pb0 {
            padding-bottom: 0;
        }

        &.pt0 {
            padding-top: 0;
        }
    }

    .avi-info-popup-rules {
        display: inline-block;
        width: 100%;
        padding-bottom: 5px;
        vertical-align: top;
    }

    .avi-info-popup-header {
        color: #AAA;
        font-weight: 500;
    }

    hr {
        margin: 5px 0;
    }

    .usage {
        height: 160px;
        margin-top: 15px;

        .usage-header {
            width: 100%;
            text-align: center;
        }

    }

    .disk-usage, .cpuCount {
        display: inline-block;
        width: 49%;
        height: 100%;
        margin: 0;
        padding: 0;
        vertical-align: top;

        &[avi-knob] {
            display: inline-block;
            width: 100px;
            margin-left: (400px  / 2 - 100px)  / 2;
            padding: 0;
        }

        img {
            margin: auto;
        }
    }


    .img-value {
        position: absolute;
        top: 22px;
        left: 0;
        width: 60px;
        text-align: center;
    }

    /* SE network interfaces */
    .interfacesList {
        .small-shadow-bottom();
        display: block;
        position: absolute;
        top: -55px;
        left: 410px;
        width: 700px;
        max-height: 480px;
        overflow-x: visible;
        overflow-y: auto;
        background-color: @aviWhite;

        .title {
            margin-left: 10px;
            color: @aviTitleColor;
        }

        table {
            th {
                &.macAddr { width: 9em; }
                &.ips { width: 16em; }
            }

            td {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

.avi-info-row {
    display: flex;
    align-items: flex-start;

    .equal-width();

    .avi-info-group {
        padding: 5px 10px;
        .flex-col;
    }

    &.no-header {
        .avi-info-group {
            padding: 0 10px 5px 10px;
        }
    }

    .avi-info-group-header {
        color: #AAA;
        font-weight: 500;
        .flex-col;
    }

    &.header-only {
        .avi-info-group {
            padding: 0 10px;
        }
    }
}

.clickBox {
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.clickBox:hover {
    background-color: #F3F3F3;
}
