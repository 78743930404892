.vs-logs-type-dropdown {
    .vs-logs-type-dropdown-clarity-overrides();
    margin-right: var(--clr-label-border-radius);
}

.vs-logs-type-dropdown-clarity-overrides() {
    clr-select-container.clr-form-control {
        margin-top: 0;
    }
}
