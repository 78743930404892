.avi-dynamic-data-grid {
    .avi-dynamic-data-grid-clarity-overrides();
}

.avi-dynamic-data-grid-clarity-overrides() {
    h6:not([cds-text]).avi-dynamic-data-grid__grid-title {
        padding-bottom: 0.3rem;
        color: var(--cds-global-color-blue-700);
    }

    .datagrid-spinner {
        top: 0;
        height: 100%;
    }
}
