.avi-page-header {
    &__header-container {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
    }

    &__header-text {
        padding-right: .25rem;
    }

    &__edit-button {
        --min-width: 2.5rem;
        width: 2.5rem;
    }
}
