.notification-page--pre-welcome-aws {
    .notification-page {
        &__body {
            align-items: flex-start;
        }

        &__support-document {
            margin: 0.8em 0;
        }
    }
}
