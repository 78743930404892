.node-vitals-widget {
    &__usage-info {
        display: block;
        padding: 10px 10px 15px;
        font-size: var(--cds-global-typography-font-size-3);

        &:not(:last-child) {
            border-bottom: 1px solid var(--clr-table-border-color);
        }
    }
}
