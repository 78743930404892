@logControlHeight: 32px;

.log-sidebar {
    .avi-btn-group button {
        line-height: 1em !important;
    }

    .full button {
        width: 50%;
    }

    .sidebar {
        padding-bottom: 15px;

        .log-sidebar-items-header {
            flex: 1 0 0;
            font-size: 14px;
            font-weight: bold;
        }

        ul {
            &.log-sidebar-items-list {
                margin: 0 15px;
                border: 1px solid @borderGray;

                &:last-child {
                    border-bottom: 0;
                }
            }

            & > li {
                display: block;
                position: relative;
                padding: 10px 20px;
                border-top: 1px solid @borderGray;

                &:first-child {
                    border-top-width: 0;
                }

                &:last-child {
                    border-bottom: 1px solid @borderGray;
                }

                i.icon-plus {
                    color: @aviWhite;
                }

                &.opened {
                    background: @highlightColor;
                }

                &:not(.opened) {
                    &:hover {
                        background: #F6F6F6;
                    }
                }
            }

            &.savedSearch {
                & > li {
                    position: relative;

                    i.icon {
                        position: absolute;
                        right: 5px;
                    }
                }
            }
        }
    }
}
