.gslb-placement-grid {
    &__grid-name-column {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .avi-data-grid-clarity-overrides();
}

.avi-data-grid-clarity-overrides() {
    .avi-data-grid {
        margin-top: 0;

        h6.avi-data-grid__grid-title {
            padding-bottom: 0;
        }
    }
}
