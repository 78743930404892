@spacing: 5px;

.waf-add-exception-dialog {
    &__name {
        margin-bottom: 16px;
        margin-left: @spacing;
    }

    &__error {
        margin-bottom: 10px;
    }

    &__exclusion {
        margin-bottom: 10px;
        margin-left: @spacing;
    }

    &__existing-value {
        padding: @spacing 0;

        &:not(:last-child) {
            margin-right: @spacing;
        }

        &:not(:first-child) {
            margin-left: @spacing;
        }
    }

    &__separator {
        padding: @spacing;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        margin-left: @spacing;

        &:not(:last-child) {
            margin-bottom: @spacing;
        }
    }

    &__no-new-exception {
        margin-left: @spacing;
    }

    &__control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: @spacing;
        }

        &:not(:first-child) {
            margin-left: @spacing;
        }
    }

    &__trash {
        cursor: pointer;
    }

    &__content-container {
        width: 100%;
    }

    &__spinner-container {
        width: 100%;
        text-align: center;
    }
}
