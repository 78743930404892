file {
    position: relative;

    input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }

    .force-border-radius-right {
        border-radius: 4px !important;
    }
}
