@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.avi-notification {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: stretch;
    min-height: 60px;
    animation-name: fadeIn;
    animation-duration: 150ms;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    background-color: white;
    animation-fill-mode: both;

    &--error {
        .avi-notification-color-setter(@avi-red);
    }

    &--success {
        .avi-notification-color-setter(@avi-green);
    }

    &--warning {
        .avi-notification-color-setter(@avi-orange);
    }

    &--info {
        .avi-notification-color-setter(@avi-blue);
    }

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        color: white;
    }

    &__icon {
        font-size: @avi-baseline-px * 1.25;
    }

    &__message {
        flex: 1;
        align-self: center;
        padding: 10px;
    }

    &__close-button {
        align-self: flex-start;
        margin: 5px;
        font-size: @avi-baseline-px;
        cursor: pointer;
        justify-self: flex-start;
    }

    .avi-notification-color-setter(@color) {
        border-color: @color;

        .avi-notification__icon-container {
            background-color: @color;
        }
    }
}
