@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.messages {
    display: flex;
    position: fixed;
    z-index: 1100;
    top: @aviTopHeaderHeight + 10px;
    left: 50%;
    flex-direction: column;
    width: 500px;
    margin-left: -250px;

    .message {
        position: relative;
        margin-bottom: 15px;
        padding: 10px 20px;
        border: 1px solid #CCC;
        background-color: #FFF;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
        .border-radius();

        .message-controls {
            @pad: 2px;
            position: absolute;
            top: @pad;
            right: @pad;
            left: @pad;
        }

        .message-text {
            margin-top: 10px;
            vertical-align: baseline;

            strong {
                vertical-align: baseline;
            }
        }
    }
}

.scale-progress, .progress-bar-wrapper {
    .progress {
        height: 8px;
        padding: 1px;
        border: 1px solid @aviGreySpecial;
        border-radius: 5px;
        line-height: 5px;

        .bar {
            display: inline-block;
            height: 8px;
            transition: width 1s ease;
            background-color: @aviGreen1;
            .border-radius();
        }
    }
}
