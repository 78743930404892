.timing-tile {
    display: flex;
    box-sizing: border-box;
    flex: 1 1 auto;
    overflow: hidden;
    text-align: center;

    &--highlighted {
        background-color: #F9F9F9;
    }

    &__icon {
        display: inherit;
        flex: 1 1 50%;
        flex-direction: column;
        justify-content: center;

        &-image {
            display: block;
            flex: 0 0 auto;
            max-width: 50px;
            max-height: 35px;
            margin: 0 auto;
        }

        &-title {
            flex: 0 0 auto;
            height: 1.5em;
            line-height: 1.5em;
        }
    }

    &__value {
        display: inherit;
        flex: 1 1 50%;
        flex-direction: column;
        justify-content: center;

        &-title {
            flex: 0 1 auto;
        }

        //avi-value
        &-value {
            flex: 0 1 auto;

            .timeValue {
                font-size: 1.286em;
            }

            .timing-units {
                color: @aviGrey1;
                font-size: 0.857em;
                vertical-align: bottom;
            }
        }
    }
}
