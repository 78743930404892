@font-face {
    font-family: 'brandon_text';
    src: url('BrandonText-Light.woff2') format('woff2'),
        url('BrandonText-Light.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url('BrandonText-Medium.woff2') format('woff2'),
        url('BrandonText-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url('BrandonText-Bold.woff2') format('woff2'),
        url('BrandonText-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url('BrandonText-Regular.woff2') format('woff2'),
        url('BrandonText-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
