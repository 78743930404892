.traffic-capture-page-card {
    & &__header {
        text-transform: uppercase;
    }

    & &__placeholder {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        color: var(--cds-alias-object-interaction-color-disabled);
    }

    & &__stop-btn {
        --color: var(--cds-alias-status-danger);
    }

    & &__progress-section {
        display: flex;
        align-items: center;
        padding-top: 1.2rem;
        padding-bottom: 0.6rem;
        border-top: 1px solid var(--clr-table-border-color);
    }

    & &__spinner-label {
        margin-left: 0.7rem;
    }

    // To override margin-top set in container component
    .avi-textarea-container {
        margin-top: 0;
    }

    // To override styles set in by default for textarea component
    .clr-textarea, .clr-textarea:focus {
        outline: none;
        box-shadow: none;
        resize: none;
    }
}
