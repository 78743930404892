.welcome-modal {
    box-sizing: border-box;

    .welcome-modal-full-modal-overrides();
    .welcome-modal-clarity-overrides();

    & &__next-btn {
        margin-top: 20px;
    }

    & &__setup-cloud-checkbox-container {
        margin-top: 0;
        margin-right: 28px;
    }

    .clr-checkbox-wrapper {
        .welcome-modal__setup-cloud-label {
            color: var(--clr-color-neutral-0);
        }
    }
}

.welcome-modal-full-modal-overrides() {
    .full-modal-config__body-content {
        padding: 0;
    }
}

.welcome-modal-clarity-overrides() {
    .clr-form {
        padding: 0;
    }

    .clr-control-inline {
        .clr-control-label {
            margin-right: 1.2rem;
        }
    }
}
