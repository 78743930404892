@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.pki-crl-upload-success-notification {
    position: absolute;
    right: 0;
    bottom: 0;
}

.avi-messages-overrides();

/**
 * Need to override the parent messages class to show the notification over the modal.
 * Adding the psuedo class to ensure the overriden styling gets applied
 * only to the 'pki-crl-upload-success-notification' notifications.
 */
.avi-messages-overrides() {
    .messages:has(pki-crl-upload-success-notification) {
        z-index: @deepestModalZIndex + 10;
    }
}
