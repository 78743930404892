@import '~ajs/less/themes/default-theme/clr-variables.less';

.vs-logs-significance-options {
    .vs-logs-significance-options-clarity-overrides();
    display: flex;

    &__logs-label {
        display: flex;
        align-items: center;
        margin-right: var(--clr-label-border-radius);
        color: var(--cds-global-color-black);
        font-family: @font-family;
        font-size: var(--clr-combobox-font-size);
        font-weight: var(--cds-global-typography-font-weight-bold);
    }

    &__checkbox {
        margin-right: var(--clr-label-border-radius);
    }

    &__tooltip {
        margin: 3px 6px;
    }
}

.vs-logs-significance-options-clarity-overrides() {
    clr-checkbox-container.vs-logs-significance-options__checkbox {
        margin-top: 0;
    }
}
