.system-update-about-card {
    display: block;
    height: 100%;

    &__row {
        display: flex;
        align-items: flex-start;
        margin-top: 0.5rem;
    }

    &__control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    .dropdown .dropdown-toggle {
        cds-icon[shape^=angle].system-update-about-card__dropdown-caret {
            top: initial;
        }

        .system-update-about-card__dropdown-trigger-text {
            margin-right: 5px;
        }
    }

    &__dropdown-menu {
        box-sizing: border-box;
    }

    &__dropdown-option-title {
        color: var(--avi-tundora-gray);
        font-weight: 600;
    }

    .system-update-about-card__compliance-mode-setting {
        &--enabled {
            color: var(--cds-alias-status-success);
        }

        &--not-enabled {
            color: var(--cds-alias-status-disabled);
        }
    }

    .system-update-about-card-clarity-overrides();
}

// Clarity by default specifies max-width as 18rem. Few rollback versions have width greater than that.
.system-update-about-card-clarity-overrides {
    .dropdown-menu {
        max-width: fit-content;
    }
}
