@import (reference) '~ajs/less/clr-mixins.less';

.admin-user-setup {
    .clr-login-page-overrides();

    & &__username {
        // To override readonly input's styles from layout.less
        &[readonly] {
            border-bottom: 0.05rem solid var(--clr-forms-border-color);
            background: none;
        }
    }
}
