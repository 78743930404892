.ssl-certificate-create-application-modal {
    & &__validate-button {
        display: flex;
    }

    &__spinner-container {
        margin-top: 16px;
    }

    &__spinner {
        left: 50%;
    }
}
