// Default Variables
@simple-line-font-path        : "./simple-line-icons/";
@simple-line-font-family      : "simple-line-icons";
@simple-line-icon-prefix      : sl-icon-;

//Fonts
@font-face {
    font-family: '@{simple-line-font-family}';
    src:
        url('@{simple-line-font-path}Simple-Line-Icons.woff2') format('woff2'),
        url('@{simple-line-font-path}Simple-Line-Icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*
 Use the following CSS code if you want to have a class per icon.
 Instead of a list of all class selectors, you can use the generic [class*="icon-"] selector, but it's slower:
*/

.@{simple-line-icon-prefix}  {
    &arrow-down,
    &arrow-up,
    &control-end,
    &plus,
    &trash,
    &settings,
    &check,
    &minus,
    &exclamation,
    &info,
    &close,
    &layers,
    &compass,
    &doc,
    &note,
    &options,
    &options-vertical,
    &wrench,
    &cloud-upload,
    &paper-clip,
    &screen-desktop,
    &avi,
    &list,
    &reload {
        font-family: '@{simple-line-font-family}';
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.@{simple-line-icon-prefix}arrow-down:before {
    content: "\e819";
}

.@{simple-line-icon-prefix}arrow-up:before {
    content: "\e816";
}

.@{simple-line-icon-prefix}plus:before {
    content: "\e865";
}

.@{simple-line-icon-prefix}settings:before {
    content: "\e86a";
}

.@{simple-line-icon-prefix}trash:before {
    content: "\e840";
}

.@{simple-line-icon-prefix}check:before {
    content: "\e85e";
}

.@{simple-line-icon-prefix}minus:before {
    content: "\e808";
}

.@{simple-line-icon-prefix}exclamation:before {
    content: "\e806";
}

.@{simple-line-icon-prefix}info:before {
    content: "\e870";
}

.@{simple-line-icon-prefix}close:before {
    content: "\e887";
}

.@{simple-line-icon-prefix}layers:before {
    content: "\e8af";
}

.@{simple-line-icon-prefix}compass:before {
    content: "\e883";
}

.@{simple-line-icon-prefix}doc:before {
    content: "\e88a";
}

.@{simple-line-icon-prefix}note:before {
    content: "\e8b7";
}

.@{simple-line-icon-prefix}options:before {
    content: "\e81a";
}

.@{simple-line-icon-prefix}options-vertical:before {
    content: "\e81b";
}

.@{simple-line-icon-prefix}wrench:before {
    content: "\e831";
}

.@{simple-line-icon-prefix}cloud-upload:before {
    content: "\e889";
}

.@{simple-line-icon-prefix}paper-clip:before {
    content: "\e85c";
}

.@{simple-line-icon-prefix}avi:before {
    content: "\e8c2";
}

.@{simple-line-icon-prefix}screen-desktop:before {
    content: "\e878";
}

.@{simple-line-icon-prefix}list:before {
    content: "\e83a";
}

.@{simple-line-icon-prefix}reload:before {
  content: "\e869";
}

.@{simple-line-icon-prefix}control-end:before {
    content: "\e8a6";
}
