@import (reference) '~ajs/less/themes/default-theme/colors.less';

.tech-support-generation-modal {
    &__case-error {
        margin-top: 10px;
    }

    &__case-exclamation {
        color: @aviHealthBad1;
    }
}
