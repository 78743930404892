@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-waf-rule-groups {
    max-width: @default-signpost-max-width;

    &__data-grid {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
    }

    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__group-name {
        display: block;
        min-width: 200px;
        .hide-overflow-content-mixin();
    }

    &__expanded-content {
        &-rule-name-wrapper {
            .hide-overflow-content-mixin()
        }

        &-rule-id {
            max-width: 25%;
            margin-right: 0.5rem;
            .hide-overflow-content-mixin();
        }

        &-rule-name {
            max-width: 75%;
            .hide-overflow-content-mixin();
        }
    }

    &__empty-field {
        min-width: @signpost-content-min-width;
    }
}

.hide-overflow-content-mixin() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
