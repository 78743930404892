@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.dropdown-list-option {
    display: block;
    padding: 10px 12px;
    border-bottom: 1px solid @backgroundGray;

    .hide-text-overflow;

    &:hover {
        background-color: @backgroundGray;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
}
