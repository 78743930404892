.jwt-claim-match-config {
    &__general-container {
        margin-top: 5px;
    }

    & &__checkbox-container {
        margin-top: 0;
    }

    &__claim-value-container {
        margin-left: 15px;
    }

    &__delete-icon-container {
        position: relative;
    }

    &__remove-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}
