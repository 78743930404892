.avi-data-grid-column-selection {
    &__view-columns-btn {
        --color: var(--cds-global-color-gray-600);
        display: flex;
        cursor: pointer;

        &:hover {
            --color: var(--cds-global-color-blue-700);
        }
    }

    &__panel {
        width: 12rem;
        border: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
        border-radius: var(--clr-table-border-radius);
        background-color: var(--clr-table-bgcolor);
        box-shadow: 0 0 2px 0 var(--clr-table-border-color);
    }

    &__content {
        margin: 0.9rem 0.9rem;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.6rem;
    }

    &__close-btn {
        --color: var(--cds-global-color-gray-600);
        cursor: pointer;

        &:hover {
            --color: var(--cds-global-color-blue-700);
        }
    }

    &__list {
        margin-bottom: 0.5rem;

        clr-checkbox-container.clr-form-control {
            margin-top: 0;
        }
    }
}
