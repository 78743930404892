.gslb-site-modal {
    & &__no-margin-top {
        margin-top: 0;
    }

    &__setup-dns-checkbox {
        display: inline-block;
        margin: 0 28px 20px 0;
        .gslb-site-modal-clarity-overrides();
    }
}

.gslb-site-modal-clarity-overrides {
    .avi-label-with-tooltip__label {
        color: var(--clr-color-neutral-0);
    }
}
