@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.pool-list {
    .grid-field-throughput {
        width: 20%;
    }

    &__vs-separator {
        .hide-text-overflow();
    }
}
