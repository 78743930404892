.multiline-list {
    border: 1px solid #989C9C;
    border-top: none;
    border-radius: @aviBorderRadius;

    .header {
        height: 25px;
        margin: -1px -1px 0 -1px;
        padding: 0 15px;
        border: 1px solid @aviGrape0;
        background-color: @aviGrape1;
        color: #FFF;
        line-height: 25px;
        .border-radiuses(@r, 0, 0, @r);
    }

    ul {
        height: 200px;
        margin: 0;
        padding: 0;
        overflow-y: auto;
        background-color: #FFF;
        color: @aviBlue0;
        list-style: none;
        .border-radiuses(0, @r, @r, 0);

        li {
            box-sizing: border-box;
            height: @buttonHeight;
            padding: 0 @standardMargin;
            border: 1px solid transparent;
            line-height: @buttonHeight;

            &:nth-child(even) {
                background-color: @aviBackgroundColor;
            }

            &:not(.selected) {
                &:not(.no-hover):hover {
                    border-color: @aviGrape0;
                    box-shadow: 0 0 5px 0 rgba(red(@aviGrape0), green(@aviGrape0), blue(@aviGrape0), .5);
                    cursor: pointer;
                }
            }

            &.selected {
                border: 1px solid @aviGreen0;
                background-color: @aviGreen3;
                cursor: pointer;
            }
        }
    }

    .status {
        padding: 5px 10px;
        background-color: #888;
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }
}
