checkbox {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 1px solid @textMidGray;
    border-radius: 2px;
    background-color: @aviWhite;
    font-size: 14px;
    line-height: 1;
    text-align: center;

    .icon-check {
        display: none;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        color: white;
        font-size: 14px;
        line-height: 20px;
    }

    &.checked {
        border-color: @checkbox-green;
        background: @checkbox-green;

        .icon-check {
            display: block;
        }
    }

    input[type=checkbox] {
        width: 20px;
        height: 20px;
        margin: -1px 0 0 -1px;
        opacity: 0;
        cursor: pointer;
    }

    &.disabled {
        border-color: @textLiteGray;

        input[type=checkbox] {
            cursor: inherit;
        }

        &.checked {
            background: @textLiteGray;

            .icon-check {
                color: white;
            }
        }
    }
}

.checkbox-label {
    display: inline-flex;
    align-items: center;
    line-height: 20px;

    > checkbox {
        margin-right: 5px;
    }

    i.icon-help-circled:before {
        line-height: 20px;
        vertical-align: inherit;
    }
}

label.flex-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    &.inline {
        display: inline-flex;
    }

    &.vert-center {
        transform: translate(0%, 50%);
    }

    > checkbox {
        margin-right: 5px;
    }

    > span {
        line-height: 20px;
    }
}

.add-on {
    .checkbox-label {
        line-height: 35px;

        > checkbox {
            margin-top: 7px;
            margin-right: 0;
        }
    }

    &.checkbox-label {
        checkbox {
            display: inline;
            line-height: 35px;
        }
    }
}
