@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.ng-file-upload {
    display: flex;
    position: relative;
    box-sizing: border-box;
    height: 1.2rem;
    color: #000;

    &__file-label-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
        margin-right: 15px;
        padding: 0 0.3rem;
        border-bottom: 1px solid #999;
        font-size: 0.65rem;
    }

    &__file-label {
        .hide-text-overflow();

        &--without-value {
            opacity: .54;
            color: black;
            font-size: var(--cds-global-typography-font-size-4);
        }
    }

    &__clear-icon {
        --color: var(--cds-alias-status-info);
        z-index: 1;
        cursor: pointer;
    }

    &__input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }

    &[disabled] {
        .ng-file-upload {
            &__input {
                display: none;
            }
        }
    }
}
