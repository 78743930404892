@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/components/button.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';
@import 'file-upload-textarea/file-upload-textarea';

@fileUploadButtonWidth: 100px;

.file-upload {
    display: flex;
    position: relative;
    height: 35px;

    &__wrapper {
        display: flex;
        box-sizing: border-box;
        flex: 1 1 100%;
        align-items: center;
        overflow: hidden;
        border: 1px solid @borderGray;
        border-radius: @aviBorderRadius;
        background-color: white;
        color: @textMidGray;

        &--no-border {
            border: 0;
        }
    }

    &__input-file {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;

        &[disabled] {
            display: none;
        }
    }

    &__file-name {
        flex: 1 1 auto;
        padding-left: @avi-baseline-px * 0.5;
    }

    &__button {
        display: flex;
        flex: 0 0.5 auto;
        align-items: center;
        align-self: stretch;
        justify-content: center;
        min-width: @fileUploadButtonWidth;
        padding: 0 (@avi-baseline-px * 1.25);
        background-color: @midGray;
        color: white;
        white-space: nowrap;
        user-select: none;

        &--primary {
            background-color: @buttonPrimaryColor;
        }

        &--disabled {
            background-color: @aviDisabled;
            color: @aviGreySpecial;
            cursor: default;
        }
    }

    &--type--button {
        min-width: @fileUploadButtonWidth;

        .file-upload {
            &__wrapper {
                border: none;
            }

            &__button {
                flex: 1 1 100%;
            }
        }
    }
}
