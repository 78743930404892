.static-ip-pool-configuration {
    &__header {
        margin-bottom: 10px;
    }

    &__ip-input {
        position: relative;
        margin-top: 15px;
    }

    &__type-select {
        margin-top: 10px;
    }

    &__trash-btn {
        position: absolute;
        left: 100%;
        margin-left: 10px;
    }

    &__add-btn {
        margin-top: 10px;
    }
}
