@import (reference) '../components/navigation.less';
@import (reference) '../avistrap.less';
@import (reference) '../themes/default-theme/animate.less';
@import (reference) '../pages/generic.less';
@import (reference) '../components/modal.less';
@import (reference) '../components/modal2.less';

// Main Page Layout
@aviSectionPadding: 5px;
@standardMargin: 15px;
@colGap: 2%;
@minSize: 768px;
@maxSize: 2000px;
@aviAppLevelAlertHeight: 36px;

@buttonHeight: 35px;

@aviBorderRadius: 3px;

// Background
@aviPaneBackground: @aviBackgroundColor;

// Content
@aviContentInputWidth: 360px;

//vertical margins of modal sectionsgs
@modalSectionVerticalMargin: 30px;

html {
    font-size: 20px;
}

body {
    min-width: @minSize;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    font-size: 14px;
    font-weight: 300;
}

.clearfix:before, .clearfix:after {
    content: '';
    display: table;
    line-height: 0;
}

// Class used for pages and page containers, primarily in router.
.avi-state-view {
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow: auto;

    &__child-container {
        flex-grow: 1;
    }

    // Violates BEM, but needed to add props to element generated by @uirouter/angular-hybrid lib
    ui-view-ng-upgrade {
        flex-grow: 1;
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.align-bottom {
    vertical-align: bottom;
}

.align-middle {
    vertical-align: middle;
}

.align-top {
    vertical-align: top;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.vertical-align-middle {
    vertical-align: middle;
}

.hide {
    display: none;
}

.hidden {
    display: none !important;
}

.hide-overflow {
    overflow: hidden;
}

span.count {
    font-size: 14px;
    vertical-align: bottom;
}

.descriptionField {
    height: @buttonHeight * 2;
}

.lg-txt {
    font-size: 1.714em;
}

.sm-txt {
    font-size: 0.857em;
}

//Icons
.icon-rotate-90:before {
    transform: rotate(90deg);
}

.sub-header-left, .sub-header-right {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-start;
}

.sub-header-right {
    flex: 0 0 auto;
    justify-content: flex-end;

    .item-alert-bell {
        width: 25px;
        height: 25px;
        margin: 0 10px;
        font-size: 20px;
        line-height: 25px;
    }
}

.lg-icon {
    font-size: 20px;
}

// Form Base

select, textarea, input[type=text], input[type=password], input[type=datetime],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email], input[type=url],
input[type=search], input[type=tel], input[type=color] {
    display: inline-block;
    // Correcting Alignment Issues
    height: @buttonHeight;
    margin: 0;
    padding: 0;
    border-radius: @aviBorderRadius;
    outline: none;
    font-size: 1em;

    .readOnlyInputStyles() {
        border: 1px solid @aviGreySpecial;
        background: @aviGrey3;
        box-shadow: none;
        color: @aviBodyColor;
    }

    &[disabled], &[readonly] {
        .readOnlyInputStyles();
    }
}

input[type=text], input[type=password], input[type=datetime],
input[type=date], input[type=month], input[type=time], input[type=week],
input[type=number], input[type=email], input[type=url], input[type=search],
input[type=tel], input[type=color], textarea {
    // background-color: transparent;
    box-sizing: border-box;
    padding: 0 5px;
    transition: all .2s ease-in-out;
    border: 1px solid @borderGray;
    border-radius: 2px;
    outline: none;
    font-family: @fontFamily;

    &::placeholder {
        color: @aviGrey2;
    }
}

textarea {
    height: @standardMargin * 10;
    padding: @standardMargin/3;
    line-height: 1.5;
}

.search.input-inline {
    display: inline-block;
    position: relative;

    input[type=text] {
        .border-radius();
    }

    & > div.inner-icon, & > button {
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        color: @aviGreySpecial;
        font-size: 1em;
    }

    & > div.inner-icon {
        line-height: @buttonHeight;

        &.highlight-on-hover:hover {
            color: @aviBlue0;
        }
    }

    & > button {
        outline: none;
        background: none;

        &:hover {
            color: @aviBlue0;
        }
    }
}

.infrastructureList, .applicationList, .templatesList, .adminList {
    padding: 0 @pagePaddingWidth @pagePaddingWidth;
}

.absolute-page {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;

    .scroll-container() {
        display: block;
        position: relative;
        flex: 1 1;
        padding: 10px @pagePaddingWidth;
        overflow: hidden;
        overflow-y: auto;
    }
}

// misleading name, used all over the place, not by insights
.insights-body-container {
    .absolute-page .scroll-container();
}

.avi-main {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.events, &.logs {
        .body {
            padding: 10px @pagePaddingWidth;
        }

        .log-filter-container {
            z-index: 1;
            padding: 10px @pagePaddingWidth;

            .log-barchart {
                margin: 0;
            }
        }
    }

    &.alerts {
        .body {
            padding: 0 @pagePaddingWidth;
        }
    }

    &.dns-records {
        .body {
            padding: 5px @pagePaddingWidth;
        }
    }

    .body {
        overflow-y: auto;
    }
}

// Admin
.grid-field-last_signed_in {
    width: 350px;
    vertical-align: middle;
}

.full-width {
    width: 100%;
    vertical-align: middle;
}

.text-no-wrap {
    .hide-text-overflow();
    overflow-y: auto;
}

.no-wrap {
    white-space: nowrap;
}

.noresults {
    display: block;
    width: 100%;
    height: @buttonHeight;
    line-height: @buttonHeight;
    text-align: center;
}

.h-controls-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;

    &.no-padding {
        padding: 0;
    }

    &.align-right {
        justify-content: flex-end;
    }

    &.align-bottom {
        align-items: flex-end;
    }

    &.align-top {
        align-items: flex-start;
    }

    .h-control {
        flex: 1 1 auto;
        max-width: 100%;
        padding-right: 5px;

        label {
            .hide-text-overflow();
            word-break: keep-all;
            vertical-align: baseline; // to override our default 'top' value
        }

        i.icon-help-circled {
            vertical-align: baseline; // to override our default 'top' value
        }

        .inner-text {
            width: 100%;
        }

        &:last-child {
            padding-right: 0;
        }

        &.no-flex {
            flex: 0 0 auto;
        }

        &.equal {
            flex: 1 1 0;
            min-width: 0;

            &.for-two {
                max-width: 50%;
            }
        }

        &.flex-11 {
            flex: 1 1 0;
            overflow: hidden;
        }

        &.flex-22 {
            flex: 2 2 0;
            overflow: hidden;
        }

        &.flex-33 {
            flex: 3 3 0;
            overflow: hidden;
        }

        &.flex-4 {
            flex: 0 0 100%;
        }

        &.flex-3 {
            flex: 0 0 75%;
        }

        &.flex-2 {
            flex: 0 0 50%;
        }

        &.flex-1 {
            flex: 0 0 25%;
        }
    }

    &.with-checkbox {
        align-items: stretch;

        & > .h-control.with-checkbox {
            display: flex;
            justify-content: center;
            padding-top: 21px;
        }
    }

    &.col-gap {
        > .h-control:not(:last-child) {
            padding-right: @colGap;
        }

        > .h-control.equal.for-two {
            max-width: 50% - @colGap / 2;
        }
    }

    .h-col {
        box-sizing: border-box;
        flex: initial;
        width: 50%;
        padding: 5px 0;
    }
}

label.block.relative {
    input[type=text][required], input[type=number][required] {
        width: 100%;
    }
}

.avi-modal-header > h1 {
    .hide-text-overflow();
    overflow-y: auto;
}

fieldset {
    padding: 5px 10px;
    border: 1px solid @borderGray;
    border-radius: 5px;

    legend {
        padding: 0 5px;
    }
}

.abs-tr-10 {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-component {
    .block;
    .avi-modal;
    .avi-modal.modal2;
}

.avi-prompt {
    .avi-modal;
    .avi-modal.modal3;
    max-width: 900px;
    transform: none;

    .avi-modal-header {
        min-height: initial;
    }

    h1 {
        padding: 0;
        font-weight: inherit;
    }

    .control-group > label {
        margin: initial;
    }
}

textarea.no-resize {
    resize: none;
}

.table-layout {
    width: 100%;
    table-layout: auto;

    td, th {
        &:not(:last-child) {
            padding-right: @colGap;
            vertical-align: middle;
        }
    }
}

.col-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > .col {
        flex: 1 1 0;
        min-width: 0;

        &:not(:last-child) {
            padding-right: @colGap;
        }

        &.col-half {
            max-width: 50% - @colGap / 2;
        }

        &.collapsed {
            flex: 0 0 auto;
            width: auto;
        }
    }
}

//chrome scrolling bug: github.com/kamilkp/angular-vs-repeat/issues/156
[vs-repeat] {
    overflow-anchor: none;
}
