.bgp-peers-page {
    &__peers-grid, &__alert {
        padding: 0 30px 30px;
    }

    &__idle-bgp-peer-state {
        --color: var(--cds-global-color-gray-400);
    }

    &__spinner {
        margin-top: 1rem;
        text-align: center;
    }

    &__reason {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
        padding: 0;
    }

    &__reason-label {
        color: var(--cds-global-typography-color-500);
        font-weight: 600;
    }
}
