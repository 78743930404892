.avi-progress-bar {
    font-size: .7rem;

    &__header {
        margin-bottom: 5px;
        font-weight: 500;
    }

    &__bottom-text {
        margin-top: 5px;
    }

    &__message {
        margin-left: 5px;
    }

    &__progress-group {
        &--small {
            font-size: 1px;
        }
    }
}
