.gslb-site-preview {
    margin: 0 25px;
    border-bottom: 1px solid var(--cds-alias-object-interaction-color);

    .gslb-site-preview-clarity-overrides();

    &__item {
        margin: 15px 0;
    }

    &__item-value {
        margin-top: 5px;
        color: var(--cds-global-color-white);
        line-height: 1.25rem;
    }

    &__item-preview-defaults {
        padding-top: 20px;
        border-top: 1px solid var(--cds-alias-object-interaction-color);
    }
}

// To override clarity styles for header tags.
.gslb-site-preview-clarity-overrides() {
    h4:not([cds-text]), h6:not([cds-text]) {
        color: var(--cds-global-color-white);
    }

    h4:not([cds-text]) {
        margin-bottom: 25px;
    }
}
