.traffic-capture-modal {
    & &__header {
        text-transform: uppercase;
    }

    & &__placeholder {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        color: var(--cds-alias-object-interaction-color-disabled);
    }

    & &__no-margin-top {
        margin-top: 0;
    }
}
