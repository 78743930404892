@import (reference) '~ajs/less/themes/default-theme/colors.less';

.recent-updates-list {
    margin: 10px;

    &__title {
        font-size: 18px;
    }

    &__no-tiles-placeholder {
        margin-top: 10px;
        color: @aviDisabled;
    }
}
