@import (reference) '~ajs/less/themes/default-theme/colors.less';

.radio-button {
    @radioButtonSize: 20px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: @radioButtonSize;
    height: @radioButtonSize;
    margin-right: 5px;
    transition: background-color 0.25s ease-in-out;
    border: 1px solid @aviGreySpecial;
    border-radius: @radioButtonSize;
    outline: none;
    background-color: white;
    line-height: @radioButtonSize;

    &:not([checked]):not([disabled]) {
        &:hover {
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25),
                inset 0 0 0 4px #FFF;
            cursor: pointer;
        }
    }

    &[checked] {
        background-color: @aviGreen0;
        box-shadow: inset 0 0 0 4px #FFF;
    }

    &[disabled] {
        border-color: @aviGrey2;
        background: @aviGrey3;
    }

    input {
        opacity: 0;
        pointer-events: none;
    }
}
