@import (reference) '~ajs/less/themes/default-theme/colors.less';

.avi-dropdown-button {
    box-sizing: border-box;
    line-height: 1rem;
    .avi-dropdown-button-cds-icon-overrides();

    &__caret-icon {
        --color: @aviWhite;
        margin-left: 5px !important; // To override cds-icon's default margins.
    }

    &__dropdown-toggle {
        &.btn {
            padding-right: 0.6rem;
        }

        &:not(.btn) {
            padding-right: 0;
        }
    }

    &__button {
        &--icon {
            --height: 24px;
            --min-width: 24px;
            --padding: 0;
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;
        min-width: 6rem;
        max-width: 18rem;
        max-height: 12.5rem;
        margin: .1rem 0;
        padding: .6rem 0;
        overflow-y: auto;
        border: .05rem solid #CCC;
        border-radius: .15rem;
        background: @aviBackgroundColor;
    }

    &__item {
        padding: .15rem 1.2rem;
        background: var(--cds-global-color-white);
        color: @textMidGray;
        text-align: left;

        &:hover {
            background: #E8E8E8;
            cursor: pointer;
        }

        &--disabled {
            color: @midGray;

            &:hover {
                cursor: not-allowed;
            }
        }
    }
}

// When cds-icon is under .btn class,
// direction attribute would not work.
// clarity lib: _buttons.clarity.scss#L264
.avi-dropdown-button-cds-icon-overrides() {
    .btn {
        cds-icon {
            transform: rotate(180deg);
        }
    }
}
