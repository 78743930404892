@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';

@healthScoreSize: 30px;
@healthScoreBgColor: #ebebeb;
@wafHealthColor: #49A1C1;

avi-healthscore,
span[avi-healthscore],
.avi-healthscore {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-right: 2px;
    margin-left: 4px;
}

.waf-health-status,
.avi-healthscore-outer {
    width: @healthScoreSize;
    height: @healthScoreSize;
    border-radius: 100%;
}

.waf-health-status {
    position: absolute;
    z-index: 0;
    top: -4px;
    box-sizing: border-box;
    padding: 18px;
    border: 1px solid @wafHealthColor;
    background-color: white;
}

.waf-shield-icon {
    display: block;
    position: absolute;
    z-index: 2;
    top: -5px;
    right: -3px;
    box-sizing: border-box;
    padding-top: 3px;
    border: 1px solid;
    border-radius: 100%;
    background-color: white;
    color: @wafHealthColor;
    font-size: 11px;
    line-height: 0;
}

.avi-healthscore-outer {
    color: @textDarkGray;
    font-size: 12px;
    line-height: @healthScoreSize;
    text-align: center;

    .disabledIcon {
        color: @aviBodyColor;
    }
}

.avi-healthscore-text {
    padding-left: 5px;
    border: solid 1px @aviGreySpecial;
    border-bottom-width: 2px;
}

a {
    .healthscore-text-colors {
        color: @aviBodyColor;
    }
}

// --------- Assigning Health Colors to Anything ----------------//
.healthscore-color-maker(@tint0, @tint1, @tint2) {
    transition: all .25s linear;
    background-color: @tint1;
    fill: @tint1;

    i.icon.icon-exclamation {
        color: #FFF;
    }

    &:hover, &.selected {
        background-color: @tint0;
        fill: @tint0;
    }
}

.healthscore-colors {
    &.high {
        .healthscore-color-maker(@aviHealthGood0, @aviHealthGood1, @aviHealthGood2);
    }

    &.medium {
        .healthscore-color-maker(@aviHealthFine0, @aviHealthFine1, @aviHealthFine2);
    }

    &.low {
        .healthscore-color-maker(@aviHealthBad0, @aviHealthBad1, @aviHealthBad2);
    }

    &.unknown, &.down, &.resources {
        .healthscore-color-maker(@aviHealthDown0, @aviHealthDown1, @aviHealthDown2);
    }

    &.disabled, &.inactive, &.creating, &.unused, &.initializing, &.loading {
        .healthscore-color-maker(@aviHealthDisabled0, @aviHealthDisabled1, @aviHealthDisabled2);
    }

    &.unavailable {
        border: 1px solid @aviHealthDisabled0;
    }
}

// ---------- End assigning health colors to anything --------------//

// TODO -- Refactor
.avi-healthscore-colors(@tint0, @tint1, @tint2) {
    background-color: @tint1;
}

.healthscore-popup-down {
    padding: 5px;
}

.healthscore-badge {
    .border-radius(@aviBorderRadius);
}

.healthscore-sparkline {
    path.line.health_score {
        stroke-width: 2px;
        stroke: @aviBlue0;
    }
}

.avi-healthscore-popup {
    width: 350px;
    border: solid 1px #E0E0E0;
    border-radius: 2px;
    background-color: #EBEBEB;
    box-shadow: 0 2px 4px 0 @aviGrey1;
    font-size: 14px;
    line-height: 16px;

    .insights-link {
        margin-bottom: 20px;
    }

    // Header styling
    .avi-healthscore-popup-title {
        padding: 15px 20px 0;
        overflow: hidden;
        color: @textDarkGray;
        font-size: 24px;
        line-height: 30px;
        word-break: break-all;
    }

    // Chart Label
    .avi-health-score-sparkline-label {
        padding: 10px 10px 0 10px;
        color: @aviBodyColor;
        font-size: 13px;
    }

    // Giving sparkline height
    .avi-healthscore-popup-sparkline {
        height: 70px;
        margin: 10px;

        svg.chart {
            margin-bottom: 10px;
            overflow: visible;

            g {
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        }
    }

    .avi-healthscore-popup-content {
        overflow-x: scroll;

        .healthscore-sparkline-colors(@stroke: @aviHealthGood0, @fill: @aviHealthGood1) {
            svg {
                path {
                    stroke: @stroke;
                    color: @stroke;

                    &.area {
                        fill: @fill;
                    }
                }
            }
        }

        div.sparkline {
            &.high {
                .healthscore-sparkline-colors();
            }

            &.medium {
                .healthscore-sparkline-colors(@aviHealthFine0, @aviHealthFine1);
            }

            &.low {
                .healthscore-sparkline-colors(@aviHealthBad0, @aviHealthBad1);
            }
        }
    }

    // Background for values (not total)

    // Middle section styling
    .value {
        padding: 5px 10px;
    }

    .partheader {
        width: 115px;
        color: @aviBodyColor;
        font-size: 15px;
        line-height: 20px;
    }

    .partvalue {
        width: 45px;
        line-height: 20px;
        text-align: right;
    }

    .partheader, .partvalue, .bar-container {
        flex: 1 1 auto;

        .performanceBar {
            width: 100%;
            background: #EBEBEB;
        }

        .hsLine {
            position: absolute;
            top: -136px;
            width: 0;
            height: 146px;
            border-left: 1px dashed @textDarkGray;
        }
    }

    .bar {
        box-sizing: border-box;
    }

    .subtractive.bar {
        border: 1px solid @aviHealthDown0;
        border-bottom-width: 2px;
        background-color: @aviHealthDown1;
    }

    .subtractive.partvalue {
        color: @aviHealthDown1;
    }
    // Styling the bars
    .bar-container {
        width: 70px;
        height: 10px;
        margin-left: 5px;

        div {
            height: 100%;
        }
    }

    div.sparkline {
        height: 70px;
    }
}

.hsInnerColorScheme(@color) {
    .health-border.notUp {
        background-color: @color;
    }
}

.health-icons {
    .downIcon, .upIcon {
        color: white;
    }

    .disabledIcon {
        color: black;
    }
}

.avi-healthscore-popup-content {
    margin: 15px;
    background-color: white;
    white-space: normal;
}

div.aviPopover.healthScoreCard {
    z-index: @headerZindex + 1;
}

div.aviPopoverCarat.healthScoreCard {
    .healthAndUnitCardPopoverCarat();
}

.healthAndUnitCardPopoverCarat () {
    z-index: @headerZindex + 2;

    &.top {
        &:before {
            top: 1px;
            border-top-color: lighten(@aviGrey1, 15%);
        }

        &:after {
            border-top-color: @healthScoreBgColor;
        }
    }

    &.bottom {
        &:before {
            top: -1px;
            border-bottom-color: lighten(@aviGrey1, 15%);
        }

        &:after {
            border-bottom-color: @healthScoreBgColor;
        }
    }

    &.right {
        &:before {
            left: -1px;
            border-right-color: lighten(@aviGrey1, 15%);
        }

        &:after {
            border-right-color: @healthScoreBgColor;
        }
    }

    &.left {
        &:before {
            left: 1px;
            border-left-color: lighten(@aviGrey1, 15%);
        }

        &:after {
            border-left-color: @healthScoreBgColor;
        }
    }
}
