.backup-overview-card {
    display: block;
    width: 100%;
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        text-transform: uppercase;
    }

    &__enabled-label {
        color: var(--clr-color-success-500);
    }

    &__deactivated-label {
        color: var(--cds-alias-status-disabled);
    }

    &__backup-icon {
        margin-right: 5px;
        color: var(--clr-color-success-500);
    }

    &__row {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;

        &:not(:first-child) {
            margin-top: 0.5rem;
        }
    }

    &__control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}
