@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.item-alert-level-icon {
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
    border-radius: 1em;

    &__icon {
        .absolutelyCentered();
        box-sizing: border-box;
        width: 0.9em;
        height: 0.9em;
        border: 2px solid @aviGreySpecial;
        border-radius: 1em;

        &_level_ {
            &high {
                border-color: @aviRed0;
                background: @aviRed1;
            }

            &medium {
                border-color: @aviYellow0;
                background: @aviYellow1;
            }

            &low {
                border-color: @aviBlue0;
                background: @aviBlue1;
            }
        }
    }
}
