.vs-logs-page {
    @component-padding: 0 10px;

    &__top-bar {
        padding: 25px 30px 12px;
        background-color: var(--cds-global-color-white);

        &-container {
            display: flex;
        }
    }

    &__search-bar {
        width: 100%;
    }

    &__below-search-bar {
        width: 100%;
        margin-top: 6px;
        overflow: auto;
    }

    &__significance-options {
        float: left;
    }

    &__current-timeframe {
        margin-top: 4px;
        float: right;
        font-size: 13px;
    }

    &__main-content {
        padding: @component-padding;
    }

    &__alert-container {
        display: flex;
        margin: 10px;
    }

    &__alert-text {
        font-size: var(--cds-global-typography-font-size-4);
    }
}
