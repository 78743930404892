.avi-state-page {
    display: flex;
    flex-grow: 1;
    height: 100%;

    &__main-section {
        flex-grow: 1;
        overflow: auto;
    }

    &__content {
        padding: 20px;
    }

    &__sidebar {
        flex-shrink: 0;
    }
}
