.avi-alert-group {
    &__alert {
        display: flex;
        margin-bottom: 10px;
        font-size: 0.65rem;
        font-weight: normal;

        cds-icon {
            padding-right: 10px;
        }
    }

    &__deprecation-message {
        display: flex;
    }
}
