/* How to use:
 * Give the grid-container the class: .grid-container.
 * If an element needs to take up the entire grid-container's width, give it class: .grid-container__cell--w--full.
 * Give child elements a col-span by giving class: .grid-container__cell--w--x, where x is min(1),
 * to max(@grid-col-cells-base).
 * If grid-container itself is also a child of another parent grid, give it both classes:
 * .grid-container__cell--w--x .grid-container.
 * If an element needs to take up more than one row height, give it .grid-container__cell--h--y, where y is min(1),
 * to max(@grid-row-cells-max).
 * If greater row height is desired than currently provided, then change '@grid-row-cells-max' to
 * reflect desired height.
 *
 * To have children (cells) automatically have full-width, use .grid-container-full instead.
 * See below .grid-container-full and it's child (cell) overrides at bottom.
 */

@import (reference) './layout.less';

@grid-row-cells-max: 10;
@grid-col-cells-base: 12;

.gridContainerGenerics() {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
}

// Main generic grid-container class
.grid-container {
    .gridContainerGenerics();
    grid-template-columns: repeat(@grid-col-cells-base, 1fr);
    //TODO: Might wanna create classes for following 2 props for different situations,
    .setGridGaps(@standardMargin);

    //vertical margin is controlled by rows-gap
    .control-group {
        margin-bottom: 0;

        &--vertical-gap {
            margin-bottom: 15px;
        }
    }

    &--gap {
        &--none {
            .setGridGaps(0);
        }

        &--legacy {
            .setGridGaps(@colGap, @standardMargin);
        }
    }
}

.grid-container__cell--w--full {
    grid-column-end: -1;
    grid-column-start: 1;
}

// makes sure to start a new grid row
.grid-container__cell--col-start--1 {
    grid-column-start: 1;
}

// sets grid column and row gaps
.setGridGaps(@columnGap, @rowGap: @columnGap) {
    grid-column-gap: @columnGap;
    grid-row-gap: @rowGap;
}

// Recursive fn to generate different size grid-col and grid-row classes
.generateGridConfigs(@grid-row-cells-max + 1, @grid-col-cells-base);

.generateGridConfigs(@rowMax, @colMax, @i: 1) when (@i < @rowMax), (@i < @colMax) {
    .grid-container__cell--h--@{i} when (@i < @rowMax) {
        grid-row: span (@i);
    }

    .grid-container__cell--w--@{i} when (@i < @colMax) {
        grid-column-end: span (@i);

        &.grid-container {
            grid-template-columns: repeat(@i, 1fr);
        }
    }

    .generateGridConfigs(@rowMax, @colMax, (@i + 1));
}

// Use this on parent instead to make children (cells) automatically have full width automatically
.grid-container-full() {
    .grid-container();
    grid-row-gap: 1rem;

    > * {
        .grid-container__cell--w--full();

        .clr-form-control {
            margin-top: 0;
        }

        > *, .clr-form-control {
            margin-top: 0;
        }
    }

    // for cell overrides, in case desired to have less than full width cell
    > .grid-container-full__cell-half {
        .grid-container__cell--w--6();
        grid-column-start: unset;
    }

    > .grid-container-full__cell-third {
        .grid-container__cell--w--4();
        grid-column-start: unset;
    }

    > .grid-container-full__cell-fourth {
        .grid-container__cell--w--3();
        grid-column-start: unset;
    }
}
