.display-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-inline-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.justify-flex-end {
    display: flex;
    justify-content: flex-end;
}

.justify-flex-start {
    display: flex;
    justify-content: flex-start;
}

.justify-flex-space-around {
    display: flex;
    justify-content: space-around;
}

.align-flex-end {
    display: flex;
    align-items: flex-end;
}

.align-flex-start {
    display: flex;
    align-items: flex-start;
}

.align-baseline {
    display: flex;
    align-items: baseline;
}

.equal-width {
    display: flex;

    & > * {
        flex: 1 1 0;
    }
}

.no-flex {
    flex: 0 0 auto;
}

.flex-wrap {
    flex-wrap: wrap;
}
