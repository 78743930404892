@import (reference) '~ajs/less/themes/default-theme/colors.less';

.capsule {
    display: flex;
    box-sizing: border-box;
    height: 1.75em;
    border: 1px solid @aviGrey2;
    border-radius: 4px;
    background-color: @aviWhite;

    &__label, &__value {
        padding: 3px 4px;
        white-space: nowrap;
    }

    &__label {
        border-right: 1px solid @aviGrey2;
        color: @aviGrey1;
    }

    &__value {
        max-width: 14em;
        padding-top: 4px;
        overflow: hidden;
        //has different line-height, more of top padding should solve it
        font-weight: 500;
        text-overflow: ellipsis;
    }
}
