.node-vitals-usage-info {
    &__name-container {
        display: flex;
        align-items: center;
    }

    &__name {
        flex: 1;
        padding-left: 5px;
        font-size: var(--cds-global-typography-font-size-3);
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__leader-label {
        color: var(--clr-color-neutral-600);
    }

    &__vitals-container {
        display: flex;
        padding-top: 10px;
    }

    &__vitals-label {
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__cpu {
        flex: 1;
        margin-right: 10px;
    }

    &__memory {
        flex: 2;
        margin-right: 10px;
    }

    &__disk {
        flex: 2;
    }

    &__cpu-percent,
    &__disk-values,
    &__memory-values {
        padding-top: 5px;
    }

    &__disk-values,
    &__memory-values {
        display: flex;
        justify-content: space-between;
    }

    &__units-label {
        color: var(--clr-color-neutral-600);
        font-size: var(--cds-global-typography-font-size-1);
    }
}
