@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.full-modal {
    display: grid;
    position: fixed;
    z-index: @deepestModalZIndex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    grid-template-areas: 'logo logo logo'
        'search search search'
        'breadcrumbs config preview';
    grid-template-columns: 2fr 7fr 3fr;
    grid-template-rows: 36px var(--cds-global-layout-space-md) auto;
    height: 100%;
    padding-top: 24px;
    background: var(--avi-ebonyclay-blue);
    .full-modal-clarity-overrides();

    &__config-container {
        position: relative;
        grid-area: config;
        overflow-y: auto;
    }

    &__logo {
        grid-area: logo;
        height: 40px;
        padding-left: 24px;
    }

    &__title {
        margin-left: 10px;
        color: var(--cds-global-color-white);
        font-size: 18px;
        vertical-align: middle;
    }

    &__config {
        height: 100%;
    }

    &__breadcrumbs {
        grid-area: breadcrumbs;
        min-width: 0;
        min-height: 0;
    }

    &__preview {
        grid-area: preview;
    }
}

// Footer mixin
// We're using this mixin in 3 places - FullModalConfigFooter, FullModalBreadcrumbs, and
// FullModalPreview. These 3 components are laid out as 3 columns, and the footer visually extends
// through all 3. The reason we don't use a single footer component is because it makes more logical
// sense for the footer buttons to be a part of the FullModalConfig only.
.full-modal-footer-mixin() {
    box-sizing: border-box;
    height: 60px;
    margin-bottom: 24px;
    border-top: 1px solid var(--cds-global-color-white);
    background: var(--avi-ebonyclay-blue);
}

.full-modal-clarity-overrides() {
    ul:not([cds-list]) {
        padding-left: 24px;
    }
}
