@import (reference) '~ajs/less/themes/default-theme/colors.less';

// VS logs signpost specific max height and max width values.
@signpost-max-width: 850px;
@signpost-max-height: 500px;
@default-signpost-max-width: 550px;
@scrollable-content-max-height: 445px;
@custom-signpost-min-width: 250px;
@custom-e2e-signpost-max-width: 785px;
@custom-waf-latency-signpost-max-width: 450px;
@signpost-content-min-width: 100px;

.vs-logs-signpost {
    max-width: @signpost-max-width;
    max-height: @signpost-max-height;
    overflow: auto;

    .signpost-data-grid-overrides();

    &__alert {
        display: flex;
        margin-bottom: 0.5rem;
    }
}

.signpost-data-grid-overrides() {
    .avi-dynamic-data-grid {
        margin-top: 0;

        h6:not([cds-text]).avi-dynamic-data-grid__grid-title {
            color: @textMidGray;
            font-size: var(--cds-global-typography-font-size-6);
        }

        .avi-data-grid {
            margin-top: 0;

            &__action-bar {
                margin-top: 0;
            }

            .datagrid {
                margin-top: 0;
            }
        }
    }
}
