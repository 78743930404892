@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.new-progress-bar {
    // Using this to override legacy code on class: icon-loader
    .new-progress-bar__loader-icon {
        font-size: 14px;
    }

    &__title {
        margin-bottom: 11px;
        font-size: 14px;
        font-weight: bold;
    }

    &--default {
        .new-progress-bar {
            &__progress {
                height: 8px;
                padding: 1px;
                border: 1px solid @aviGreySpecial;
                border-radius: 5px;
            }

            &__progress-bar {
                height: 8px;
                transition: width 1s ease;
                border-radius: 3px;
                background-color: @aviGreen1;
            }
        }
    }

    &--transition {
        position: absolute;
        width: 100%;
        height: 4px;
        padding: 0;
        border: 0;

        .new-progress-bar__progress-bar {
            height: 4px;
            transition-property: width;
            transition-duration: 4s;
            background-color: @alb-progress-bar-transition-color;
        }

        &--fast .new-progress-bar__progress-bar {
            transition-duration: 500ms;
        }
    }

    &--message {
        box-sizing: border-box;
        padding: 16px 20px 10px 20px;
        border: 1px solid @avi-border-color;
        border-radius: 2px;
        background-color: white;

        .new-progress-bar {
            &__progress {
                width: 100%;
                height: 5px;
                border-radius: 2px;
                background-color: @avi-border-color;
            }

            &__progress-bar {
                height: 100%;
                transition: width 1s;
                border-radius: 2px;
                background-color: #9AAB26;
            }

            &__text {
                display: flex;
                flex-direction: row;
                padding-top: 2px;
                font-size: @avi-baseline-px * .75;
            }

            &__message-text {
                padding-left: 3px;
            }
        }
    }
}
