@import (reference) '~ajs/less/themes/default-theme/colors.less';

.gslb-replication-status {
    cursor: default;

    &__state-icon {
        &--error {
            fill: @aviHealthDown1;
        }
    }

}
