@import (reference) '~ajs/less/layout/grid-container.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.full-modal-config-section() {
    padding: 1.8rem 1.5rem;

    &:nth-child(n+2) {
        border-top: 1px solid var(--clr-table-border-color);
    }
}

.full-modal-config {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    background: var(--avi-gallery-gray);

    &--with-tabs {
        background: transparent;
    }

    &__header {
        z-index: @deepestModalZIndex;
        flex-shrink: 1;
        min-height: 0;
    }

    &__body, &__confirmation {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
    }

    &__body-content {
        padding-bottom: 1.8rem;

        &--with-tabs {
            padding-bottom: 0;
        }
    }

    &__errors {
        display: block;
    }

    // Way to set styles before grid was introduced below
    &__section {
        .full-modal-config-section();
    }

    // Use instead of &__section above, to reduce number of divs needed
    &__grid-container-full {
        .full-modal-config-section();
        .grid-container-full();
    }

    // Used within the full-modal-config to take up a single row in the modal.
    &__form-control-row {
        display: flex;
        align-items: flex-start;
        .full-modal-config-clarity-overrides__margin-top()
    }

    // Used as children within .full-modal-config__form-control-row
    // as form elements that should be side-by-side.
    &__form-control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: .5rem;
        }
    }

    .full-modal-config__fieldset {
        margin: 1rem 0;
        padding: .5rem;
        border: 1px solid var(--clr-table-border-color);
        background: var(--cds-global-color-white);
    }

    &__full-modal-config-header-tabs {
        display: flex;
        flex: 1;
    }

    .full-modal-config-clarity-overrides()
}

.full-modal-config-clarity-overrides() {
    clr-radio-container {
        .full-modal-config-clarity-overrides__margin-top()
    }

    clr-checkbox-container {
        .full-modal-config-clarity-overrides__margin-top()
    }

    h3:not([cds-text]) {
        padding-bottom: 0.6rem;
    }

    h6:not([cds-text]) {
        padding-bottom: 0.6rem;
        color: var(--cds-global-color-blue-700);
    }

    & avi-data-grid_header {
        & h6:not([cds-text]) {
            padding-bottom: 0;
        }
    }

    input[type=password][readonly],
    input[type=text][readonly],
    input[type=number][readonly],
    input[type=email][readonly],
    input:not([type])[readonly] {
        border: 0;
        background: none;
        pointer-events: none;
    }
}

.full-modal-config-clarity-overrides__margin-top() {
    margin-top: 1.2rem;
}
