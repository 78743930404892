@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.navbar-dropdown-overrides() {
    border: none;
    background: none;
    color: @aviWhite;

    .dropdown-container {
        margin: 5px 0 0 0;

        .choice .placeholder {
            color: @aviWhite;
        }

        .dropdown-value-container {
            min-width: @avi-baseline-px * 5.5;
            max-width: @avi-baseline-px * 8;
            padding: 0 0 5px 15px;
        }

        .expand {
            margin: 0 0 5px 0;
        }
    }
}

.tenant-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: @avi-baseline-px * 5.5;
    height: @aviTopHeaderHeight;

    &__dropdown {
        .navbar-dropdown-overrides();
        padding: 0;
    }

    &__dropdown-popover {
        top: 61px !important;
    }
}
