.avi-pool-server-summary {
    .server-sparkline-header {
        width: 270px;
    }

    table td {
        line-height: 35px;
    }

}
