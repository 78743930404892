.client-insight {
    text-align: left;

    .page-load-time-header {
        line-height: 22px;

        .clear-filters {
            line-height: 26px;
        }
    }

    .resource-timing-table {
        .main-url {
            color: @aviGrey0;
            font-size: 12px;
        }

        .resource-url {
            font-size: 16px;
        }

        .url-segment-cell {
            width: 30%;
            overflow: hidden;

            a:link, a:visited, a:active, a:hover, a {
                color: inherit;
                text-decoration: none;
            }
        }

        .resource-timing-cell-th {
            width: 50%;
        }

        .original-url {
            font-size: 16px;
        }


        .text-cell {
            line-height: 36px;
        }
    }

    .navigation-timing-no-data {
        margin-top: -20px;
    }

    .panel {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 0;
        overflow-x: hidden; // so lists can expand
        overflow-y: auto;
        border: none;
        vertical-align: top;

        .list-title {
            font-weight: bold;
        }

        &.expandedMode {
            position: relative;
            z-index: 2;
            overflow-x: visible;
            overflow-y: visible;

            &.border-right, &.border-left {
                border: none;
            }

            .top-list {
                min-width: 200px;
                border-right: 1px solid #CCC;
                border-bottom: 1px solid #CCC;
                border-left: 1px solid #CCC;
                background-color: @aviPaneBackground;
            }
        }

        .inner {
            padding: 5px;
        }
    }

    .map-section-wrapper {
        display: flex;
        width: 100%;
    }

    .not-map-section {
        display: inline-flex;
        box-sizing: border-box;
        flex: 1 0;
        flex-direction: column;
        border-left: 1px solid @borderGray;

        .long-section {
            padding: 10px;
        }

        .top-section {
            display: flex;
            border-bottom: 1px solid @borderGray;

            .regular-section, .long-section {
                display: inline-block;
                position: relative;
                overflow: hidden;
            }

            .regular-section {
                flex: 1 1;
                padding: 10px;

                &:not(:last-child) {
                    border-right: 1px solid @borderGray;
                }
            }
        }
    }

    .world-map-section {
        display: inline-block;
        box-sizing: border-box;
        min-width: 500px;
        padding: 10px;

        .list-title {
            padding: 5px;
        }

        .world-map {
            width: 100%;
            height: auto;
        }
    }

    // Some classes to make it easy to do border stuff
    // take out when you get a chance -- will
    .border-right {
        border-right: 1px solid #DDD;
    }

    .border-left {
        border-left: 1px solid #CCC;
    }

    .border-bottom {
        border-bottom: 1px solid #CCC;
    }
}

.client-insight-title {
    display: block;
    position: relative;
    margin: 0 0 10px -20px;
    font-size: 16px;
    font-weight: 500;
}


// Colors for everything!
.blocking_time {
    background-color: @aviRed2;

    &.filtered {
        background-color: @aviRed3;
    }
}

.blocking_time-color {
    color: @aviRed2;
}

.redirection_time {
    background-color: @aviYellow1;

    &.filtered {
        background-color: @aviYellow3;
    }
}

.redirection_time-color {
    color: @aviYellow1;
}

.dns_lookup_time {
    background-color: @aviBlue1;

    &.filtered {
        background-color: @aviBlue3;
    }
}

.dns_lookup_time-color {
    color: @aviBlue1;
}

.connection_time {
    background-color: @aviPurple1;

    &.filtered {
        background-color: @aviPurple3;
    }
}

.connection_time-color {
    color: @aviPurple1;
}

.application_response_time {
    background-color: @aviGrape1;

    &.filtered {
        background-color: @aviGrape3;
    }
}

.application_response_time-color {
    color: @aviGrape1;
}

.page_download_time {
    background-color: @aviYellow1;

    &.filtered {
        background-color: @aviYellow3;
    }
}

.page_download_time-color {
    color: @aviYellow1;
}

.page_download_time_total-color {
    color: @aviGreen1;
}

.page_download_time_total {
    background-color: @aviGreen1;

    &.filtered {
        background-color: @aviGreen3;
    }
}

.page_load_time {
    background-color: @aviRed0;

    &.filtered {
        background-color: @aviRed3;
    }
}

.page_load_time-color {
    color: @aviRed0;
}

.dom_content_load_time {
    background-color: @aviBlue0;

    &.filtered {
        background-color: @aviBlue3;
    }
}

.dom_content_load_time-color {
    color: @aviBlue0;
}

.page_downloading_time {
    background-color: @aviGreen0;

    &.filtered {
        background-color: @aviGreen3;
    }
}

.page_downloading_time-color {
    color: @aviGreen0;
}

.waiting_time {
    background-color: @aviGrape1;

    &.filtered {
        background-color: @aviGrape3;
    }
}

.waiting_time-color {
    color: @aviGrape1;
}

/* security insights */
div.vs-sec-insights {
    div.two-column-container > div.left-section {
        text-align: left;
    }
}
