//used by GSLB, GslbService Modals and GslbPoolMemberForm directive
.gslb-modals-mixin() {
    div.trash-button {
        position: relative;
        right: 5px;
        bottom: 5px;
        width: 28px;
        height: 28px;
        margin: auto 0;
    }

    a.link {
        font-size: 1.1em;
    }

}

div.gslb-edit {
    .gslb-modals-mixin();

    div.ip-address-wrapper,
    div.dns-rule-wrapper {
        position: relative;
        margin-bottom: 5px;
    }
}
