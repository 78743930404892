@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';
@import (reference) './log-results.less';
@import (reference) './log-paginate.less';

table.diff-view {
    width: 100%;
    table-layout: fixed;
    cursor: pointer;

    thead {
        #avi-table .thead();

        th {
            padding-left: 1em;

            i[class^='icon'] {
                position: absolute;
                right: 5px;
            }
        }
    }

    td {
        padding: .5em;
        overflow: hidden;
        border: 1px solid grey;
        word-break: break-all;
        vertical-align: top;
        user-select: none;
    }

    li {
        &.nodiff {
            display: none;

            &.show {
                display: list-item;
            }
        }

        &.new {
            background-color: @aviGreen3;
        }

        &.deleted {
            background-color: @aviRed3;
        }

        &.edited {
            background-color: @aviYellow3;

            span.value {
                background-color: @aviYellow1;
            }

            span.placeholder {
                visibility: hidden;
            }
        }

        &.new, &.deleted, &.edited, &.empty {
            li.nodiff {
                display: inherit;
            }
        }

        &.empty {
            background-color: @aviGrey3;
            color: @aviGrey3;

            ul {
                visibility: hidden;
            }
        }
    }

    &.full-view {
        //for the full view just show everything
        li.nodiff {
            display: inherit;
        }
    }

    &.no-diff {
        //prev and current configurations are the same
        cursor: default;

        th i[class^='icon'] {
            display: none;
        }
    }
}
