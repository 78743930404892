.helper-text-tooltip-icon {
    &__section {
        box-sizing: border-box;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #FFF;
    }

    &__section-header {
        font-weight: 600;
        text-transform: uppercase;
    }
}
