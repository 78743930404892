@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.item-alert-bell {
    &__icon {
        &_level_ {
            &high {
                color: @aviRed0;
            }

            &medium {
                color: @aviYellow0;
            }

            &low {
                color: @aviBlue0;
            }
        }
    }
}

.aviPopover.with-item-alert-bell-popover {
    z-index: @headerZindex;
}
