@import (reference) '~ajs/less/themes/default-theme/theme.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

.hs-insights {
    @padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    padding: @padding;

    &__graph-container {
        display: flex;
        flex-direction: inherit;
        height: 100%;
        overflow-x: hidden;
    }

    &__graph-row {
        display: inherit;
        box-sizing: border-box;
        height: 100%;
        margin-bottom: @padding / 2;
    }

    &__graph-tree {
        width: 70%;
        overflow: auto;
    }

    &__graph-sidebar {
        width: 30%;
        margin-left: @padding * 2;
        padding: 20px @padding 10px;
        overflow: hidden;
        background: @aviWhite;
    }

    &__graph-sidebar-content {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
    }

    &__graph-sidebar-title {
        margin-bottom: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid @midGray;
        color: @aviBlack;
        font-size: 14px;
        font-weight: bold;
    }
}
