@import '~ajs/less/themes/default-theme/clr-variables.less';

.vs-logs-actions {
    margin-bottom: 10px;

    &__export {
        &-dropdown-panel {
            width: 8rem;
            margin-top: 3px;
            border: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
            border-radius: var(--clr-table-border-radius);
            background-color: var(--clr-table-bgcolor);
            box-shadow: 0 0 2px 0 var(--clr-table-border-color);
        }

        &-dropdown-content {
            margin: 0.5rem 0 0.3rem 0;
        }

        &-dropdown-button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 30px;
            padding: 0 1rem;
            background-color: var(--clr-table-bgcolor);
            color: var(--clr-dropdown-item-color);
            font-family: @font-family;

            &:hover {
                background-color: var(--clr-dropdown-bg-hover-color);
            }
        }

        &-dropdown-button.deactivated {
            color: var(--clr-forms-label-disabled-color);
            cursor: not-allowed;
        }
    }
}
