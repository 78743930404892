.scheduler-modal {
    & &__fieldset-container {
        padding: 0 !important;
    }

    & &__type-checkbox-container {
        margin: 10px;
    }

    &__selection-wrapper-section {
        padding: 10px;
        border-top: 1px solid var(--cds-global-color-gray-400);
    }
}
