.clr-wrapper .series-list {
    h2.series-list__title {
        font-size: var(--cds-global-typography-body-font-size);
        font-weight: var(--cds-global-typography-font-weight-medium);
        line-height: var(--cds-global-typography-body-line-height);
    }

    &__details span {
        font-weight: var(--cds-global-typography-font-weight-light);

        &:not(lg-txt) {
            font-size: var(--cds-global-typography-body-font-size);
        }

        &.lg-txt {
            font-size: var(--cds-global-typography-section-font-size);
        }
    }
}
