@import (reference) '../full-modal.component.less';

.full-modal-breadcrumbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__breadcrumb {
        display: block;
        margin-bottom: 2px;
        padding-left: 10px;
    }

    &__breadcrumbs-container {
        flex: 1;
        padding-left: 24px;
        overflow-y: auto;
    }

    &__footer {
        .full-modal-footer-mixin();
    }
}
