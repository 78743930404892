@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.vip-list-popover {
    z-index: @headerZindex;
    width: 400px;
    max-height: 80%;
    padding: 20px;
    overflow-y: auto;
    transition: height .5s, top 1s;
    border-radius: @aviBorderRadius;
    background: var(--cds-global-color-white);
    box-shadow: 0 1px 5px 0 var(--cds-global-color-gray-400);

    &__header {
        display: flex;
        justify-content: space-between;

        &-title {
            margin-bottom: 10px;
            font-weight: 400;
        }

        &-close-icon {
            z-index: 2;
            cursor: pointer;
        }
    }

    &__state-icon {
        margin-right: 6px;
        margin-bottom: 3px;

        &--up {
            fill: var(--cds-global-color-green-600);
        }

        &--pending {
            fill: var(--cds-global-color-gray-500);
        }

        &--down {
            fill: var(--cds-global-color-red-700);
        }
    }

    &__vip-list {
        border: 1px solid var(--cds-global-color-gray-400);

        &:not(:last-child) {
            border-bottom: none;
        }
    }

    &__vip {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    &__vip-address {
        font-weight: 400;
    }

    &__vip-state-reason {
        padding: 10px;
        border-top: 1px solid var(--cds-global-color-gray-400);
    }
}
