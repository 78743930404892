@import (reference) '~ajs/less/themes/default-theme/colors.less';

.modal-backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .75);
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.avi-modal {
    position: fixed;
    z-index: 1050;
    border: 1px solid @aviGreySpecial;
    outline: none;
    background-clip: padding-box;
    background-color: white;
}

.avi-modal-header {
    padding: 0 15px;
}

.avi-modal-header .close {
    margin-top: 2px;
}

.avi-modal-header h3 {
    margin: 0;
    line-height: 30px;
}

.avi-modal-body {
    position: relative;
    overflow-y: auto;
}

.avi-modal-footer {
    margin-bottom: 0;
    padding: 14px 15px 15px;
    *zoom: 1;
    text-align: right;
}

.avi-modal-footer:before, .avi-modal-footer:after {
    content: '';
    display: table;
    line-height: 0;
}

.avi-modal-footer:after {
    clear: both;
}

.avi-modal-footer .avi-btn + .avi-btn {
    margin-bottom: 0;
    margin-left: 5px;
}

.avi-modal-footer .avi-btn-group .avi-btn + .avi-btn {
    margin-left: -1px;
}

.avi-modal-footer .avi-btn-block + .avi-btn-block {
    margin-left: 0;
}

.close {
    float: right;
}

.close:hover, .close:focus {
    text-decoration: none;
    cursor: pointer;
}

button.close {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    appearance: none;
}

.clearfix {
    clear: both;
}

.clickable {
    cursor: pointer;
}

// Outer container
.progress {
    height: 20px;
}

.progress-bar {
    box-sizing: border-box;
    width: 0%;
    height: 100%;
    float: left;
    border: 1px solid @aviBlue0;
    border-left-width: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: @aviBlue2;
    color: #FFF;
    font-size: 12px;
    text-align: center;
}

/* to prevent elements with ng-show\ng-hide from blinking before being parsed */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.absolutelyCentered() { //mixin only
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.absolutely-centered { //class
    .absolutelyCentered();
}

*[avi-loader] {
    height: 1.6em;

    & > div {
        text-align: center;
    }
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.relative {
    position: relative;
}
