@import (reference) '../../directives/vs-log-filter/vs-log-filter.directive.less';
@dropdown-items-list-color: var(--cds-global-color-white);
@dropdown-items-border-color: var(--cds-global-color-gray-400);

.vs-log-filter-list {
    color: @link-text-color;
    font-weight: @link-font-weight;

    &:hover {
        text-decoration: underline @link-underline-color 1px;
        text-underline-offset: 2px;
        cursor: pointer;
    }

    &__operators-item {
        padding: 6px 24px;
        border-right: 1px solid @dropdown-items-border-color;
        border-left: 1px solid @dropdown-items-border-color;
        background-color: @dropdown-items-list-color;
        color: var(--cds-global-color-gray-900);
        font-weight: @link-font-weight;
        text-align: right;

        &:last-of-type {
            border-bottom: 1px solid @dropdown-items-border-color;
        }

        &:first-of-type {
            border-top: 1px solid @dropdown-items-border-color;
        }

        &:hover {
            background-color: var(--cds-global-color-gray-200);
            cursor: pointer;
        }
    }
}
