@import (reference) '~ajs/less/themes/default-theme/colors.less';

// Button Base
// transition all click stuffs

@buttonHeight: 35px;
@buttonBorderRadius: 3px;
@buttonBorderWidth: 1px;
@buttonDefaultHoverColor: #666;
@buttonDefaultColor: #999;
@buttonPrimaryColor: #8ead1f;
@buttonPrimaryHoverColor: #7a9518;

a, button, .avi-btn {
    transition: all .10s linear;
    cursor: pointer;
}

.avi-btn {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: @buttonHeight;
    min-height: @buttonHeight;
    padding: 0 20px;
    overflow: hidden;
    border-radius: @buttonBorderRadius;
    outline: 0;
    background-color: @buttonDefaultColor;
    color: white;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover, &:active {
        background-color: @buttonDefaultHoverColor;
    }

    &.avi-btn-primary, &.avi-btn-success {
        border: 0;
        background-color: @buttonPrimaryColor;
        color: white;

        &:hover, &:active {
            background-color: @buttonPrimaryHoverColor;
        }

        i {
            color: inherit;
        }
    }

    // Danger Button (Red)
    &.avi-btn-danger {
        border: 0;
        color: white;

        &, &:hover {
            background-color: @aviRed1;

        }
    }

    // Removes all styling of button but keeps dimensions
    &.avi-btn-transparent {
        padding: 0 5px;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        color: @textMidGray;

        &:hover {
            background-color: rgba(158, 158, 158, 0.2);
        }
    }

    &.icon-button {
        display: flex;
        width: @buttonHeight;
        padding: 0;
        color: @textMidGray;
        font-size: 20px;

        &:not(.avi-btn-transparent) {
            &, &:hover {
                border: 1px solid @borderGray;
                background-color: white;
            }
        }

        .big {
            @size: 50px;
            width: @size;
            height: @size;
            font-size: 25px;
        }
    }

    &.disabled, &[disabled] {
        cursor: default;
        pointer-events: none;

        &, &:hover, &:active {
            background-color: @aviDisabled;
            color: @aviGreySpecial;
        }
    }

    // button sizing
    &.avi-btn-sm {
        padding: 5px;
        font-size: 12px;
    }
}

button.avi-btn {
    display: inline-block;
}

//Reset Shadow
.search.input-inline button.avi-btn {
    border: none;

    &:hover {
        border: none;
        background: none;
    }
}

// Add New button
.add-btn {
    margin: 0;
    float: right;
}

.avi-btn-group {
    display: inline-flex;
    flex: 1 0 0;
    max-width: 100%;
    border-style: solid;
    border-radius: @buttonBorderRadius;
    border-color: @borderGray;
    background-color: white;
    color: @textMidGray;

    & > .avi-btn {
        padding: 0 10px;
        border-width: @buttonBorderWidth @buttonBorderWidth @buttonBorderWidth 0;
        border-style: inherit;
        border-radius: 0;
        border-color: inherit;
        color: inherit;

        &:first-child {
            border-left-width: @buttonBorderWidth;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        &:not([disabled]):not(.disabled) {
            box-shadow: none;

            &:hover, &:active, &:not(.active) {
                background-color: inherit;
            }

            &.active {
                background: @buttonDefaultHoverColor;
                color: white;
            }
        }

        &[disabled], &.disabled {
            &.active {
                background: @midGray;
            }
        }
    }

    & > label.avi-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

button.avi-btn.view-details {
    padding: 2px 6px;
}

@flatBtnFontSize: 15px;

.flat-btn-group {
    & > button.flat-btn {
        &:nth-child(n+2) {
            border-left: none;
        }
    }
}

button.flat-btn {
    position: relative;
    min-width: 2em;
    height: 2em;
    padding: 0 .15em;
    border: 1px solid @aviGrey2;
    color: @aviGrey1;
    font-size: @flatBtnFontSize;
    line-height: @flatBtnFontSize;
    text-align: center;

    &[disabled] {
        color: @aviGrey3;
    }

    &:hover:not([disabled]) {
        color: @aviBlue0;
    }
}

.avi-btn-group.add-remove-button-group {
    width: auto;
    min-width: 75px;
}

button.button-green-rounded {
    width: 200px;
    height: 40px;
    border-radius: 40px;
    background-color: @aviGreen4;
    color: @aviWhite;
    font-size: 15px;
    cursor: pointer;

    &.disabled, &[disabled] {
        background: @aviGrey3;
        box-shadow: none;
        color: @aviDisabled;
        cursor: auto;
    }
}

.header-collapse-button {
    @size: 20px;
    display: block;
    position: relative;
    width: @size;
    height: @size;
    border: 1px solid @borderGray;
    color: #848484;
    font-size: 14px;
    line-height: @size;
    text-align: center;
    cursor: pointer;

    .icon.icon-minus {
        display: none;
    }

    &.expanded {
        .icon.icon-minus {
            display: block;
        }

        .icon.icon-plus {
            display: none;
        }
    }
}
