@import (reference) '~ajs/less/clr-mixins.less';

.login-page {
    .clr-login-page-overrides();

    .polling-progress-bar {
        height: 4px;
        margin-top: 0;

        .clr-progress-bar-overrides();
    }
}

.clr-progress-bar-overrides() {
    .progress {
        height: 4px;
    }
}
