.url-top-list-styling {
    .original-url {
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    .url-top-list {
        display: table;
        width: 100%;
        table-layout: auto;

        .list-item.url-list-item {
            display: table-row;
            position: relative;
            vertical-align: middle;
        }

        .title-section, .stats, .sideways-stacking-bar-chart-container {
            display: table-cell;
            padding: 5px;
            vertical-align: middle;
        }

        .title-section, .stats {
            width: 1px;
            white-space: nowrap;
        }

        .title-section {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .stats {
            text-align: right;
        }
    }

    .oneline-bar-chart {
        height: 30px;
    }

    // Reseting stuff set in TopList.less
    .top-list-timing-section {
        display: block;
        position: relative;
        margin: 0;
        overflow: hidden;
    }
}

