.prechecks-list {
    &__item {
        display: flex;
        margin-top: 5px;
        font-size: 14px;
    }

    &__label {
        margin-left: 4px;
        padding-top: 3px;
    }

    & &__spinner {
        margin-top: 4px;
        margin-right: 4px;
        margin-left: 4px;
    }
}
