.waf-crs-list {
    &__async-file-submit {
        display: block;
        margin-top: 4px;
    }

    &__crs-update-alert-container {
        padding: 0.5rem 0;
    }
}
