@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '../../avi-dropdown.component.less';

@clr-small-border: .25rem;
@clr-large-border: .3rem;

.horizontal-caret-borders(@topColor, @rightColor, @bottomColor, @leftColor) {
    border-top: @clr-large-border solid @topColor;
    border-right: @clr-small-border solid @rightColor;
    border-bottom: @clr-large-border solid @bottomColor;
    border-left: @clr-small-border solid @leftColor;
}

.avi-dropdown-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    height: 100%;
    padding: 0 16px;
    color: var(--avi-scorpion-gray);
    .hide-text-overflow();

    &__custom-template-container {
        .hide-text-overflow();
        width: 100%;
    }

    &__label {
        .hide-text-overflow();
    }

    &__tooltip {
        position: relative;
        box-sizing: border-box;
        max-width: 250px;
        padding: .45rem .6rem;
        background: var(--cds-global-color-black);
        color: var(--cds-global-color-white);
        .avi-dropdown-border-radius();

        // The rules below are for the tooltip caret, which needs to change based on the position
        // of the tooltip relative to the origin.
        &::before {
            content: '';
            position: absolute;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }

        &--right {
            &::before {
                top: .35rem;
                left: -.4rem;
                .horizontal-caret-borders(
                    var(--cds-global-color-black), var(--cds-global-color-black), transparent, transparent,
                );
            }
        }

        &--left {
            &::before {
                top: .35rem;
                right: -.4rem;
                .horizontal-caret-borders(
                    var(--cds-global-color-black), transparent, transparent, var(--cds-global-color-black),
                );
            }
        }
    }
}
