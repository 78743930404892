.update-card {
    display: block;
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }

    &__view-list {
        margin-right: 5px;
        color: var(--clr-color-success-500);
    }
}
