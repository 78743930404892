@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.legend {
    text {
        color: #777;
        font-size: 12px;
    }
}

.chart-legend {
    position: absolute;
    z-index: 5;
    top: 7px;
    right: 5px;
    flex: 0 0 0;

    .legend-container {
        display: block;
        position: relative;
        max-height: 350px;
        margin-top: 10px;
        padding: 5px 0;
        overflow-y: auto;

        .legend-pair {
            display: block;
            position: relative;
            padding: 0 15px;

            &.last-visible {
                cursor: not-allowed;
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .legend-pair {
        &.disabled {
            .chart-color {
                background-color: transparent;
            }
        }

        .legend-text {
            display: inline-block;
            max-width: 130px;
            .hide-text-overflow();
        }
    }

    .main-legend {
        &.collapsed {
            .legend-container {
                display: none;
            }
        }
    }

    .chart-color {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 100%;
    }
}
