@import (reference) '~ng/modules/diagram/components/double-arrow/double-arrow.component.less';
@import (reference) '../../../directives/vs-log-filter/vs-log-filter.directive.less';

@bridge-text-blue: @arrow-color-blue;
@bridge-text-green: @arrow-color-green;
@bridge-text-yellow: @arrow-color-yellow;
@bridge-text-violet: var(--cds-global-color-violet-800);

@bridge-content-padding: 12px;

.vs-log-cinematic-end-to-end-bridge {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1px;

    &--no-arrow {
        padding-right: @bridge-content-padding;
        padding-left: @bridge-content-padding;
    }

    &__title {
        padding: 0 @bridge-content-padding;
        color: var(--cds-global-typography-color-500);
        font-size: var(--cds-global-typography-font-size-2);
        font-weight: var(--cds-global-typography-font-weight-medium);
        white-space: nowrap;

        &--blue {
            color: @bridge-text-blue;
        }

        &--green {
            color: @bridge-text-green;
        }

        &--yellow {
            color: @bridge-text-yellow;
        }

        &--violet {
            color: @bridge-text-violet;
        }

        &--active {
            .title-underline()
        }

        &-clickable {
            cursor: pointer;

            &:hover {
                .title-underline()
            }
        }
    }

    &__arrow {
        width: 100%;
        margin-bottom: 6px;
    }

    &__status-code {
        font-size: var(--cds-global-typography-font-size-4);
    }

    &__time {
        font-size: var(--cds-global-typography-font-size-5);
        font-weight: var(--cds-global-typography-font-weight-light);

        &-unit {
            color: var(--cds-global-color-cool-gray-300);
            font-size: var(--cds-global-typography-font-size-0);
        }
    }
}

.title-underline() {
    text-decoration: underline @link-underline-color 1px;
    text-underline-offset: 2px;
}
