@import (reference) '~ajs/less/layout/flex.less';

dns-policy-rule-modal {
    .match-header, .action-header {
        .display-flex;
        .align-flex-start;
        justify-content: space-between;

        .icon-cancel {
            border: none;
            background: none;
        }
    }

    div.h-control.match-criteria {
        flex: 0 0 175px;
        margin-right: 15px;
    }
}
