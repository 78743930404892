@import (reference) '~ajs/less/fonts/avi';

//alerts list on grid
collection-grid.alerts {
    & > tbody > tr > td, & > thead > tr > th {
        text-align: left;

        &.grid-field-timestamp {
            width: 10em;
            padding-left: .5em;
            white-space: nowrap;
        }

        &.grid-field-level {
            width: 8em;
        }

        &.grid-field-obj_name {
            width: 10em;
        }

        &.grid-field-summary {
            width: 25%;
        }

        &.rowactions {
            width: 5%;
        }
    }

    .item-alert-level-icon + span {
        margin-left: .4em;
        text-transform: capitalize;
    }

    tr {
        &.row {
            height: 30px;

            & > td.rowactions > a {
                display: inline-block;

                & > i[class='icon-cancel']:before {
                    color: @aviRed0;
                }
            }

            //replaces plus with minus on details opening
            &.expanded > td.rowactions > span > a > i[class='icon-plus']:before {
                &:extend(.icon-minus:before);
            }
        }

        &.details > td[colspan] {
            padding: 1em;

            & > div.summary {
                margin: .5em 0;

                span.key { font-weight: bold }
            }

            /* list of related events in alert details */
            div.alertRelatedEvents {
                //display:block;
                position: relative;
                width: 99%;
                margin: 1em auto;

                & > div.header {
                    padding: 0.3em 0;
                    background-color: @aviGreySpecial;
                    color: @aviWhite;
                    text-align: center;
                    .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);
                }

                & > i {
                    position: absolute;
                    top: 5px;
                    right: 6px;
                    cursor: pointer;
                }

                grid > div.grid > table > tbody {
                    tr.expanded > td.rowactions > a > i[class='icon-plus']:before {
                        &:extend(.icon-minus:before);
                    }
                }
            }
        }
    }

    tr.expanded > td.rowactions > span > a > i[class='icon-plus']:before {
        &:extend(.icon-minus:before);
    }
}

alert-config-grid-expander {
    grid {
        .c-grid-table-header-cell--field-name--type {
            width: 200px;
        }
    }
}
