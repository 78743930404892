.dns-records {
    & &__card {
        display: flex;
        height: 135px;
    }

    & &__value {
        display: flex;
        justify-content: space-between;
        width: 250px;
    }

}
