@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.vip-list-popover {
    z-index: @headerZindex;
    width: 400px;
    max-height: 80%;
    padding: 20px;
    overflow-y: auto;
    transition: height .5s, top 1s;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    box-shadow: 0 1px 5px 0 @aviGrey1;

    &__close-icon {
        position: absolute;
        z-index: 2;
        top: 0.5em;
        right: 0.5em;
        cursor: pointer;
    }

    &__header {
        margin-bottom: 10px;
        font-weight: 400;
    }

    &__state-icon {
        margin-right: 6px;
        margin-bottom: 3px;

        &--up {
            fill: @aviHealthGood1;
        }

        &--pending {
            fill: @aviGrey1;
        }

        &--down {
            fill: @aviHealthDown1;
        }
    }

    &__vip-list {
        border: 1px solid @aviGrey2;

        &:not(:last-child) {
            border-bottom: none;
        }
    }

    &__vip {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        &-address {
            font-weight: 400;

            &-separator {
                position: relative;
                right: 2px;
            }
        }
    }

    &__vip-state-reason {
        padding: 10px;
        border-top: 1px solid @aviGrey2;
    }
}
