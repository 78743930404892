/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

// pool and vs edit modals only, create component if needed
.vrf-context-setter-wrapper {
    .col();

    // to match cloud selector
    margin: ~'calc(3em + 10px)' auto 0;

    collection-dropdown {
        float: none;
    }
}
