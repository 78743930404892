@import (reference) '~ajs/less/themes/default-theme/theme.less';

.avi-virtualservice-security {
    .security-dashboard {
        & > * {
            .small-shadow-bottom;
            background-color: @aviWhite;
            text-align: initial;
        }
    }

    .left-section.not-expanded {
        margin-top: 40px;
    }

    .body-font-size-medium-font-weight-mixin {
        font-size: var(--cds-global-typography-body-font-size);
        font-weight: var(--cds-global-typography-font-weight-medium);
        line-height: var(--cds-global-typography-body-line-height);
    }

    .ddos-section {
        div[chart-with-overlays] {
            display: block;
            height: 460px;
            margin-top: 17px;

            .performance-chart {
                border: none;

                .graph-title {
                    &.graph-overlay {
                        left: 15px;
                    }

                    &__heading {
                        display: block;
                        .body-font-size-medium-font-weight-mixin();
                    }

                    &__units {
                        .body-font-size-medium-font-weight-mixin();
                        font-weight: var(--cds-global-typography-font-weight-light);
                    }
                }
            }

            .performance-chart-controls.text-left {
                border: none;
                text-align: center;

                li.header {
                    display: none;
                }
            }

            .chartWithOverlaysGridWrapper > div {
                position: relative;

                div a {
                    position: relative;
                    left: 2px;
                }

                a.hideButton {
                    position: absolute;
                    right: 10px;
                }
            }
        }
    }

    .security-header-styling {
        border-bottom: 1px solid @aviGreySpecial;

        h2 {
            .body-font-size-medium-font-weight-mixin();
        }
    }

    .security-ssl-header {
        padding: 15px 0 20px 15px;
        .security-header-styling();
    }

    .security-ddos-header {
        height: 24px;
        padding: 15px;
        .security-header-styling();
    }

    .security-ddos-subheader {
        height: 40px;

        h3 {
            .body-font-size-medium-font-weight-mixin();
        }

        > div {
            display: inline-block;
            box-sizing: border-box;
            width: 45%;
            margin: 2%;

            @media (max-width:1152px) {
                font-size: 1em;
            }
        }
    }

    div[pie-chart-dashboard] {
        display: inline-block;
        width: 45%;
        height: 204px;
        padding-left: 15px;

        & > div.pie-chart-dashboard {
            height: 90%;
        }
    }

    .security-value {
        height: 81px;
        border-bottom: 1px solid @aviGreySpecial;
    }

    .grid {
        .dos-attack {
            background-color: @aviRed3;
        }
    }

}
