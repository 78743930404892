.right-rail-expander-text-child {
    display: flex;
    align-items: center;
    height: 36px;
    padding-left: 46px;
    overflow-x: hidden;
    color: var(--cds-global-color-gray-700);

    &:hover {
        transition-duration: 0.3s;
        background-color: var(--cds-global-color-gray-400);
        cursor: pointer;
    }

    &--active {
        background-color: var(--cds-global-color-white);
    }
}
