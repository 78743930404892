@import (reference) '~ajs/less/themes/default-theme/clr-variables.less';

.avi-label-with-tooltip {
    display: inline-flex;
    align-items: center;
    height: 20px;
    font-family: @font-family  !important;

    &__required {
        position: relative;
        padding-right: 7px;

        &::after {
            content: '*';
            position: absolute;
            color: var(--avi-scarlet-red);
            font-size: 24px;
            font-weight: normal !important;
            line-height: 0;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        padding-left: 3px;

        .avi-tooltip-icon__icon {
            --color: var(--avi-lochmara-blue);
        }
    }

    &__label {
        color: var(--avi-tundora-gray);
        font-size: @base-font-size;
        font-weight: 600;

        &--no-bold {
            font-weight: initial;
        }
    }
}
