.role-permission-setter {
    display: flex;

    &__icon-btn {
        outline: none;
    }

    &__icon {
        color: var(--cds-global-color-warm-gray-300);
    }

    &__icon.active {
        color: var(--cds-global-color-blue-700);
    }
}
