@import (reference) '~ajs/less/layout/grid-container.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';
@import (reference) '../avi-cinematics-portal/avi-cinematics-portal.component.less';

.cinematic-section() {
    padding: 1.8rem 1.5rem;

    &:nth-child(n+2) {
        border-top: 1px solid var(--clr-table-border-color);
    }
}

.cinematic {
    display: flex;
    position: fixed;
    z-index: @cinematicContentZIndex;
    // new shorthand added in 2021 for setting top right bottom left
    inset: @aviTopHeaderHeight 0 0 0;
    flex-direction: column;
    height: 100%;
    padding-top: 40px;
    background: var(--cds-global-color-gray-50);

    &__header {
        z-index: @cinematicContentZIndex + 10px;
        flex-shrink: 1;
        min-height: 0;
    }

    &__body {
        position: relative;
    }

    &__body, &__confirmation {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
    }

    &__body-content {
        padding-bottom: 1.8rem;
    }

    &__errors {
        display: block;
        position: sticky;
        z-index: 1;
        top: 20px;
        margin: 20px 20px 0 20px;
    }

    // Way to set styles before grid was introduced below
    &__section {
        .cinematic-section();
    }

    // Use instead of &__section above, to reduce number of divs needed
    &__grid-container-full {
        .cinematic-section();
        .grid-container-full();
    }

    // Used within the cinematic to take up a single row in the modal.
    &__form-control-row {
        display: flex;
        align-items: flex-start;
        .cinematic-clarity-overrides__margin-top()
    }

    // Used as children within .cinematic__form-control-row
    // as form elements that should be side-by-side.
    &__form-control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: .5rem;
        }
    }

    .cinematic__fieldset {
        margin: 1rem 0;
        padding: .5rem;
        border: 1px solid var(--clr-table-border-color);
        background: var(--cds-global-color-white);
    }

    &cinematic-header-tabs {
        display: flex;
        flex: 1;
    }

    .cinematic-clarity-overrides()
}

.cinematic-clarity-overrides() {
    clr-radio-container {
        .cinematic-clarity-overrides__margin-top()
    }

    clr-checkbox-container {
        .cinematic-clarity-overrides__margin-top()
    }

    h3:not([cds-text]) {
        padding-bottom: 0.6rem;
    }

    & avi-data-grid_header {
        & h6:not([cds-text]) {
            padding-bottom: 0;
        }
    }
}

.cinematic-clarity-overrides__margin-top() {
    margin-top: 1.2rem;
}
