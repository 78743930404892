.cinematic-tab-section {
    display: block;

    &:not(:first-child) {
        border-top: 1px solid var(--clr-table-border-color);
    }

    &:last-child {
        padding-bottom: 70px;
    }
}
