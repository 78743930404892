@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/components/dropdown-panel.less';

@cellHeight: 35px;
@headerCellHeight: 30px;
@r: @aviBorderRadius;
@controlsContainerHeight: 50px;

collection-grid {
    display: block;
}

.table-bottom {
    display: block;
    position: relative;
    padding: 10px;
}

// legacy, used by collection-grid only
.grid {
    display: block;
    position: relative;
    height: 100%;

    .table-wrapper {
        display: block;
        position: relative;
        padding: 0;
        overflow: hidden;
        overflow-y: auto;
        background: white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),
            0 1px 1px 0 rgba(0, 0, 0, .14),
            0 2px 1px -1px rgba(0, 0, 0, .12);
    }

    .grid-controls {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-height: @controlsContainerHeight;

        .grid-controls-right, .grid-controls-left {
            display: flex;
            align-items: center;

            .grid-action-button {
                box-sizing: border-box;
            }
        }

        .grid-controls-left {
            flex: 1 1 auto;
            justify-content: flex-start;
        }

        .grid-controls-right {
            flex: 0 0 auto;
            justify-content: flex-end;
        }

        .actions-panel {
            display: flex;
            position: relative;
            flex: 0 0 auto;
            align-items: center;
            margin-right: 10px;

            .grid-action, .grid-action-button {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            .options {
                border: 1px solid @aviGreySpecial;
                border-radius: 2px;
                white-space: nowrap;

                input[type=checkbox] {
                    margin: 9px 5px 3px 8px;
                }
            }
        }

        .grid-default-controls {
            display: inline-flex;
            position: relative;
            flex: 0 0 auto;
            align-items: center;
            margin-right: 10px;

            & > *:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .displaying {
        padding: 10px 10px 0;
        font-size: 12px;
    }

    .table-header-container {
        display: block;
        position: relative;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        background-color: white;
    }

    .header-table, .body-table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 15px;
    }

    .header-table-wrapper, .body-table-wrapper {
        display: block;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
    }

    .header-table-header {
        color: @textMidGray;
        font-weight: bold;
        text-align: left;
    }

    .header-table-row {
        border-bottom: 1px solid @borderGray;
    }

    .header-table-cell, .body-table-cell {
        box-sizing: border-box;

        .table-action-icon {
            color: @midGray;
        }
    }

    .header-table-cell {
        position: relative;
        padding: 0;
        overflow: hidden;

        &.sorted-asc, &.sorted-desc {
            .inner {
                border-left-color: transparent;
            }
        }

        &:first-child {
            position: relative;

            .inner {
                border-left: none;
            }
        }

        &:last-child {
            .inner {
                border-right: none;
            }

            & > div.columns-setup {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &.selectable {
            width: 66px;
        }

        &.rowactions {
            width: 100px;
            overflow: hidden;

            .inner {
                justify-content: flex-end;
            }
        }

        &:nth-of-type(2) {
            width: auto;
        }

        &.grid-field-index {
            width: 70px;
        }

        .inner, .icon {
            line-height: 25px;
        }

        .inner {
            display: flex;
            position: relative;
            align-items: center;
            padding: 10px;
            text-align: left;

            & > div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .field-name {
                display: inline-block;
                align-items: center;
                overflow: hidden;
                line-height: inherit;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .icon {
            display: inline-block;
            position: relative;
            margin-left: 5px;
        }

        selection-panel li .icon-check {
            position: inherit;
            float: right;
        }
    }

    .body-table-body {
        color: @textDarkGray;
    }

    .body-table-row {
        height: 45px;
        border-bottom: 1px solid #EBEBEB;

        & + .details {
            border-bottom: 1px solid #EBEBEB;

            & > td[colspan] {
                padding: @standardMargin;
            }
        }

        .row-expander-icon {
            display: none;

            .icon-minus {
                display: none;
            }
        }

        &.selected {
            background-color: @rowHighlightColor;
        }

        &.clickable {
            cursor: pointer;

            .row-expander-icon {
                display: inline-block;
            }

            &:hover {
                background: @rowHighlightColor;
            }

            &.expanded {
                box-sizing: border-box;
                background-color: @rowHighlightColor;

                .row-expander-icon {
                    .icon-plus {
                        display: none;
                    }

                    .icon-minus {
                        display: inline;
                    }
                }
            }
        }

        &.dragged {
            background: @rowHighlightColor;
        }
    }

    .body-table-cell {
        margin: 0;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;

        &.disabled {
            color: @aviGrey0;
        }

        &.rowactions {
            width: 100px;
            text-align: right;

            a {
                text-decoration: none;

                &[disabled] {
                    display: none;
                }
            }
        }

        &[grid-drag-and-drop-handle] {
            user-select: none;
        }

        a {
            color: inherit;
        }

        input[type=text], input[type=number] {
            box-sizing: border-box;
            width: 100%;
            height: 30px;
            margin: 2px 0;
            line-height: 30px;
        }
    }

    .drop-mask {
        display: none;
        position: fixed;
        z-index: 9998;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & > div.empty {
        padding: 10px;
        border: 1px solid #CCC;
        border-top: none;
        background-color: #FFF;
        .border-radiuses(0, @r, @r, 0);
    }

    &.disabled {
        opacity: 0.65;
    }

    .resize {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 0;
        width: 5px;
        height: 25px;
        border-right: 1px solid @borderGray;
        cursor: col-resize;
    }

    .column-configuration {
        border: 1px solid #989C9C;
        border-top: none;
        border-radius: @aviBorderRadius;

        .header {
            height: 25px;
            margin: -1px -1px 0 -1px;
            padding: 0 15px;
            border: 1px solid @aviGrape0;
            background-color: @aviGrape1;
            color: #FFF;
            line-height: 25px;
            .border-radiuses(@r, 0, 0, @r);
        }

        ul {
            height: 200px;
            margin: 0;
            padding: 0;
            overflow-y: auto;
            background-color: #FFF;
            color: @aviBlue0;
            list-style: none;
            .border-radiuses(0, @r, @r, 0);

            li {
                box-sizing: border-box;
                height: @buttonHeight;
                padding: 0 @standardMargin;
                border: 1px solid transparent;
                line-height: @buttonHeight;
                cursor: pointer;

                &:nth-child(even) {
                    background-color: @aviBackgroundColor;
                }

                &:not(.selected) {
                    &:hover {
                        border-color: @aviGrape0;
                        box-shadow: 0 0 5px 0 rgba(red(@aviGrape0), green(@aviGrape0), blue(@aviGrape0), .5);
                    }
                }

                &.selected {
                    border: 1px solid @aviGreen0;
                    background-color: @aviGreen3;
                    cursor: default;
                }
            }
        }
    }

    .table-navigation {
        .justify-flex-end;
        position: relative;
        flex-direction: row;
        align-content: flex-end;
        align-items: center;
        padding: 10px;

        .avi-label {
            margin: 0 10px;
        }

        dropdown {
            float: none;
        }
    }
}

.selection-panel {
    display: none;
    position: fixed;
    z-index: @modalZIndex;
    top: 29px;
    left: -1px;
    min-width: 120px;
    margin-top: 3px;
    margin-left: -1px;
    padding: 10px 0;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
    color: @textMidGray;
    line-height: normal;

    &:before {
        content: '▲';
        position: absolute;
        top: -12px;
        left: 6px;
        transform: scaleX(1.5);
        color: white;
        font-size: 12px;
        text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.27);
    }

    a {
        display: block;
        position: relative;
        padding: 10px 25px;
        overflow: hidden;
        color: inherit;
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.active {
            color: @aviBrandColor;
        }

        &:hover {
            background-color: #F1F1F1;
        }
    }
}

// Width overrides for columns
.grid-field-health {
    width: 90px;
}

.grid-field-throughput {
    width: 100px;
}

cell {
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 100%;
    //FIXME can't use overflow:hidden due to HS bubbles

    &[disabled] {
        justify-content: center;//WTF?
    }

    & > span {
        .hide-text-overflow();
    }
}

/*div.grid-debug {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0; right: 0;
    opacity: 0.5;
    height: 4px;
    background-color: cyan;

    &.top {
        background-color: magenta;
    }
}*/

.aviPopover.collection-grid-create-actions-popover {
    .create-actions-panel {
        .dropdown-panel();
    }
}
