// Colors for charts (and graphs and bars and everything) is set here.
// Classnames are just the same as the metric names (generally)
// There are a few defaults (and default-0, default-1) etc allow
// us to dynamically set colors

@import (reference) '~ajs/less/components/performance-chart.less';

.no-data {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid @aviBodyColor;
    border-left: 1px solid @aviBodyColor;
    color: @aviBodyColor;
    text-align: center;
}

@keyframes stuck {
    0% {
        transform: scale(0);
        stroke-opacity: 1;
    }

    100% {
        transform: scale(2);
        stroke-opacity: 0;
    }
}

circle.sparkline-outerCircle {
    transform: scale(1.0);
}

.stuck {
    circle.sparkline-outerCircle {
        animation: stuck 1s infinite;
        fill-opacity: 0;
    }
}

.performance-chart,
.stacked-bar-chart,
.scatter-plot-styling-main {

    // so that things (like .graph-title) can be positioned relative to charts easily
    position: relative;
    overflow: hidden;
    // Seting up some basic chart settings
    background-color: white;
    cursor: pointer;

    .performance-chart-legend {
        display: block;
        position: relative;
        text-align: right;
    }

    .chart-zoom-controls {
        position: absolute;
        top: 60px;
        right: 15px;

        .chart-zoom-button {
            width: 25px;
            height: 25px;
            border: 1px solid @borderGray;
            background: white;
            color: #888;
            line-height: 25px;
            cursor: pointer;
        }
    }

    .graph-overlay {
        display: inline-block;
        position: absolute;
        z-index: 2;
    }

    .shape {
        position: absolute;
        width: 15px;
        height: 15px;
        transition: all .1s ease-in-out;
        border-radius: 100%;
        font-size: 10px;
        line-height: 15px;
        text-align: center;

        i {
            transition: all .1s ease-in-out;
            color: #FFF;
            line-height: 15px;
        }

        &.clicked {
            box-shadow: inset 0 0 0 1px #FFF;
        }

        &.anomaly {
            background: @aviYellow1;
        }

        &.alert, &.alerts {
            background: @aviRed1;
        }

        &.config {
            background: @aviBlue1;
        }

        &.system {
            background: @aviPurple1;
        }

        &.user {
            background: @aviGrey1;
        }
    }

    .graph-shape {
        margin-top: @performanceChartHeaderHeight;
    }

    .graph-title {
        // Stuff for fun transition
        display: inline-block;
        top: 10px;
        left: 50px;
        flex: 0 0 0;
        overflow: hidden;
        text-align: left;
        text-overflow: clip;
        white-space: nowrap;

        h2 {
            display: inline-block;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        div.reason-string {
            position: absolute;
            margin-top: 20px;
            color: @aviBrandColor;
            font-size: 12px;
        }
    }

    // Settings for the axis
    .axis path, .axis line {
        stroke-width: 0;
        fill: @aviGreySpecial;
        shape-rendering: crispEdges;
    }

    .anomaly {
        cursor: pointer;
    }
}

path.line.limit-line {
    stroke: @aviRed0;
}

.axis-maker() {
    text {
        stroke-width: 0;
        fill: @aviBodyColor;
        font-size: 10px;
        shape-rendering: crispEdges;
    }

    line {
        stroke-width: 0;
        fill: @aviGreySpecial;
    }
}

.chart-with-overlays {
    border: 0;
    border-radius: 0;

    .chart-wrapper {
        .small-shadow-bottom();
        background: #FFF;
    }

    .performance-chart, .stacked-bar-chart, .scatter-plot-styling-main {
        .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);
    }

    chart-with-overlays-tables {
        collection-grid {
            tr.body-table-row.main-anomaly {
                background-color: @aviYellow1;
            }
        }
    }

    .overlay-panel {
        margin: 10px 0;
    }
}

.axis {
    .axis-maker();
}

.performance-chart {
    .area {
        stroke-width: 1px !important;

        &.hidden {
            stroke-width: 0 !important;
        }
    }
}

.chartColors (@strokeColor: @aviBlue0, @fillColor: @aviBlue3) {
    color: @strokeColor;

    &.chart-color {
        transition: background-color .25s ease-in-out;
        border: 1px solid @strokeColor;
        background-color: @fillColor;
    }

    &.chart-color.no-border {
        border: 0;
    }

    &.font-color {
        color: @fillColor;
    }

    .hoverCircles(@strokeColor);
    .labelColoring(@strokeColor);

    path&, circle& {
        stroke: @strokeColor;
        fill: @fillColor;
    }

    rect& {
        stroke: @strokeColor;
        fill: @fillColor;
    }

    rect&.selected {
        stroke: @strokeColor;
        fill: @strokeColor;
    }

    path&.line {
        fill: none;
    }

    // for scatter-plots to make selected more distinct
    circle&.selected {
        fill: @strokeColor;
    }

    .area, &.area {
        stroke-width: 0;
        stroke: @strokeColor;
        fill: @fillColor;
    }

    &.pieChartSlice {
        stroke: @strokeColor;
        fill: @fillColor;
    }

    .axis {
        .axis-maker();
    }

    path.line-path& {
        stroke: @strokeColor;
    }

    circle.v-circle& {
        stroke: @strokeColor;
        fill: @fillColor;
    }
}

.hoverCircles(@col) {
    circle.sparkline-outerCircle {
        stroke: @col;
        fill: white;
    }

    circle.sparkline-innerCircle {
        fill: @col;
    }

    .sparkline-textBackground {
        fill: @col;
    }
}

.labelColoring(@fill, @textColor: white) {
    &.sparkline-textBackground {
        stroke: none;
        fill: @fill;
    }

    &.sparkline-text {
        fill: @textColor;
    }
}

//         Defaults          //
.chart-color {
    border: 1px solid @aviGreen0;
    background-color: @aviGreen3;
}

.chart-color.total {
    background-color: @aviGreen0;
}

path.area {
    stroke: @aviGreen0;
    fill: @aviGreen3;
}

path.line {
    stroke: @aviGreen0;
    fill: none;
}

.default-0 {
    .chartColors(@aviYellow0, @aviYellow3);
}

.default-1 {
    .chartColors(@aviPurple0, @aviPurple3);
}

.default-2 {
    .chartColors(@aviGrape0, @aviGrape3);
}

.default-3 {
    .chartColors(#777, #ccc);
}

.default-4 {
    .chartColors(@aviRed0, @aviRed3);
}
// Chart Colors
.chart-color-green {
    .chartColors(@aviGreen0, @aviGreen1);
}

.chart-color-fuchsia {
    .chartColors(@aviFuchsia0, @aviFuchsia1);
}

.chart-color-blue {
    .chartColors(@aviBlue0, @aviBlue1);
}

.chart-color-purple {
    .chartColors(@aviPurple0, @aviPurple1);
}

.chart-color-grape {
    .chartColors(@aviGrape0, @aviGrape1);
}

.chart-color-grey {
    .chartColors(@aviGrey0, @aviGrey1);
}

.chart-color-yellow {
    .chartColors(@aviYellow0, @aviYellow1);
}

.chart-color-green-two {
    .chartColors(@aviGreen4, @aviGreen5);
}

.chart-color-fuchsia-two {
    .chartColors(@aviFuchsia4, @aviFuchsia5);
}

.chart-color-blue-two {
    .chartColors(@aviBlue4, @aviBlue5);
}

.chart-color-purple-two {
    .chartColors(@aviPurple4, @aviPurple5);
}

.chart-color-grape-two {
    .chartColors(@aviGrape4, @aviGrape5);
}

.chart-color-clear {
    stroke: transparent;
    fill: transparent;
}

.anomaly {
    fill: @aviYellow0;
}

.chart-color-bad {
    .chartColors(@aviRed0, @aviRed1);
}

.chart-color-warning {
    .chartColors(@aviYellow0, @aviYellow1);
}

.performance-chart-graph, .performance-chart__license-upgrade-message {
    height: 350px;
    max-height: 350px;
}
