@import 'grid-table-row-action-reordering/grid-table-row-action-reordering';

.c-grid-table-row-actions {
    color: @midGray;
    text-align: right;
    user-select: none;

    &__transclude-wrapper {
        display: inline-block;
        overflow: hidden;
    }

    &__action {
        display: inline-block;
        text-decoration: none;
        vertical-align: top;
        cursor: pointer;
    }
}
