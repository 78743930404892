div[scatter-plot] {
    display: block;

    & > div[avi-loader] {
        position: absolute;
        top: 2em;
        right: 0;
        left: 0;
    }
}

div.scatter-plot-and-table > div:nth-child(2) {
    margin-top: 1em;
}
