@import (reference) '~ng/modules/update/components/system-update/system-update.component.less';

@marginLeft: 10px;

.seg-update-page {
    .update-page-mixin();

    &__status-label {
        margin-left: @marginLeft;
        vertical-align: middle;
    }

    &__in-progress-label {
        margin-left: @marginLeft;
        vertical-align: super;
    }

    &__seg-grid-expander {
        width: 100%;
    }

    .avi-data-grid-overrides();
}
