.nav-pills {
    display: inline-block;

    li {
        padding: 5px 10px;
        float: left;
        transition: border-color .25s ease-in-out;
        border-bottom: 2px solid transparent;

        &:first-child {
            margin-left: 0;
        }

        &.active {
            border-bottom: 2px solid @aviBrandColor;

            a {
                color: @aviBrandColor;

                &:hover {
                    text-decoration: none;
                    cursor: default;
                }
            }
        }

        &:not(.active) a {
            color: @aviBlue0;

            &:hover {
                color: darken(@aviBlue0, 15%);
                text-decoration: none;
            }
        }

        .disabled {
            color: @aviDisabled;
        }
    }
}

.nav-tabs {
    @tabOffset: @buttonHeight - 2px;
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 30px;
    float: left;
    *zoom: 1;
    border-bottom: 1px solid @aviBlue0;
    background-color: @aviBlue1;
    box-shadow: none;

    &:before, &:after {
        content: '';
        display: table;
        line-height: 0;
    }

    &:after {
        clear: both;
    }

    & > li {
        height: @tabOffset;
        margin-top: 2px;
        margin-right: 2px;
        padding: 0 @standardMargin;
        float: left;
        line-height: @tabOffset;
        .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);

        &:first-child {
            margin-left: @standardMargin;
        }

        & > a {
            text-decoration: none;
            cursor: default;
        }

        &.active {
            background: @aviWhite;

            & > a {
                color: @aviBodyColor;
            }
        }

        &:not(.active):not(.locked) > a {
            color: darken(@aviBlue0, 10%);

            &:hover {
                color: @aviWhite;
                cursor: pointer;
            }
        }

        &.locked {
            cursor: not-allowed;
        }

        &.locked > a {
            color: @aviBodyColor;
            pointer-events: none;

            &:hover {
                cursor: not-allowed;
            }
        }
    }
}
