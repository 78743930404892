/* Avi-table is basic class for tables in logs, events and alerts. Made from grid styles.
* log-results-table is more specific with td styles for different alert/event/log values.
*/

/* small system style tooltip for response codes and significance reasons
in log details */
div.log-tooltip {
    position: absolute;
    max-width: 30em;
    max-height: 15em;
    padding: .25em;
    overflow: hidden;
    border: 1px solid @aviGrey1;
    border-radius: @aviBorderRadius;
    background-color: #FEFFDE;
    font-size: smaller;
    text-overflow: ellipsis;

    & > ul {
        list-style: square inside;

        & > li {
            padding-bottom: .25em;
        }
    }
}

div.log-results {
    padding: 1px;
    transition: .25s linear all;

    &.paginate {
        bottom: @logPaginateHeight;
    }

    &.empty {
        bottom: auto;
    }

    div.errorMsg {
        padding: 0.5em 0;
    }
}

.ipFlow {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;

    .key {
        font-weight: bold;
    }

    .ipAddress {
        display: inline-block;
        float: left;

        &:last-child {
            width: 50%;
        }
    }
    // IP Flow
    .direction {
        margin-top: 4px;
        overflow: hidden;

        .arrow {
            &.left {
                content: '';
                width: 0;
                height: 0;
                margin-left: 5px;
                float: left;
                border-top: 3px solid transparent;
                border-right: 8px solid @aviGreen0;
                border-bottom: 4px solid transparent;
            }

            &.right {
                content: '';
                width: 0;
                height: 0;
                float: right;
                border-top: 3px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 8px solid @aviGreen0;
            }
        }

        .line {
            display: block;
            width: ~'calc(100% - 15px)';
            height: 2px;
            margin-top: 2px;
            margin-right: 35px;
            margin-left: 10px;
            background: @aviGreen0;
        }
    }
}

table.log-results-table {
    @logTimelineWidth: 25%;

    span.key {
        font-weight: bold;
    }

    tr {
        th {
            &.log-timeline {
                padding: 0 0 0 1em !important;
                word-spacing: .25em;

                & > a {
                    color: @aviWhite;
                    text-decoration: none;

                    &.disabled {
                        color: darken(@aviGrape0, 10%);
                        pointer-events: all;

                        &:hover {
                            color: #FFF;
                        }
                    }
                }

                .seperator {
                    color: @aviGrape0;
                    text-shadow: 1px 0 0 rgba(255, 255, 255, .25);
                }
            }
        }

        & > th, & > td {
            @logSmallWidth: 4.5em;
            transition: color .25ms ease-in-out;

            ul li {
                margin: 0.5em 0;
            }

            &.centered {
                padding: 0;
                text-align: center;
            }

            &.log-timestamp {
                width: 8em;
                padding-left: .5em;
                white-space: nowrap;
            }

            &.log-ip {
                width: 7em;
                padding: 0 1em;
            }

            &.log-ms-name {
                width: 12em;
                padding: 0 1em;
            }

            &.log-port {
                width: @logSmallWidth;
            }

            &.log-method {
                width: @logSmallWidth;
            }

            &.log-status-code {
                width: @logSmallWidth;
            }

            &.log-bytes {
                width: @logSmallWidth;
            }

            span.val {
                color: @aviBlue0;
            }

            &.log-uri {
                width: 27%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.log-timeline {
                width: @logTimelineWidth;
                padding: 0;
                transition: all .25s linear;
                white-space: nowrap;
            }

            &.log-waf-column {
                width: 7em;
            }

            &.log-waf-cell {
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 1.5em;
            }

            &.log-recommendations {
                width: 16px;

                .checkbox-list {
                    --color: var(--cds-global-color-blue-700);
                }
            }

            &.log-dns-response {
                .hide-text-overflow;

                .val {
                    margin-right: 10px;
                }
            }

            //checkbox or icon
            &.checkbox {
                width: 2em;
                padding: 0;
                color: @aviBlue0;
                line-height: 1em;
                text-align: center;
                vertical-align: middle;

                &:hover {
                    color: darken(@aviBlue0, 15%);
                }

                i[class*='icon-'] {
                    cursor: pointer;
                }
            }

            th.checkbox {
                color: #FFF;

                &:hover {
                    color: #FFF;
                }
            }
        }

        &.even {
            background-color: @aviWhite;
        }

        &.odd {
            background: #EFEFEF;
        }

        &.details {
            & > td[colspan] {
                padding: 0;
                background-color: @aviWhite;
                box-shadow: inset 0 2px 5px rgba(0, 0, 0, .1),
                    inset 0 -2px 5px rgba(0, 0, 0, .1);

                div.logDetails {
                    position: relative;

                    div.timingsWrap {
                        margin: 0 auto;

                        div.e2e-timings { //most styles are in log-callout.less
                            height: 75px;

                            & > .c_rtt, & > .s_rtt, & > .total, & > .app_resp, & > .data_tr {
                                &:before {
                                    left: .3em;
                                }

                                &:after {
                                    right: .1em;
                                }

                                & > span:first-child {
                                    font-weight: bold;
                                }
                            }

                            & > .l_bracket, & > .r_bracket {
                                top: -1em;
                            }

                            &.layer4 {
                                & > .c_rtt, & > .s_rtt, & > .total {
                                    & > .caption {
                                        bottom: -.5em;
                                    }
                                }
                            }
                        }
                    }

                    & > table {
                        width: 100%;
                        /* main table for log details */
                        table-layout: fixed;

                        td {
                            width: 20%;
                            padding: 15px 10px 10px 10px;
                            border-right: 1px solid @aviGreySpecial;
                            vertical-align: top;

                            &:last-child {
                                border-right: 0;
                            }

                            &.double {
                                width: 40%;

                                & > ul > div.full-log-show-link {
                                    text-align: right;

                                    & > div {
                                        display: inline-block;
                                        padding: .2em .4em;
                                        border: 1px solid @aviGreySpecial;
                                        border-radius: @aviBorderRadius;
                                        background-color: @aviBlue3;
                                        text-decoration: underline;
                                        cursor: pointer;
                                    }
                                }

                                /* pseudo table for http request details */
                                div.request-details-header {
                                    .border-radiuses (@aviBorderRadius, 0, 0, @aviBorderRadius);
                                    margin-top: .5em;
                                    padding: .3em 0;
                                    background-color: @aviGreySpecial;
                                    color: @aviWhite;
                                    text-align: center;
                                }

                                div.request-details {
                                    .border-radiuses (0, @aviBorderRadius, @aviBorderRadius, 0);
                                    padding: 5px;
                                    overflow-x: scroll;
                                    border: 1px solid @aviGreySpecial;
                                    border-top: none;
                                    white-space: nowrap;

                                    span.val {
                                        word-wrap: break-word;
                                        word-break: break-all;
                                    }
                                }
                            }

                            & > ul {
                                margin-top: 0.5em;

                                //log details columns
                                & > li {
                                    margin: 0.5em 0;

                                    &:first-child {
                                        margin-top: 0;
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    & > span.key {
                                        font-weight: bold;
                                    }

                                    & > span.val > ul {
                                        margin: .25em 0 0 .25em;
                                    }

                                    &.modified {
                                        padding: @standardMargin/3;
                                        border: 1px solid @aviYellow0;
                                        color: @aviYellow0;

                                        span.key {
                                            color: @aviYellow0;
                                        }
                                        .border-radius();
                                    }

                                    .flag {
                                        display: inline-block;
                                        width: 16px;
                                        height: 14px;
                                        margin: 0 .15em;
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                    }
                                }
                            }

                            ul.dns-response-records {
                                margin-left: 1em;
                            }
                        }
                    }
                }
            }
        }

        &.alertDetails {
            & > td[colspan] {
                padding: 1em;

                & > div.summary {
                    margin: .5em 0;

                    span.key {
                        font-weight: bold;
                    }
                }

                /* list of related events in alert details */
                div.alertRelatedEvents {
                    position: relative;
                    width: 99%;
                    margin: 1em auto;

                    & > div.header {
                        .border-radiuses(@aviBorderRadius, 0, 0, @aviBorderRadius);
                        padding: 0.3em 0;
                        background-color: @aviGreySpecial;
                        color: @aviWhite;
                        text-align: center;
                    }

                    & > i {
                        position: absolute;
                        top: 5px;
                        right: 6px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.layer4 {
        td, th {
            &.log-timeline {
                width: @logTimelineWidth * 1.27;
            }
        }
    }
}

.checkboxSection {
    display: flex;
    position: relative;
    align-items: center;
    justify-items: flex-start;
    margin-bottom: .75em;
    vertical-align: baseline;

    label.flex-checkbox {
        margin-right: .75em;
    }
}

.searchResultsInfo {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10px;
    vertical-align: baseline;

    & > div {
        display: flex;
        position: relative;
        flex: 1 1 0;
    }

    & > .left {
        justify-content: flex-start;

        .sel-total-items, .sel-total-logs {
            margin: 0 5px;
        }
    }

    & > .middle {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > *:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    & > .right {
        justify-content: flex-end;
        white-space: nowrap;
    }

    a.disabled {
        background-color: @aviBlue2;
        color: @aviWhite;
    }
}

div.log-table-actions { /* download button */
    margin-bottom: .5em;
    text-align: left;

    button {
        line-height: 1em;
    }
}

//taken from the grid
table.avi-table {
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid @aviGreySpecial;
    text-align: left;

    & > thead {
        #avi-table .thead();
    }

    tr {
        &.row:not(.body-table-row) {
            height: 30px;
            transition: background .25s ease-in-out;
            border-right: 1px solid @aviGreySpecial;
            border-left: 1px solid @aviGreySpecial;

            &.clickable:hover {
                background: @aviBlue3;
                cursor: pointer;
            }

            &.child:last-child {
                border-bottom: 1px solid @aviGreySpecial;
            }

            &:nth-child(even) {
                background-color: @aviGrey3;
            }

            &.expanded {
                box-sizing: border-box;
                border-bottom: 1px solid @aviBlue2;
                background-color: lighten(@aviBlue3, 10%) !important;
            }
        }

        &.details {
            border: 1px solid;
            border-top: none;
            background-color: @aviWhite;

            & > td {
                padding: 5px 8px;
            }
        }

        td {
            margin: 0;
            padding: 0 1em;
            overflow: hidden;
            line-height: 1em;
            vertical-align: middle;

            &.rowactions {
                text-align: right;
            }

            &.pb5 {
                .pb5;
            }

            &.pt5 {
                .pt5;
            }
        }
    }
}

#avi-table .thead {
    background-color: @aviGrape1;

    & > tr {
        & > th {
            position: relative;
            height: 1.75em;
            padding: 0 1em;
            overflow: hidden;
            border: 0;
            border-top: 1px solid @aviGrape0;
            border-bottom: 1px solid @aviGrape0;
            border-left: 2px outset @aviGreySpecial;
            color: @aviWhite;
            font-weight: normal;
            line-height: 1.75em;
            text-overflow: ellipsis;
            white-space: nowrap;

            .icon {
                position: absolute;
                top: 0;
                right: 5px;
                transition: color .25s ease-in-out;
                color: @aviGrape0;
                line-height: 24px;
                text-shadow: 0 1px 0 rgba(255, 255, 255, .25);

                &:hover {
                    color: #FFF;
                    text-shadow: none;
                }

                &.icon-sort-asc, &.icon-sort-desc {
                    color: #FFF;
                }
            }

            &:first-child {
                position: relative;
                border-left: 1px solid @aviGrape0;

                .inner {
                    border-left: none;
                }
            }

            &:last-child {
                border-right: 1px solid @aviGrape0;

                .inner {
                    border-right: none;
                }
            }
        }
    }
}

.waf-log-container {
    @wafBlueColor: #49A1C1;
    padding: 20px;

    &__latencies {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid @wafBlueColor;
    }

    &__latency {
        display: block;
        position: relative;
        flex: 1 1 0;
        text-align: center;

        .waf-log-latencies__latency-tick {
            content: '';
            display: block;
            position: absolute;
            bottom: -16px;
            width: 2px;
            height: 10px;
            background-color: @wafBlueColor;
        }

        &:after {
            right: 0;
            .waf-log-latencies__latency-tick();
        }

        &:first-child {
            :before {
                .waf-log-latencies__latency-tick();
            }
        }
    }

    &__title {
        display: block;
        position: relative;
        margin-bottom: 5px;
        padding: 10px;
        background-color: @wafBlueColor;
        color: #FFF;
    }

    &__group {
        border: 1px solid @borderGray;

        &-header {
            padding: 20px;
            border-bottom: 1px solid #CCC;
        }

        &-title {
            margin-bottom: 1em;
            font-size: 14px;
        }
    }

    &__rules {
        &-title {
            margin-bottom: 1em;
            font-size: 14px;
        }

        &-exception {
            margin-bottom: 20px;
        }

        &-body {
            display: flex;
            align-items: stretch;
            justify-content: left;
            padding-left: 30px;
            overflow: hidden;

            &-left, &-right {
                padding: 15px;
                overflow: hidden;
            }

            &-left {
                width: 65%;
            }

            &-right {
                width: 35%;
                background-color: #FAFAFA;
            }
        }
    }

    &__rule {
        display: flex;
        justify-content: left;
        margin-bottom: 15px;

        &-label {
            width: 10%;
            padding-right: 20px;
            text-align: right;
        }

        &-value {
            width: 90%;
            font-weight: bold;
        }

        &-match,
        &-tags {
            white-space: normal;
        }

        &-match &-element,
        &-match &-value,
        &-tag {
            display: inline-block;
            position: relative;
            padding: 5px;
            border: 1px solid @borderGray;
            font-weight: bold;
        }

        &-match {
            display: inline-flex;
            position: relative;
            margin: 5px;
            font-size: 12px;
        }

        &-match &-element {
            font-weight: normal;
        }

        &-match &-value {
            max-width: 200px;
            overflow: hidden;
            border-left: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-tag {
            margin: 5px;
        }
    }
}
