.avi-fieldset {
    border: 1px solid var(--clr-table-border-color);
    border-radius: 3px;
    background: var(--cds-global-color-white);

    &__header {
        display: flex;
        justify-content: space-between;
        padding: .5rem;
    }

    &__remove-icon {
        cursor: pointer;
    }

    &__content {
        padding: .5rem;
        border-top: 1px solid var(--clr-table-border-color);
    }
}
