.vs-log-cinematic-sub-section-icap {
    @standard-gap: 10px;
    @border-style: 1px solid var(--clr-table-border-color);

    &__column-group {
        display: flex;
    }

    &__grid-expanded-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-section {
            display: flex;
            box-sizing: border-box;
            padding: @standard-gap 0;

            &:not(:first-child) {
                border-top: @border-style;
            }

            &:not(:last-child) {
                border-bottom: @border-style;
            }
        }

        &-response-title {
            padding-top: @standard-gap;
        }
    }

    &__response-column {
        margin-right: @standard-gap;
        background: var(--cds-global-color-gray-50);
    }

    &__violation-box {
        display: flex;
        box-sizing: border-box;
        margin-top: @standard-gap;
        padding: @standard-gap;
        border: @border-style;
        border-radius: var(--cds-alias-object-border-radius-100);
    }

    &__violation-info {
        padding-left: calc(@standard-gap / 2);
    }
}
