@import (reference) '~ajs/less/themes/default-theme/clr-variables.less';
@import (reference) '~ajs/less/clr-mixins.less';

.avi-textarea-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.2rem;
    font-size: @base-font-size;

    .ng-touched.ng-invalid.clr-textarea {
        border-color: var(--avi-milano-red);
        color: var(--avi-milano-red);
    }

    // Clarity overrides
    .clr-form-control {
        .clr-form-control-mixin();

        .clr-textarea {
            width: 100%;
            font-size: 0.7rem;

            &[readonly], [readonly='true'], [readonly='readonly'] {
                padding: 0 0.3rem;
                border: 0;
                background: initial;
                pointer-events: none;
            }
        }

        .clr-control-label {
            display: none;
        }
    }
}
