.gslb-subdomains-tree {
    width: fit-content;
    min-width: 41rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--clr-table-border-color);

    &__container {
        position: relative;
    }

    &__node-list {
        display: inline-block;
    }

    .gslb-subdomains-tree-clarity-overrides();

    &__node-list-item:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &__node-card {
        display: inline-block;
        width: 10rem;
        height: 1.5rem;
        padding: 10px;
        border: 1px solid green;
    }

    &__background-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        & > svg {
            path {
                stroke-width: 1;
                stroke: var(--clr-table-border-color);
                fill: none;
            }
        }
    }
}

.gslb-subdomains-tree-clarity-overrides() {
    li > ul:not([cds-list]).gslb-subdomains-tree__node-list {
        margin-left: 0;
    }
}

