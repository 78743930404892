ordered-grid.http-request,
ordered-grid.http-response,
ordered-grid.http-security,
ordered-grid.network-security,
ordered-grid.dns-policy {
    .ordered-grid__cell--name {
        flex: 0 0 15%;
    }

    .ordered-grid__cell--match, .ordered-grid__cell--action {
        .align-flex-start;
    }
}
