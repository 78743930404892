@import (reference) '~ajs/less/themes/default-theme/colors.less';

.avi-icon {
    &--success {
        color: @avi-green;
    }

    &--error {
        color: @avi-red;
    }

    &--warning {
        color: @avi-orange;
    }

    &--info {
        color: @avi-blue;
    }
}
