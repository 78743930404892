@import (reference) '~ajs/less/themes/default-theme/colors.less';

.policy-rule {
    &__expander {
        display: block;
        margin-bottom: 2px;
    }

    &__expander-opener {
        background: white;
    }

    &__opener {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        height: 50px;
        padding: 10px;
    }

    &__name {
        padding-left: 10px;
        font-weight: 600;
    }

    &__buttons-container {
        display: flex;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 50px;
        background: @expander-button-color;
        color: white;
        cursor: pointer;

        &:hover {
            color: @aviBrandColor;
        }
    }

    &__drag-icon {
        padding-right: 10px;
    }
}
