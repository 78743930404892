@import (reference) '~ajs/less/themes/default-theme/colors.less';

.icap-opswat-logs {
    padding: 10px;

    &__title {
        margin-bottom: 20px;
        color: @textMidGray;
        font-size: 18px;
        line-height: 1.2em;
    }

    &__field_name {
        margin-bottom: 7px;
        color: @textMidGray;
        font-weight: 700;
    }

    &__filter {
        color: @hyper-link;
    }

    &__violation {
        margin-top: 0;

        &-field-name {
            margin-bottom: 7px;
            color: @textMidGray;
            font-weight: 700;

            &--file-name {
                display: inline;
                position: relative;
                left: -27px;
            }
        }

        &-list-item {
            margin-right: 20px;
            margin-bottom: 15px;
            padding-left: 27px;
            border: 1px solid @borderGray;
        }

        &-sub-section {
            margin: 15px;
            margin-left: 10px;
        }

        &-warning-icon {
            position: relative;
            left: -30px;
            margin-right: 0;
        }

        &-filter {
            color: @hyper-link;
        }
    }
}
