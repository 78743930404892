@import (reference) '~ajs/less/layout/layout.less';

slider {
    display: inline-block;
    -webkit-touch-callout: none;
    user-select: none;

    .scale {
        height: 5px;
        margin-top: 15px;
        margin-bottom: 30px;
        border-radius: @aviBorderRadius;
        background-color: @aviGrey3;

        .position {
            width: 100px;
            height: 5px;
            float: left;
            border-radius: @aviBorderRadius;
            background-color: @aviBlue0;
        }

        .slider {
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            width: 15px;
            height: @buttonHeight;
            margin-top: -15px;
            margin-left: -7px;
            float: left;
            border: 1px solid @aviBlue0;
            border-bottom-width: 2px;
            border-radius: @aviBorderRadius;
            background-color: @aviBlue1;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
            line-height: @buttonHeight;
            cursor: default;

            i [class^='icon-']:before, [class*=' icon-']:before {
                margin: 0;
                color: @aviBlue0;
                text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
            }
        }

        .points {
            position: absolute;
            height: 5px;

            .point {
                position: absolute;
                width: 60px;
                margin-left: -30px;
                float: left;
                font-size: 12px;
                text-align: center;
                white-space: initial;

                .pointer {
                    width: 50%;
                    height: 20px;
                    border-right: 1px solid #CCC;
                }

                label {
                    width: 100%;
                }
            }
        }
    }

    .trigger {
        position: relative;
        top: -16px;
        height: 52px;
        margin-top: -5px;
        cursor: pointer;
    }
}

*.unselectable {
    user-select: none;
}
