@import (reference) '~ajs/less/layout/grid-container.less';

.bot-management-policy-modal {
    &__fieldset-content-grid {
        .grid-container-full();
        padding: 10px 0;
    }

    & &__checkbox-container {
        margin-top: 0;
    }
}
