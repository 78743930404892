@import (reference) '../ordered-grid.less';

.ordered-grid__create-menu.avi-menu-list {
    width: 150px;
    padding: 5px;
    border: 0;
    border-radius: 0;
    background-color: @aviGrey1;

    .create-button {
        .display-flex;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: @create-button-color;

        .plus {
            color: white;
            font-size: 20px;
            font-weight: 100;
            line-height: 20px;
        }
    }
}

.ordered-grid__create-menu__list-item.avi-menu-list-item {
    height: 28px;
    padding: 0 10px;
    background-color: @aviGrey1;
    color: white;
    .display-flex;
    .justify-flex-start;

    > .icon-wrapper {
        width: 20px;
        margin-right: 5px;
    }

    &:hover {
        background-color: @aviGrey2;
        color: white;
    }
}

ordered-grid-controls {
    .grid-controls {
        .display-flex;
        .align-flex-start;
        .justify-flex-start;
        position: relative;
        margin: 12px 0;

        .create-button {
            background-color: @create-button-color;
        }

        i.icon-search {
            color: @aviGrey0;
            font-size: 22px;
        }

        .sl-icon-plus {
            color: @aviBlue1;
            font-size: 24px;
        }

        .grid-control-section {
            .display-flex;
            height: 100%;
            padding: 0 20px;

            &.separator {
                height: 35px;
                border-right: 1px solid @aviGrey2;
            }

            &.filters {
                align-items: flex-start;
            }

            .filterset {
                flex: 1;

                &:not(:first-child) {
                    padding-left: 15px;
                    border-left: 1px solid @aviGrey2;
                }

                &:not(:last-child) {
                    padding-right: 15px;
                }

                .filter {
                    display: inline-block;
                    margin: 2px 0;
                    padding: 7px;
                    border: 1px solid @aviGrey2;
                    border-radius: 15px;
                    font-size: 12px;
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    &:hover {
                        background-color: @row-hover-color;
                    }

                    &.enabled {
                        border-color: @Grey;
                        background-color: @Grey;
                        color: white;
                    }
                }
            }
        }
    }
}
