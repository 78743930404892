.dropDownOptions() {
    .options {
        display: none;
        position: absolute;
        z-index: @modalZIndex;
        margin-left: -1px;
        border: 1px solid @borderGray;
        border-radius: 0 0 4px 4px;
        background-color: @aviBackgroundColor;
        color: @textDarkGray;
        line-height: 30px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 0 5px 0 11px;
                overflow: hidden;
                border-left: 4px solid transparent;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;

                &.group-title {
                    background-color: #5F6695;
                    color: @aviWhite;
                    font-size: 12px;
                    line-height: 22px;
                    cursor: default;

                    &:hover {
                        background-color: #5F6695;
                        color: @aviWhite;
                    }
                }

                &:hover {
                    background-color: @textMidGray;
                    color: white;
                }

                &.selected {
                    border-left: 4px solid @textMidGray;
                }

                &.selected:hover {
                    cursor: default;
                }
            }
        }

        .empty {
            padding: 2px 0;
            line-height: 30px;
            text-align: center;
        }

        .scrollable {
            max-height: 225px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .custom-options {
            border-bottom: 1px solid @borderGray;
        }

        button {
            width: 100%;
            .border-radiuses(0, @aviBorderRadius, @aviBorderRadius, 0);

            &.avi-btn {
                box-shadow: 0 0 0 transparent;
            }

            &.avi-btn.avi-btn-primary, &.avi-btn.avi-btn-secondary {
                border-bottom-width: 1px;
            }
        }

        .filter {
            margin: -1px -1px 0 -1px;
            padding: 0;
            overflow: hidden;

            input {
                width: 100%;
                .border-radiuses(@r, 0, 0, @r);
            }
        }
    }
}

dropdown,
collection-dropdown,
log-input-pills,
autocomplete,
collection-dropdown-custom {
    display: block;
    position: relative;
    box-sizing: border-box;
    min-height: @buttonHeight;
    float: left;
    transition: all .2s ease-in-out;
    border: 1px solid @borderGray;
    border-radius: @aviBorderRadius;
    background-color: @aviBackgroundColor;
    color: @textDarkGray;
    line-height: @buttonHeight - 2;
    text-align: left;

    &.disabled, &[disabled] {
        background: @aviGrey3;
        pointer-events: none;

        .dropdown-container {
            cursor: default;

            .expand i {
                color: @aviGrey1;
            }

            .choice, .dropdown-value-text, .prepend {
                color: @aviGrey0;
            }
        }

        input[type=text] {
            background-color: transparent;
        }
    }

    &[required] {
        .dropdown-container {
            &.editable {
                .expand {
                    right: 44px;
                }

                .edit {
                    right: 14px;
                    .border-radiuses();
                }

                .choice {
                    margin-right: 74px;
                }
            }
        }
    }

    .dropdown-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        .dropdown-value-container {
            display: flex;
            position: relative;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            min-width: 0;
            //for clear X button
            padding-right: 25px;
            padding-left: 15px;

            .dropdown-value-text,
            .dropdown-value-placeholder {
                flex: 1 1;
                flex-basis: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .dropdown-value-placeholder {
                color: @aviGrey2;
            }

            .dropdown-value-reset {
                text-align: center;
            }

            & > span.reset {
                position: absolute;
                right: 5px;
            }
        }

        .prepend {
            display: block;
            position: relative;
            padding-left: 15px;
        }

        &.has-prepend {
            .dropdown-value-container {
                padding-left: 5px;
            }
        }

        .edit {
            display: none;
            top: 0;
            right: 0;
            width: 30px;
            height: 100%;
            border-left: 1px solid @borderGray;
            background-color: @aviBackgroundColor;
            color: @aviBlue0;
            text-align: center;

            &:hover {
                color: darken(@aviBlue0, 10%);
            }
            .border-radiuses(@r, @r, 0, 0);
        }

        .expand {
            display: inline-block;
            top: 0;
            right: 0;
            width: 35px;
            height: 100%;
            text-align: center;
        }

        .choice {
            display: block;
            position: relative;
            flex: 1;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .placeholder {
                padding-left: 3px;
                color: @aviGrey2;
            }

            ul {
                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 3px 0 0 3px;
                line-height: 14px;
                list-style: none;

                li {
                    display: inline-block;
                    position: relative;
                    box-sizing: border-box;
                    max-width: 99%;
                    margin: 0 3px 3px 0;
                    padding: 6px 25px 6px 6px;
                    overflow: hidden;
                    border-radius: @aviBorderRadius;
                    background-color: #5F6695;
                    color: @aviWhite;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;

                    i.icon-cancel {
                        position: absolute;
                        right: 5px;
                        cursor: default;
                    }
                }
            }

            input, input:focus, input:hover {
                width: inherit;
                height: 26px;
                margin: 0;
                padding: 0;
                border: none;
                box-shadow: none;
                line-height: 26px;
            }

        }

        .choice.multiple {
            margin: 0;
            white-space: normal;

            .placeholder {
                padding-left: 18px;
            }
        }

        .data-options {
            display: none;
        }
    }

    .dropdown-container.editable {
        .expand {
            right: 30px;
        }

        .edit {
            display: block;
        }
    }

    .drop-mask {
        display: none;
        position: fixed;
        z-index: 9998;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .dropDownOptions();
}

collection-dropdown-custom {
    .filter > .search.input-inline {
        display: block;

        input[type=text] {
            border-radius: 0;
        }
    }
}

.aviPopover.collDropdown {
    z-index: @aviSelectZindex;
    margin-top: -1px;

    .dropDownOptions();

    .search {
        display: block;
    }

    .options {
        display: block;
        position: relative;
    }
}

// Autocomplete should not have border because it has an input inside which already has it
autocomplete {
    border: none;
    box-shadow: none;

    &:focus, &:hover {
        border: none !important;
        box-shadow: none;
    }

}

.input-append {
    .add-on {
        dropdown,
        dropdown:hover {
            border: 0;
            background: 0;
            box-shadow: none;
        }
    }
}
