.cinematic-header-tabs {
    & &__tabs-list {
        display: flex;
        align-items: flex-end;
    }

    .cinematic-header-tabs-clarity-override();

    &__tab {
        box-sizing: border-box;
        margin-right: 24px;
        padding: 0 5px 3px 5px;
        font-size: .8rem;
        cursor: pointer;

        &--active {
            padding-bottom: 0;
            border-bottom: 3px solid var(--clr-color-action-600);
        }

        &--deactivated {
            color: #999;
            cursor: not-allowed;
        }
    }
}

// override Clarity styles to give the tab list a left padding
.cinematic-header-tabs-clarity-override {
    ul:not([cds-list]).cinematic-header-tabs__tabs-list {
        padding: 0 24px;
    }
}
