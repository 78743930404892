.add-attachments-grid {
    .add-attachments-grid-overrides();
}

.add-attachments-grid-overrides() {
    .clr-wrapper {
        // Since avi-dropdown-button is used inside avi-data-grid_actions elements,
        // Remove button from mulitpleactions is getting displayed in new line.
        // To have avi-dropdown-button and Remove button in same line resetting display property.
        .datagrid-action-bar {
            display: inherit;
        }
    }
}
