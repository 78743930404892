@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.async-file-submit {
    &__file-upload-button-container {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        height: 50px;
        border: 1px solid @avi-border-color;
        border-radius: 2px;
        background-color: white;
    }

    &__file-upload-button {
        box-sizing: border-box;
        height: 40px;
        font-size: @avi-baseline-px;
    }
}
