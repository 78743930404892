.license-add-panel {
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__body {
        min-height: 170px;
    }

    &__title {
        display: flex;
    }

    &__title-text {
        font-size: 0.8em;
        letter-spacing: 0.5px;
        line-height: 1.8;
        text-transform: uppercase;
    }

    &__license-key {
        display: flex;
        align-items: flex-end;
    }
}
