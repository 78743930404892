.loader {
    width: 100px;
    height: 16px;

    p {
        display: inline-block;
        width: 2px;
        height: 10px;
        margin: 0;
        padding: 0;
        animation: loader 1.3s infinite;
        border: 1px solid @aviGreen0;
        border-radius: @aviBorderRadius;
        background: @aviGreen1;
        box-shadow: 0 0 5px 0 rgba(red(@aviGreen0), green(@aviGreen0), blue(@aviGreen0), .25);

        &:nth-child(1) {
            animation-delay: .1s;
        }

        &:nth-child(2) {
            animation-delay: .2s;
        }

        &:nth-child(3) {
            animation-delay: .3s;
        }

        &:nth-child(4) {
            animation-delay: .4s;
        }

        &:nth-child(5) {
            animation-delay: .5s;
        }
    }
}

@keyframes loader {
    50% {
        border-color: @aviGreySpecial;
        background: transparent;
        box-shadow: 0 0 0 0 transparent;
    }
}

