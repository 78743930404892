@import (reference) '~ajs/less/layout/flex.less';

.collapsible-list {
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;

        &--clickable {
            cursor: pointer;
        }
    }

    &__button {
        cursor: pointer;
    }

    &__toggle {
        .no-flex;
    }

    &__waf-item:not(.visible) {
        display: none !important;
    }
}
