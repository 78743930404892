@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

// Card Sizes
@compactCard: 70px;

// Card Base
.ajs-avi-card {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 81px;
    text-shadow: 0 1px 0 #FFF;

    .endToEnd, &.end-to-end-container, .sparkline-card-styling,
    .pie-chart-card-styling, .pie-chart-card, scatter-plot-card {
        &.clickable:not(.active) {
            &:hover {
                background: #F6F6F6;
            }
        }

        &.active {
            background: @highlightColor;
            cursor: default;
        }
    }

    & > sparkline-card, & > scatter-plot-card {
        height: 100%;
    }

    h3 {
        margin-right: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@unitsColor: @aviGrey1;

.units {
    color: @unitsColor;
    font-size: 14px;
    vertical-align: bottom;
}

.units-color {
    color: @unitsColor;
}

.card-anomalies {
    display: inline-block;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid @aviGreySpecial;
    font-size: 14px;
    line-height: 16px;

    i {
        color: @aviYellow0;
    }
}

.card-styling {
    border-bottom-width: 1px;
    border-style: solid;
    border-color: @borderGray;
}
