@input-font-size: 0.7rem;

.avi-repeated-strings {
    &__input-container {
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        align-items: center;
        min-height: 24px;
        border-bottom: 1px solid var(--cds-global-color-gray-500);
        font-size: var(--cds-global-typography-font-size-4);

        &:focus-within {
            border-bottom-color: var(--clr-forms-focused-color);
        }

        avi-repeated-strings.ng-dirty.ng-invalid & {
            border-bottom: 1px solid var(--cds-alias-status-danger);
        }

        /**
         * Adding below class as Avi Repeated Numbers Component is going to use Avi Repeated Strings Component.
         * Adding this in avi-repeated-numbers.component.less is giving doubly highlighted border.
         */
        avi-repeated-numbers.ng-dirty.ng-invalid & {
            border-bottom: 1px solid var(--cds-alias-status-danger);
        }

        &--disabled:focus-within {
            border-bottom-color: var(--clr-forms-border-disabled-color);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        margin-right: 2px;
        overflow: hidden;
        cursor: pointer;

        &-tag {
            &:focus {
                outline: none;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-content {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-close-icon {
            margin-left: 3px;
        }

        &-edit-input {
            padding: 0 var(--clr-btn-vertical-margin);
            border: none;
            background: none;
            font-size: var(--input-font-size);

            &:focus {
                outline: none;
            }
        }
    }

    &__main-input {
        flex-grow: 1;
        padding: 0 var(--clr-btn-vertical-margin);
        border: none;
        background: none;
        font-size: var(--input-font-size);

        &:focus {
            outline: none;
        }

        avi-repeated-strings.ng-dirty.ng-invalid & {
            color: var(--cds-alias-status-danger);
        }

        &::placeholder {
            opacity: 0.54;
            color: inherit;
        }
    }

    &__delete-icon {
        cursor: pointer;
    }
}
