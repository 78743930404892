@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.cinematic-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 8px 4px -4px var(--cds-global-color-gray-300);

    &__main {
        display: flex;
        flex: 0 1 96px;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__header-text {
        padding: 24px 0 0 24px;
        font-weight: 600;
        .hide-text-overflow();
    }

    &__close-button {
        margin-right: 30px;
        cursor: pointer;
    }
}
