@import (reference) '~ajs/less/themes/default-theme/colors.less';

.gslb-service-tree-domains {
    display: inline-block;
    position: relative;
    margin: 0 10%;
    padding: 0 10px;
}

unit-tree {
    @horizontalMargin: 4em;
    display: block;
    position: relative;

    &:not(:last-child) {
        .unit-tree-container {
            .unit-tree-heading, .unit-tree-list {
                border-bottom: 1px solid @borderGray;
            }
        }
    }

    .unit-tree-container {
        display: block;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;

        .unit-tree-collapse-bar {
            position: absolute;
            z-index: 2;
            right: 0;
            left: 0;
            height: 40px;
            cursor: pointer;
        }

        .unit-tree-collapse-button {
            display: inline-block;
            position: absolute;
            z-index: 3;
            top: 25px;
            right: 0;
        }

        .unit-tree-heading {
            display: flex;
            position: relative;
            box-sizing: border-box;
            align-items: center;
            height: 70px;
            font-size: 15px;
            cursor: pointer;

            .vs-name {
                margin-left: 20px;
            }
        }

        .unit-tree-list, .gslb-service-tree {
            display: block;
            position: relative;
            padding: 40px 0 20px 0;
            overflow: hidden;
            white-space: nowrap;

            unit-card {
                max-width: 100%;
            }
        }
    }

    servers-list {
        margin-left: @horizontalMargin;

        div.servers {
            display: inline-block;
            vertical-align: middle;
        }

        ul {
            margin-right: @horizontalMargin / 2;

            &.minimized > li > unit-card {
                //for big number of Server's circles
                margin: 0 .25em;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    ul {
        display: inline-block;
        position: relative;
        z-index: 1;
        white-space: nowrap;

        & > li > {
            vertical-align: inherit;

            & > ul {
                margin-left: @horizontalMargin;
            }
        }

        &.vs-group {
            display: inline-block;
            position: relative;
            margin-right: @horizontalMargin;
            vertical-align: top;

            li:not(:first-child) {
                margin: 20px 0;
            }
        }

        &.pools {
            & > li {
                min-height: 60px; //adds vertical margins when there are no networks in pool

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        &.nets {
            & > li > div {
                display: inline-block;
                width: 150px;//to make all servers align within different rows
                vertical-align: inherit;
            }

            servers-list {
                margin-left: @horizontalMargin / 2;
            }
        }
    }

    .unit-tree-list .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        & > svg {
            path {
                stroke-width: 1;
                stroke: @aviGrey1;
                fill: none;
            }
        }
    }

    .se-list-container {
        display: block;
        position: relative;
        margin-top: 60px;

        .se-list {
            display: inline-block;
            position: relative;
            padding: 10px;
            background-color: @backgroundGray;

            .se-item {
                display: inline-block;
                position: relative;
                height: 50px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }
}
