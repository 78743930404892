.avi-toast {
    width: 20rem;

    .avi-toast-clarity-overrides();

    &__text-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        display: inline-block;
        max-width: 16rem;
        overflow: hidden;
        white-space: nowrap;
    }

    &__content {
        max-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__action-container {
        display: flex;
        justify-content: flex-end;
    }
}

.avi-toast-clarity-overrides() {
    .alert {
        margin-top: 0;
        box-shadow: 0 0 15px var(--cds-global-color-gray-400);
    }

    h6:not([cds-text]) {
        line-height: normal;
    }
}
