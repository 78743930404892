.collapsed-sidebar-icon-list {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        align-items: center;
        height: 36px;
        padding-left: 15px;

        &-icon {
            margin-right: 3px;
        }

        &:hover {
            transition-duration: 0.3s;
            background-color: var(--cds-global-color-gray-400);
        }
    }
}
