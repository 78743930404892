.seg-update-grid-expander {
    padding: 10px;

    &__label {
        font-weight: var(--cds-global-typography-font-weight-bold);
    }

    &__data {
        margin-top: 5px;
    }
}
