.ip-reputation-db-list-page-header {
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &__icon {
        margin-right: 10px;
    }

    &__alert {
        margin-bottom: 25px;
    }

    &__error-container {
        max-height: 500px;
        overflow-y: auto;
    }

    &__error-message {
        word-break: break-all;
    }
}
