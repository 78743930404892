.case-attachments-grid {
    .case-attachments-grid-overrides();
}

.case-attachments-grid-overrides() {
    // To have avi-tooltip-icon and error message in same line resetting display property.
    &__icon {
        & .avi-tooltip-icon {
            display: inherit;
        }
    }
}
