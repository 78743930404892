@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.waf-rules-log {
    height: 50px;
    padding: 0 10px;

    &__rule-info {
        padding: 0 0 10px 20px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__tag {
        display: inline-flex;
        align-items: center;
        height: 20px;
        margin: 3px;
        margin-right: 5px;
        padding: 0 10px;
        border-radius: 10px;
        background: @wafBlueColor;
        color: white;
        font-size: @avi-baseline-px * 0.825;
        font-weight: 500;
    }

    &__rule {
        display: flex;
        justify-content: space-between;
        padding: 0 0 12px 30px;
        overflow-x: hidden;
        cursor: pointer;
    }

    &__rule-content {
        flex: 1;
    }

    &__rule-name {
        padding: 12px 0;
    }
}
