@border-width: 1px;
@border-color: var(--cds-global-color-gray-500);
@caret-small-border: 5px;
@caret-large-border: calc(@caret-small-border + @border-width);
@caret-white-border-color: var(--cds-alias-object-overlay-background);

.caret-outer-borders(@topColor, @rightColor, @bottomColor, @leftColor) {
    border-top: @caret-large-border solid @topColor;
    border-right: @caret-large-border solid @rightColor;
    border-bottom: @caret-large-border solid @bottomColor;
    border-left: @caret-large-border solid @leftColor;
}

.caret-inner-borders(@topColor, @rightColor, @bottomColor, @leftColor) {
    border-top: @caret-small-border solid @topColor;
    border-right: @caret-small-border solid @rightColor;
    border-bottom: @caret-small-border solid @bottomColor;
    border-left: @caret-small-border solid @leftColor;
}

.signpost {
    position: relative;
    box-sizing: border-box;
    min-width: 250px;
    min-height: 100px;
    padding: .45rem .6rem;
    border: var(--cds-alias-object-border-width-100) solid @border-color;
    border-radius: var(--cds-alias-object-border-radius-100);
    background: var(--cds-alias-object-overlay-background);

    &-header {
        display: flex;
        flex-direction: row-reverse;

        &__close-button {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &-body {
        margin: 5px 10px 10px 10px;
    }

    // The rules below are for the tooltip caret, which needs to change based on the position
    // of the tooltip relative to the origin.
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }

    &--left {
        // put larger borders in ::before to be center-shadowed by smaller borders in ::after
        &::before {
            top: 50%;
            right: calc(-2 * @caret-large-border);
            .caret-outer-borders(@border-color, transparent, transparent, @border-color);
        }

        &::after {
            top: calc(50% + @border-width);
            right: calc(-2 * @caret-small-border);
            .caret-inner-borders(@caret-white-border-color, transparent, transparent, @caret-white-border-color);
        }
    }

    &--right {
        // put larger borders in ::before to be center-shadowed by smaller borders in ::after
        &::before {
            top: 50%;
            left: calc(-2 * @caret-large-border);
            .caret-outer-borders(@border-color, @border-color, transparent, transparent);
        }

        &::after {
            top: calc(50% + @border-width);
            left: calc(-2 * @caret-small-border);
            .caret-inner-borders(@caret-white-border-color, @caret-white-border-color, transparent, transparent);
        }
    }

    &--top-left {
        // put larger borders in ::before to be center-shadowed by smaller borders in ::after
        &::before {
            right: calc(-2 * @caret-large-border);
            bottom: 10px;
            .caret-outer-borders(@border-color, transparent, transparent, @border-color);
        }

        &::after {
            right: calc(-2 * @caret-small-border);
            bottom: calc(10px + @border-width);
            .caret-inner-borders(@caret-white-border-color, transparent, transparent, @caret-white-border-color);
        }
    }

    &--bottom-left {
        // put larger borders in ::before to be center-shadowed by smaller borders in ::after
        &::before {
            top: 20px;
            right: calc(-2 * @caret-large-border);
            .caret-outer-borders(@border-color, transparent, transparent, @border-color);
        }

        &::after {
            top: calc(20px + @border-width);
            right: calc(-2 * @caret-small-border);
            .caret-inner-borders(@caret-white-border-color, transparent, transparent, @caret-white-border-color);
        }
    }

    &--top-right {
        // put larger borders in ::before to be center-shadowed by smaller borders in ::after
        &::before {
            bottom: 10px;
            left: calc(-2 * @caret-large-border);
            .caret-outer-borders(@border-color, @border-color, transparent, transparent);
        }

        &::after {
            bottom: calc(10px + @border-width);
            left: calc(-2 * @caret-small-border);
            .caret-inner-borders(@caret-white-border-color, @caret-white-border-color, transparent, transparent);
        }
    }

    &--bottom-right {
        &::before {
            top: 20px;
            left: calc(-2 * @caret-large-border);
            .caret-outer-borders(@border-color, @border-color, transparent, transparent);
        }

        &::after {
            top: calc(20px + @border-width);
            left: calc(-2 * @caret-small-border);
            .caret-inner-borders(@caret-white-border-color, @caret-white-border-color, transparent, transparent);
        }
    }
}
