@import (reference) './colors.less';
@import (reference) './usefulMixins.less';
@import (reference) '../../layout/layout.less';

@pagePaddingWidth: 30px;

.small-shadow-bottom {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}

// Font Stack
@fontFamily: 'brandon_text', sans-serif;
@lineHeight: 1.231;

body {
    color: @aviBodyColor;
    font-family: @fontFamily;
    line-height: @lineHeight;
}


//Main Page Title
h1 {
    color: @aviBodyColor;
    font-size: 1.571em;
    line-height: @buttonHeight;
}
//Section Title
h2 {
    color: @aviBodyColor;
    font-size: 1.286em;
}

//Tab Title
h3 {
    color: @aviBodyColor;
    font-size: 1.143em;
}

h4 {
    color: @aviBodyColor;
    font-size: 1em;
    font-weight: bold;
}

.bold {
    font-weight: 500;
}

// links
a:link, a:visited {
    color: @aviBlue0;
    text-decoration: none;
}

.blue {
    color: @aviBlue0;
}

a:hover {
    color: darken(@aviBlue0, 15%);
    text-decoration: underline;
}

a.disabled, a[disabled] {
    color: @aviDisabled;
    text-decoration: none;
    pointer-events: none;
}

a.permission-denied {
    color: inherit;
    cursor: default;
    pointer-events: none;
}

// Animate Override
.animated {
    animation-duration: 150ms;

    &.fadeInLeft, &.fadeInRight {
        animation-duration: 250ms;
    }
}

// Loader
i.icon-loader {
    display: inline-block;
    animation: spin 1s infinite linear;
    color: @aviGreen0;
    font-size: 1.286em;
    text-align: center;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

input,
dropdown,
collection-dropdown,
collection-dropdown-custom,
textarea {
    &.ng-dirty.ng-invalid,
    &.ng-dirty.ng-invalid-pattern,
    &.ng-not-empty.ng-invalid {
        border-color: @aviRed0 !important;
        color: @aviRed0 !important;
    }
}

//lovely Firefox fix for untouched & required form fields to avoid weird red borders
//don't forget to donate Mozilla! Otherwise you know.
.ng-untouched.ng-invalid-required {
    box-shadow: none;
}

select,
textarea,
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color] {
    color: @aviBodyColor;
}

.required {
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 15px;
    height: @buttonHeight;
    transition: all .1s linear;
    border: 1px solid @aviRed0;
    background: @aviRed1;
    color: darken(@aviRed0, 15%);
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    .border-radiuses(@r, @r, 0, 0);
    text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}

.label-required {
    position: relative;
    vertical-align: baseline; // to override our default 'top' value

    &:after {
        content: '*';
        position: relative;
        top: 5px;
        color: red;
        font-size: 24px;
        line-height: 0;
    }
}


.warning {
    max-height: 6em;
    padding: 10px 15px;
    overflow: auto;
    border: 1px solid @aviRed0;
    background-color: lighten(@aviRed3, 10%);
    color: @aviRed1;
}

.success {
    padding: 10px 15px;
    border: 1px solid limegreen;
    background-color: greenyellow;
    color: #006400;
}

.inprogress {
    padding: 10px 15px;
    border: 1px solid #888B54;
    background-color: #FF0;
    color: #888B54;
}

.healthScoreColors(@property: color) {
    &.high {
        @{property}: @aviHealthGood1;
    }

    &.medium {
        @{property}: @aviHealthFine1;
    }

    &.low {
        @{property}: @aviHealthBad1;
    }

    &.down {
        @{property}: @aviHealthDown1;
    }

    &.loading {
        @{property}: @aviHealthLoading0;
    }
}

@headerHeight: 100px;

// This has been added because Angular 8 does not have an ngShow/ngHide, and the 'hidden' attribute
// is used instead. 'hidden' inherently has 'display: none' set, but can be overridden by rules like
// 'display: flex', so we set '!important' here to make it more similar to 'ng-hide'.
[hidden] {
    display: none !important;
}
