.waf-learning-data {
    &__content {
        width: 100%;
        min-height: 3rem;
        padding: 0 0.5rem;
        border: 1px solid var(--clr-table-border-color);
        border-radius: 4px;

        & &-data {
            overflow: scroll;
            border: none;
            white-space: pre-wrap;
        }
    }

    &__spinner {
        width: 100%;
        text-align: center;
    }
}
