@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.full-modal-config-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-bottom: 1px solid var(--clr-table-border-color);
    background: var(--avi-gallery-gray);

    &__main {
        display: flex;
        flex: 0 1 60px;
        align-items: flex-start;
        justify-content: space-between;
    }

    & &__header-text {
        padding: 16px 0 0 24px;
        color: var(--cds-global-color-blue-700);
        font-size: 19px;
        font-weight: 450;
        text-transform: uppercase;
        .hide-text-overflow();
    }

    & &__header-name {
        display: flex;
        flex: 1 24px;
        flex-direction: row;
        align-items: flex-start;
        margin: 0 24px 24px 24px;
        white-space: nowrap;
    }

    & &__header-content {
        overflow: hidden;
    }

    &__ellipsis {
        color: var(--cds-global-color-blue-700);
        font-weight: 400;
    }

    & &__overflow {
        overflow: hidden;
    }

    & &__expand {
        display: revert;
        word-break: break-all;
        white-space: revert;
    }

    &__config-viewer {
        align-self: center;
        margin-right: auto;
        padding-left: 0.25rem;
    }

    &__close-button {
        margin: 10px 10px 0 0;
        cursor: pointer;
    }
}
