.alert-config-grid-expander {
    display: flex;
    width: 100%;

    &__container {
        flex: 1;
        padding: 10px 0;
    }

    &__grid-events-list {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
