@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

e-auto-complete {
    display: inline-block;
    position: relative;

    input {
        width: 100%;
        line-height: inherit;
    }

    & > div[avi-loader] {
        position: absolute;
        top: 0;
        right: .3em;
        bottom: 0;
        height: 1.6em;
        margin: auto 0;
    }

    & > div.suggestions {
        display: none;
        position: absolute;
        z-index: @aviSelectZindex;
        left: 0;
        min-height: 8px;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid @aviGrey2;
        background: @aviWhite;
        box-shadow: 0 1px 5px 0 @aviGrey2;
        text-align: left;
        cursor: pointer;

        & > div.list {
            & > table {
                width: 100%;

                &.variable td {
                    &.value {
                        width: 17em;
                    }
                }

                &.operator td {
                    &.value {
                        width: 2.5em;
                    }
                }

                &.query td {
                    &.value {
                        width: 6em;
                    }

                    &.percent {
                        width: 6em;
                    }

                    &.padding {
                        width: 10em;
                    }
                }

                tr.selected {
                    background: @aviGrey3;
                }

                td {
                    padding: .5em 0 .5em 1em;
                    color: @aviBodyColor;

                    &.descr {
                        width: 17em;
                    }

                    &.value {
                        color: @aviBlue0;
                        font-weight: normal;

                        & > span.provided {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
