.selected-seg-list {
    &__expander {
        color: var(--cds-global-typography-color-500);
        font-size: var(--cds-global-typography-font-size-4);
        .selected-seg-list-expander-overrides();
    }

    &__header {
        padding: 10px;
        border: 1px solid var(--clr-table-border-color);
    }

    &__content {
        padding: 10px;
        border-right: 1px solid var(--clr-table-border-color);
        border-bottom: 1px solid var(--clr-table-border-color);
        border-left: 1px solid var(--clr-table-border-color);
    }

}

.selected-seg-list-expander-overrides() {
    .expander__caret {
        color: var(--cds-global-typography-color-200);
        font-size: var(--cds-global-typography-font-size-1);

        &--hovered {
            color: inherit;
        }
    }

    .avi-data-grid {
        margin-top: 0;

        &__action-bar {
            margin-top: 0;
        }
    }
}
