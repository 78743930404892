.avi-health-score-down {
    margin: 20px 12px 12px 14px;

    &__row {
        &-label {
            font-size: 11px;
            font-weight: 600;
        }

        &-value {
            padding: 6px;
            font-size: 11px;
            font-weight: 400;
        }

        &:not(:first-child) {
            margin-top: 6px;
        }
    }
}
