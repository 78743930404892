@import (reference) '~ajs/less/themes/default-theme/animate.less';

.upgrade-transcript-modal {
    &__content {
        min-height: 318px;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    // To override clarity textarea styles.
    & &__transcript-textarea {
        height: 140px;
        line-height: 20px;
        resize: none;
    }

    &__status {
        .animated();
        .fadeIn();
    }

    &__status-grid {
        margin: 15px 0;
    }

    &__upgrade-state-message {
        font-family: var(--clr-display-font); // To override cds-alert message font-family
    }

    // To override margin-top set in container component
    .avi-textarea-container {
        margin-top: 0;
    }

    // To override readonly textarea's styles from .avi-textarea-container component
    .clr-form-control {
        .clr-textarea {
            &[readonly] {
                padding: 0.4rem 0.6rem;
                border: 1px solid var(--clr-table-border-color);
                font-size: 0.65rem;
                pointer-events: auto;
            }
        }
    }
}
