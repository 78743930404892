@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.full-modal-breadcrumb {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    height: 60px;
    padding: 10px;
    border-radius: 3px 0 0 3px;
    background: var(--avi-daintree-blue);
    color: var(--avi-silver-gray);

    &__text-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-width: 0;
        padding: 0 5px;
    }

    &__name {
        width: 100%;
        font-size: .6rem;
        font-weight: 600;
        text-transform: uppercase;
        .hide-text-overflow();

        &--active {
            color: var(--cds-global-color-blue-700);
        }
    }

    &__description {
        width: 100%;
        .hide-text-overflow();
    }

    &--active {
        background: var(--avi-gallery-gray);
        color: var(--cds-global-color-black);
    }
}
