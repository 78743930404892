.avi-tooltip-icon {
    display: flex;

    &__icon {
        cursor: pointer;
    }

    &__backdrop {
        opacity: 0;
    }
}
