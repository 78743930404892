@import (reference) '../../components/common/grid/grid.less';

div[pie-chart-card] {
    position: relative;
    overflow: hidden;

    &:last-child > div {
        .border-radiuses(@r, @r);
    }

    //div wrapper inside directive
    & > div {
        box-sizing: border-box;
        height: 100%;
        padding: 5px;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: @aviGreySpecial;

        //selected tile
        &.active {
            background-color: @aviWhite;
            background-image: none;
        }

        & > div {
            display: inline-block;
            box-sizing: border-box;
            width: 49%;

            &.current-values {
                padding-left: .3em;
                white-space: nowrap;

                //level of ng-ifs
                & > div {
                    padding-left: .5em;
                }

                &[pie-chart] {
                    width: 50%;
                    height: 50%;
                }
            }
        }
    }
}
