@import (reference) '~ajs/less/layout/layout.less';

.item-alert-popover {
    display: block;
    width: 300px;

    &__header {
        display: flex;
        height: 2em;
        padding: 0 0.5em;
        border-radius: @aviBorderRadius @aviBorderRadius 0 0;
        background-color: @aviDarkBackgroundColor;
        color: @aviWhite;
        line-height: 2em;
        text-transform: capitalize;
        white-space: nowrap;

        &-title {
            flex: 1 1 40%;
        }

        &-count {
            flex: 1 1 content;

            &:not(:last-child) {
                padding-right: .45em;
            }
        }
    }

    &__content {
        position: relative;
        min-height: 3em;
        max-height: 40em;
        overflow-y: scroll;
        border-width: 0 1px;
        border-style: solid;
        border-color: @aviGrey1;

        [avi-loader] {
            .absolutelyCentered();
        }

        &_button_no {
            border-bottom-width: 1px;
            border-radius: 0 0 @aviBorderRadius @aviBorderRadius;
        }
    }

    // single alert row in a list
    &__alert {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        min-height: 3em;
        padding: 0.25em 0;
        overflow: hidden;
        border-bottom: 1px solid @aviGrey3;
        color: @aviGrey0;

        &_level_ {
            &high {
                .item-alert-popover__alert-summary {
                    color: @aviRed0;
                }
            }

            &medium {
                .item-alert-popover__alert-summary {
                    color: @aviYellow0;
                }
            }

            &low {
                .item-alert-popover__alert-summary {
                    color: @aviBlue0;
                }
            }
        }

        .item-alert-level-icon {
            flex: 0 0 2em;
        }

        &-text {
            flex: 1 5 100%;
            min-width: 0;
        }

        &-obj-name {
            word-break: break-all;
        }

        &-action {
            flex: 0 0 1em;
            color: #000;

            &:hover {
                color: @aviRed0;
                cursor: pointer;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__no-alerts {
        color: @aviDisabled;
        line-height: 3em;
        text-align: center;
    }

    &__go-to-button {
        width: 100%;
        border-radius: 0 0 @aviBorderRadius @aviBorderRadius;
    }
}
