@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-waf-rule {
    max-width: @default-signpost-max-width;

    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__wrapper {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
    }

    &__content {
        min-width: @signpost-content-min-width;
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-separator {
            margin-right: 0.7rem;
        }
    }
}
