@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';
@import 'grid-table-header/grid-table-header';
@import 'grid-table-row-expander/grid-table-row-expander';
@import 'grid-table-row-actions/grid-table-row-actions';

@r: @aviBorderRadius;
@controlsContainerHeight: 50px;
@gridFontSize: 15px;
@gridRowHeight: 55px;
@gridBorderColor: #EBEBEB;

.c-grid {
    display: flex;
    color: black;
    font-size: @gridFontSize;

    &__wrapper {
        display: flex;
        position: relative;
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;
    }

    &__multi-actions {
        display: flex;
        position: relative;
        flex: 0 0 auto;
        align-items: center;
        margin-right: 10px;
    }

    &__mutli-action, &__multi-action-button {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    &__controls {
        display: flex;
        position: relative;
        flex: 0 0 @controlsContainerHeight;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2px;
    }

    &__rows-count {
        flex: 0 0 @gridRowHeight / 2;
        padding-left: 10px;
        font-size: 0.8em;
    }

    &__table-wrapper {
        display: flex;
        flex: 1 1 auto;
        flex-flow: column;
        //so that box shadow is visible
        margin: 2px;
        overflow: hidden;
        background-color: @aviWhite;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),
            0 1px 1px 0 rgba(0, 0, 0, .14),
            0 2px 1px -1px rgba(0, 0, 0, .12);

        .c-grid-table-header {
            flex: 1 0 auto;
        }
    }

    &__table-body {
        flex: 1 1 auto;
        overflow: hidden;
        overflow-y: auto;
        font-size: 15px;
    }

    &__table-body-row {
        display: flex;
        flex-direction: row;
        //height defined by cells
        border-bottom: 1px solid @gridBorderColor;

        &--selected, &--dragged {
            background-color: @rowHighlightColor;
        }

        &--expanded {
            flex-wrap: wrap;
            background-color: @rowHighlightColor;
        }
    }
}

/*div.grid-debug {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0; right: 0;
    opacity: 0.5;
    height: 4px;
    background-color: cyan;

    &.top {
        background-color: magenta;
    }
}*/

@gridCellHeight: @gridRowHeight;

.c-grid__table-cell {
    position: relative;
    box-sizing: border-box;
    //height and line-height are breaking custom cell templates
    height: @gridCellHeight;
    padding: 0 10px;
    overflow: hidden;
    line-height: @gridCellHeight;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--w--full {
        flex: 1 1 auto;
    }

    a {
        color: inherit;
    }
}
