@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

policy-grid-expander {
    width: 100%;
}

.policy-grid-expander {
    .display-flex;
    .align-flex-start;

    &__info-container {
        box-sizing: border-box;
        flex: 0 0 48.5%;
        border: 1px solid @aviGrey0;

        &--match {
            margin-right: 0.5%;
            margin-left: 1%;
        }

        &--action {
            margin-right: 1%;
            margin-left: 0.5%;
        }

        &__header {
            padding: 5px 10px;
            background-color: @aviGrey0;
            color: white;
            font-size: 11px;
            text-transform: uppercase;
        }

        &__body {
            padding: 10px;
        }
    }

    &__info {
        padding: 3px 0;
    }

    &__key {
        color: @aviGrey0;
        font-size: 11px;
        text-transform: uppercase;
    }
}
