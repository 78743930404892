@import (reference) '~ajs/less/avistrap.less';

.event-summary {
    &__progress-bar-wrapper {
        width: 100%;
        .progress;
    }

    &__progress-bar {
        .progress-bar;
    }
}
