.generic-hdr-match {
    &__input-container {
        display: block;
        margin-top: 10px;

        &--value {
            margin-left: 15px;
        }
    }

    &__input-container-single-value {
        margin-top: 10px;
        margin-left: 15px;
    }
}
