@right-rail-width: 240px;
@right-rail-width-collapsed: 48px;
@right-rail-header-height: 36px;

.avi-right-rail {
    display: flex;
    flex-direction: column;
    width: @right-rail-width;
    // 200px is the cumulative headers width and it can't be stored in a variable since "~" is
    // being used here as "max" isn't well supported by .less
    height: ~'max(100vh - 200px, 100%)';
    transition: width 0.2s ease;
    background-color: var(--cds-global-color-gray-200);

    &--collapsed {
        width: @right-rail-width-collapsed;
    }

    &__trigger {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: var(--cds-global-color-gray-200);

        &-icon {
            margin-left: 15px;
            transition-duration: 0.2s;
        }
    }

    &__header {
        position: sticky;
        z-index: 1; // shadow contents scrolled to the header
        top: 0;
        width: 100%;
        height: @right-rail-header-height;
        min-height: @right-rail-header-height;
        border-bottom: 0.5px solid var(--cds-global-color-gray-400);
        background-color: var(--cds-global-color-gray-200);

        &:hover {
            cursor: pointer;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;

        &--collapsed {
            overflow-y: auto;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
