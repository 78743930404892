@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

sparkline-card, [sparkline-card] {
    display: block;
    position: relative;

    &.child {
        border-left: 10px solid @aviGreySpecial;
    }

    sparkline, [sparkline] {
        display: block;
        position: relative;

        div.sparkline-graph {
            display: block;
            position: relative;
            width: 100%;
            height: 70px;
        }
    }

    [avi-loader] {
        .absolutelyCentered();
    }
}


.sparkline-card-styling {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;

    .sparkline-errors {
        margin-top: 9px;
        white-space: nowrap;
    }

    .sparkline-no-data {
        color: @aviGrey2;
    }

    .bad-error {
        color: @aviRed0;
    }

    .reason-string {
        width: 100%;
        overflow: hidden;
    }

    .sparkline {
        clear: both;
    }

    .units {
        line-height: 26px;
    }

    & h3, [cds-text] {
        font-size: var(--cds-global-typography-font-size-4);
        font-weight: var(--cds-global-typography-font-weight-medium);
    }
}

//small size for the cards
card-list > div.list.small-cards {
    sparkline-card, [sparkline-card] {
        sparkline, [sparkline] {
            display: block;
            position: relative;

            div.sparkline-graph {
                display: block;
                position: relative;
                height: 30px;
            }
        }
    }

    .sparkline-card-styling {
        .units {
            line-height: 16px;
        }

        .sparkline-value-container {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-start;
            margin-top: 5px;
            font-size: 13px;
        }

        .sparkline-errors {
            width: 95px;
            margin-top: 0;
            margin-left: 10px;
        }

        .sparkline {
            clear: none;
        }

        .card-values { float: none; }
    }
}

