.notification-page {
    &__container {
        .absolutelyCentered();
        width: 630px;
        height: 130px;
        margin: auto;
    }

    &__info {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        height: 100%;
        padding: 10px 10px 10px 15px;
        color: @aviWhite;
    }

    &__header {
        color: @aviWhite;
        text-shadow: none;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1em 0;

        &--fixed {
            width: 70%;
        }
    }

    &__avi-loader {
        position: absolute;
        top: 15px;
        right: 10px;
    }

    &__link {
        border-bottom: 1px solid transparent;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
