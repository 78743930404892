@import (reference) '~ajs/less/themes/default-theme/colors.less';

.expander-action-row {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 10px;
    background: @expander-button-color;
    color: white;

    &__message {
        font-weight: 600;
    }
}
