@import (reference) '~ajs/less/themes/default-theme/colors.less';

.license-tier-select-card {
    margin-bottom: 0.5rem;
    padding: 0.6rem;
    border: 1px solid @borderGray;
    border-radius: 3px;
    background-color: @backgroundGray;

    :hover {
        cursor: pointer;
    }

    &--disabled {
        color: @textMidGray;

        :hover {
            cursor: not-allowed;
        }
    }

    &__header {
        display: flex;
        padding-bottom: 7px;
    }

    &__name {
        font-size: 0.7rem;
        font-weight: 500;
    }

    &__radio {
        display: flex;
        align-items: center;
    }

    &__description {
        font-size: 0.7rem;
        font-weight: 300;
    }

    &__service-core-info {
        display: inline;
        padding-left: 5px;
        font-size: 0.5rem;
        line-height: 2em;
    }
}
