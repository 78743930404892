@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/flex.less';

svg.chart {
    @lineColor: #8084E7;
    margin: 0;
    padding: 0;

    rect.event-rect {
        opacity: 0;
    }

    g.line-container {
        stroke: @lineColor;

        path.line-path {
            stroke-width: 2px;
            fill: none;
        }
    }

    g.guideline {
        g.guideline-container {
            pointer-events: none;

            line.v-line {
                stroke-width: 1px;
                stroke: #787878;
                stroke-opacity: .35;
            }

            circle.v-circle {
                stroke-width: 2px;
                stroke: #FFF;
                stroke-opacity: .8;
                fill-opacity: 1;
                r: 5px;
            }

            g.y-value {
                rect.y-value-bg {
                    stroke-width: 1px;
                    stroke: @midGray;
                    fill: white;
                }

                text.y-value-text {
                    fill: @textDarkGray;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
    }

    line.average-line {
        stroke-dasharray: 10;
        stroke: @midGray;
    }

    text.average-label {
        fill: @textMidGray;
        font-size: 12px;
        text-anchor: end;
    }

    g.dominators-container {
        pointer-events: none;

        g.chart-dominators {
            polygon.dompoly {
                fill: @lineColor;
                fill-opacity: .35;
            }
        }
    }
}

g.chart-axis {
    path, line {
        stroke: @midGray;
        shape-rendering: crispEdges;
    }

    .tick {
        text {
            fill: @textMidGray;
        }
    }
}

g.axis-timestamp {
    text.axis-timestamp-text {
        fill: @textMidGray;
        font-size: 8px;
        text-anchor: middle;
    }
}

g.axis-timestamp-circle {
    circle {
        fill: @textMidGray;
        r: 3px;
    }
}

.axis-left {
    g.chart-axis line {
        stroke-width: 0;
    }
}

.axis-bottom {
    g.chart-axis {
        line {
            stroke-width: 1px;
        }

        g.tick {
            text {
                transform: translate(0, 5px);
            }
        }
    }

    g.axis-timestamp {
        text.axis-timestamp-text {
            transform: translate(0, 12px);
        }
    }

    g.axis-timestamp-circle {
        circle {
            transform: translate(0, 1px);
        }
    }
}

.chart-tooltip {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    box-sizing: border-box;
    max-width: 200px;
    padding: 5px 10px;
    overflow: hidden;
    border: 1px solid @borderGray;
    border-radius: 3px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    pointer-events: none;

    .chart-tooltip-row {
        .display-flex;
        position: relative;
        width: 100%;
        padding: 5px 0;
        font-size: 12px;

        .chart-tooltip-cell {
            display: inline-block;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.name {
                flex: 1 0 0;
                min-width: 0;
                padding-right: 10px;
            }

            &.value {
                text-align: right;
            }
        }
    }

    &.compact {
        padding: 0 10px;

        .chart-tooltip-cell.name {
            display: none;
        }
    }
}
