@import '~ajs/less/themes/default-theme/clr-variables.less';
@import (reference) '../../directives/vs-log-filter/vs-log-filter.directive.less';
@import (reference)
    '../../components/vs-log-cinematic/vs-log-cinematic-end-to-end-bridge/vs-log-cinematic-end-to-end-bridge.component.less';

@text-black: var(--cds-global-typography-color-500);
@text-blue: @bridge-text-blue;
@text-green: @bridge-text-green;
@text-yellow: @bridge-text-yellow;
@text-violet: @bridge-text-violet;

.vs-log-duration-detail {
    &__container {
        width: 379px;
        padding: 12px 36px 12px 24px;
        background-color: var(--cds-global-color-white);
    }

    &__row {
        display: flex;
        margin-top: 10px;

        &-black {
            color: @text-black;
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-blue {
            color: @text-blue;
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-green {
            color: @text-green;
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-yellow {
            color: @text-yellow;
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-violet {
            color: @text-violet;
            font-weight: var(--cds-global-typography-font-weight-medium);
        }

        &-value {
            margin-left: auto;
        }
    }
}
