.avi-health-score-footer {
    display: flex;
    margin-top: 5px;
    border-top: 1px solid var(--cds-global-color-gray-600);
    justify-items: center;

    &__view-health {
        font-size: 12px;
        font-weight: 600;
    }
}
