@import (reference) '~ajs/less/themes/default-theme/clr-variables.less';

.vs-logs-save-search-modal {
    &__content {
        width: 100%;
    }

    &__label {
        color: var(--cds-global-color-gray-800);
        font-size: @base-font-size;
        font-weight: var(--clr-font-weight-bold);
    }
}
