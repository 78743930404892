.avi-dropdown-options {
    &__virtual-scroll-container {
        .cdk-virtual-scroll-content-wrapper {
            width: 100%;
        }
    }

    &__option {
        display: block;
        box-sizing: border-box;
        height: 30px;

        &--selectable {
            cursor: pointer;
        }

        &--highlighted {
            background-color: var(--cds-global-color-gray-100);
        }

        &--selected {
            background-color: var(--avi-mystic-white);
        }

        &--centered {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
