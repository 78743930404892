@import (reference) '~ajs/less/themes/default-theme/colors.less';

.template-confirm {
    position: absolute;
    top: 47px;
    bottom: 45px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1%;
    padding-bottom: 60px;
    overflow-y: auto;

    .matrix-container {
        max-height: 250px;
        margin: 0 30px;
        overflow-y: auto;
        border-bottom: 1px solid @aviGreySpecial;

        grid {
            text-align: left;

            div.grid > table tr.row {
                height: 30px;
            }
        }
    }

    .c-grid__table-cell {
        width: 50%;
    }
}
