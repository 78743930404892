@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';

.gslb-replication-status-popover {
    z-index: @headerZindex;
    width: 300px;
    max-height: 80%;
    padding: 10px;
    overflow-y: auto;
    transition: height .5s, top 1s;
    border: 10px solid transparent;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    box-shadow: 0 1px 5px 0 @aviGrey1;
    color: black;

    &__site-name {
        font-size: 22px;
        line-height: 24px;
    }

    &__title {
        margin-top: 20px;
        font-weight: 600;
        line-height: 18px;
    }

    &__data {
        margin: 2px 5px;
    }
}
