.avi-bar-graph {
    margin-bottom: 15px;
    padding-top: 5px;
    padding-right: 10px;
    border: var(--cds-global-color-gray-400) solid 1px;
    box-shadow: 0 4px 2px -2px var(--cds-global-color-gray-400);

    &__alternate-state {
        height: 65px;
        margin: auto;
        padding-top: 48px;
    }

    &__awaiting-data {
        width: 0;
    }

    &__err-state {
        width: fit-content;
    }

    &__legend {
        display: flex;
        margin: -10px 20px 10px;

        &-label {
            padding-right: 10px;
        }
    }

    &__tooltip {
        position: absolute;
        z-index: 1;
        top: 47px;
        width: fit-content;
        background-color: white;
    }
}
