@import (reference) '../../../../../../../less/themes/default-theme/colors.less';

.psm-log {
    height: 50px;
    padding: 0 10px;

    &--child {
        padding-left: 30px;
    }

    &--grandchild {
        padding-left: 50px;
    }

    &--great-grandchild {
        padding-left: 70px;
    }
}
