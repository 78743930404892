.system-settings-access-system-access-card {
    & &__icon {
        transform: rotate(90deg);
    }

    & &__row {
        display: flex;
        align-items: flex-start;
    }

    & &__control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    & &__success {
        color: var(--cds-global-color-green-700);
    }

    .system-settings-access-system-access-card-clarity-overrides();
}

.system-settings-access-system-access-card-clarity-overrides() {
    h6:not([cds-text]).system-settings-access-system-access-card__header {
        color: var(--cds-global-color-blue-700);
    }
}
