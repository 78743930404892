.vs-logs-signpost-default-header {
    &__wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: .5em;
        background-color: var(--cds-global-color-white);
        text-align: center;
    }

    &__content {
        display: inline-block;
        padding: 0 1em;
        background-color: var(--cds-global-color-white);
        line-height: 2em;

        &-number {
            color: var(--cds-global-color-green-600);
            font-size: x-large;
        }

        &-contentText {
            color: var(--cds-global-color-warm-gray-500);
            font-size: small;
        }

        &-line {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--cds-global-color-warm-gray-400);
        }
    }
}
