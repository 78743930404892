span, div {
    &[log-filter-click],
    &[log-selection] {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &[log-selection] &.log-filter-selected {
        background-color: @aviGreen3;
        text-decoration: underline;
        cursor: pointer;
    }

    &[log-filter-click][disabled] {
        color: @midGray;
        cursor: default;
        pointer-events: none;
    }
}
