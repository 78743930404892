.repeated-input {
    display: flex;
    margin-bottom: 10px;

    .buttons {
        flex: 0 0 100px;
        background-color: initial;

        &.disabled {
            box-shadow: none;
        }
    }

    .inputs {
        flex: 1 1 auto;

        input {
            width: 100%;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}
