@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-waf-latency {
    max-width: @custom-waf-latency-signpost-max-width;

    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__table {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
        table-layout: fixed;

        tr {
            border-bottom: 1px solid var(--clr-table-border-color);
        }

        &-head {
            text-align: left;

            th:not(.vs-logs-signpost-custom-waf-latency__table-head-phase) {
                padding: 10px;
            }

            &-phase {
                width: 120px;
            }
        }

        &-body {
            tr {
                td {
                    .vs-logs-signpost-custom-waf-latency {
                        &__progress-container {
                            display: flex;
                        }

                        &__progress {
                            max-width: 80%;
                            .signpost-clarity-overrides()
                        }
                    }
                }
            }
        }

        &-phase {
            &-cell {
                padding: 0 10px;
                overflow: hidden;
                background-color: var(--cds-global-color-blue-600);
                color: var(--cds-global-color-white);
                font-size: 12px;
                text-align: center;
                text-overflow: ellipsis;
                vertical-align: middle;
            }

            &-label {
                overflow: hidden;
                word-wrap: normal;
                white-space: nowrap;
            }
        }

        &-cell {
            padding: 10px;
        }
    }
}

.signpost-clarity-overrides() {
    progress {
        max-width: 90%;
    }
}
