@import (reference) '~ajs/less/components/card.less';

scatter-plot-card {
    display: block;
    position: relative;
    box-sizing: border-box;
    height: @compactCard;
    padding: 5px 0 5px 5px;

    &:hover {
        background: #F6F6F6;
        cursor: pointer;
    }

    & > div[scatter-plot] { //actual chart inside
        height: .7 * @compactCard;
    }
}
