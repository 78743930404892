@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

virtualservice-faults {
    display: block;
    margin: 15px;
    font-size: 15px;

    &.message {
        box-sizing: border-box;
        width: 500px;
    }

    .count {
        .avi-red;

        &:hover {
            color: @aviHealthDown1;
        }
    }

    li:before {
        content: '•';
    }

    li.pool {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 5px 10px;

        &:before {
            content: '‣';
        }
    }

    i.icon-attention-2 {
        color: @aviHealthDown1;
    }
}
