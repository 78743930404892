.avi-notifications-portal {
    > * {
        position: fixed;
        right: 1rem;
        bottom: 10px;
        margin-bottom: 10px;
        animation-name: fadeInUp;
        animation-duration: .5s;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
        animation-fill-mode: both;
    }
}
