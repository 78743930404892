.vs-logs-search-bar {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    transition: background-size var(--cds-global-animation-duration-quick) ease;
    border-bottom: var(--cds-alias-object-border-width-100, calc((1 / var(--cds-global-base, 20)) * 1rem)) solid;
    border-bottom-color: var(--cds-alias-object-interaction-border-color);
    background: linear-gradient(180deg,
        var(--cds-alias-object-opacity-0) 95%,
        var(--cds-alias-object-interaction-border-color) 0) no-repeat;
    background-size: 0% 100%;

    &:focus-within {
        border-bottom-color: var(--cds-alias-status-info);
        background-size: 100% 100%;
    }

    &__input-container {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        align-items: center;
        min-height: 24px;
        font-size: var(--cds-global-typography-font-size-4);
    }

    &__filter-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        margin-right: 2px;
        margin-bottom: 2px;
        cursor: pointer;
    }

    &__input {
        display: flex;
        flex-grow: 1;
    }

    &__button {
        margin-right: 6px;
        color: var(--clr-btn-primary-bg-color);
        cursor: pointer;
    }

    &__saved-search-star {
        margin-right: 6px;
        fill: var(--clr-btn-primary-bg-color);
        cursor: pointer;
    }

    &__logs-label {
        font-size: var(--clr-btn-appearance-form-font-size);
        font-weight: var(--cds-global-typography-font-weight-bold);
        white-space: nowrap;
    }
}
