@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-significance {
    max-width: @default-signpost-max-width;

    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__data-grid {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
    }

    &__hide-overflow-content {
        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__empty-field {
        min-width: @signpost-content-min-width;
    }
}
