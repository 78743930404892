.match-display-wrapper {
    @match-bottom-margin: 12px;
    @match-left-margin: 10px;
    margin-bottom: 30px;

    &__criteria-wrapper {
        display: flex;

        &--subheader {
            margin-bottom: @match-bottom-margin;
            margin-left: @match-left-margin;
        }
    }

    &__label {
        margin-bottom: @match-bottom-margin;
        font-weight: 500;
    }

    &__section {
        &--content {
            margin-left: @match-left-margin;

            &-with-subheader {
                margin-left: @match-left-margin * 2;
            }
        }
    }
}
