.se-group-list-expander {
    width: 100%;

    &__section-container {
        padding-right: 10px;
    }

    &__section-label {
        font-weight: bold;
    }

    &__section-data {
        margin-top: 6px;
    }

    &__link {
        color: var(--cds-global-color-blue-700);
    }
}
