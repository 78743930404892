@import (reference) '~ajs/less/components/dropdown-panel.less';
@import (reference) '~ajs/less/components/navigation.less';
@import (reference) '~ajs/less/themes/default-theme/z-index.less';
@import (reference) '~ajs/less/themes/default-theme/theme.less';

@dashboardSubheaderHeight: @subnavHeight + 10px;

.dashboard_alert--insufficient-permissions {
    margin: 1.5rem;
}

.dashboard-subheader {
    .avi-subheader;
    height: @dashboardSubheaderHeight;
    border: 0;
    background-color: transparent;

    .dashboard-controls {
        & > * {
            margin-right: 10px;
        }
    }
}

.dashboard-wrapper {
    display: flex;
    flex: 1;
    min-height: 0;
    padding: 0 @pagePaddingWidth;

    .panel-body {
        margin: 0;
        padding: 0;
    }

    .unit-list-view {
        unit-card {
            margin: 0 50px 30px 0;
        }
    }

    .dashboard-container {
        .small-shadow-bottom;
        flex: 1;
        padding: 10px @pagePaddingWidth;
        overflow-y: auto;
        background: white;

        .unit-list-view {
            margin: 40px 0 0;
        }

        .dashboard-header {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid @borderGray;

            &__container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__legend-item {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }
}

div.main-legend {
    .small-shadow-bottom;
    @openButtonWidth: 50px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 20px 15px 15px;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 15px;

    & > div.button {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: inherit;
        width: @openButtonWidth;
        height: 30px;
        color: @midGray;
        font-size: 30px;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
        pointer-events: auto;

        & > span.icon {//for the ideal central alignment
            margin-left: 2px;
        }
    }

    & > div.text-legend > p {
        margin-top: 10px;
    }

    &.collapsed {
        width: @openButtonWidth;
        height: 30px;
        padding: 0;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: none;
    }
}
