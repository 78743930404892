@import (reference) '~ajs/less/layout/layout.less';

.stacked-end-to-end {
    color: white;

    .timing-container {
        position: relative;
        z-index: 1;
    }

    .bar {
        height: 35px;
        line-height: 35px;
        .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);

        .title-bar {
            padding: 0 10px 0 2px;
        }
    }

    .sideways-stacking-bar-chart {
        z-index: 3
    }

}
