@import (reference) '~ajs/less/components/button.less';

.vs-waf-top-hits {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_hits-wrapper {
        @tileMargin: 10px;
        @tileWidth: 30%;//approximate
        display: inherit;
        flex-wrap: wrap;
        width: 100%;

        > waf-top-hits-container {
            box-sizing: border-box;
            flex: 1 1 auto;
            width: @tileWidth;
            margin: 0 @tileMargin;

            &:nth-child(n + 4) {
                margin-top: 2 * @tileMargin;
            }

            //first six tiles only are affected
            &:nth-child(-3n + 6) {
                margin-right: 0;
            }

            &:nth-child(-3n + 4) {
                margin-left: 0;
            }

            // second row has custom sizes
            &:nth-child(4) {
                width: 0.6 * @tileWidth;
            }

            &:nth-child(6) {
                width: 1.4 * @tileWidth;
            }

            &.combination {
                width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }


    &_buttons-row {
        display: flex;
        width: 100%;
        padding: 10px 0;

        &-right, &-left {
            display: flex;
            width: 50%;
        }

        &-left {
            justify-content: flex-start;
        }

        &-right {
            justify-content: flex-end;
        }

        button {
            .avi-btn();
            margin: 0 5px;

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
