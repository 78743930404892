@import (reference) '~ajs/less/layout/layout.less';

.sideways-stacking-bar-chart {
    z-index: 1;
    width: 100%;

    .bar {
        padding-bottom: 5px;
        text-align: center;
        .border-radiuses(0, @aviBorderRadius, @aviBorderRadius, 0);

        .viewable-title {
            padding-right: 2px;
            padding-left: 2px;
            overflow: hidden;
        }
    }

    .oneline-bar-chart {
        overflow: hidden;
        border-radius: @aviBorderRadius;

        .container {
            width: 100%;
        }

        .oneline-bar {
            display: inline-block;
            border: 0;
            color: white;
            text-align: center;
            vertical-align: top;

            .viewable-title {
                padding-right: 2px;
                padding-left: 2px;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .first-bar {
            .border-radiuses(0, 0, @aviBorderRadius, @aviBorderRadius);
        }

        .last-bar {
            .border-radiuses(@aviBorderRadius, @aviBorderRadius, 0, 0);
        }

        .first-bar.last-bar {
            border-radius: @aviBorderRadius;
        }
    }
}
