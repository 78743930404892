.auth-attribute-match {
    &__name-container {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--clr-table-border-color);
    }

    &__string-container {
        margin: 1rem 0 0 15px;
    }
}
