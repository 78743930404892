.traffic-capture-page {
    & &__card-container {
        display: flex;
        max-width: 50%;
        margin-top: 1.2rem;
    }

    & &__card {
        flex-basis: 0;
        flex-grow: 1;
    }

    & &__grid {
        margin-top: 1.2rem;
    }
}
