.vs-logs-collapsed-sidebar {
    display: flex;
    flex-direction: column;

    &__saved-search-section {
        box-sizing: border-box;
        padding-bottom: 17px;
        border-bottom: 0.5px solid var(--cds-global-color-gray-400);
    }

    &__data-section {
        box-sizing: border-box;
        padding-top: 12px;
    }
}
