// Styling for SimpleTable Directive
@aviTableRowHover: @aviBlue3;
@aviTableRowSelected: @aviBlue2;
@aviTableEven: #F9F9F9;

//TODO drop this
table.avi-base-table {
    .avi-table-styling(@width: 100%, @cellPadding: 10px) {
        width: @width;
        border-collapse: collapse;

        thead {
            text-align: left;

            i {
                color: #777;
            }

            tr {
                th {
                    position: relative;
                    height: 30px;
                    padding: 0 @cellPadding;
                    border-left: 1px solid @aviGreySpecial;
                    background-color: @aviGrape0;
                    color: white;
                    line-height: 30px;

                    &.noBorder {
                        border-left: none;

                        .inner {
                            border-left: none;
                        }
                    }

                    .inner {
                        position: relative; // for the menu to be based off of
                        height: 30px;
                        font-weight: bold;
                        line-height: 30px;
                        white-space: nowrap;

                        i.expandableMenu {
                            color: white;
                        }

                        .selectOptions {
                            display: inline-block;
                            position: absolute;
                            top: 30px;
                            left: -@cellPadding;
                            min-width: 140px;
                            border: 1px solid #CCC;
                            border-radius: @aviBorderRadius;
                            background-color: #FFF;
                            color: #777;

                            & > div {
                                padding: 0 10px;
                            }
                        }
                    }
                }

                th.selectable {
                    width: 15px;

                    input[type=checkbox] {
                        margin: 0;
                    }
                }

                th.rowactions {
                    width: 15px;
                }
            }
        }

        th:first-child, td:first-child {
            .inner {
                min-width: 30px;
            }
        }

        tr:nth-child(even) {
            background-color: #EEE;
        }

        tbody {
            tr {
                td {
                    margin: 0;
                    padding: 0 @cellPadding;

                    .inner {
                        input[type=text] {
                            width: 100%;
                            border: 0;
                            background-color: transparent;
                            font-size: 14px;
                        }

                        input[type=text]:focus {
                            background-color: #FFF;
                        }
                    }

                    td.ellipsify {
                        .ellipsifier();
                    }
                }

                td.selectable {
                    width: 15px;

                    input[type=checkbox] {
                        margin: 0;
                    }
                }
            }
        }
    }

    &.centered {
        td, th {
            text-align: center;
        }
    }

    .avi-table-styling(100%);
}
