.label-count-legend {
    @marginTop: 4px;
    padding: 4px 8px 3px;
    border: var(--cds-global-color-gray-400) solid 1px;

    &__title {
        margin-top: @marginTop;
        font-weight: bold;
    }

    &__row {
        margin-top: @marginTop;
    }
}
