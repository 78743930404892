@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.avi-collection-dropdown-selected-values-container {
    padding: 0 0 3px 10px;
    color: black;
    .hide-text-overflow();

    &__multiple-selected-value-container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        align-items: center;
        min-width: 0;
    }

    &__multiple-selected-value {
        margin: 2px;
    }

    &__selected-value-prepend {
        margin-right: 0.25rem;
        color: #454545;
        font-size: 0.65rem;
        font-weight: 600;
    }
}
