@import (reference) '~ajs/less/themes/default-theme/clr-variables.less';
@import (reference) '~ajs/less/clr-mixins.less';

.avi-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.2rem;
    font-size: @base-font-size;

    &--no-margin-top {
        margin-top: 0;
    }

    & &__error-text {
        color: var(--avi-milano-red);
    }

    & &__success-text {
        color: var(--clr-color-success-500);
    }

    .ng-touched.ng-invalid.clr-input {
        transition: background-size .2s ease;
        border-bottom-color: var(--avi-milano-red);
        background: linear-gradient(to bottom, transparent 95%, var(--avi-milano-red) 95%) no-repeat;
        background-size: 0 100%;
        color: var(--avi-milano-red);
    }

    // Clarity overrides
    .clr-control-container .clr-form-control {
        .clr-form-control-mixin();

        .clr-input {
            width: 100%;
            font-size: 0.7rem;

            // Overriding avi input styling.
            &[type=password], &[type=number], &[type=text], &[type=email], &[type=time], &:not([type]) {
                font-family: inherit;

                &[disabled] {
                    border: 0;
                    background: initial;
                    color: var(--cds-global-color-black);

                    &:not([readonly]), &[disabled]&:not([readonly=true]) {
                        border-bottom: 1px solid var(--avi-dusty-gray);
                    }
                }

                &::placeholder {
                    color: inherit;
                    font-family: @font-family;
                }
            }
        }

        .clr-control-label {
            display: none;
        }
    }
}
