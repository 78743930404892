.avi-repeated-collection-or-custom-value {
    &__add-item-button {
        margin-top: 15px;
    }

    &__input-field {
        &:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--clr-table-border-color);
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__remove-icon {
        margin-right: 10px;
        cursor: pointer;
    }
}
