@import (reference) '~ajs/less/themes/default-theme/colors.less';

.chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.tooltip-container {
    height: 0;

    & .tooltip-body {
        font-size: 10px;

        & .used-license-cores {
            color: #74B1D6;
        }

        & .unlicensed-usage {
            color: #E94227;
        }

        & .tooltip-header {
            font-weight: 900;
        }
    }
}
