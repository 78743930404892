@arrow-color-default: var(--cds-global-typography-color-500);
@arrow-color-blue: #50b5d2; // can't find a tokened color mapping or alike to the mock color
@arrow-color-green: var(--cds-global-color-green-600);
@arrow-color-yellow: var(--cds-global-color-yellow-500);
@arrow-color-deep-blue: var(--cds-global-color-blue-700);

.double-arrow {
    width: 100%;
    min-width: 17px;

    &__line {
        display: inline-block;
        width: calc(100% - 10px);
        height: 1px;
        margin: 0 -5px 2px -5px;
        background-color: @arrow-color-default;

        &--blue {
            background: @arrow-color-blue;
        }

        &--deep-blue {
            background: @arrow-color-deep-blue;
        }

        &--green {
            background: @arrow-color-green;
        }

        &--yellow {
            background: @arrow-color-yellow;
        }
    }

    &__head {
        &-left,
        &-right {
            display: inline-block;
            padding: 2px;
            border: solid;
            border-width: 0 1px 1px 0;
            color: @arrow-color-default;
        }

        &-left {
            // compensate the negative margins added to the arrow line to symmetrize the block
            margin-left: 5px;
            transform: rotate(135deg);
        }

        &-right {
            transform: rotate(-45deg);
        }

        &--blue {
            border-color: @arrow-color-blue;
        }

        &--deep-blue {
            color: @arrow-color-deep-blue;
        }

        &--green {
            border-color: @arrow-color-green;
        }

        &--yellow {
            border-color: @arrow-color-yellow;
        }
    }
}
