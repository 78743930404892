.cportal-page {
    &__pulse-header {
        padding-right: .25rem;
    }

    &__pulse-spinner {
        display: inline-block;
        margin-top: 0.2rem;
        margin-right: 0.5rem;
    }

    &__header-container {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
    }

    &__status-icon-and-text {
        display: flex;
        align-items: center;
    }

    &__pulse-status {
        margin-right: 0.5rem;
    }

    &__alert {
        margin-bottom: 1.4rem;
    }

    &__card {
        display: block;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__controller-title-text {
        text-transform: uppercase;
    }

    &__registered-title-text {
        align-self: center;
        color: var(--cds-alias-status-disabled);
        font-size: .7rem;
    }

    &__body {
        display: flex;
        padding: .625rem 0;
    }

    &__cards {
        display: flex;
    }

    & &__cloud-services-card {
        width: 340px;
        margin-right: 24px;
    }

    & &__cloud-services-card-header {
        display: flex;
    }

    & &__cloud-services-title-text {
        margin-left: 6px;
        text-transform: uppercase;
    }

    & &__controller-card {
        width: 480px;
    }

    & &__portal-url {
        margin-bottom: 18px;
    }

    .avi-tooltip-icon__icon[status='default'] {
        color: var(--cds-alias-status-disabled);
    }

    &__controller-edit {
        flex-grow: 1;
        padding-right: 0.5rem;
    }

    &__register-btn-warning[status='danger'][action*='flat'] {
        --color: var(--cds-alias-status-danger);
    }
}
