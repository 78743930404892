@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';

// styling of rectangular areas and popover showed over it
// should match analytics colors of the same metrics
@c-rtt-color: @aviGreen1;
@s-rtt-color: @aviBlue1;
@app-resp-color: @aviYellow1;
@data-tr-color: @aviPurple1;
@waf-tt-color: #49A1C1;
@total-color: @aviGrey2; //l4 only when contributors don't sum up to total

log-timeline {
    display: block;
    position: relative;

    & > div.wrap {
        display: flex;
        position: relative;
        min-width: 4px;
        height: 18px;
        overflow: hidden;
        border-radius: 5px;

        & > div {
            display: inline-block;
            min-width: 1px;
            height: 100%;
            margin: 0;
            padding: 0
        }
    }

    & > div.duration {
        position: absolute;
        top: 3px;
        height: 1em;
        padding-left: .3em;
        font-size: small;
        line-height: 1em;
    }

    .c-rtt {
        background-color: @c-rtt-color;
    }

    .s-rtt {
        background-color: @s-rtt-color;
    }

    .app-resp {
        background-color: @app-resp-color;
    }

    .data-tr {
        background-color: @data-tr-color;
    }

    .total {
        background-color: @total-color;
    }

    .waf-tt {
        background-color: @waf-tt-color;
    }

    svg.log-timeline-request-chart {
        display: block;
    }
}

.log-timeline-popover {
    position: absolute;
    z-index: 1000;
    width: 232px;
    padding: 5px;
    border: 1px solid @aviGrey2;
    border-radius: @aviBorderRadius;
    background: @aviWhite;

    table {
        width: 100%;
        table-layout: fixed;

        td {
            &.name {
                width: 50%;
                .span-mixin() {//color brick
                    display: inline-block;
                    width: .5em;
                    height: .5em;
                    margin: .35em .35em 0 0;
                }

                &.name.c-rtt span {
                    .span-mixin();
                    background: @c-rtt-color;
                }

                &.name.s-rtt span {
                    .span-mixin();
                    background: @s-rtt-color;
                }

                &.name.app-resp span {
                    .span-mixin();
                    background: @app-resp-color;
                }

                &.name.data-tr span {
                    .span-mixin();
                    background: @data-tr-color;
                }

                &.name.total span {
                    .span-mixin();
                    background: @total-color;
                }

                &.name.waf-tt span {
                    .span-mixin;
                    background: @waf-tt-color;
                }
            }

            &.val {
                padding-left: .6em;
            }
        }

        tr {
            &.t_border td {
                padding-top: 3px;
                border-top: 1px solid @aviGrey2;
            }

            &.b_border td {
                padding-bottom: 3px;
                border-bottom: 1px solid @aviGrey2;
            }
        }
    }

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -7px;
        left: 167px;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #CCC;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -6px;
        left: 168px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #FFF;
        border-left: 6px solid transparent;
    }
}
