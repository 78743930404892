*[infinite-scroll] {
    display: block;
    position: relative;
    line-height: normal;

    & > div[avi-loader] {
        position: absolute;
        right: 0;
        bottom: 1em;
        left: 0;
        margin: auto;
    }

    & > div[ng-transclude] {
        display: block;
        position: relative;
        height: 100%;
        overflow: hidden;
    }
}
