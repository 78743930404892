.ipam-dns-aws-credentials-dialog {
    & &__fieldset-header {
        margin-top: 0;
    }

    .aws-ipam-credentials-clarity-overrides();
}

.aws-ipam-credentials-clarity-overrides() {
    .clr-wrapper {
        .clr-radio-wrapper {
            input[type=radio]:disabled {
                opacity: 1;
            }
        }
    }

    h6:not([cds-text]) {
        margin: 1.2rem 0;
        color: var(--cds-global-color-blue-700);
    }
}
