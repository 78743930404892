.avi-radio-container {
    margin-top: 1.2rem;

    &--no-margin-top {
        margin-top: 0;
    }

    /**
     * Clarity override. Removes the margin-top from the inner element and adds it to the wrapper
     * component.
     */
    & &__clr-radio-container {
        margin-top: 0;
    }
}
