.case-comments {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    &__user-icon {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid var(--cds-alias-status-info-shade);
        border-radius: 100%;
    }

    &__comment-header {
        display: flex;
        align-items: center;
    }

    &__created-date {
        margin-left: 10px;
    }

    &__comment-body {
        margin-top: 10px;
        word-break: break-all;
        white-space: pre-wrap;
    }

    &__no-case-comments {
        margin-top: 20px;
        text-align: center;
    }
}
