/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

@import (reference) '~ajs/less/avistrap.less';

.vrf-context-setter {
    display: block;
    position: relative;

    [avi-loader] {
        .absolutely-centered();
    }
}
