.inner-text {
    display: inline-block;
    position: relative;

    input[type=text],
    input[type=password],
    input[type=number],
    dropdown,
    collection-dropdown {
        width: 100%;
        padding-right: 55px;
    }

    .add-on {
        .border-radiuses(@aviBorderRadius,@aviBorderRadius,0,0);
        display: inline-block;
        position: absolute;
        top: 1px;
        right: 1px;
        height: 33px;
        padding: 0 15px;
        float: left;
        border-left: 1px solid @borderGray;
        background-color: @aviBackgroundColor;
        line-height: 33px;
    }
}
