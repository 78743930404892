.app-about-modal {
    &__row {
        display: flex;
        align-items: flex-start;

        &:not(:first-child) {
            margin-top: 0.5rem;
        }
    }

    &__control {
        flex: 1;
        min-width: 0;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    &__copyright {
        font-size: x-small;
    }
}
