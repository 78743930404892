.alb-login {
    &__forgot-password {
        margin-top: 0.6rem;
        font-size: 0.7rem;
        text-align: right;
    }

    &__input-container {
        margin: .3rem 0 .9rem;
    }
}
