@import '~ajs/less/themes/default-theme/clr-variables.less';

.avi-data-grid {
    box-sizing: border-box;
    margin-bottom: 0.3rem;

    // override Clarity data grid style to hide the placeholder image
    // it's not configurable (01/11/2021) according to: https://github.com/vmware/clarity/issues/580
    & clr-datagrid .datagrid-placeholder-image {
        display: none;
    }

    & clr-datagrid .datagrid {
        flex: revert;
        margin-top: 0;
    }

    // override Clarity styles to avoid unexpected large z-index
    & clr-datagrid {
        & .datagrid-row-sticky,
        & .datagrid-row .datagrid-row-sticky,
        & .datagrid-header {
            z-index: auto;
        }
    }

    // override Clarity styles to fix that the grid height doesn't shrink as the number of items decreases
    // values from Clarity are - display: flex; flex-flow: column nowrap;
    & clr-datagrid.datagrid-host {
        display: initial;
    }

    &__footer {
        min-height: 36px;
    }

    & &__action-bar {
        margin-top: 0;
        margin-bottom: 0.3rem;

        &-search-container {
            display: flex;
            position: relative;
            justify-content: flex-end;
        }

        &-search-icon {
            cursor: pointer;
        }

        &-search-input {
            width: 100%;

            & input[type=search] {
                font-family: @font-family;
            }
        }

        &-search-clear-icon {
            --color: var(--clr-btn-primary-bg-color);
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
        }

        &-left-actions {
            display: flex;
        }
    }

    .clr-checkbox-wrapper .clr-control-label {
        display: block;
    }

    &__btn-group {
        &:not(:first-child) {
            margin-left: 5px;
        }
    }

    &__row {
        .datagrid-select {
            .datagrid-select-checkbox-overrides();
        }

        .datagrid-cell {
            align-self: center;
        }
    }

    &__multipleaction-button {
        &:not(:first-child) {
            margin-left: 5px;
        }
    }

    &__singleactions {
        display: flex;
        justify-content: center;
    }

    &__single-action-icon-container {
        background: none;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__icon {
        --color: var(--cds-global-color-blue-700);
        cursor: pointer;

        &--deactivated {
            --color: var(--cds-global-color-gray-600);
            cursor: not-allowed;
        }

        &--movable {
            cursor: move;
            pointer-events: none;
        }
    }

    &__field-header-icon {
        margin-right: 5px;
    }

    &__expanded-content {
        border-top: 1px solid var(--clr-table-border-color);
    }

    &__cell-transform {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__pagination {
        // The clarity pagination component automatically adds clr-icon icons. These clr-icons have
        // the rule "all: initial", so that nothing is inherited, including cursor styles. The
        // following rule is for adding styles to those icons.
        button:not([disabled]) {
            clr-icon {
                cursor: pointer;
            }
        }
    }

    &__singleaction-cell {
        &--movable {
            cursor: move;
        }
    }

    &__singleactions-list {
        border: 1px solid var(--clr-table-border-color);
        background-color: var(--clr-table-bgcolor);

        &--item {
            padding: 6px 24px;

            &:hover {
                background-color: var(--clr-dropdown-bg-hover-color);
            }
        }
    }

    .avi-data-grid-expanded-content-clarity-overrides();

    &__field-label-with-tooltip {
        .avi-data-grid-field-label-with-tooltip-overrides();
    }
}

.avi-data-grid-column-tooltip {
    &__panel {
        width: 12rem;
        border: var(--clr-table-borderwidth) solid var(--clr-table-border-color);
        border-radius: var(--clr-table-border-radius);
        background-color: var(--clr-table-bgcolor);
        box-shadow: 0 0 2px 0 var(--clr-table-border-color);
    }

    &__content {
        margin: 0.9rem 0.9rem;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.6rem;
    }
}

.datagrid-select-checkbox-overrides() {
    .clr-control-label {
        min-height: 1.2rem;
        margin-top: 0;
        padding-left: 1.1rem;
    }
}

// Clarity override for column-based-expanded-content
.avi-data-grid-expanded-content-clarity-overrides() {
    & clr-datagrid {
        .datagrid-row-flex .datagrid-row-detail.avi-data-grid__column-based-expanded-content-row {
            display: block;
            padding: 0;

            .datagrid-cell {
                padding: 0.55rem 0.6rem 0.55rem;
            }
        }
    }
}

.avi-data-grid-field-label-with-tooltip-overrides() {
    .avi-label-with-tooltip__label {
        color: inherit;
        font: inherit;
    }
}

.avi-data-grid-cdk-drag-mixin() {
    .cdk-drop-list-dragging {
        .cdk-drag {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        clr-dg-row:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }

    .cdk-drag-animating {
        transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
    }

    .cdk-drag-preview {
        opacity: 0;
    }
}

.avi-data-grid-cdk-drag-mixin();
