.attachments-selection {
    &__grid {
        margin-top: 10px;
    }

    &__grid-container {
        width: 100%;
    }

    &__spinner {
        text-align: center;
    }
}
