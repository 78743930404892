@import (reference) '~ajs/less/layout/grid-container.less';

.match-and-action-display-wrapper {
    width: 100%;
    color: var(--cds-global-color-black);

    .grid-container-full();

    &__section {
        .grid-container-full__cell-half();

        &--match {
            border-right: 1px solid var(--cds-global-color-gray-400);
        }

        &-content {
            margin-left: 7px;
        }
    }

    &__action-content {
        margin-top: 5px;
        margin-left: 7px;
    }
}
