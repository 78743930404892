.prechecks-list-expander {
    margin-top: 5px;

    &__accordian {
        .prechecks-list-expander-clarity-overrides();
    }
}

// To override clarity styles for accordian.
.prechecks-list-expander-clarity-overrides() {
    & .clr-accordion-inner-content {
        padding: 10px !important;
    }

    .clr-accordion-header-button {
        &:focus {
            outline: none;
        }
    }
}
