.error-page-preview {
    margin: 0 10px;
    padding: 10px 0;
    border-top: 1px solid var(--cds-alias-object-interaction-color);
    border-bottom: 1px solid var(--cds-alias-object-interaction-color);
    color: var(--cds-global-color-white);

    .error-page-preview-clarity-overrides();

    &__item {
        display: flex;
    }

    &__item-value {
        color: var(--cds-global-color-white);
        line-height: 24px;
    }
}

// To override clarity styles for header tags.
.error-page-preview-clarity-overrides() {
    h4:not([cds-text]), h6:not([cds-text]) {
        flex-grow: 1;
        color: var(--cds-global-color-white);
    }

    h4:not([cds-text]) {
        margin-bottom: 25px;
    }
}
