@import (reference) '../../vs-logs-signpost.component.less';

.vs-logs-signpost-custom-groups {
    &__loader {
        overflow: hidden;
        text-align: center;
    }

    &__name-column {
        display: block;
        max-width: 7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__grids-section {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
    }

    &__data-grid {
        &:not(:last-child) {
            display: block;
            margin-bottom: 1.2rem;
        }
    }

    &__empty-field {
        min-width: @signpost-content-min-width;
    }
}
