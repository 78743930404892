.gslb-subdomains-list-page {
    &__menu {
        display: flex;
        align-items: center;
    }

    &__create-btn {
        margin-right: 1rem;
    }

    .gslb-subdomains-list-page-clarity-overrides();

    &__tree-view-container {
        margin-top: 1rem;
    }

    &__tree-header {
        display: flex;
        margin-bottom: 0.5rem;
    }
}

.gslb-subdomains-list-page-clarity-overrides() {
    .gslb-subdomains-list-page__view-toggle-btn {
        .radio.btn {
            .clr-radio-wrapper {
                label {
                    width: auto;

                    .avi-label-with-tooltip__label {
                        color: var(--clr-btn-default-color);
                        font-weight: var(--cds-global-typography-font-weight-semibold);
                    }
                }
            }

            input[type=radio]:checked + label {
                .avi-label-with-tooltip__label {
                    color: inherit;
                }
            }
        }
    }

    // Align headers based on tree nodes placement.
    h6:not([cds-text]).gslb-subdomains-list-page__tree-header-label {
        width: 13.3rem;
        margin-right: 3rem;
        color: var(--cds-global-color-blue-700);
    }
}
