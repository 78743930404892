@import (reference) '../vs-log-filter/vs-log-filter.directive.less';

.vs-log-filter-range {
    color: @link-text-color;
    font-weight: @link-font-weight;

    &:hover {
        text-decoration: underline @link-underline-color 1px;
        text-underline-offset: 2px;
        cursor: pointer;
    }
}
