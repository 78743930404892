@import (reference) '~ajs/less/themes/default-theme/colors.less';

.nsm-log {
    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 50px;
        background: @expander-button-color;
        color: white;
        cursor: pointer;

        &:hover {
            color: @aviBrandColor;
        }
    }
}
