.avi-collection-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__dropdown {
        flex: 1;
        min-width: 0;
    }

    &__menu {
        flex: 0 0 auto;
        padding-left: 5px;
    }

    &__vertical-ellipsis {
        --color: var(--cds-global-color-blue-700);
    }

    &__spinner {
        margin-left: 5px;
    }
}
