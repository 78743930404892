@import (reference) '~ajs/less/clr-mixins.less';

.password-change {
    .clr-login-page-overrides();

    &__alert {
        margin-bottom: 1.2rem;
    }

    & &__input-container {
        margin: .3rem 0 .9rem;
    }
}
