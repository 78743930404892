@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/layout.less';

upload-status {
    .progress {
        width: 85%;
        height: 8px;
        padding: 1px;
        border: 1px solid @aviGreySpecial;
        border-radius: @aviBorderRadius;
    }

    .progress-text {
        width: 10%;
        line-height: 19px;
    }

    .bar {
        height: 8px;
        transition: width 1s;
        border-radius: @aviBorderRadius;
        background-color: #B5DA2F;
    }
}
