.vs-log-cinematic-sub-section-dns-response {
    &__column-group {
        display: flex;
        margin: 1rem 0 1.5rem;
    }

    &__grid-expanded-content {
        display: flex;
        width: 100%;
        padding: 10px 0;
    }
}
