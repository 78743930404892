.virtual-service-faults {
    padding: 0 30px 20px;
    background-color: var(--cds-global-color-white);

    &__fault-header {
        font-weight: var(--clr-font-weight-bold);
    }

    &__fault-container {
        &:not(:first-child) {
            margin-top: 5px;
        }
    }
}
