.in-use-interface-list-expanded-content {
    &__spinner {
        display: flex;
        justify-content: center;
    }

    .in-use-interface-list-expanded-content__ip-address-grid {
        // Override Clarity's styling for nested datagrid rows.
        .clarity-overrides();
    }

    &__alert-group {
        display: block;
        margin-bottom: 10px;
    }
}

.clarity-overrides() {
    .datagrid-row-flex .datagrid-cell {
        padding-top: .55rem;
    }
}
