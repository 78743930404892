.preview-field {
    display: block;
    margin-bottom: 16px;

    &__content-container {
        display: block;
        height: 24px;
        margin-bottom: 5px;
        color: var(--cds-global-color-white);

        &--label {
            font-weight: 500;
        }
    }
}
