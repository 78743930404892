.avi-health-score-bar {
    display: flex;
    align-items: center;
    padding: 5px 0;

    &__label {
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        &--penalty {
            width: 90px;
            margin-left: 10px;
        }

        &--bold {
            font-weight: 600;
        }
    }

    &__value-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10px;
        border-radius: 15px;
    }

    &__value {
        width: 30px;
        font-size: 12px;
        line-height: 20px;
    }

    &__bar-vessel {
        width: 190px;
        height: 10px;
        overflow: hidden;
        border-radius: 15px;
        background-color: var(--cds-global-color-gray-300);

        div {
            height: 100%;
        }

        .high {
            background-color: var(--cds-alias-status-success);
        }

        .medium {
            background-color: var(--cds-alias-status-warning);
        }

        .low {
            background-color: var(--cds-global-color-tangerine-500);
        }

        .penalty {
            background-color: var(--cds-alias-status-danger);
        }
    }

    &__fill {
        width: calc(var(--healthScore) * 1%);
        margin-left: calc(var(--penaltyMargin) * 1%);
    }
}
