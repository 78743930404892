.timing-chart-card() {
    img {
        height: 35px;
        transition: width .25s ease-in-out;

        @media @ltablet {
            width: 32px;
        }

        @media @ptablet {
            width: 32px;
        }

        &.avi {
            width: 50px;

            @media @ltablet {
                width: 37px;
            }

            @media @ptablet {
                width: 37px;
            }
        }
    }

    i {
        color: @aviGreen0;
    }

    avi-value.total {
        span.timeValue {
            color: @aviGreen0;
            font-size: 1.714em;
        }
    }

    .header {
        div span {
            height: 30px;
            line-height: 24px;
        }

        abbr {
            p {
                @media @ldisplay {
                    display: none;
                }
            }

            &:before {
                @media @ldisplay {
                    content: attr(title);
                }
            }
        }
    }

    &.total {
        border-left: 1px solid @aviGreySpecial;
    }

    .timeValue {
        font-size: 1.286em;

        &.total {
            color: @aviGreen0;
            font-size: 1.714em;
        }
    }

    .timing-units {
        color: @aviGrey1;
        font-size: 0.857em;
        vertical-align: bottom;
    }
}

.end-to-end-timing {
    .small-shadow-bottom;
    position: relative;
    margin-bottom: 15px;
    padding: 10px;
    overflow: hidden;
    background: white;
    white-space: nowrap;

    .end-to-end-minimized-title {
        position: absolute;
        left: 10px;
        font-size: 15px;
    }

    .scale-down {
        transform: scale(.9);
    }

    .timing-chart-card();

    .endToEnd-Section, .endToEnd-Time, .serverLayer {
        display: inline-block;
        box-sizing: border-box;
        margin-right: -4px;
        font-size: 1em;
        text-align: center;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
        vertical-align: middle;

        p {
            margin: 0;
        }

        .security-warning {
            display: inline-block;
            margin-right: 20px;
        }

        .inline-load-balancer {
            display: inline-block;
        }
    }

    .endToEnd-Time {
        padding: @standardMargin 0;
    }

    // Differences in Layer 7
    &.layer-7 {
        .endToEnd-Time {
            padding: 15px 10px;
        }

        .serverLayer {
            width: 30%;
            padding: 5px 0;
            background-color: #F9F9F9;

            .endToEnd-Time {
                width: 50%;
            }

            .endToEnd-Section {
                width: 25%;
            }
        }
    }
    // Differences in Layer 4
    &.layer-4 {
        .endToEnd-Section, .endToEnd-Time {
            padding: 15px 10px;
        }
    }

    .endToEnd-navigationTiming {
        display: block;
        height: 65px;
        transition: height .25s ease-in-out;

        .stacked-end-to-end .bar {
            height: 30px;
            line-height: 30px;
        }
    }
    // Mini Override styles
    .minified {
        width: 100%;
        text-align: center;

        img {
            width: 24px;

            &.avi {
                width: 36px;
            }
        }

        .header {
            display: none;
        }

        .endToEnd-Time {
            margin: 0 5px;
            padding: 0;

            &.total {
                border: 0;
            }
        }

        .endToEnd-navigationTiming {
            height: 35px;

            .stacked-end-to-end .bar {
                height: 20px;
                line-height: 20px;
            }
        }

        .timingTitle {
            font-size: 0;
        }
    }
    // Log Override styles
    &.log {
        display: inline-block;
        width: 100%;
        margin: @standardMargin 0;
        padding: 0;
        overflow: visible;
        border: none;
        box-shadow: none;
        white-space: normal;

        .header {
            margin-bottom: @standardMargin/3;
        }

        .endToEnd-Section {
            width: 25%;
        }

        .endToEnd-Time {
            position: absolute;
            width: 10%;
            margin-left: -5%;
        }

        .serverLayer {
            position: relative;
            width: 25%;
            margin-left: 5%;
            padding: 0;
            background: none;
            box-shadow: none;

            .endToEnd-Time {
                width: 40%;
            }
        }

        .responseLayer {
            position: absolute;
            width: 100%;
            height: 25px;
            margin-top: 10px;
            border: 1px solid @aviBodyColor;
            border-right-width: 0;
            background: #FFF;
            color: @aviBodyColor;
            line-height: 25px;
            text-shadow: none;

            .triangle {
                position: absolute;
                top: -1px;
                left: -11px;
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 10px solid @aviBodyColor;
                border-bottom: 14px solid transparent;

                &:after {
                    content: '';
                    position: absolute;
                    top: -12px;
                    left: 2px;
                    width: 0;
                    height: 0;
                    border-top: 12px solid transparent;
                    border-right: 9px solid #FFF;
                    border-bottom: 13px solid transparent;
                }
            }

            &.good {
                border-color: @aviGreen0;
                color: @aviGreen0;

                .triangle {
                    border-right-color: @aviGreen0;
                }
            }

            &.bad {
                border-color: @aviRed0;
                color: @aviRed0;

                .triangle {
                    border-right-color: @aviRed0;
                }
            }
        }
        // Log Layer-4 Overrides
        &.layer-4 {
            .endToEnd-Section {
                width: 33.333%;
            }

            .serverLayer {
                width: 13.333%;

                .endToEnd-Section {
                    width: 100%;
                }
            }
        }
    }
    // E2E Overrides
    &.e2esummary {
        border: none;

        .timeValue, .timeValue.total {
            font-size: 1em;
        }

        &.layer-4 > .minified {
            .header {
                display: block;

                i {
                    display: none;
                }

                a {
                    cursor: pointer;

                    &.active {
                        color: @aviBodyColor;

                        &:hover {
                            text-decoration: none;
                            cursor: default;
                        }
                    }
                }
            }

            .endToEnd-Section, .endToEnd-Time, .serverLayer {
                width: 15%;
            }
        }

        &.layer-7 > .minified {
            .header {
                display: block;

                i {
                    display: none;
                }

                a {
                    cursor: pointer;

                    &.active {
                        color: @aviBodyColor;

                        &:hover {
                            cursor: default;
                        }
                    }
                }
            }

            .endToEnd-Time {
                width: 10%;
            }

            .serverLayer {
                .endToEnd-Time {
                    width: 45%;
                }
            }
        }
    }
}

div.end-to-end-timing-flex {
    .display-flex();
    margin: 30px 10px;

    & > div.t-card {
        flex: 1 1 0;
        .display-flex();
        flex-direction: column;

        .timing-chart-card();

        & > div.header {
            margin: 5px 0;
        }

    }

    &.total-only { //reordering cards
        & > div.t-card {
            &.lb-icon {
                order: 1
            }

            &.server-icon {
                order: 3
            }

            &.total-value {
                order: 2
            }
        }
    }

}

div[type="'pool'"] {
    .end-to-end-timing {
        &.layer-7 {
            .endToEnd-Time {
                width: 15%;

                &.total {
                    margin-left: 2%;
                }
            }

            .serverLayer {
                .endToEnd-Time {
                    width: 50%;
                }
            }

            .minified {
                h2 {
                    width: 15%;
                }
            }
        }
    }
}
