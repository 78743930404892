@import (reference) '~ajs/less/themes/default-theme/clr-variables.less';

.avi-auto-complete {
    & &__list-container {
        margin-top: 0 !important;
    }

    & &__input {
        width: 100% !important;
        font-family: @font-family;
    }

    .clr-input-group {
        width: 100% !important;

        &.clr-focus {
            width: 100% !important;
        }
    }
}

avi-auto-complete {
    // clrDataList component shows input in red color even before its touched ie with ng-untouched state.
    &.ng-untouched {
        .clr-error {
            .clr-input-group, .clr-input-group.clr-focus {
                border-bottom-color: var(--clr-forms-border-color);
                background: linear-gradient(to bottom, transparent 95%, var(--clr-forms-focused-color) 95%) no-repeat;
            }
        }
    }

    &.ng-touched {
        .clr-error {
            .clr-input-group, .clr-input-group.clr-focus {
                border-bottom-color: var(--clr-forms-invalid-color);
                background: linear-gradient(to bottom, transparent 95%, var(--clr-forms-invalid-color) 95%) no-repeat;
            }
        }
    }
}
