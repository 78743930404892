@import (reference) '~ajs/less/themes/default-theme/colors.less';

.expander-action-confirmation {
    &__buttons-container {
        display: flex;
        align-items: center;
    }

    .expander-action-confirmation__cancel {
        margin-right: 10px;
        color: @aviBlue1;
    }
}
