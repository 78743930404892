.ssl-rating-preview {
    margin: 0 25px;
    border-bottom: 1px solid var(--cds-alias-object-interaction-color);

    .ssl-rating-preview-clarity-overrides();

    &__item {
        margin: 15px 0;
    }

    &__item-value {
        margin-top: 5px;
        color: var(--cds-global-color-green-500);
        line-height: 1.25rem;
    }

    &__rating {
        &--good, &--high, &--excellent {
            color: var(--cds-alias-status-success);
        }

        &--average, &--medium {
            color: var(--cds-alias-status-warning-shade);
        }

        &--bad, &--low, &--very-bad, &--not-secure {
            color: var(--cds-alias-status-danger-shade);
        }

        &--no-data {
            color: var(--cds-alias-object-interaction-color-disabled);
        }
    }

    &__item-preview-defaults {
        padding-top: 20px;
        border-top: 1px solid var(--cds-alias-object-interaction-color);
    }
}

// To override clarity styles for header tags.
.ssl-rating-preview-clarity-overrides() {
    h4:not([cds-text]), h6:not([cds-text]) {
        color: var(--cds-global-color-white);
    }

    h4:not([cds-text]) {
        margin-bottom: 25px;
    }
}
