@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';

add-pool-servers {
    @margin: 1em;
    display: block;
    overflow: hidden;

    > div {
        box-sizing: border-box;
        width: 100%;

        .main {
            display: flex;
            flex-direction: row;

            > div {
                position: relative;
                flex: 1 1 auto;
                margin-right: @margin / 2;

                > input.servers-str {
                    width: 100%;
                }

                > div[avi-loader] {
                    .absolutelyCentered();
                    right: @margin / 2;
                    left: auto;
                }

                > div.resolutions {
                    display: flex;
                    flex-direction: row;
                    margin-top: @margin;
                    overflow: hidden;

                    > input {
                        flex: 1 1 auto;
                    }
                }
            }

            > button {
                flex: 0 1 100px;
            }
        }
    }
}
