@import (reference) '~ajs/less/themes/default-theme/colors.less';

.vs-address-grid-cell {
    &__state-icon {
        margin-right: 6px;
        cursor: pointer;

        &--up {
            fill: @aviHealthGood1;
        }

        &--pending {
            fill: @aviGrey1;
        }

        &--down {
            fill: @aviHealthDown1;
        }
    }

    &__items {
        display: flex;
        align-items: center;
    }

    &__item {
        margin-right: 15px;
    }
}
