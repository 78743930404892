.ip-reputation-change-request-card {
    display: block;
    height: 100%;

    &__header {
        text-transform: uppercase;
    }

    &__description {
        margin-bottom: 25px;
    }

    &__change-button {
        text-transform: uppercase;

        &-description {
            margin-top: 3px;
            font-size: 0.8em;
        }
    }
}
