@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/layout/layout.less';
@import (reference) '~ajs/less/fonts/avi';

div.avi-server-analytics {
    .cpu-stats, .memory-stats {
        position: relative;
        height: 135px;
        padding: 10px;
        background-color: @aviWhite;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),
            0 1px 1px 0 rgba(0, 0, 0, .14),
            0 2px 1px -1px rgba(0, 0, 0, .12);
    }

    .cpu-stats {
        margin-right: 10px;

        .server, .pool, .host {
            height: 78px;

            div.legend {
                position: absolute;
                bottom: 0;
                text-align: center;
            }
        }

        .server {
            div[percent-arc-chart] g.percent-arc.used path {
                fill: @aviGreen1;
            }
        }
    }

    .memory-stats {
        .server, .pool, .host {
            div.legend {
                margin-top: 5px;
                text-align: center;
            }
        }

        .server {
            div[percent-pile-chart] div.bar.full {
                background-color: @aviGreen1;
            }

        }
    }

    div.top-metrics-block {
        margin-bottom: 1em;
        text-align: right;

        & > div.stats-summary {
            display: inline-block;
            box-sizing: border-box;
            width: 50%;
            padding-right: 2em;
            text-align: center;

        }

        & > div.hs-monitor {
            display: inline-block;
            //border: 1px solid grey;
            box-sizing: border-box;
            width: 50%;
            max-height: 266px;
            text-align: center;
            .border-radius();

        }
    }

    collection-grid {
        // scrollable rows zone
        .body-table-wrapper {
            max-height: 16em;
            overflow-y: auto;
        }

        cell i.icon {
            &.icon-arrow-up {
                color: @aviHealthGood1;
            }

            &.icon-arrow-down {
                color: @aviHealthDown1;
            }

            &.icon-circle {
                .healthScoreColors();
            }
        }

        th.grid-field-status {
            width: 8%;
        }

        th.grid-field-success {
            width: 10%;
        }

        tr.expanded > td.rowactions > a > i[class='icon-plus']:before {
            &:extend(.icon-minus:before);
        }

        pre {
            white-space: pre-wrap;
        }

    }
}
