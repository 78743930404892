@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/theme.less';

.unit-info--virtualservice {
    position: relative;
    min-width: 500px;

    &__header {
        .display-flex;
        flex: 1 1;
        align-items: center;
        justify-content: flex-start;
    }

    &__label {
        position: relative;
        max-width: 700px;
        margin-right: 20px;
        cursor: pointer;
        .hide-text-overflow();

        &--popup-opened {
            .small-shadow-bottom;
            margin-left: -10px;
            padding: 10px;
            background-color: white;
        }
    }

    &__name {
        margin-left: 5px;
        white-space: nowrap;
    }

    &__scale-buttons {
        .display-flex;
        flex: 1 1;

        .avi-btn:not(:last-child) {
            margin-right: 3px;
        }
    }
}

.unit-info-table {
    margin: 0 10px;
    padding: 5px 0;

    &__tr, &__td {
        overflow: hidden;
        word-break: normal;
        white-space: normal;
    }

    &__tr {
        .display-flex;
        flex: 1 1;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__header {
        flex: 1 1;
        color: #AAA;
        font-weight: 500;
    }

    &__sub-header {
        margin-left: 5px;
    }

    &__td {
        flex: 1 1;
        align-self: flex-start;
    }

    &__td--max-height {
        max-height: 80px;
        overflow: auto;
    }

    &__td-item {
        .hide-text-overflow();
    }
}
