@import (reference) '.././vs-logs-signpost.component.less';

.vs-logs-signpost-default {
    max-width: @default-signpost-max-width;

    &__wrapper {
        display: block;
        max-height: @scrollable-content-max-height;
        overflow: auto;
    }
}
