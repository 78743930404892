@import (reference) '~ajs/less/themes/default-theme/colors.less';

.icap-nsx-defender-logs {
    padding: 10px;

    &__title {
        margin-bottom: 20px;
        color: @textMidGray;
        font-size: 18px;
        line-height: 1.2em;
    }

    &__field {
        margin-bottom: 15px;

        &-name {
            margin-bottom: 7px;
            color: @textMidGray;
            font-weight: 700;
        }
    }

    &__filter {
        color: @hyper-link;
    }
}
