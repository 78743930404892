@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.waf-log-match-element {
    display: inline-flex;
    margin-right: 5px;
    border: 1px solid @borderGray;
    font-size: @avi-baseline-px * .825;
    font-weight: 600;

    &__name {
        padding: 3px 10px;
        border-right: 1px solid @borderGray;
    }

    &__value {
        padding: 3px 10px;
    }
}
