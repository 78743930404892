.traffic-capture-preview {
    margin: 25px;
    padding-bottom: 25px;

    &__field {
        &--no-data {
            color: var(--cds-alias-object-interaction-color-disabled);
        }
    }
}
