@import (reference) '~ng/modules/cinematic/components/avi-cinematics-portal/avi-cinematics-portal.component.less';

.recommendation-dialog {
    position: fixed;
    z-index: @cinematicContentZIndex + 1;

    &__content-container {
        width: 100%;
    }

    &__loader {
        margin: 40px 0;
        text-align: center;
    }
}
