.servers-section {
    height: 376px;
    margin: -15px;
    padding: 10px 15px;
}

.pool-server {
    grid {
        table {
            tbody {
                tr.disabled {
                    td {
                        .inner {
                            background-color: #EEE;
                            color: #E2D5CE;
                        }
                    }
                }
            }
        }
    }

    .left-panel {
        display: none;
        position: absolute;
        z-index: 2;
        top: 0;
        right: -60%;
        box-sizing: border-box;
        width: 60%;
        height: 100%;
        overflow-y: auto;
        transition: right .25s ease-in-out;
        border-bottom: 1px solid @aviGreySpecial;
        border-left: 2px solid @aviGreySpecial;
        background: @aviBackgroundColor;
        background-color: @aviBackgroundColor;
        box-shadow: inset 1px 0 0 rgba(255, 255, 255, 1);

        a {
            .icon-remove {
                cursor: pointer;

                &:hover {
                    color: @aviRed0;
                }
            }
        }

        .avi-btn.close {
            border: none;
            box-shadow: none;

            &:hover {
                background: transparent;
                color: @aviRed0;
            }
        }

        .grid .actions-panel.floating {
            background-color: #F0F0F0;
        }
    }

    .networkChoice {
        width: 100%;
    }

    .left-panel.open {
        display: block;
        right: 0;
    }

    .labels {
        width: 100%;

        label {
            width: auto;
            float: left;
        }

        label.address {
            width: 36.2%;
        }

        label.port {
            width: 11.4%;
        }

        label.ratio {
            width: 10.5%;
        }
    }

    .control-group.addr-field {
        .controls {
            .address {
                margin-bottom: 7px;
            }

            .arrow {
                margin: 0 3px;
            }
        }
    }
}

.avi-pool-server-summary {
    grid {
        .c-grid-table-header-cell--field-name--port {
            width: 150px;
        }

        .c-grid-table-header-cell--field-name--ratio {
            width: 60px;
        }
    }

    .grid table td {
        line-height: normal;
    }
}
