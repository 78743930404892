.right-rail-vertical-expander {
    width: 100%;

    &__header {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 0 12px 0 24px;
        padding-right: 12px;
        overflow-x: hidden;
        background-color: var(--cds-global-color-gray-200);
        color: var(--cds-global-color-gray-700);

        &-leading-icon {
            margin-right: 5px;
        }

        &-text {
            flex-grow: 1;
            font-weight: var(--cds-global-typography-font-weight-bold);
            text-align: start;
        }

        &-expander {
            transition-duration: 0.2s;
        }

        &:hover {
            transition-duration: 0.3s;
            background-color: var(--cds-global-color-gray-400);
            cursor: pointer;
        }
    }

    &__body {
        width: 100%;
        transition-duration: 0.2s;
    }
}
