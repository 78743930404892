@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.waf-log-entry {
    display: block;
    margin-bottom: 2px;
    border: 1px solid @borderGray;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-header {
            color: @textDarkGray;
            font-size: @avi-baseline-px * .625;
            font-weight: 600;
            text-transform: uppercase;
        }

        &--border-top {
            border-top: 1px solid @borderGray;
        }

        &--no-border-bottom {
            border-bottom: 0;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
        font-size: @avi-baseline-px;
    }

    &__content {
        flex: 1;
    }

    &__expander {
        display: block;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
