/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

@import (reference) '~ajs/less/themes/default-theme/pixels.less';

.avi-form-label-tooltip {
    font-size: @avi-baseline-px * 0.8;
    word-break: break-word;
    white-space: normal;

    &__desc {
        &:not(:last-child) {
            padding-bottom: 5px;
            border-bottom: 1px solid @aviGrey2;
        }
    }

    &__header {
        margin: 8px 0 5px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__header,
    &__value {
        padding: 0 10px;
    }
}
