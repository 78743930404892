@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/layout/layout.less';

.log-filter-number-popover {
    display: block;
    padding: 5px;
    border: 1px solid @aviGrey2;
    background-color: @aviWhite;
    text-align: right;
    .border-radius();


    .log-filter-number-popover_filter {
        display: block;
        padding: 5px;

        &:hover {
            text-decoration: underline;
        }

        &:nth-child(n + 2) {
            border-top: 1px solid @aviGrey2;
        }
    }
}
