.system-settings-client-management-access-config {
    & &__no-margin-top {
        margin-top: 0;
    }

    &__address-container {
        margin-left: 15px;
    }

    &__cds-divider {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__address-header {
        display: flex;
        justify-content: space-between;
    }

    &__remove-icon {
        margin-right: 5px;
        cursor: pointer;
    }

    &__add-item-button {
        margin-top: 10px;
        margin-left: 15px;
    }
}
