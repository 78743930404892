.avi-slider {
    &__container {
        display: flex;
        align-items: center;
    }

    & &__input-container {
        box-sizing: border-box;
        width: 30%;
        min-height: 24px;
        border-bottom: 1px solid var(--cds-global-color-gray-500);
        font-size: var(--cds-global-typography-font-size-4);

        &:focus-within {
            border-bottom-width: 2px;
            border-bottom-color: var(--clr-forms-focused-color);
        }

        avi-slider.ng-dirty.ng-invalid & {
            border-bottom: 1px solid var(--cds-alias-status-danger);
        }
    }

    & &__input {
        width: 100%;
        height: 24px;
        padding: 0 .3rem 0 .3rem;
        border: none;
        background: none;
        font-size: 0.7rem;

        &:focus {
            outline: none;
        }

        &[disabled] {
            border: 0;
            opacity: 0.54;
            background: initial;
            cursor: not-allowed;
        }

        avi-slider.ng-dirty.ng-invalid & {
            color: var(--cds-alias-status-danger);
        }

        &::placeholder {
            opacity: 0.54;
            color: inherit;
        }
    }

    &__range-container {
        position: relative;
        top: 9px;
        width: 70%;
        padding-left: 10px;

        &--fullwidth {
            top: 0;
            width: 100%;
            padding-left: 0;
        }
    }

    & &__error-text {
        color: var(--clr-color-danger-700);
    }
}
