@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/layout/layout.less';

.client-insight-nav-timing-chart {
    @barMarkWidth: 3px;

    .timing-container, .timing-container-inner {
        .border-radius();
    }

    .timing-container {
        height: 31px;
        margin-top: -10px;
        // .timing-container-inner {
        //     border: 1px solid #fff;
        // }
        // border: 1px solid #ccc;
        line-height: 0;

        .pltAndDlt.bar {
            position: absolute;
            height: 20px;
        }
    }

    .bar-mark {
        position: absolute;
        z-index: 1;
        width: @barMarkWidth;
        margin-top: -16px;
        margin-left: -@barMarkWidth / 2;
        vertical-align: bottom;

        .border-radiuses(0, @aviBorderRadius, 0, 0);
    }

    .bar-mark.page_download_time {
        height: 36px;
        border-bottom-right-radius: 3px;
    }

    .bar-mark-placeholder {
        width: @barMarkWidth + 3px;
    }
}
