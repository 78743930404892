.vs-logs-search-bar-dropdown-option {
    &__operator {
        &-container {
            display: flex;
        }

        &-symbol {
            width: 10%;
        }
    }

    &__groupby {
        &-container {
            display: flex;
        }

        &-value {
            width: 33%;
        }
    }
}
