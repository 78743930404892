.avi-state-page {
    .clr-user-page-list-overrides();
}

.clr-user-page-list-overrides() {
    .avi-data-grid {
        &__btn-group {
            cds-button {
                margin-left: 5px;
            }
        }
    }
}
