gslb-pool-member-form {
    //.gslb-modals-mixin(); no need, used within gslbService modal

    div.gslb-pool-member-wrapper {
        position: relative;

        div.is-set-by-ip-selector {
            align-items: flex-end;
        }

        div.h-control.vs-selection {
            div.control-group.select-vs-ip {
                label {
                    position: relative;
                    margin-top: 10px;
                }
            }
        }

        & > div.trash-button {
            position: absolute;
            top: 10px;
            bottom: auto;
        }
    }
}
