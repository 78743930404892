@import (reference) '../layout/grid.less';
@import (reference) '../../../src/components/common/grid/grid.less';
@import (reference) '../themes/default-theme/theme.less';

// Margin Spacing
.space-right {
    margin-right: @standardMargin/3;
}

.space-left {
    margin-left: @standardMargin/3;
}

.space-bottom {
    margin-bottom: @standardMargin/3;
}

.space-top {
    margin-top: @standardMargin;
}

// Margin Top
.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

// Margin Bottom
.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb30 {
    margin-bottom: 30px;
}

// Margin Left
.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml15 {
    margin-left: 15px;
}

// Margin Right
.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr15 {
    margin-right: 15px;
}

// Padding Top
.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15 {
    padding-top: 15px;
}

// Padding Bottom
.pb0 {
    padding-bottom: 0;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15 {
    padding-bottom: 15px;
}

// Padding Left
.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl15 {
    padding-left: 15px;
}

// Padding Right
.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr15 {
    padding-right: 15px;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.m15 {
    margin: 15px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

//Gutter Replacement
.rg {
    padding-right: @gutter;
}

.lg {
    padding-left: @gutter;
}

.control-group .value {
    display: block;
    margin-top: 7px;
    font-weight: bold;
}

.nowrap {
    white-space: nowrap;
}

// Generic modal styles
.avi-modal.avi-create-pool, .avi-modal.avi-virtualservice-create {
    .input-prepend {
        input[type=text], input[type=password], input[type=number] {
            margin-left: -3px;
        }
    }

    .flex-input-append .add-on {
        color: @aviBlue0;
    }

    .input-append .add-on {
        box-sizing: border-box;
        padding-right: 10px;
        padding-left: 10px;
        background-color: @aviBackgroundColor;
        color: @aviBlue0;
    }

    .input-append {
        .oper_status {
            display: inline-block;
            z-index: 5;
            border: 1px solid #A2B563;
            background-color: #AECC48;
            color: #FFF;
            font-family: sans-serif;
            text-align: center;
            .border-radius();
        }

        .oper_status.up {
            border: 1px solid #A2B563;
            background-color: #AECC48;
        }

        .oper_status.down {
            border: 1px solid #999;
            background-color: #999;
        }
    }
}

//Form inputs
input[type=number] {
    appearance: textfield;
}

// Hide spinners for input number field
::-webkit-inner-spin-button {
    appearance: none;
}

::-webkit-outer-spin-button {
    appearance: none;
}

// Represents column, used in modal
.col {
    width: 48.5%;
}

.inline-children {
    & > * {
        display: inline-block;
        box-sizing: border-box;
    }
}

div.inline-children-2, div.inline-children-3, div.inline-children-4 {
    & > * {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;

        &:not(:first-child) {
            margin-left: @colGap / 2;
        }

        &:not(:last-child) {
            margin-right: @colGap / 2;
        }
    }
}

.inline-children-builder(@columns, @horMargin: 1) {
    & > * {
        width: unit(round((100 - @horMargin * (@columns*2 - 2))/@columns, 2), %);
    }
}

div.inline-children-2 {
    .inline-children-builder(2)
}

div.inline-children-3 {
    .inline-children-builder(3)
}

div.inline-children-4 {
    .inline-children-builder(4)
}

div.required-input {
    > label {
        width: 100%;

        > input {
            width: 100%;
        }
    }
}

.text-overflow {
    .hide-text-overflow();
}

section {
    margin: @modalSectionVerticalMargin;
}

section.header-section {
    .small-shadow-bottom;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    margin: 25px 30px;
    padding: 25px 50px;
    overflow: hidden;
    background: white;
    white-space: nowrap;

    &.lg-txt {
        font-size: 20px;
        font-weight: 200;
    }

    .header-left, .header-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.align-flex-start {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .header-left {
        min-height: 50px;
    }

    .header-right {
        justify-content: flex-end;
    }
}

section.box {
    .small-shadow-bottom;
    position: relative;
    margin: 25px 30px;
    padding: 25px 50px;
    background: white;

    .lg-txt {
        font-size: 20px;
        font-weight: 200;
    }
}

i {
    font-style: italic;
}
