@import (reference) '~ajs/less/themes/default-theme/colors.less';

.license-tier-switch-modal {
    color: @aviBlack;

    &__select-area {
        margin-top: 1rem;
    }

    &__card-alert-msg {
        margin-top: 0.6rem;
    }

    &__card-alert-msg-text {
        color: @textMidGray;
    }
}
