.gradient-loader {
    display: block;
    animation: gradientLoader 1.8s linear infinite;
    background: linear-gradient(to right, #E6E9E8, #FFF, #E6E9E8);
    background-size: 300%;

    @keyframes gradientLoader {
        0% { background-position: 300% }
        100% { background-position: 0% }
    }
}

