@import (reference) '~ajs/less/themes/default-theme/colors.less';

*[percent-arc-chart] {
    height: 100%;

    & > svg g.percent-arc {
        &.free > path {
            fill: @aviGrey3;
        }

        &.used > path {
            fill: @aviBlue1;
        }
    }
}

server-percent-arc-chart {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
