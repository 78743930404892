.auth-mapping-rules-grid {
    &__action {
        display: flex;
    }

    &__action-label {
        margin-right: 10px;
        font-weight: 500;
    }
}
