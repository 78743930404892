@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/themes/default-theme/pixels.less';

@create-button-color: #85ba65;
@row-hover-color: #f3f4f8;
@header-font-size: 10px;

.ordered-grid {
    display: block;
    position: relative;
    border: 1px solid @aviGrey0;
    border-bottom: 0;

    a:hover {
        text-decoration: none;
    }

    &__table-wrapper {
        display: block;
        position: relative;
        padding: 0;
        background: white;
    }

    &__cell {
        display: flex;
        box-sizing: border-box;
        flex-grow: 1;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--header {
            position: relative;
            padding: 0;

            &__title {
                .display-flex;
                .justify-flex-start;
                .hide-text-overflow;
                padding: 5px;
            }
        }

        &--body {
            position: relative;
            box-sizing: border-box;
            height: @avi-baseline-px * 2.5;
            margin: 0;
            padding: 5px;
            font-size: @avi-baseline-px * 0.85;
            font-weight: 200;
        }

        &--empty-body {
            justify-content: center;
        }

        &.expanded {
            height: auto;
            max-height: 500px;
            transition: max-height 0.5s ease-in;
        }

        &--drag-and-drop {
            flex: 0 0 30px;
        }

        &--rowactions {
            flex: 0 0 100px;
            margin-right: 10px;
            .display-flex;
            .justify-flex-end;

            i.icon {
                color: @aviGrey0;

                &:hover {
                    color: black;
                }
            }

            i.icon-dot-3 {
                font-size: 18px;
                vertical-align: initial;
            }
        }
    }

    &__header {
        display: flex;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        flex-grow: 1;
        height: 23px;
        overflow: hidden;
        border-bottom: 1px solid @aviGrey0;
        background-color: white;
        color: @aviGrey0;
        font-size: @header-font-size;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
    }

    &__body {
        display: block;
        position: relative;
        color: #4A4A4A;

        &__row {
            display: flex;
            position: relative;
            box-sizing: border-box;
            align-items: flex-start;
            height: 52px;
            padding: 5px 0;
            border-bottom: 1px solid @aviGrey0;
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            &__drag-placeholder {
                background-color: @row-hover-color;
            }

            &--expanded {
                position: relative;
                z-index: 200;
                background: white;

                &:hover {
                    background-color: initial;
                }

                &-template {
                    display: flex;
                    justify-content: flex-start;
                    height: auto;
                    padding: 10px;
                    border-top: 0;
                    box-shadow: 0 4px 20px -5px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }

    &__resizer {
        position: absolute;
        z-index: 1;
        top: 10%;
        right: 0;
        width: 5px;
        height: 80%;
        border-right: 1px solid @borderGray;
        cursor: col-resize;
    }
}

.create-between {
    display: flex;
    position: absolute;
    z-index: 1000;
    top: -6px;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    padding: 5px 0;
    color: @aviBlue1;
    cursor: pointer;

    &:hover {
        .create-between__line {
            .display-flex;
        }
    }

    &__line {
        display: none;
        width: 100%;
        height: 2px;
        background-color: @aviBlue1;
    }

    &__text {
        display: flex;
        padding: 5px;
        border: 1px solid @aviBlue1;
        border-radius: 15px;
        background: white;
    }

    &__icon {
        margin-right: 5px;
    }

    &--bottom {
        top: initial;
        bottom: -5px;
    }
}

.ordered-grid__cell--enable {
    flex: 0 0 60px;
}

.ordered-grid__cell--index {
    flex: 0 0 40px;
    .justify-flex-end;
}

div.avi-menu-list.ordered-grid-menu {
    width: 180px;
    padding: 5px;
    border: 0;
    border-radius: 0;
    background-color: @aviGrey1;

    li.avi-menu-list-item {
        height: 28px;
        padding: 0 10px;
        background-color: @aviGrey1;
        color: white;
        .display-flex;
        .justify-flex-start;

        > .icon-wrapper {
            width: 20px;
            margin-right: 5px;
        }

        &:hover {
            background-color: @aviGrey2;
        }
    }

    .create-button {
        .display-flex;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: @create-button-color;

        .plus {
            color: white;
            font-size: 20px;
            font-weight: 100;
            line-height: 20px;
        }
    }
}

.circle-button {
    .display-flex;
    width: 27px;
    height: 27px;
    margin: 3px 0;
    border-radius: 50%;

    .plus {
        color: white;
        font-size: 30px;
        font-weight: 100;
        line-height: 30px;
    }
}
