@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';
@import (reference) '~ajs/less/layout/layout.less';

.popoverMixIn() {
    position: fixed;
    box-sizing: border-box;
    transition: opacity .4s ease;
}

//Popover.js factory
.aviPopover {
    .popoverMixIn();
    z-index: 100;
    background-color: @aviWhite;
    color: @aviBodyColor;
    .border-radius();
}

.aviPopoverCarat {
    .popoverMixIn();
    z-index: 102;
    width: 0;
    height: 0;

    //triangle arrow mixIn
    .boxArrow() {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        border: 10px solid transparent;
    }

    &:before, &:after {
        .boxArrow();
    }

    &.top {
        &:before {
            top: 1px;
            border-top-color: @aviGreySpecial;
        }

        &:after {
            border-top-color: @aviWhite;
        }
    }

    &.bottom {
        &:before {
            top: -1px;
            border-bottom-color: @aviGreySpecial;
        }

        &:after {
            border-bottom-color: @aviWhite;
        }
    }

    &.right {
        &:before {
            left: -1px;
            border-right-color: @aviGreySpecial;
        }

        &:after {
            border-right-color: @aviWhite;
        }
    }

    &.left {
        &:before {
            left: 1px;
            border-left-color: @aviGrey1;
        }

        &:after {
            border-left-color: @aviWhite;
        }
    }
}
