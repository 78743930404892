@import (reference) '~ajs/less/avistrap.less';
@import (reference) '~ajs/less/layout/flex.less';
@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

@width: 50px;
@small-width: 28px;
@circle-size: 22px;
@small-circle-size: 10px;
@enabled-color: #A1BF36;
@borderColor: #888;

.tri-switch {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    width: @width;
    padding: 2px;
    border-radius: 25px;
    background-color: @enabled-color;
    cursor: pointer;

    &--off {
        justify-content: flex-start;
        background-color: @aviGrey2;
    }

    &--gray {
        background-color: @aviGrey2;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__circle {
        width: @circle-size;
        height: @circle-size;
        border-radius: 50%;
        background-color: white;
    }

    &__indeterminate {
        width: 12px;
        height: 2px;
        margin-right: 7px;
        background-color: white;
    }

    &--small {
        width: @small-width;
        padding: 1px;
        border: 1px solid @borderColor;

        .tri-switch__circle {
            width: @small-circle-size;
            height: @small-circle-size;
        }

        .tri-switch__indeterminate {
            width: 7px;
            margin-right: 5px;
        }
    }
}
