.avi-menu {
    display: inline-block;
    position: relative;
}

.avi-menu-button {
    display: inline-block;
    position: relative;
}

.avi-menu-list {
    display: none;
    position: absolute;
    z-index: @aviSelectZindex;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 1px solid @aviGreySpecial;
    border-radius: @aviBorderRadius;
    background: @aviWhite;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
    color: @aviBlue0;
    line-height: 30px;
    list-style: none;

    &.open {
        display: block;
    }

    a {
        display: block;
        text-decoration: none;
    }
}

.avi-menu-list-item {
    display: block;
    position: relative;
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        background-color: @aviBackgroundColor;
        color: darken(@aviBlue0, 10%);
    }
}
