.configured-network-subnet-expander {
    margin: 10px 0;

    &__label {
        padding-right: 10px;
        text-align: right;
    }

    &__static-ip-pool {
        margin-bottom: 10px;
    }
}
