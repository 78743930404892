.match-adder {
    &__match-list-container {
        margin-top: 10px;

        & > * {
            display: block;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__fieldset {
        display: block;
        margin-top: 10px;
    }

    &__header {
        width: 100%;
    }

    &__header-text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--cds-alias-status-disabled);
    }
}
