.barchart-simple {
    width: 96%;
    height: 25px;
    margin: auto;
    border: 1px solid @aviGreen0;
    border-radius: 5px;

    div {
        width: 1%;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: @aviGreen3;
    }
}

.barchart-label {
    margin-left: 2%;
}
