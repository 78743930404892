@import (reference) '~ajs/less/layout/layout.less';

.root {
    &__app-level-alerts {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        // We need max-height so that multiple app level alerts are not stacked.
        max-height: @aviAppLevelAlertHeight;
        overflow: hidden;
    }
}
