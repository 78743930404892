.ip-reputation-db-list-page-card {
    display: block;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        text-transform: uppercase;
    }

    &__last-update {
        color: var(--clr-color-neutral-600);
    }
}
