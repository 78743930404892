@import (reference) '~ajs/less/themes/default-theme/colors.less';

dos-rate-limiter, dos-rate-limiter-vs {
    display: block;

    & > div {
        & > div.list {//ng-repeated
            padding: .5em 0 1em 0;
            border-bottom: 1px solid @aviGrey3;

            & > div.limiter-settings {
                & > div {
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 0 .5em;

                    &.count {
                        width: 20%;
                        padding-left: 0;
                    }

                    &.period {
                        width: 20%;

                        div.inner-text {
                            display: block;
                        }
                    }

                    &.action {
                        width: 55%;
                    }

                    &.remove-btn {
                        width: 4%;
                        padding-right: 0;
                        text-align: center;
                        vertical-align: bottom;
                    }

                    &.action-details {
                        display: block;
                        padding: 0;

                        & > label {
                            display: inline-block;
                            box-sizing: border-box;
                            padding: 0 .5em;
                        }

                        &.redirect, &.local {
                            display: block;

                            label {
                                display: inline-block;
                                box-sizing: border-box;

                                &.status-code {
                                    width: 20%;
                                }
                            }
                        }

                        &.local {
                            padding-left: 40%;

                            label {
                                &.file {
                                    width: 80%;
                                    padding-right: 0;
                                }
                            }
                        }

                        &.redirect {
                            padding-left: 20%;

                            label {
                                &.status-code {
                                    padding: 0 .5em;
                                }

                                &.protocol {
                                    width: 40%;
                                    padding-top: 20px;
                                    text-align: center;
                                }

                                &.port {
                                    width: 20%;
                                }

                                &.keep-query {
                                    width: 20%;
                                    padding-top: 20px;
                                    text-align: center;
                                }

                                &.host {
                                    width: 25%;
                                    padding: 0 .5em;
                                }

                                &.path {
                                    width: 75%;
                                    padding: 0 .5em;
                                }
                            }
                        }
                    }
                }
            }
        }

        & > div.type-selection {
            & > dropdown {
                width: 50%;
            }
        }
    }

    dropdown, input {
        display: inline-block;
        width: 100%;
    }
}

dos-rate-limiter-vs {
    & > div > div.list {
        & > div.limiter-settings > div {
            &.action {
                width: 60%;
                padding-right: 0;
            }
        }
    }
}
