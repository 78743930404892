.ipam-dns-aws-profile-config {
    & input[type=password] {
        border: none;
        background: none;
    }

    &__proxy-config {
        margin-top: 1.2rem;
    }

    &__loader {
        text-align: center;

        &--with-margin {
            margin: 1rem;
        }
    }

    & &__fieldset-header {
        margin-top: 0;
    }
}
