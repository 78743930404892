.license-card-usage {
    &__label {
        padding: 0.25rem 0;
        color: var(--cds-global-color-black);
        font-size: 13px;
        font-weight: var(--cds-global-typography-font-weight-semibold);
    }

    &__tooltip {
        vertical-align: bottom;
    }

    &__usage-count-color {
        color: var(--cds-global-color-blue-600);
    }

    &__reserve-count-color {
        color: var(--cds-global-color-blue-900);
    }

    &__max-count-color {
        color: var(--cds-global-color-black);
    }
}
