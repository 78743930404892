@import (reference) '~ajs/less/themes/default-theme/colors.less';
@import (reference) '~ajs/less/themes/default-theme/usefulMixins.less';

.user-card {
    &__wrapper {
        min-height: 105px;
        padding: 10px 0 0 10px;
    }

    &__item {
        padding-bottom: 10px;
        .hide-text-overflow();
    }

    &__my-account {
        margin-left: 10px;
        font-size: 15px;
        cursor: pointer;
    }

    &__last-login {
        padding-top: 10px;
        color: @textLiteGray;
    }

    &__username {
        font-size: 14px;
        font-weight: bold;
    }

    &__logo {
        padding: 0 5px;
        color: @aviBrandColor;
        font-size: 40px;
    }

    &:hover {
        background-color: @aviWhite;
        color: inherit;
        text-decoration: none;
    }
}
